import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { talentService, vendorService, venueService } from '../../../../services';
import { IUser } from '../../../../types';
import { PopupLayout } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { urlUtil } from '../../utils';
import * as Styled from './complete-profile.styled';

export interface ICompleteProfile {
  onCancel: () => void;
  user?: IUser;
  setIsCompleteProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompleteProfile = ({ onCancel, user, setIsCompleteProfile }: ICompleteProfile) => {
  const history = useHistory();

  const { data: dataVendor } = useQuery(
    APP_KEYS.QUERY_KEYS.GET_MY_VENDOR,
    () => vendorService.getMyVendor(),
    {
      enabled: user?.role === 'VENDOR'
    }
  );

  const { data: dataTalent } = useQuery(
    APP_KEYS.QUERY_KEYS.GET_MY_VENDOR,
    () => talentService.getMyTalent(),
    {
      enabled: user?.role === 'TALENT'
    }
  );

  const { data: dataVenue } = useQuery(
    APP_KEYS.QUERY_KEYS.GET_MY_VENDOR,
    () => venueService.getMyVenue(),
    {
      enabled: user?.role === 'VENUE'
    }
  );

  const data: { [key: string]: any } = {
    VENDOR: {
      id: dataVendor?.id,
      link: 'PUBLIC_VENDOR'
    },
    VENUE: {
      id: dataVenue?.id,
      link: 'PUBLIC_VENUE'
    },
    TALENT: {
      id: dataTalent?.id,
      link: 'PUBLIC_TALENT'
    },
    'EVENT PLANNER': {
      id: user?.owner_id,
      link: 'PUBLIC_EVENT_PLANER'
    }
  };

  const onSave = () => {
    // @ts-ignore
    const { id, link } = data[user?.role];

    setIsCompleteProfile(false);

    // @ts-ignore
    const a = `${urlUtil(APP_KEYS.ROUTER_KEYS[link])}?id=${id}`;

    history.push({
      pathname: a,
      state: { isCompleteProfile: false }
    });

    window.location.reload();
  };

  return (
    <PopupLayout
      onCancel={onCancel}
      onSave={onSave}
      type='button'
      title='Welcome To ESPO'
      saveText='go'
      cancelText='Later'
      styled={Styled.PopupLayoutStyled}
    >
      <Styled.Container>
        <Styled.Image src={Icons.profieIcon} alt='profie-icon' />

        <Styled.Title>Complete Your Profile</Styled.Title>
        <Styled.Text>
          We highly recommend completing your profile. Having a complete profile increases your
          chances of being discovered by others searching for individuals with your services.
        </Styled.Text>
      </Styled.Container>
    </PopupLayout>
  );
};
