import { getIn, useFormikContext } from 'formik';
import React, { MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Icons } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { IGetAllEventsParameter, IGetAllJobParameter } from '../../../../types';
import {
  Accordion,
  Drawer,
  Input,
  InputDropdownCroup,
  InputMatchedWords,
  Loader,
  NotFoundItem,
  Pagination,
  PopupLayout,
  Portal
} from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket } from '../../../common/hooks';
import { EventPlanerCard } from '../../../event-planer/component';
import { getAllEventPlaners } from '../../../event-planer/hooks';
import { EventCard } from '../../../event/component';
import { getAllEvents } from '../../../event/hooks';
import { getAllFavorites } from '../../../favorites/hooks';
import { JobCard } from '../../../job/component';
import { getAllJobs } from '../../../job/hooks';
import { TalentCard } from '../../../talent/component';
import { getAllTalent } from '../../../talent/hooks';
import { VendorCard } from '../../../vendor/component';
import { getAllVendors } from '../../../vendor/hooks';
import { VenueCard } from '../../../venue/component';
import { getAllVenues } from '../../../venue/hooks';
import {
  Location,
  filtersConst,
  filtersEvent,
  filtersJob,
  filtersTalent,
  filtersVendor,
  filtersVenues,
  initValueEvent,
  initValueJob,
  initValueTalent,
  initValueVendor,
  initValueVenue
} from '../../constants';
import { useHomeLayoutContext } from '../../hooks';
import { urlUtil } from '../../utils';
import { Tads } from '../tabs/tads';
import * as Styled from './component-layout.styled';

export interface IComponentLayout {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const ComponentLayout = ({ value, setValue }: IComponentLayout) => {
  const history = useHistory();

  const { values, setValues, resetForm } = useFormikContext<
    IGetAllEventsParameter | IGetAllJobParameter
  >();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);

  const [focused, setFocused] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [isFilters, setIsFilter] = useState<null | string>(null);

  const d = { ...values, page };

  const { isLoading, data: eventData } = getAllEvents(d, values, page, value);
  const { isLoading: isLoadingVendors, data: vendorData } = getAllVendors(d, values, page, value);
  const { isLoading: isLoadingTalents, data: talentData } = getAllTalent(d, values, page, value);
  const { isLoading: isLoadingVenues, data: venueData } = getAllVenues(d, values, page, value);
  const { isLoading: isLoadingJobs, data: jobsData } = getAllJobs(d, values, page, value);
  const { isLoading: isLoadingEventPlanners, data: eventPlannersData } = getAllEventPlaners(
    d,
    values,
    page,
    value
  );

  // @ts-ignore
  const filtersString = filtersConst[value];

  const Date: { [key: string]: any } = {
    events: {
      Component: EventCard,
      data: eventData?.events ?? [],
      count: eventData?.count ?? 0,
      props: { isStatus: true },
      filters: filtersEvent(values),
      link: 'PUBLIC_EVENT',
      role: 'Events',
      initValue: initValueEvent
    },
    vendors: {
      Component: VendorCard,
      data: vendorData?.vendors ?? [],
      count: vendorData?.count ?? 0,
      props: {},
      filters: filtersVendor(values),
      link: 'PUBLIC_VENDOR',
      role: 'Vendors',
      initValue: initValueVendor
    },
    talents: {
      Component: TalentCard,
      data: talentData?.talents ?? [],
      count: talentData?.count ?? 0,
      props: {},
      filters: filtersTalent(values),
      link: 'PUBLIC_TALENT',
      role: 'Talents',
      initValue: initValueTalent
    },
    venues: {
      Component: VenueCard,
      data: venueData?.venues ?? [],
      count: venueData?.count ?? 0,
      props: {},
      filters: filtersVenues(values),
      link: 'PUBLIC_VENUE',
      role: 'Venues',
      initValue: initValueVenue
    },
    jobs_posting: {
      Component: JobCard,
      data: jobsData?.jobs ?? [],
      count: jobsData?.count ?? [],
      props: { isStatus: false, isFavorite: true },
      filters: filtersJob(values),
      link: 'PUBLIC_JOB',
      role: 'Jobs',
      initValue: initValueJob
    },
    event_planners: {
      Component: EventPlanerCard,
      data: eventPlannersData?.event_planers ?? [],
      count: eventPlannersData?.count ?? 0,
      props: {},
      filters: filtersTalent(values),
      link: 'PUBLIC_EVENT_PLANER',
      role: 'EventPlaners',
      initValue: initValueTalent
    }
  };

  const { Component, data, props, filters, link, role, initValue, count } = Date[value];

  const { isLoading: isLoadingFavorites, data: favoritesData } = getAllFavorites(role, false);

  const isClear = () =>
    Object.keys(values).some(
      (key) =>
        key !== 'rating' &&
        (Array.isArray(getIn(values, key))
          ? getIn(values, key)?.length > 0
          : getIn(values, key) !== '')
    );

  const onHistory = (_link: string, id: string) => () => {
    // @ts-ignore
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS[_link])}?id=${id}`);
  };

  const clear = () => {
    for (const argument in initValue) {
      if (typeof initValue[argument] !== 'string') {
        initValue[argument] = [];
      }
    }

    resetForm({ values: { ...initValue } });

    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS.PAGE)}?tabs=${value}`);
  };

  const loading =
    isLoading ||
    isLoadingJobs ||
    isLoadingFavorites ||
    isLoadingVendors ||
    isLoadingVenues ||
    isLoadingEventPlanners ||
    isLoadingTalents;

  const onClick = (tab: string) => {
    setValue(tab);
    setPage(1);

    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS.PAGE)}?tabs=${tab}`);
  };

  const onIsFilter1 = (str: string | null) => {
    setIsFilter(str);
  };

  const onIsFilter2 = () => {
    setFocused(!focused);
  };

  const onIsFilter = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    window.innerWidth < 950 ? onIsFilter1('filter') : onIsFilter2();
  };

  const toggleDetails = (detailsId: string, flag: boolean | null) => {
    const details = document.getElementsByTagName('details');
    for (let i = 0; i < details.length; i++) {
      if (flag) {
        if (details[i].id !== detailsId && details[i].open) {
          details[i].open = false;
        }
      } else {
        details[i].removeAttribute('open');

        details[i].open = false;
      }
    }
  };

  const checkIfAnyValueFilled = () => {
    for (const key in values) {
      const _value = getIn(values, key);
      if (key !== 'rating' && (Array.isArray(_value) ? _value.length > 0 : _value !== '')) {
        return true;
      }
    }
    return false;
  };

  return (
    <Styled.Container onClick={() => setFocused(false)}>
      <Styled.Title>Discover</Styled.Title>
      <Styled.CommonForm>
        <Styled.Sticky2 stickyTop='0.5rem'>
          <Styled.FilterContainer>
            <InputDropdownCroup
              matchedWords={Location}
              name='location'
              placeholder='Choose Location'
              isFilter
              isFilterData
              isCache
              item='cities'
              titleItem='state'
              height='3.5rem'
              mt='0'
              withIcon={Styled.LocationIcon}
            />

            <Styled.FiltersText>
              <span>FILTERS</span>
              {isClear() ? (
                <span onClick={clear} className='clear'>
                  CLEAR ALL
                </span>
              ) : null}
            </Styled.FiltersText>

            <Styled.AccordionContainer>
              {filters.map(({ title, component }: any, index: number) => (
                <Styled.AccordionDiv
                  key={index}
                  onClick={toggleDetails.bind(this, `details${index}`, true)}
                >
                  <Accordion index={index} title={title}>
                    {component}
                  </Accordion>
                  <Styled.Line2 className='line' />
                </Styled.AccordionDiv>
              ))}
            </Styled.AccordionContainer>
          </Styled.FilterContainer>
        </Styled.Sticky2>

        <Styled.ContentContainer>
          <Styled.Sticky data-element='tabs'>
            <Styled.NawTab>
              <Tads value={value} onClick={onClick} resetForm={resetForm} />
            </Styled.NawTab>

            <Styled.Div>
              <Input
                name='search'
                placeholder={`Search for ${role.toLowerCase()}`}
                withIcon={Styled.SearchIcon}
                height='3.5rem'
                gapFromLabel='0'
                isClear
              />

              {filtersString?.length || window.innerWidth < 950 ? (
                <Styled.IconContainer onClick={(e) => onIsFilter(e)} isFocused={focused}>
                  <Styled.Icon
                    className='icon'
                    style={{
                      WebkitMaskImage: `url(${
                        window.innerWidth < 950 ? Icons.filterIcon : Icons.sortIcon
                      })`,
                      WebkitMaskSize: '100% 100%',
                      maskImage: `url(${
                        window.innerWidth < 950 ? Icons.filterIcon : Icons.sortIcon
                      })`,
                      background:
                        window.innerWidth < 950 && checkIfAnyValueFilled()
                          ? COLORS.purple
                          : focused
                          ? COLORS.white
                          : COLORS.black
                    }}
                  />
                </Styled.IconContainer>
              ) : null}

              {focused && window.innerWidth > 600 && (
                <Styled.FilterRating className='sort'>
                  {filtersString.map((_value: string, index: number) => {
                    const rating = getIn(values, 'rating');

                    return (
                      <Styled.FilterRatingDiv
                        key={index}
                        isSelect={rating === _value}
                        onClick={() => {
                          setValues((v: any) => ({ ...v, rating: _value }));
                          setFocused(false);
                        }}
                      >
                        {_value}
                      </Styled.FilterRatingDiv>
                    );
                  })}
                </Styled.FilterRating>
              )}
            </Styled.Div>
          </Styled.Sticky>

          <Styled.EventContainer length={!!data?.length}>
            {loading ? (
              <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
            ) : null}

            {!loading && data?.length
              ? data.map((_d: any) => {
                  const is = favoritesData?.favorites.find(
                    (v: any) => +v.owner_id === +_d.id && v.role === role
                  );
                  return (
                    <Styled.DivEvent key={_d.id}>
                      <Styled.DivEvent2 onClick={onHistory(link, _d.id)}>
                        <Component data={_d} {...props} isUserFavorite={!!is} socket={socket} />
                      </Styled.DivEvent2>
                      <Styled.Line className='line' />
                    </Styled.DivEvent>
                  );
                })
              : null}

            {!loading && !data?.length && (
              <NotFoundItem
                isSearch
                title='No Results Found'
                subTitle='It seems we can’t find any results based on your search.'
              />
            )}
            {count && count > 20 ? (
              <Pagination
                totalCount={count}
                pageSize={20}
                siblingCount={1}
                onPageChange={(num: number) => setPage(num)}
                currentPage={page}
              />
            ) : null}
          </Styled.EventContainer>
        </Styled.ContentContainer>

        {!!isFilters && (
          <Drawer
            onClose={onIsFilter1.bind(this, null)}
            open={!!isFilters}
            slidePosition='bottom'
            contentPosition='bottom'
          >
            <Portal>
              <PopupLayout
                title='Filters'
                saveText={`Show ${data?.length} ${value}`}
                onCancel={onIsFilter1.bind(this, null)}
                onSave={onIsFilter1.bind(this, null)}
                type='button'
                isFulWidth
                titleComponent={
                  <Styled.FiltersText>
                    <span onClick={clear} className='clear'>
                      CLEAR ALL
                    </span>
                  </Styled.FiltersText>
                }
              >
                <Styled.Filters>
                  <InputDropdownCroup
                    matchedWords={Location}
                    name='location'
                    placeholder='Choose Location'
                    isFilter
                    isNewWindow
                    isFilterData
                    isCache
                    item='cities'
                    titleItem='state'
                    height='3.5rem'
                    mt='0'
                    withIcon={Styled.LocationIcon}
                  />

                  {filtersString?.length ? (
                    <InputMatchedWords
                      matchedWords={filtersString}
                      withIcon={Styled.SortIcon}
                      name='rating'
                      placeholder='Sort By'
                      height='3.5rem'
                      mb={SPACES.l}
                      isNewWindow
                    />
                  ) : null}

                  <Styled.AccordionContainer>
                    {filters.map(({ title, component }: any, index: number) => (
                      <Styled.AccordionDiv key={index}>
                        <Accordion
                          isNewWindow
                          index={index}
                          title={title}
                          onClick={toggleDetails.bind(this, `details${index}`, false)}
                        >
                          {component}
                        </Accordion>
                        <Styled.Line2 className='line' />
                      </Styled.AccordionDiv>
                    ))}
                  </Styled.AccordionContainer>
                </Styled.Filters>
              </PopupLayout>
            </Portal>
          </Drawer>
        )}
      </Styled.CommonForm>
    </Styled.Container>
  );
};
