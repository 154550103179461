import { Slider } from '@mui/material';
import { styled as styledMaterial } from '@mui/material/styles';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;
  max-width: 24rem;
  position: relative;
  ${Center};
  justify-content: flex-start;
`;
export const Text = styled.p`
  position: absolute;
  top: -${SPACES.xxxxl};

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const CustomSliderDiv = styled.div`
  width: 100%;
  gap: ${SPACES.xxl};
  ${Center};

  margin: ${SPACES.xxxxxxls} 0;

  & > span {
    font-size: ${FONTS.SIZES.xxxxxl_};
  }
`;

export const CustomSlider = styledMaterial(Slider)(() => ({
  maxWidth: '25rem !important',
  width: '100% !important',
  margin: '0 !important',
  color: `${COLORS.purple} !important`,
  '& .MuiSlider-thumb': {
    backgroundColor: COLORS.purple //color of thumbs
  },
  '& .MuiSlider-rail': {
    color: COLORS.white100 ////color of the slider outside  teh area between thumbs
  }
}));
