import styled from 'styled-components';

import {
  CommonContainer,
  CommonContainerBetween,
  CommonContent,
  CommonDescription,
  CommonDolar,
  CommonIcon,
  CommonNew,
  CommonNewContainer,
  CommonTitle
} from '../../../common/component/card';

export const Container = styled(CommonContainer)``;
export const Icon = styled(CommonIcon)``;
export const Content = styled(CommonContent)``;
export const ContainerBetween = styled(CommonContainerBetween)``;
export const Description = styled(CommonDescription)``;
export const Title = styled(CommonTitle)``;
export const NewContainer = styled(CommonNewContainer)``;
export const New = styled(CommonNew)``;
export const Dolar = styled(CommonDolar)``;
export const CenterDiv = styled.div`
  display: flex;
  align-items: center;
`;
