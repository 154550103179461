import TimePicker from 'rc-time-picker';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, SHADOWS, SPACES } from '../../../../../theme';
import { IInputMask } from '../../../types';
import { IInputProps } from '../../../types';

export const OptionBlock = styled.div<Partial<IInputMask>>`
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  opacity: ${({ readOnly }) => (readOnly ? '0.4' : '1')};

  margin-left: ${({ ml }) => ml ?? '0'};
  margin-right: ${({ mr }) => mr ?? '0'};
  margin-bottom: ${({ mb }) => mb ?? '0'};
  margin-top: ${({ mt }) => mt ?? '0'};
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const Label = styled.label<{ required?: boolean; isError: boolean }>`
  display: block;

  ${Fonts}

  text-transform: capitalize;

  color: ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        right: 0;
        top: 0;
      }
    `}
`;

const commonStyles = css<IInputProps>`
  margin: 0;
  padding: ${({ innerPads }) => innerPads ?? `${SPACES.xs} ${SPACES.m}`};

  border: 1px solid ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};
  border-radius: ${SPACES.xxs};
  box-shadow: ${SHADOWS.xxs};
  background-color: ${COLORS.white};

  &:not(:focus-within) {
    cursor: pointer;
  }

  &::placeholder {
    color: ${({ placeholderColor, theme }) => placeholderColor ?? theme.COLORS.semiTransparentGrey};
  }

  &:focus-within {
    border: 1px solid ${COLORS.purple};
    outline: 1px solid ${COLORS.purple};
  }
`;

export const StyleInput = styled(TimePicker).attrs(({ height, readOnly }: IInputProps) => ({
  height,
  readOnly
}))`
  width: 100%;
  height: ${({ height }) => (height ? height : '100%')};

  ${Fonts};

  font-size: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.semi_bold};

  color: ${COLORS.black};

  ${commonStyles}
  &:focus-within {
    outline: ${({ readOnly }) => readOnly && 'none'};
  }

  background: ${({ readOnly }) => readOnly && COLORS.white};
  margin-top: ${({ gapFromLabel }) => gapFromLabel ?? SPACES.xxxs};
  position: relative;

  cursor: ${({ readOnly }) => (readOnly ? 'initial' : 'pointer')};
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'initial')};
`;

export const ErrorInfoContainer = styled.div<{ mb?: string }>`
  display: flex;
  align-items: center;
  position: absolute;

  left: 0;
  bottom: -15px
    ${({ mb }) =>
      mb &&
      css`
        margin-bottom: ${mb};
      `};
`;

export const ErrorInfoText = styled.div`
  box-sizing: border-box;
  font-family: ${FONTS.FAMILIES.inter};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  color: ${COLORS.mainRed};
`;
