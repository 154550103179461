import React from 'react';

import { Icons } from '../../../../assets';
import { Links } from '../../constants';
import * as Styled from './footer.styled';
import { FilePath } from '../../../../utils';

export const icons = [
  { href: Links.facebook, icon: FilePath(Icons.facebook), alt: 'facebook' },
  { href: Links.instagram, icon: FilePath(Icons.instagram), alt: 'instagram' },
  { href: Links.linkedin, icon: FilePath(Icons.linkedin), alt: 'linkedin' }
];

export const Footer = () => {
  const texts = [
    { text: 'CONTACT US', href: Links.contact_us },
    { text: 'PRIVACY POLICY', href: Links.privacy_policy },
    { text: 'TERMS & CONDITIONS', href: Links.terms_conditions }
  ];

  return (
    <Styled.Footer>
      <Styled.Div>
        <Styled.LogoDiv>
          <Styled.Logo src={FilePath(Icons.logo2)} alt='logo' />
          <Styled.Title>Events. Suppliers. Planners. Opportunities.</Styled.Title>
        </Styled.LogoDiv>
        <Styled.IconDiv>
          {icons.map(({ icon, href, alt }, index) => (
            <Styled.IconContainer key={index} href={href} target='_blank'>
              <img src={icon} alt={alt} />
            </Styled.IconContainer>
          ))}
        </Styled.IconDiv>
      </Styled.Div>

      <Styled.Div2>
        <Styled.Text>© 2023 ESPO</Styled.Text>

        <Styled.IconDiv>
          <Styled.TEst>
            DESIGNED BY{' '}
            <Styled.A href={Links.designed} target='_blank'>
              <Styled.Span>ORIZON</Styled.Span>
            </Styled.A>
          </Styled.TEst>

          {texts.map(({ text, href }, index) => (
            <Styled.A key={index} href={href} target='_blank'>
              <Styled.Text>{text}</Styled.Text>
            </Styled.A>
          ))}
        </Styled.IconDiv>
      </Styled.Div2>
    </Styled.Footer>
  );
};
