import styled, { css } from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${SPACES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};
`;

export const Description = styled.div<{ isCard?: boolean; showEllipsis?: any }>`
  ${({ isCard, showEllipsis }) =>
    isCard
      ? css`
          ${Fonts};

          position: relative;
          max-height: 3rem;
          overflow: hidden;
          text-align: justify;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 4rem;
            height: 1.5rem;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
            -webkit-mask: linear-gradient(to bottom, transparent, black 1.5rem, black);
            mask: linear-gradient(to bottom, transparent, black 1.5rem, black);
          }

          &:after {
            content: '';
            content: ${showEllipsis ? "'...'" : "''"};
            position: absolute;
            bottom: 0;
            right: 0;
            padding-left: 0.6em;
            background-color: white;
          }
        `
      : css`
          ${Fonts}
          font-family: ${FONTS.FAMILIES.slussen};
          font-style: normal;
          font-weight: ${FONTS.WEIGHTS.normal};
          font-size: ${FONTS.SIZES.l};
          line-height: ${FONTS.SIZES.xxxxl};
          color: ${COLORS.black};
        `}
`;
