import styled, { css } from 'styled-components';

import { ReactComponent as ArrowLeftSvgIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightSvgIcon } from '../../../../assets/icons/arrow-right.svg';
import { COLORS, FONTS, SHADOWS, SPACES } from '../../../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${`${SPACES.m} 0`};
  background-color: inherit;
`;

export const PaginateButtonsList = styled.ul`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.SPACES.xxxxxs};
    gap: ${SPACES.xxxxl};
  }
`;
export const PaginateButtonsListItem = styled.li``;

const buttonsCommonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;

  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${FONTS.SIZES.m};
  box-shadow: ${SHADOWS.xs};

  color: ${COLORS.black};
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;

  cursor: pointer;
`;

export const PaginationButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  ${buttonsCommonStyles}
  border-radius: ${SPACES.xxs};

  &.selected {
    color: ${COLORS.white};
    background-color: ${COLORS.black};
  }
`;

const prevNextBtnStyles = css`
  ${buttonsCommonStyles}
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid;
  border-radius: 8px;

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    transform: scale(0.98);
  }
`;

export const PrevPageButton = styled.button`
  ${prevNextBtnStyles}
  margin-right: auto;
`;

export const NextPageButton = styled.button`
  ${prevNextBtnStyles}
  margin-left: auto;
`;

export const ArrowLeftIcon = styled(ArrowLeftSvgIcon)``;

export const ArrowRightIcon = styled(ArrowRightSvgIcon)``;
