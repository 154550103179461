import styled, { css } from 'styled-components';

import { Icons } from '../../../../assets';
import { COLORS, FONTS } from '../../../../theme';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  background-image: url(${Icons.vector});
  background-position: center;
  background-repeat: no-repeat;

  opacity: 0.08;
`;

export const SubTitle = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};
`;

export const Btn = styled.div`
  width: 100%;
  ${Center}
  justify-content: flex-end;
`;

export const Test = styled.div`
  max-width: 672px;
  width: 100%;

  ${Center}

  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: 10;
  overflow: auto;

  background: #1188e6;
  position: absolute;
`;
