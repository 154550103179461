import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

export const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${SPACES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};
`;

export const CommonContainer = styled.div`
  width: 100%;
  position: relative;
  height: fit-content;

  display: flex;

  gap: ${SPACES.xxl};

  &:hover {
    & > div > div > .title {
      color: ${COLORS.purpleHover};
    }
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;
  }
`;

export const CommonContent = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
`;

export const CommonContainerBetween = styled.div`
  width: 100%;
  ${Center};
  justify-content: space-between;
`;

export const CommonText = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const CommonTitle = styled(CommonText)`
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${SPACES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const CommonSubTitle = styled(CommonText)`
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const CommonDescription = styled.div`
  ${Fonts};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ContentSpace = styled.div`
  width: 100%;
  ${Center};
  justify-content: space-between;

  ${Fonts};

  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${SPACES.s};
  line-height: ${FONTS.SIZES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Content = styled.div`
  width: 100%;
  ${Center};
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${SPACES.l};

  margin: ${SPACES.l} 0;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin: ${SPACES.l} 0 0 0;
  }
`;

export const Chip = styled.div`
  width: fit-content;
  cursor: pointer;

  gap: ${SPACES.xs};

  ${Center};
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.semi_bold};

  padding: ${SPACES.xxs} ${SPACES.s};
  background: ${COLORS.white100};
  border-radius: 4px;
`;

export const ContentIcon = styled.div`
  width: 100%;
  ${Center};
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${SPACES.l};

  margin: ${SPACES.l} 0;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin: ${SPACES.l} 0 ${SPACES.sm} 0;
    gap: ${SPACES.s} ${SPACES.l};
  }
`;

export const CommonIcon = styled.div`
  height: 20px;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const Div = styled.div`
  ${Center}
  gap: ${SPACES.xs};
`;

export const TextIcons = styled(CommonDescription)`
  ${Fonts};
  -webkit-line-clamp: 1;
`;

export const ImageOneDiv = styled.div<{ imgHeight?: string }>`
  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight} !important` : '12.5rem')};

  border-radius: 8px;
  background: ${COLORS.black};
  ${Center};

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    max-height: 15rem;
  }
`;

export const Image = styled.img<{ imgHeight?: string; isFull?: boolean }>`
  min-width: 12.5rem;
  max-width: 12.5rem;

  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight} !important` : '12.5rem')};
  border-radius: 8px;
  object-fit: cover;

  @media screen and (max-width: ${MEDIA.tablet}) {
    max-width: 100%;
    height: ${({ isFull }) => (isFull ? `100% !important` : 'fit-content !important')};
    max-height: ${({ imgHeight }) => (imgHeight ? `${imgHeight} !important` : '12.5rem')};
  }
`;

export const ImageContainer = styled.div`
  width: 3rem;
  aspect-ratio: 1/1;
  position: absolute;
  background: ${COLORS.black};
  top: ${SPACES.l};
  left: ${SPACES.l};

  border-radius: 8px;

  ${Center};
`;

export const FavoriteIcon2 = styled(CommonIcon)`
  min-width: 20px;
  background: ${COLORS.white};

  &:hover {
    background: ${COLORS.purple};
  }
`;

export const DivStar = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    background: ${COLORS.black};
    border-radius: 8px;
    padding: ${SPACES.xxs} ${SPACES.s};

    gap: ${SPACES.s};
    position: absolute;
    top: ${SPACES.l};
    right: ${SPACES.l};

    & > .text {
      color: ${COLORS.white};
    }
  }
`;

export const CommonNewContainer = styled.div`
  display: flex;
  gap: ${SPACES.l};
`;

export const CommonNew = styled.div`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${SPACES.s};
  line-height: ${FONTS.SIZES.l};

  color: ${COLORS.white};

  padding: ${SPACES.xxs} ${SPACES.s};

  background: ${COLORS.blue};
  border-radius: 4px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;
export const CommonDolar = styled.img`
  width: 0.625rem;
  height: 1rem;
`;

export const ImgLoad = styled.img`
opacity: 0;
position: absolute;

 &.none {
     display: none;
  }

`
