import styled, { css } from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../theme';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 6rem);

  padding-left: ${SPACES.l};
  padding-bottom: 9.375rem;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding-left: 0;
    padding-bottom: 0;
    height: calc(100% - 2rem);
  }
`;

export const ArrowBackComponent = css`
  display: none;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: flex;
    margin: 0 0 ${SPACES.xxxxl} 0;
  }
`;

export const Div = styled.div`
  &:hover {
    & > .line {
      border-top: 1px solid ${COLORS.purple};
    }
  }
`;

export const Div2 = styled.div`
  width: 100%;
  cursor: pointer;
`;
