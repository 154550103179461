import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { SubTitle } from '../../../auth/component/sing-up/sing-up.styled';

const Hover = css`
  background: ${COLORS.black};

  & > p {
    color: ${COLORS.white};
  }

  & > div {
    background: ${COLORS.purple};
  }
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<{ styles: any }>`
  width: 100%;

  display: flex;
  position: relative;
  ${({ styles }) => styles}
`;

export const RoleContainer = styled.div`
  width: 100%;

  display: flex;
  overflow-x: auto;
  position: relative;

  gap: ${SPACES.xxxxl};

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  scroll-behavior: smooth;

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.s};
  }
`;

export const Role = styled.div<{ isValue: boolean }>`
  ${Center};
  max-height: 3.5rem;

  padding: ${SPACES.xxxxl};
  gap: 10px;

  border-radius: 8px;
  border: 1px solid ${COLORS.black};
  cursor: pointer;

  ${({ isValue }) =>
    isValue &&
    css`
      ${Hover}
    `}

  &:hover {
    ${Hover}
  }
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const RoleText = styled(SubTitle)<{ isJob: boolean; isEventPlaner: boolean }>`
  font-weight: ${FONTS.WEIGHTS.bold};
  width: ${({ isJob }) => (isJob ? '8.125rem' : 'fit-content')};

  ${({ isEventPlaner }) =>
    isEventPlaner &&
    css`
      width: 10rem;
    `}
`;

export const DivBut = styled.div`
  height: 3.5rem;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 5;
  background: ${COLORS.purple};

  ${Center};

  border-radius: 0 50% 50% 0;

  padding: 10px;
  opacity: 0.88;
`;

export const DivBtnEnd = styled(DivBut)`
  height: 3.5rem;
  aspect-ratio: 1/1;

  position: absolute;
  top: 0;

  left: 92.5%;
  left: 92.5%;

  z-index: 5;
  background: ${COLORS.black};

  ${Center};

  border-radius: 50% 0 0 50%;
`;

export const Btn = styled.div`
  height: 30px;
  ${Center};
  border-radius: 50%;
  background: ${COLORS.white};

  border: 1px solid ${COLORS.black};

  padding: 10px;
`;
