import { getIn, useFormikContext } from 'formik';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons } from '../../../../../assets';
import { SPACES } from '../../../../../theme';
import { useClickOutside } from '../../../hooks';
import { IWProps, TIconTypeProps } from '../../../types';
import { searchKeyPressControl } from '../../../utils';
import { Drawer } from '../../drawer';
import { FirstLayout } from '../../popup-layout';
import { Portal } from '../../portal';
import { InputHint } from '../input-matched-words/input-hint';
import * as CommonStyled from '../input-matched-words/input-matched-words.styled';
import * as Styled from './input-dropdown-croup.styled';
import { FilePath } from '../../../../../utils';

export interface IInputDropdownCroup extends IWProps {
  name: string;
  label?: string;
  placeholder?: string;
  isFilter?: boolean;
  matchedWords: any[];
  isCache?: boolean;
  isFilterData?: boolean;
  item: string;
  titleItem: string;
  withIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  innerPads?: string;
  iconsStyles?: TIconTypeProps;
  isNewWindow?: boolean;

  width?: string;
  height?: string;
}

export const InputDropdownCroup = ({
  name,
  label,
  placeholder,
  isFilter = false,
  isCache = false,
  isFilterData = false,
  isNewWindow = false,
  readOnly = false,
  matchedWords,
  item,
  titleItem,
  iconsStyles,
  width,
  height,
  withIcon,
  innerPads,
  ...props
}: IInputDropdownCroup) => {
  const location = useLocation();
  const history = useHistory();
  const { values, touched, errors, setTouched, setValues, setErrors } = useFormikContext();

  const value = getIn(values, name);
  const touche = getIn(touched, name);
  const error = getIn(errors, name);

  const [input, setInput] = useState<string>(value ?? '');

  useEffect(() => {
    if (!value && input !== 'All Locations') {
      setInput('');
    }
  }, [value]);

  const isError = !!error && touche;
  const svgStyles = {
    ...CommonStyled.defaultSvgStyles,
    ...iconsStyles,
    top: label ? '3.25rem' : '65%'
  };
  const pads = withIcon ? `${SPACES.xs} ${SPACES.xxxxxxl}` : innerPads;

  const [focused, setFocused] = useState(false);
  const [selectedHint, setSelectedHint] = useState<number | null>(null);

  const onSetTouched = (flag: boolean) => {
    setTouched({ ...touched, [name]: flag });

    setFocused(flag);
  };

  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (focused && !isNewWindow) {
      console.log('-------');
      setFocused(false);
    }
  });

  const filterItem = isFilter ? input : value;
  const filterData = matchedWords?.filter((v) =>
    v?.cities.toLowerCase()?.includes(filterItem?.trim().toLowerCase())
  );

  const isValueComplete =
    isFilterData && !matchedWords.filter((a) => a[item].includes(input)).length;
  const is = isFilterData ? isValueComplete : touche;

  const data = isFilter && is ? filterData : matchedWords;

  const onSetValues = (str: string) => {
    const text = str === 'All Locations' && isCache ? '' : str;

    setValues((v: any) => ({ ...v, [name]: text }));
    setInput(str);
  };

  const setValue = (str: string, ind: number) => {
    onSetTouched(false);

    setSelectedHint(ind);

    !value?.includes(str) && onSetValues(str);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);

    const isIncludes = data.filter((a) => a[item].includes(e.target.value)).length;

    !isIncludes && setErrors({ [name]: 'No Options found' });
  };

  let letterGroup = '';

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      setValues((v: any) => ({ ...v, [name]: input }));
    }

    if (e.key === 'Enter' && value) {
      setTouched({ ...touched, [name]: false });
      setFocused(false);
    }
  };

  useEffect(() => {
    if (isCache) {
      const searchParams = new URLSearchParams(location.search);
      if (value) {
        searchParams.set(name, value);
      } else if (searchParams.has(name)) searchParams.delete(name);

      const newSearch = searchParams.toString();

      history.push({ search: newSearch });
    }
  }, [value, isCache]);

  return (
    <CommonStyled.InputBlock readOnly={readOnly} {...props} ref={ref}>
      {label && (
        <CommonStyled.Label isError={!focused && isError} htmlFor='avatar'>
          {label}
        </CommonStyled.Label>
      )}
      <CommonStyled.Input
        width={width}
        height={height}
        autoComplete='off'
        isError={isError}
        name={name}
        value={isFilter ? (isCache && value.length ? value : input) : value}
        id={name}
        readOnly={readOnly}
        placeholder={placeholder}
        onClick={onSetTouched.bind(this, !focused)}
        innerPads={pads}
        onChange={(e) => onChange(e)}
        onKeyUp={handleKeyUp}
        onKeyDown={searchKeyPressControl({
          selectedHint,
          setInputValue: onSetValues,
          setSelectedHint,
          matchedWords: data,
          item
        })}
        touche={touche}
      />

      {withIcon && React.createElement(withIcon, svgStyles)}

      <CommonStyled.Arrow
        focused={focused}
        isLabel={!!label}
        isNewWindow={isNewWindow}
        src={isNewWindow ? FilePath(Icons.arrowRight2) : FilePath(Icons.arrowBottom)}
        alt='arrowIcon'
      />

      {focused && data?.length > 0 && !isNewWindow && (
        <CommonStyled.SuggestedBlock>
          {data.map((contact, ind) => {
            if (contact[titleItem] !== letterGroup) {
              letterGroup = contact[titleItem];
              return (
                <React.Fragment key={letterGroup}>
                  <Styled.Title>{letterGroup}</Styled.Title>
                  <InputHint
                    padding={`${SPACES.xs} ${SPACES.m} ${SPACES.xs} ${SPACES.l} `}
                    str={contact[item]}
                    index={ind}
                    key={`${ind}-${value}-${selectedHint}`}
                    setValue={setValue}
                    selected={ind === selectedHint}
                    isChip={false}
                  />
                </React.Fragment>
              );
            }
            return (
              <InputHint
                padding={`${SPACES.xs} ${SPACES.m} ${SPACES.xs} ${SPACES.l} `}
                str={contact[item]}
                index={ind}
                key={`${ind}-${value}-${selectedHint}`}
                setValue={setValue}
                selected={ind === selectedHint}
                isChip={false}
              />
            );
          })}
        </CommonStyled.SuggestedBlock>
      )}

      {isNewWindow && focused && data?.length > 0 && window.innerWidth < 950 && (
        <Drawer
          onClose={onSetTouched.bind(this, !focused)}
          open={focused}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            <FirstLayout
              title={placeholder ?? ''}
              onCancel={onSetTouched.bind(this, !focused)}
              height='fit-content'
              style={CommonStyled.FirstLayoutStyled}
            >
              <div className='children' onClick={(e) => e.stopPropagation()}>
                <CommonStyled.SuggestedBlock2>
                  {data.map((contact, ind) => {
                    if (contact[titleItem] !== letterGroup) {
                      letterGroup = contact[titleItem];
                      return (
                        <React.Fragment key={letterGroup}>
                          <Styled.Title>{letterGroup}</Styled.Title>
                          <InputHint
                            isNewWindow={isNewWindow}
                            padding={`${SPACES.xs} ${SPACES.m} ${SPACES.xs} ${SPACES.l} `}
                            str={contact[item]}
                            index={ind}
                            key={`${ind}-${value}-${selectedHint}`}
                            setValue={setValue}
                            selected={ind === selectedHint}
                            isChip={false}
                          />
                        </React.Fragment>
                      );
                    }
                    return (
                      <InputHint
                        isNewWindow={isNewWindow}
                        padding={`${SPACES.xs} ${SPACES.m} ${SPACES.xs} ${SPACES.l} `}
                        str={contact[item]}
                        index={ind}
                        key={`${ind}-${value}-${selectedHint}`}
                        setValue={setValue}
                        selected={ind === selectedHint}
                        isChip={false}
                      />
                    );
                  })}
                </CommonStyled.SuggestedBlock2>
              </div>
              <CommonStyled.Btn
                mt={SPACES.l}
                className='btn'
                onClick={onSetTouched.bind(this, !focused)}
                type='button'
                content='save'
                variant='primary'
              />
            </FirstLayout>
          </Portal>
        </Drawer>
      )}

      {isError && error !== 'is required' ? (
        <CommonStyled.ErrorInfoContainer>
          <CommonStyled.ErrorInfoText>{error}</CommonStyled.ErrorInfoText>
        </CommonStyled.ErrorInfoContainer>
      ) : null}
    </CommonStyled.InputBlock>
  );
};
