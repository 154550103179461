import styled, { css } from 'styled-components';

import { MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import { Title } from '../../common/styles/common.styled';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  ${Center};
`;

export const Btn = styled(Button)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    margin-bottom: ${SPACES.xxxxl};
  }
`;

export const TitleT = styled(Title)``;
