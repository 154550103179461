import { AxiosError } from 'axios';
import { Formik, getIn } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';

import { userService } from '../../../../services';
import { SPACES } from '../../../../theme';
import { IAuthError, IUpdatePassword } from '../../../../types';
import { PopupLayout } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { IMessage } from '../../../common/types';
import { inputsPassword } from '../../constants';
import { IUpdatePasswordFormik } from '../../types';
import { validationSchemaUpdateUserPassword } from '../../validation/shema';
import { IEditUserInfoPopup } from '../edit-user-info';
import * as Styled from './edit-user-password.styled';

export const EditUserPassword = ({ onCancel }: IEditUserInfoPopup) => {
  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });

    onCancel();
  };

  const { mutate: updateUserPassword } = useMutation<any, AxiosError<IAuthError>, IUpdatePassword>(
    (data: IUpdatePassword) => userService.updateUserPassword(data),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = (data: IUpdatePasswordFormik) => {
    delete data.create_password;

    updateUserPassword(data);
  };

  return (
    <Formik
      initialValues={{
        current_password: '',
        create_password: '',
        confirm_password: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchemaUpdateUserPassword}
    >
      {({ errors, touched }) => (
        <Styled.CommonForm>
          <PopupLayout
            title='Change Password'
            saveText='Confirm'
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            {inputsPassword.map(({ label, name, placeholder }, index) => (
              <Styled.CommonInp
                key={index}
                isErrors={getIn(errors, name)}
                mt={SPACES.xxxxl}
                name={name}
                label={label}
                placeholder={placeholder}
                type='password'
                height='3.5rem'
              />
            ))}
          </PopupLayout>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
