import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaEditVendor = Yup.object().shape({
  gallery: validateSchema.gallery,

  name: validateSchema.text,
  profession: validateSchema.text,
  website: validateSchema.text,
  location: validateSchema.text,
  address: validateSchema.text,

  phone: validateSchema.phone,
  email: validateSchema.email,

  categories: validateSchema.categories,
  description: validateSchema.text
});
