import React from 'react';

import { PopupLayout } from './popup-layout';
import * as Styled from './popup-layout.styled';

export interface IDeleteLayout {
  onCancel: () => void;
  onSave: () => void;
}

export const DeleteLayout = ({ onCancel, onSave }: IDeleteLayout) => (
  <PopupLayout
    title='Delete Event'
    saveText='Delete'
    onCancel={onCancel}
    onSave={onSave}
    type='button'
    isDelete
    styled={Styled.StyledPopupLayout}
  >
    <Styled.DeleteTitle>Are you sure?</Styled.DeleteTitle>
    <Styled.DeleteText>
      If you delete this event, it will be permanently removed from the platform. Please confirm
      your decision before proceeding.
    </Styled.DeleteText>
  </PopupLayout>
);
