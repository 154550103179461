import styled from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../../theme';

export const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: -10px;

  &:hover {
    & > .text::before {
      border: 1px solid ${COLORS.purpleHover};
    }
  }
`;

export const Input = styled.input`
  opacity: 0;
  visibility: hidden;

  &:checked ~ .text::after {
    opacity: 1;
    background: red;
  }

  &:checked ~ .text::before {
    background: ${COLORS.semiPurple};
    border: 1px solid ${COLORS.semiPurple};
  }

  &:focus ~ .text::before {
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.15);
  }
`;

export const Span = styled.span`
  position: relative;

  & > span {
    max-width: 100%;
    display: flex;

    font-family: ${FONTS.FAMILIES.slussen};
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.normal};
    font-size: ${FONTS.SIZES.l};
    line-height: ${FONTS.SIZES.xxxxl};
    color: ${COLORS.black};

    padding-left: ${SPACES.xxxxxxls};
  }

  &:before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    border: 1px solid ${COLORS.black};

    border-radius: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    -webkit-clip-path: polygon(45% 64%, 84% 14%, 100% 28%, 47% 100%, 0 49%, 15% 32%);
    clip-path: polygon(45% 64%, 84% 14%, 100% 28%, 47% 100%, 0 49%, 15% 32%);
    -webkit-transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms ease-in-out;
    box-shadow: inset 1em 1em ${COLORS.white};

    opacity: 0;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
