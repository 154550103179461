import styled from 'styled-components';

import { MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import { Title } from '../../common/styles/common.styled';

export const Btn = styled(Button)`
  margin-top: 3.25rem;
  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    margin-top: ${SPACES.xxxxl};
  }
`;

export const TitleT = styled(Title)`
  margin-top: 6.25rem;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.s};
  }
`;
