import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { Drawer, Portal } from '../../../common/component';
import { IReviewContainer } from '../../types';
import { ReviewCard } from '../review-card/review-card';
import { ReviewCreate } from '../review-create/review-create';
import * as Styled from './review-container.styled';
import { FilePath } from '../../../../utils';

export const ReviewContainer = ({
  socket,
  rating,
  reviews,
  component,
  to_user_id,
  id,
  key_refetch,
  user_id,
  full_name
}: IReviewContainer) => {
  const [isAddReview, setIsAddReview] = useState(false);

  const arr = [
    { icon: FilePath(Icons.starIcon), text: rating, background: COLORS.star },
    { icon: FilePath(Icons.messageIcon), text: `${reviews?.length} Reviews`, background: COLORS.green }
  ];

  return (
    <Styled.Container>
      <Styled.Content>
        <div>
          <Styled.Title>READY TO SHARE THOUGHTS?</Styled.Title>
          <Styled.Div>
            {arr.map(({ icon, text, background }, index) => (
              <Styled.Div key={index}>
                <Styled.Icon
                  className='icon'
                  style={{
                    WebkitMaskImage: `url(${icon})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${icon})`,
                    background
                  }}
                />
                {text}
              </Styled.Div>
            ))}
          </Styled.Div>
        </div>

        <Styled.Btn
          onClick={() => setIsAddReview(true)}
          type='button'
          content='Leave Review'
          variant='primaryInverse'
          disabled={user_id === to_user_id}
        />
      </Styled.Content>

      {reviews?.map((review, index) => (
        <Styled.DivReview key={index}>
          <ReviewCard review={review} />
          <Styled.Line className='line' />
        </Styled.DivReview>
      ))}

      {isAddReview && (
        <Drawer
          onClose={() => setIsAddReview(false)}
          open={isAddReview}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {isAddReview && (
              <ReviewCreate
                socket={socket}
                full_name={full_name}
                id={id}
                key_refetch={key_refetch}
                to_user_id={to_user_id}
                onCancel={() => setIsAddReview(false)}
                component={component}
              />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
