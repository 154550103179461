import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, useHistory, useParams } from 'react-router-dom';

import { Icons } from '../../assets';
import { bannerService, userService } from '../../services';
import { COLORS } from '../../theme';
import { IAuthError, IBanners, IGetUser } from '../../types';
import { Drawer, Loader, Portal } from '../common/component';
import { toastContainer } from '../common/component/toast';
import { APP_KEYS } from '../common/constants';
import { userProtectedPageAdmin } from '../common/hocs';
import { useConnectionSocket } from '../common/hooks';
import { Iuuid1 } from '../common/types';
import { Footer, Header } from './component';
import { CompleteProfile } from './component/complete-profile/complete-profile';
import { IndexComponentLayout } from './component/component-layout';
import * as Styled from './home-layout.styled';
import { useHomeLayoutContext } from './hooks';
import { isPageExist, urlUtil } from './utils';
import { FilePath } from '../../utils';
import { Favorites } from '../favorites/favorites';
import { AccountSettings } from '../account-settings/account-settings';
import { EventPublic } from '../event/component';
import { VendorPublic } from '../vendor/component';
import { VenuePublic } from '../venue/component';
import { JobPublic } from '../job/component';
import { TalentPublic } from '../talent/component';
import { EventPlanerPublic } from '../event-planer/component';
import { Banners, Notifications, Objects, Users } from '../admin/component';

// const EventPublic = React.lazy(() =>
//   import('../event/component').then((module) => ({
//     default: module.EventPublic
//   }))
// );
//
// const EventPlanerPublic = React.lazy(() =>
//   import('../event-planer/component').then((module) => ({
//     default: module.EventPlanerPublic
//   }))
// );
//
// const TalentPublic = React.lazy(() =>
//   import('../talent/component').then((module) => ({
//     default: module.TalentPublic
//   }))
// );
//
// const VendorPublic = React.lazy(() =>
//   import('../vendor/component').then((module) => ({
//     default: module.VendorPublic
//   }))
// );
//
// const Users = React.lazy(() =>
//   import('../admin/component').then((module) => ({
//     default: module.Users
//   }))
// );
//
// const Banners = React.lazy(() =>
//   import('../admin/component').then((module) => ({
//     default: module.Banners
//   }))
// );
//
// const Notifications = React.lazy(() =>
//   import('../admin/component').then((module) => ({
//     default: module.Notifications
//   }))
// );
//
// const Objects = React.lazy(() =>
//   import('../admin/component').then((module) => ({
//     default: module.Objects
//   }))
// );
//
// const VenuePublic = React.lazy(() =>
//   import('../venue/component').then((module) => ({
//     default: module.VenuePublic
//   }))
// );
//
// const JobPublic = React.lazy(() =>
//   import('../job/component').then((module) => ({
//     default: module.JobPublic
//   }))
// );
//
// const AccountSettings = React.lazy(() =>
//   import('../account-settings/account-settings').then((module) => ({
//     default: module.AccountSettings
//   }))
// );
//
// const Favorites = React.lazy(() =>
//   import('../favorites/favorites').then((module) => ({
//     default: module.Favorites
//   }))
// );

export interface IHistoryState {
  state: undefined | { isCompleteProfile: boolean };
}

export const HomeLayout = () => {
  const history = useHistory();
  const client = useQueryClient();
  const { page } = useParams<{ page?: string }>();
  const { user, setUser } = useHomeLayoutContext();

  useConnectionSocket(user?.id);

  const { state }: IHistoryState = history.location as IHistoryState;
  const isComplete = !!state?.isCompleteProfile;
  const [isCompleteProfile, setIsCompleteProfile] = useState(false);

  const onClose = () => {
    setIsCompleteProfile(false);
    history.push({
      pathname: APP_KEYS.ROUTER_KEYS.HOME + APP_KEYS.ROUTER_KEYS.PAGE,
      state: { isCompleteProfile: false }
    });
  };

  useEffect(() => {
    if (!isPageExist(page)) {
      history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
    }
  }, []);

  const onError = async () => {
    await localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const onSuccess2 = async () => {
    await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_USER);
  };

  const onError2 = async (err: AxiosError<IAuthError>) => {
    toastContainer.error({ title: err.response?.data.message ?? err.message });
  };

  useEffect(() => {
    if (isComplete) {
      setIsCompleteProfile(true);
    }
  }, [isComplete]);

  const onSuccess = ({ user: _user }: IGetUser) => {
    setUser(_user);
  };

  const { isLoading, data } = useQuery(APP_KEYS.QUERY_KEYS.GET_USER, () => userService.getUser(), {
    onSuccess,
    onError
  });

  const { data: banners } = useQuery(
    APP_KEYS.QUERY_KEYS.GET_ALL_BANNERS,
    () => bannerService.allBanners(),
    {
      onError
    }
  );

  const { mutate: redBanner } = useMutation<any, AxiosError<IAuthError>, Iuuid1>(
    (_data: Iuuid1) => bannerService.redBanner(_data),
    {
      onSuccess: onSuccess2,
      onError: onError2
    }
  );

  const findBanner = (): IBanners | undefined => {
    // @ts-ignore
    const lastBanner = banners?.banners.sort((a, b) => b.createdAt - a.createdAt)[0];

    const findBannerToUser =
      lastBanner && user?.banner_ids
        ? user?.banner_ids.find((a) => a.id === lastBanner.id)
        : undefined;

    if (!data?.user.banner_ids) {
      return lastBanner;
    }
    if (!findBannerToUser) {
      return lastBanner;
    }

    if (findBannerToUser) {
      return undefined;
    }
  };

  const banner = findBanner();

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  const onScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    if (page === 'page') {
      const el = document.querySelector('[data-element="tabs"]') as HTMLDivElement;

      if (e.currentTarget.scrollTop >= 140) {
        el.classList.add('isTop');
      } else {
        el.classList.remove('isTop');
      }
    }
  };

  return (
    <Styled.Container id='container' data-element='content' onScroll={onScroll}>
      <Header user={data?.user} />
      {banner && (
        <Styled.Banner>
          <Styled.Icon
            className='icon'
            style={{
              WebkitMaskImage: `url(${FilePath(Icons.infoIcon)})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${FilePath(Icons.infoIcon)})`
            }}
          />

          <div>
            {banner?.text}

            {banner?.link && (
              <span onClick={() => window.open(banner.link, '_blank')}>
                {banner?.link_text ?? 'more information'}.
              </span>
            )}
          </div>

          <Styled.IconClose
            onClick={() => redBanner({ id: banner?.id })}
            className='icon'
            style={{
              WebkitMaskImage: `url(${FilePath(Icons.close)})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${FilePath(Icons.close)})`
            }}
          />
        </Styled.Banner>
      )}

      <Styled.Content isScroll>
        <React.Suspense
          fallback={<Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />}
        >
          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.FAVORITES)}>
            <Favorites />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING)}>
            <AccountSettings />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT)}>
            <EventPublic />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_VENDOR)}>
            <VendorPublic />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_VENUE)}>
            <VenuePublic />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_JOB)}>
            <JobPublic />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_TALENT)}>
            <TalentPublic />
          </Route>

          <Route exact path={urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT_PLANER)}>
            <EventPlanerPublic />
          </Route>

          <Route
            exact
            path={urlUtil(APP_KEYS.ROUTER_KEYS.ADMIN_USERS)}
            component={userProtectedPageAdmin(Users)}
          />
          <Route
            exact
            path={urlUtil(APP_KEYS.ROUTER_KEYS.ADMIN_BANNERS)}
            component={userProtectedPageAdmin(Banners)}
          />
          <Route
            exact
            path={urlUtil(APP_KEYS.ROUTER_KEYS.ADMIN_NOTIFICATIONS)}
            component={userProtectedPageAdmin(Notifications)}
          />
          <Route
            exact
            path={urlUtil(APP_KEYS.ROUTER_KEYS.ADMIN_OBJECTS)}
            component={userProtectedPageAdmin(Objects)}
          />
        </React.Suspense>

        <Route exact path='/discover/page'>
          <IndexComponentLayout />
        </Route>
      </Styled.Content>

      {isCompleteProfile && (
        <Drawer
          onClose={onClose}
          open={isCompleteProfile}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            <CompleteProfile
              user={data?.user}
              setIsCompleteProfile={setIsCompleteProfile}
              onCancel={onClose}
            />
          </Portal>
        </Drawer>
      )}
      <Footer />
    </Styled.Container>
  );
};
