import React from 'react';

import { IUser } from '../../../types';
import { IHomeLayoutContextProviderProps } from '../types';

type AppContextValue = {
  user: IUser | undefined;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  menu: boolean;
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

// @ts-ignore
export const HomeLayoutContext = React.createContext<AppContextValue>();

export const HomeLayoutContextProvider = ({ children }: IHomeLayoutContextProviderProps) => {
  const [user, setUser] = React.useState<IUser | undefined>();
  const [menu, setMenu] = React.useState<boolean>(false);

  const navBarLayoutContextProviderValue = React.useMemo(
    () => ({
      user,
      setUser,
      menu,
      setMenu
    }),
    [user, menu]
  );

  return (
    <HomeLayoutContext.Provider value={navBarLayoutContextProviderValue}>
      {children}
    </HomeLayoutContext.Provider>
  );
};
