import styled from 'styled-components';

import {
  CommonContainer,
  CommonContainerBetween,
  CommonContent,
  CommonDescription,
  CommonIcon,
  CommonNew,
  CommonNewContainer,
  CommonTitle
} from '../../../common/component/card';

export const Container = styled(CommonContainer)``;
export const Icon = styled(CommonIcon)``;
export const Content = styled(CommonContent)``;
export const ContainerBetween = styled(CommonContainerBetween)``;
export const Description = styled(CommonDescription)``;
export const Title = styled(CommonTitle)``;
export const NewContainer = styled(CommonNewContainer)``;
export const New = styled(CommonNew)``;
