// Local storage keys
export const STORAGE_KEYS = {
  JWT_TOKEN_STUDENT: 'JWT_TOKEN_STUDENT',
  JWT_TOKEN_INSTRUCTOR: 'JWT_TOKEN_INSTRUCTOR',
  ADDRESS: 'ADDRESS',
  TOKEN: 'TOKEN',
  TEST_TOKEN: 'TEST_TOKEN',
  USER: 'USER',
  USER_EMAIL: 'USER_EMAIL'
};

export const ROUTER_KEYS = {
  HOME: '/discover',
  LOGIN: '/',
  SING_UP: '/sign-up',
  CHECK_EMAIL: '/check-email',
  CHECK_EMAIL_FORGOT: '/check-email-forgot',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  FAVORITES: '/favorites',
  ACCOUNT_SETTING: '/account-setting',
  PUBLIC_EVENT: '/event',
  PUBLIC_JOB: '/job',
  PUBLIC_VENDOR: '/vendor',
  PUBLIC_VENUE: '/venue',
  PUBLIC_EVENT_PLANER: '/event-planer',
  PUBLIC_TALENT: '/talent',
  PAGE: '/page',
  ADMIN_USERS: '/admin-users',
  ADMIN_BANNERS: '/admin-banners',
  ADMIN_OBJECTS: '/admin-objects',
  ADMIN_NOTIFICATIONS: '/admin-notifications'
};

export const QUERY_KEYS = {
  GET_USER: 'get-user',
  GET_ALL_EVENTS: 'get-all-events',
  GET_ALL_BANNERS: 'get-all-banner',
  GET_ALL_VENDORS: 'get-all-vendors',
  GET_ALL_TALENTS: 'get-all-talents',
  GET_ALL_VENUES: 'get-all-venues',
  GET_ALL_FAVORITES: 'get-all-favorites',
  GET_ALL_JOBS: 'get-all-jobs',
  GET_ALL_EVENT_PLANERS: 'get-all-event-planners',
  GET_ALL_USER_EVENTS: 'get-all-user_events',
  GET_ALL_NOTIFICATION: 'get-all-notification',
  GET_EVENT: 'get-event',
  GET_VENDOR: 'get-vendor',
  GET_TALENT: 'get-talent',
  GET_EVENT_PLANER: 'get-event-planer',
  GET_TALENT_EXPERIENCES: 'get-talent-experiences',
  GET_VENUE_EXPERIENCES: 'get-venue-experiences',
  GET_VENUE: 'get-venue',
  GET_FAVORITE: 'get-favorites',
  GET_JOB: 'get-job',
  GET_MY_VENDOR: 'get-my-vendor',

  GET_ADMIN_INFO: 'get-admin-info',
  GET_ADMIN_USERS: 'get-admin-users',
  GET_ADMIN_OBJECTS: 'get-admin-objects',
  GET_ADMIN_NOTIFICATIONS: 'get-admin-notifications',
  GET_ADMIN_NOTIFICATION: 'get-admin-notification',
  GET_ADMIN_BANNERS: 'get-admin-banners',
  GET_ADMIN_BANNER: 'get-admin-banner'
};
