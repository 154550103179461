import styled from 'styled-components';

import { SPACES } from '../../../../theme';
import {
  CommonContainer,
  CommonErrorContainer,
  CommonTableContainer,
  CommonText,
  CommonTitle
} from '../../common/page';

export const Container = styled(CommonContainer)``;
export const Text = styled(CommonText)``;
export const Title = styled(CommonTitle)``;
export const TableContainer = styled(CommonTableContainer)``;
export const ErrorContainer = styled(CommonErrorContainer)``;

export const PaginationContainer = styled.div`
  padding: 0 ${SPACES.l};
`;
