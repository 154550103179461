import React from 'react';

import { ICheckBox } from '../../../types';
import * as Styled from '../check-box-firmik/check-box-formik.styled';

export const CheckBox = ({ isChecked, onClick, label }: ICheckBox) => (
  <Styled.Label>
    <Styled.Input type='checkbox' checked={!!isChecked} onChange={onClick} />
    <Styled.Span className='text'>
      <span>{label}</span>
    </Styled.Span>
  </Styled.Label>
);
