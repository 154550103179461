import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { eventService } from '../../../services';
import { IAuthError } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { urlUtil } from '../../home-layout/utils';

const onError = (_err: AxiosError<IAuthError>, history: any) => {
  const err = _err.response?.data as IAuthError;

  toastContainer.error({ title: err.message ?? _err.message });
  history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
};

export const getEventById = (id: string, options?: any) => {
  const history = useHistory();

  return useQuery([APP_KEYS.QUERY_KEYS.GET_EVENT, id], () => eventService.getEvent(id), {
    onError: (err: AxiosError<IAuthError>) => onError(err, history),
    ...options
  });
};

export const getAllEventByUserId = (id: string | number | undefined) => {
  const history = useHistory();

  return useQuery(APP_KEYS.QUERY_KEYS.GET_ALL_USER_EVENTS, () => eventService.userEvents(id), {
    onError: (err: AxiosError<IAuthError>) => onError(err, history),
    enabled: !!id
  });
};

export const getAllEvents = (data: any, values: any, page: number, value: string) =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ALL_EVENTS, values, page], () => eventService.getEvents(data), {
    enabled: value === 'events'
  });
