import { getIn, useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons } from '../../../../../assets';
import { SPACES } from '../../../../../theme';
import { RegexConst, passwordError } from '../../../constants';
import { IInputProps } from '../../../types';
import * as Styled from './input.styled';
import { FilePath } from '../../../../../utils';

export const Input = ({
  name,
  label,
  gapFromLabel,
  textarea,
  checkbox,
  radio,
  withIcon,
  endIcon,
  iconsStyles,
  innerPads,
  required,
  className,
  type = 'text',
  labelClassName,
  inputType = 1,
  isOptional = false,
  isClear = false,
  isCache = false,
  ...props
}: IInputProps) => {
  const [field, { touched, error }] = useField(name);

  const location = useLocation();
  const history = useHistory();

  const { values, setValues } = useFormikContext();
  const value = getIn(values, name);

  const isCommonError = touched && error;

  const shoudRenderInput = !checkbox && !textarea && !radio;

  const svgStyles = { ...Styled.defaultSvgStyles, ...iconsStyles };
  const endSvgStyles = { ...Styled.defaultEndSvgStyles, ...iconsStyles };
  const pads =
    (withIcon ? `${SPACES.xs} ${SPACES.xxxxxxl_}` : innerPads) ||
    (type === 'password' ? `${SPACES.xs} ${SPACES.xxxxxxl} ${SPACES.xs} ${SPACES.m}` : undefined);

  const [types, setTypes] = useState(type);
  const [isPassword, setIsPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string[]>([]);

  useEffect(() => {
    if (type === 'password' && isPassword) {
      setTypes('text');
    } else {
      setTypes(type);
    }
  }, [type, isPassword]);

  useEffect(() => {
    if (name.includes('password')) {
      const addStr = (str: string) => {
        if (!successMessage.includes(str)) {
          setSuccessMessage((prev) => [...prev, str]);
        }
      };
      const deleteStr = (str: string) => {
        const index = successMessage.indexOf(str);

        if (index > -1) {
          setSuccessMessage((prev: any) => {
            prev.splice(index, 1);

            return [prev];
          });
        }
      };
      const is = (reservation: any, _error: string) => {
        if (reservation) {
          addStr(_error);
        } else {
          deleteStr(_error);
        }
      };

      is(RegexConst.LOWERCASE.test(value), passwordError[0]);
      is(RegexConst.CAPITAL.test(value), passwordError[1]);
      is(RegexConst.SPECIAL.test(value), passwordError[2]);
      is(value?.length >= 8, passwordError[3]);
    }

    if (isCache) {
      const searchParams = new URLSearchParams(location.search);
      if (value) {
        searchParams.set(name, value);
      } else if (searchParams.has(name)) {
        searchParams.delete(name);
      }

      const newSearch = searchParams.toString();

      history.push({ search: newSearch });
    }
  }, [name, value, isCache]);

  const onClickPassword = () => {
    setIsPassword(!isPassword);
  };

  const onClear = () => {
    setValues((prev: any) => ({ ...prev, [name]: '' }));
  };
  const isPasswordError = !!isCommonError && passwordError.includes(error ?? '');

  return (
    <Styled.Wrapper className={className} isLabel={!!label} {...props}>
      {label && inputType === 1 && (
        <Styled.Label
          isError={!!isCommonError}
          required={required}
          htmlFor={name}
          className={labelClassName}
        >
          {label}
          {isOptional && <Styled.LabelOptional>· Optional</Styled.LabelOptional>}
        </Styled.Label>
      )}

      {shoudRenderInput && inputType === 1 ? (
        <Styled.Input
          id={name}
          autoComplete='off'
          isError={!!isCommonError}
          gapFromLabel={gapFromLabel}
          innerPads={pads}
          type={types}
          {...field}
          {...props}
        />
      ) : (
        <Styled.Input2 id={name} type={types} required {...field} {...props} />
      )}

      {label && inputType === 2 && (
        <Styled.Label2 htmlFor={name} className={labelClassName}>
          {label}
        </Styled.Label2>
      )}

      {withIcon && React.createElement(withIcon, svgStyles)}
      {endIcon && React.createElement(endIcon, endSvgStyles)}

      {type === 'password' &&
        (isPassword ? (
          <Styled.Visibility
            isError={isPasswordError}
            isCommonError={!!isCommonError}
            onClick={onClickPassword}
          />
        ) : (
          <Styled.VisibilityOff
            isError={isPasswordError}
            isCommonError={!!isCommonError}
            onClick={onClickPassword}
          />
        ))}

      {isClear && field?.value && (
        <Styled.CloseIcon
          onClick={onClear}
          className='endIcon'
          style={{
            WebkitMaskImage: `url(${FilePath(Icons.close)})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${FilePath(Icons.close)})`
          }}
        />
      )}

      {isCommonError &&
      !passwordError.includes(error ?? '') &&
      error !== 'is required' &&
      error !== 'invalid date' ? (
        <Styled.Error>{error}</Styled.Error>
      ) : null}

      {isPasswordError ? (
        <Styled.ErrorPasswordContainer>
          {passwordError.map((text, index) => {
            const isError = text === error;
            const isSuccess = successMessage.includes(text);
            return (
              <Styled.ErrorPassword isError={isError} isSuccess={isSuccess} key={index}>
                <Styled.Icon
                  isError={isError}
                  isSuccess={isSuccess}
                  style={{
                    WebkitMaskImage: `url(${FilePath(Icons.success)})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${FilePath(Icons.success)})`
                  }}
                />

                {text}
              </Styled.ErrorPassword>
            );
          })}
        </Styled.ErrorPasswordContainer>
      ) : null}
    </Styled.Wrapper>
  );
};
