import styled from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../../theme';

export const Container = styled.div<{ styled: any }>`
  width: fit-content;
  z-index: 100;

  display: flex;
  align-items: center;

  margin: ${SPACES.xxxxxxls} 0 3.125rem 0;
  padding-left: 20%;

  cursor: pointer;

  gap: ${SPACES.s};

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};

  ${({ styled }) => styled}

  @media screen and (max-width: ${MEDIA.tablet_s}) {
    padding-left: ${SPACES.l};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding-left: ${SPACES.l};
    font-size: ${FONTS.SIZES.m};
  }
`;

export const Icon = styled.img`
  width: 14px;
  height: 14px;
`;
