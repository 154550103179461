import styled from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../theme';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 2.7rem;
`;

export const Title = styled.span`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: 3rem;
  line-height: 4rem;
  text-transform: uppercase;

  color: ${COLORS.black};

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: none;
  }
`;

export const CommonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  margin-top: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.xs};
  }
`;

export const FilterContainer = styled.div`
  height: 100%;
  min-width: 22rem;
  max-width: 24rem;

  padding-right: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.tablet}) {
    min-width: 100%;
    padding-right: 0;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
