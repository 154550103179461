import { useFormikContext } from 'formik';
import React from 'react';

import { COLORS, SPACES } from '../../../../../theme';
import { Input, InputMask, Loader } from '../../../../common/component';
import * as StyledCommon from '../../../common/styles/common.styled';
import { IStepOne } from '../../../types';
import * as Styled from './step-tow.styled';

export const StepTow = ({ setStep, isLoader = false }: IStepOne) => {
  const { isValid } = useFormikContext();

  const inputs = [
    {
      name: 'first_name',
      label: 'First Name',
      placeholder: 'Your First Name'
    },
    {
      name: 'last_name',
      label: 'Last Name',
      placeholder: 'Your Last Name'
    }
  ];

  const inputsPassword = [
    {
      name: 'create_password',
      label: 'Create Password',
      placeholder: 'Your Password'
    },
    {
      name: 'confirm_password',
      label: 'Confirm Password',
      placeholder: 'Confirm Password'
    }
  ];

  const height = window.innerWidth > 600 ? '3.5rem' : '3rem';

  return (
    <StyledCommon.CommonDiv>
      <StyledCommon.Title>tell Us about yourself</StyledCommon.Title>

      <StyledCommon.SubTitle>Fill in the information to create your account.</StyledCommon.SubTitle>

      <Styled.InputContainer mt={SPACES.xxxxxxls}>
        {inputs.map((v, index) => (
          <Input key={index} {...v} height={height} />
        ))}
      </Styled.InputContainer>

      <Input
        mt={SPACES.xxxxl}
        name='company_name'
        label='Company Name'
        placeholder='Your Company Name'
        height={height}
      />

      <Styled.InputContainer mt={SPACES.xxxxl}>
        <Input name='email' label='Email' placeholder='Your Email' height='3.5rem' />
        <InputMask
          height={height}
          mask='+9-999-999-9999'
          placeholder='Phone Number'
          name='phone'
          label='Phone Number'
        />
      </Styled.InputContainer>

      {inputsPassword.map((v, index) => (
        <Input key={index} mt={SPACES.xxxxl} {...v} type='password' height={height} />
      ))}

      <StyledCommon.Btn>
        <Styled.Btn
          content={!isLoader ? 'Next' : <Loader size='small' color={COLORS.purple} height='auto' />}
          type='submit'
          variant='primary'
          onClick={() => setStep(2)}
          width='7.5rem'
          mt='3rem'
          mb='3rem'
          disabled={!isValid || isLoader}
        />
      </StyledCommon.Btn>
    </StyledCommon.CommonDiv>
  );
};
