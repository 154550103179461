import React, { ReactNode, useRef, useState } from 'react';

import { Icons, Png } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { useIsHyperLink } from '../../hooks';
import * as Styled from './public-page.styled';
import { Div0, Div2, Div4 } from './public-page.styled';
import { FilePath } from '../../../../utils';

export interface IconsPage {
  abouts: any;
  styled?: any;
}

export const IconsCard = ({ abouts, styled }: IconsPage) => (
  <Styled.AboutDiv styles={styled}>
    {abouts
      .filter((a: any) => a.text !== null && a.text !== '')
      .map(({ text, icon, title }: any, index: number) => {
        const { flag, on } = useIsHyperLink(title, text);

        return (
          <Styled.AboutDiv2 key={index} className={flag ? 'click width' : 'width'} onClick={on}>
            <Styled.DivRole className='role'>
              <Styled.CommonIcon
                style={{
                  WebkitMaskImage: `url(${icon})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${icon})`
                }}
              />
            </Styled.DivRole>
            <div>
              <Styled.CommonSubTitle className='text'>{title}</Styled.CommonSubTitle>
              <Styled.CommonUserRole className='text'>{text}</Styled.CommonUserRole>
            </div>
          </Styled.AboutDiv2>
        );
      })}
  </Styled.AboutDiv>
);

export interface IImageGeller {
  onClick: (str: string | null, i: number) => void;
  images: any;
  text: string;
}

export const ImageGeller = ({ onClick, images, text }: IImageGeller) => {
  const firstImage = images?.length ? images[0]?.path : null;
  const lastImages = images?.length && images.slice(1, images?.length);

  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);

  const imageRef = useRef(null);

  const handleImageLoad = () => {
    if (imageRef.current) {
      // @ts-ignore
      const { height, width } = imageRef.current;

      // We save the size of the photo
      setImageWidth(width);
      setImageHeight(height);
    }
  };

  const isValid = () => {
    if (imageWidth && imageHeight) {
      if (imageWidth > imageHeight) {
        return false;
      }

      if (imageWidth > 600 && imageWidth > imageHeight) {
        return true;
      }
    }
    return true;
  };

  return (
    <Styled.ImageGeleri>
      <img
        ref={imageRef}
        src={firstImage}
        alt='test_image'
        onLoad={handleImageLoad}
        style={{ display: !imageWidth && images?.length > 0 ? 'block' : 'none' }}
      />

      {isValid() ? (
        <Styled.ImageOneDiv
          isLength={images?.length}
          onClick={firstImage && onClick.bind(this, text, 0)}
        >
          <Styled.ImageOne2 src={firstImage ?? Png.defaultBlack} isLength={images?.length > 1} />
        </Styled.ImageOneDiv>
      ) : (
        <Styled.ImageOne
          onClick={firstImage && onClick.bind(this, text, 0)}
          src={firstImage ?? Png.defaultBlack}
          isLength={images?.length > 1}
        />
      )}

      <Styled.ImageGeleriScroll>
        {lastImages
          ? lastImages?.map(({ path, id: _id }: any, i: number) => (
              <Styled.ImageDiv index={i} key={i + _id}>
                <Styled.Image
                  onClick={onClick.bind(this, text, i + 1)}
                  index={i}
                  key={i + _id}
                  src={path}
                />
              </Styled.ImageDiv>
            ))
          : null}
      </Styled.ImageGeleriScroll>
    </Styled.ImageGeleri>
  );
};

export interface IProps {
  isSpace?: boolean;
  isBorder?: boolean;
  padding?: string;
  margin?: string;
}

export interface ITextContainer extends IProps {
  text?: string;
  children?: ReactNode;
}

export const TextContainer = ({ text, children, ...props }: ITextContainer) => (
  <Styled.CommonTextContainer {...props}>
    {text && <Styled.CommonSubTitle>{text}</Styled.CommonSubTitle>}
    {children}
  </Styled.CommonTextContainer>
);

export const Categories = ({ categories }: { categories: any }) => {
  const _categories = typeof categories === 'object' ? categories : JSON.parse(categories ?? '');
  return (
    <Styled.ChipContent>
      {_categories.map((text: string, _index: number) => (
        <Styled.Chip key={_index}>{text}</Styled.Chip>
      ))}
    </Styled.ChipContent>
  );
};

export interface IStarAndTitle {
  rating?: string | number;
  icon?: any;
  text?: string | null;
}

export const StarAndTitle = ({ rating, icon, text }: IStarAndTitle) => (
  <Styled.Div0>
    <Styled.Div2>
      <img src={FilePath(Icons.starIcon)} alt='star' />
      <Styled.CommonText>{rating}</Styled.CommonText>
    </Styled.Div2>
    {text && (
      <Styled.Div4>
        <Styled.CommonIcon
          style={{
            WebkitMaskImage: `url(${icon ?? FilePath(Icons.jobIcon)})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${icon ?? FilePath(Icons.jobIcon)})`,
            background: COLORS.green
          }}
        />
        <Styled.CommonText style={{ flexGrow: 1 }}>{text}</Styled.CommonText>
      </Styled.Div4>
    )}
  </Styled.Div0>
);

export interface IButtonContainer {
  isAdmin: boolean;
  isUser: boolean;
  onCopyLink: () => void;
  onEdit: () => void;
  onFavorite: () => void;
  isFavorite: boolean;
  favorite: any;
}

export const ButtonContainer = ({
  isAdmin,
  isUser,
  onFavorite,
  favorite,
  isFavorite,
  onEdit,
  onCopyLink
}: IButtonContainer) => (
  <Styled.Div>
    {isAdmin && !isUser && (
      <Styled.EditBnt
        content='Edit'
        variant='inverse2'
        startIcon={FilePath(Icons.editIcon)}
        widthIcon={SPACES.sm}
        pads={`${SPACES.xxs} ${SPACES.xs}`}
        onClick={onEdit}
      />
    )}
    <Styled.Div3>
      {isUser ? (
        <Styled.EditBnt
          content='Edit'
          variant='inverse2'
          startIcon={FilePath(Icons.editIcon)}
          widthIcon={SPACES.sm}
          pads={`${SPACES.xxs} ${SPACES.xs}`}
          onClick={onEdit}
        />
      ) : (
        <Styled.CopyEvent
          onClick={onFavorite}
          style={{
            WebkitMaskImage: `url(${favorite})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${favorite})`,
            background: isFavorite ? COLORS.black : COLORS.purple
          }}
        />
      )}

      <Styled.DivCopy>
        <Styled.CopyEvent
          className='icon'
          onClick={onCopyLink}
          style={{
            WebkitMaskImage: `url(${FilePath(Icons.copyIcon)})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${FilePath(Icons.copyIcon)})`
          }}
        />
      </Styled.DivCopy>
    </Styled.Div3>
  </Styled.Div>
);
