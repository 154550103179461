import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { ArrowBack, Drawer, GalleryPopup, Loader, Portal } from '../../../common/component';
import {
  ButtonContainer,
  Categories,
  IconsCard,
  ImageGeller,
  StarAndTitle
} from '../../../common/component/public-page/public-page';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket, useScrollStart } from '../../../common/hooks';
import { getFavoriteById, toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { ReviewContainer } from '../../../review/component';
import { getVenueById } from '../../hooks';
import { VenueCreateSpaces } from '../venue-create-spaces/venue-create-spaces';
import { VenueCreate } from '../venue-create/venue-create';
import * as Styled from './venue-public.styled';
import { FilePath } from '../../../../utils';

export const VenuePublic = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);
  useScrollStart();

  const id = search.split('=')[1];

  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [index, setIndex] = useState(0);

  const { isLoading, data } = getVenueById(id);

  const isUser = user?.id === data?.venue.user_id ?? false;

  const { isLoading: isLoadingFavorite, data: dataFavorite } = getFavoriteById(
    id,
    'Venues',
    !isUser
  );
  const { mutate: createFavorite } = toggleFavorite(id, data?.venue.user_id, socket);

  const abouts = [
    {
      icon: FilePath(Icons.userIcon2),
      title: 'Manager',
      text: data?.venue.manager
    },
    { icon: FilePath(Icons.phoneIcon), title: 'Phone Number', text: data?.venue.phone },
    { icon: FilePath(Icons.emailIcon), title: 'Email Address', text: data?.venue.email },
    { icon: FilePath(Icons.locationIcon), title: 'Location', text: data?.venue.location }
  ];

  const onGallery = (str: string | null) => {
    setEditComponent(str);
  };
  const onImage = (str: string | null, i: number) => {
    setEditComponent(str);
    setIndex(i);
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toastContainer.success({ title: 'LINK COPIED!' });
  };

  const onBack = () => {
    history.goBack();
  };

  const onFavorite = async () => {
    await createFavorite({
      role: 'Venues',
      user_id: user?.id,
      owner_id: data?.venue.id as string,
      to_user_id: data?.venue.user_id
    });
  };

  if (isLoading || isLoadingFavorite) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  const isFavorite = dataFavorite?.favorite === null;
  const favorite = isFavorite ? FilePath(Icons.favoriteIcon2) : FilePath(Icons.favoriteIcon);
  const spaces = data?.venue.spaces;
  const isAdmin = user?.role_site === 'admin';

  // @ts-ignore
  const dataImages = data?.venue?.images.length
    ? data?.venue?.images?.sort((a, b) => a.index - b.index)
    : [];

  const isSpaces = isUser || user?.role_site === 'admin' || !!(spaces && spaces.length);

  const pow = (str: string) => (
    <span>
      {str}ft
      <sup>
        <Styled.Small>2</Styled.Small>
      </sup>
    </span>
  );

  return (
    <Styled.Container>
      <ArrowBack
        onClick={onBack}
        isButton={{
          flag: true,
          isUser,
          isHiring: true,
          favorite,
          onCopyLink,
          onFavorite,
          onEdit: onGallery
        }}
      />
      <ImageGeller images={dataImages} onClick={onImage} text='gallery' />

      <Styled.Title>{data?.venue?.name}</Styled.Title>

      <Styled.TextContainer2 isSpace isBorder margin='0'>
        <StarAndTitle text='Event Space' rating={data?.venue.rating} icon={FilePath(Icons.venueIcon)} />

        <ButtonContainer
          isUser={isUser}
          onFavorite={onFavorite}
          favorite={favorite}
          isAdmin={isAdmin}
          isFavorite={isFavorite}
          onCopyLink={onCopyLink}
          onEdit={onGallery.bind(this, 'edit')}
        />
      </Styled.TextContainer2>

      <Styled.TextContainer
        margin={`${SPACES.xxxxl_} 0 ${SPACES.l} 0 `}
        padding={`0 0 ${SPACES.l} 0 `}
      >
        <Styled.SubTitle>About</Styled.SubTitle>
      </Styled.TextContainer>

      <IconsCard abouts={abouts} styled={Styled.IconsCardStyled} />

      {data?.venue?.description && (
        <>
          <Styled.TextContainer margin={`${SPACES.xxxxxl} 0 ${SPACES.xxxxl_} 0 `}>
            <Styled.SubTitle>Description</Styled.SubTitle>
          </Styled.TextContainer>

          <Styled.Text>{data?.venue?.description}</Styled.Text>
        </>
      )}

      {data?.venue.categories?.length && (
        <>
          <Styled.TextContainer margin={`${SPACES.xxxxxl} 0 ${SPACES.xxxxl_} 0 `}>
            <Styled.SubTitle>Categories</Styled.SubTitle>
          </Styled.TextContainer>

          <Categories categories={data?.venue.categories} />
        </>
      )}

      {isSpaces && (
        <Styled.TextContainer isSpace>
          <Styled.SubTitle>Event Spaces</Styled.SubTitle>

          {isUser || user?.role_site === 'admin' ? (
            <Styled.EditBnt
              content='Edit'
              variant='inverse2'
              startIcon={FilePath(Icons.editIcon)}
              widthIcon={SPACES.sm}
              pads={`${SPACES.xxs} ${SPACES.xs}`}
              onClick={onGallery.bind(this, 'experience')}
            />
          ) : null}
        </Styled.TextContainer>
      )}

      {spaces &&
        spaces.map((ex, _index) => {
          const icons = [
            { icon: FilePath(Icons.capacity), text: ex.capacity },
            { icon: FilePath(Icons.square), text: pow(ex.square) },
            { icon: FilePath(Icons.standing_capacity), text: ex.standing_capacity },
            { icon: FilePath(Icons.seating_capacity), text: ex.seating_capacity }
          ];
          return (
            <Styled.ExperienceContainer key={_index}>
              <Styled.ExperienceTitle>{ex.name}</Styled.ExperienceTitle>

              <Styled.ExperienceInfo>
                {icons
                  .filter((i) => i.text)
                  .map(({ icon, text }, i) => (
                    <Styled.Div2 key={i}>
                      <Styled.CopyEvent
                        style={{
                          WebkitMaskImage: `url(${icon})`,
                          WebkitMaskSize: '100% 100%',
                          maskImage: `url(${icon})`,
                          background: COLORS.green
                        }}
                      />
                      <Styled.Text>{text}</Styled.Text>
                    </Styled.Div2>
                  ))}
              </Styled.ExperienceInfo>

              <Styled.Text>{ex.description}</Styled.Text>

              {index !== spaces.length - 1 && <Styled.Line className='line' />}
            </Styled.ExperienceContainer>
          );
        })}

      <ReviewContainer
        full_name={`${data?.venue.name}`}
        socket={socket}
        user_id={user?.id}
        key_refetch={APP_KEYS.QUERY_KEYS.GET_VENUE}
        id={id}
        to_user_id={data?.venue.user_id}
        rating={data?.venue.rating}
        reviews={data?.venue.reviews}
        component='Venues'
      />

      {editComponent && (
        <Drawer
          onClose={onGallery.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'gallery' && (
              <GalleryPopup
                index={index}
                onClose={onImage.bind(this, null, 0)}
                images={dataImages}
              />
            )}

            {editComponent === 'edit' && (
              <VenueCreate isEdit onCancel={onGallery.bind(this, null)} />
            )}
            {editComponent === 'experience' && (
              <VenueCreateSpaces isEdit onCancel={onGallery.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
