import { Form } from 'formik';
import styled from 'styled-components';

import { Input } from '../../../common/component';

export const CommonForm = styled(Form)`
  width: 100%;
`;

export const CommonInp = styled(Input)<{ isErrors: string | null }>`
  & .vIcon {
    top: ${({ isErrors }) => (isErrors === 'is required' || !isErrors ? '65%' : '40%')};
  }
`;
