import React, { ReactNode } from 'react';

import { Icons } from '../../../../assets';
import '../../../../styles/popup-layout.css';
import { COLORS, SPACES } from '../../../../theme';
import { Loader } from '../loading';
import * as Styled from './popup-layout.styled';
import { FilePath } from '../../../../utils';

export interface IPopupLayout {
  title: string;
  isLoading?: boolean;
  disabled?: boolean;
  children: ReactNode;
  titleComponent?: ReactNode;
  onSave: (value?: any) => void;
  onCancel: () => void;
  onDelete?: () => void;
  isEdit?: boolean;
  type?: 'button' | 'submit';
  saveText?: string;
  cancelText?: string;
  isDelete?: boolean;
  isFulWidth?: boolean;
  styled?: any;
  style?: any;
  isValid?: boolean;
}

export const PopupLayout = ({
  onSave,
  onCancel,
  title,
  isLoading = false,
  disabled = false,
  isDelete = false,
  isFulWidth = false,
  isValid = true,
  style,
  onDelete,
  isEdit = false,
  type = 'button',
  saveText = 'Save',
  cancelText,
  titleComponent,
  styled,
  children
}: IPopupLayout) => (
  <div className='confirm_purchase above-all' style={style ?? {}}>
    <Styled.Container isDelete={isDelete} styled={styled}>
      <Styled.TitleContainer>
        <Styled.Div>
          <Styled.Icon
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
            style={{
              WebkitMaskImage: `url(${FilePath(Icons.close)})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${FilePath(Icons.close)})`
            }}
          />
          {title}
        </Styled.Div>
        {titleComponent}
      </Styled.TitleContainer>
      <Styled.Content className='children'>{children}</Styled.Content>

      <Styled.BtnContainer className='btn_container'>
        <Styled.Btn
          className='cancel btn'
          type='button'
          onClick={onCancel}
          content={cancelText ?? 'Cancel'}
          variant='primaryInverse'
        />

        <Styled.BtnContainer2>
          {isEdit && (
            <Styled.Btn2
              className='btn'
              onClick={onDelete}
              type='button'
              content={
                !isLoading ? 'delete' : <Loader size='small' color={COLORS.purple} height='auto' />
              }
              variant='inverse2'
              startIcon={FilePath(Icons.deleteIcon)}
              widthIcon={SPACES.sm}
            />
          )}
          {!isValid && (
            <Styled.ErrorText>
              {' '}
              Empty fields<sup>*</sup>
            </Styled.ErrorText>
          )}

          <Styled.Btn
            className='btn'
            isDelete={isDelete || isFulWidth}
            onClick={onSave}
            type={type}
            content={
              !isLoading ? saveText : <Loader size='small' color={COLORS.purple} height='auto' />
            }
            variant='primary'
            disabled={disabled}
          />
        </Styled.BtnContainer2>
      </Styled.BtnContainer>
    </Styled.Container>
  </div>
);
