import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { userService } from '../../../../services';
import { SPACES } from '../../../../theme';
import { IAuthError, IUpdateNotification, IUser } from '../../../../types';
import {
  ArrowBack,
  CheckBox,
  Drawer,
  InputAvatar,
  Portal,
  Switch
} from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage } from '../../../common/types';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { notifications } from '../../constants';
import { EditUserInfo, EditUserInfoPopup } from '../edit-user-info';
import { EditUserPassword } from '../edit-user-password';
import * as Styled from './setting.styled';
import { FilePath } from '../../../../utils';

export const Setting = () => {
  const history = useHistory();
  const client = useQueryClient();
  const formData = new FormData();

  const { user } = useHomeLayoutContext();

  const [isChecked, setIsChecked] = useState<boolean>(user?.is_avatar || true);
  const [editComponent, setEditComponent] = useState<null | string>(null);

  const onEditAccount = (str: string | null) => {
    setEditComponent(str);
  };

  const onClickCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const onBack = () => {
    history.goBack();
  };

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });

    await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_USER);
  };

  const { mutate: updateNotification } = useMutation<
    any,
    AxiosError<IAuthError>,
    IUpdateNotification
  >((data: IUpdateNotification) => userService.updateNotification(data), {
    onSuccess,
    onError
  });

  const { mutate: updateAvatar } = useMutation<any, AxiosError<IAuthError>, any>(
    (data: any) => userService.updateAvatar(data),
    {
      onSuccess,
      onError
    }
  );

  const onNotificationUpdate = (flag: boolean, name: string) => {
    updateNotification({
      notification: name,
      flag: !flag
    });
  };

  const onSaveAvatar = async (file: File) => {
    formData.append('avatar', file);
    // @ts-ignore
    formData.append('is_avatar', isChecked);

    updateAvatar(formData);
  };

  return (
    <Styled.Container>
      <ArrowBack style={Styled.ArrowBackComponent} onClick={onBack} />
      <Styled.Title>Settings</Styled.Title>
      <Styled.Text>Change your basic account setting here.</Styled.Text>

      <Styled.TextContainer margin={`${SPACES.l} 0 0 0`}>
        <Styled.SubTitle>Avatar</Styled.SubTitle>
      </Styled.TextContainer>

      <InputAvatar
        label='Profile Photo'
        width='100%'
        avatar={user?.avatar}
        isSave={false}
        onSaveAvatar={onSaveAvatar}
        mt={SPACES.l}
        mb={SPACES.xxxxxl}
      />
      <CheckBox
        onClick={onClickCheckBox}
        isChecked={isChecked}
        label='Include this photo on your profile.'
      />

      <Styled.TextContainer isSpace margin={`${SPACES.xxxxl} 0 0 0`}>
        <Styled.SubTitle>About</Styled.SubTitle>
        <Styled.EditBnt
          content='Edit'
          variant='inverse2'
          startIcon={FilePath(Icons.editIcon)}
          widthIcon={SPACES.sm}
          onClick={onEditAccount.bind(this, 'info')}
        />
      </Styled.TextContainer>

      <Formik
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email,
          phone: user?.phone,
          company_name: user?.company_name
        }}
        onSubmit={() => {}}
        enableReinitialize
      >
        <EditUserInfo readOnly />
      </Formik>

      <Styled.TextContainer>
        <Styled.SubTitle>Change Password</Styled.SubTitle>
      </Styled.TextContainer>

      <Styled.EditBnt2
        mt={SPACES.xxl}
        mb={SPACES.l}
        content='change password'
        variant='primaryInverse'
        onClick={onEditAccount.bind(this, 'password')}
      />

      <Styled.TextContainer margin={`${SPACES.l} 0 0 0 `}>
        <Styled.SubTitle>Notifications</Styled.SubTitle>
      </Styled.TextContainer>

      {notifications.map(({ title, subTitle, name }, index) => {
        const key = name as keyof IUser;
        const value = user && user[key];

        return (
          <Styled.ContainerNotification key={index}>
            <div>
              <Styled.NotificationSubTitle>{title}</Styled.NotificationSubTitle>
              <Styled.NotificationText>{subTitle}</Styled.NotificationText>
            </div>

            <Switch
              isChecked={value as boolean}
              onClick={onNotificationUpdate.bind(this, value as boolean, name)}
              name={name}
            />
          </Styled.ContainerNotification>
        );
      })}

      {editComponent && (
        <Drawer
          onClose={onEditAccount.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'info' && (
              <EditUserInfoPopup onCancel={onEditAccount.bind(this, null)} />
            )}
            {editComponent === 'password' && (
              <EditUserPassword onCancel={onEditAccount.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
