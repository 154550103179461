import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

export const HeaderStyled = styled.header`
  width: 100%;
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${COLORS.black};
  background: ${COLORS.white};

  @media screen and (max-width: ${MEDIA.tablet}) {
    height: 3.5rem;
  }
`;

export const Logo = styled.img`
  width: 8.125rem;
  height: 2.5rem;

  margin-left: ${SPACES.xxxxxxl_};

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    width: 6.25rem;
  }

  @media screen and (max-width: ${MEDIA.mobile_m}) {
    width: 5.125rem;
  }
  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-left: ${SPACES.l};
  }

  cursor: pointer;
`;

export const Text = styled.h2`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};

  cursor: pointer;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${SPACES.xxxxxxl_};
  gap: 4px;
`;
export const Line2 = styled.div`
  width: 1.5rem;
  height: 0.25rem;

  background: ${COLORS.purple};
  border-radius: 4px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  display: flex;
  height: 100%;

  ${Center}
`;

const IconCss = css`
  background: ${COLORS.black};

  & > h2 {
    color: ${COLORS.white};
  }
  & > .icon {
    background: ${COLORS.purple};
  }
  & > .arrow {
    background: ${COLORS.white};
  }
`;

export const Div2 = styled.div<{ isSelect: boolean; isNotification?: boolean }>`
  ${Center}
  height: 100%;
  min-width: 5rem;

  position: relative;

  cursor: pointer;

  background: ${COLORS.white};
  color: ${COLORS.black};

  ${({ isSelect }) =>
    isSelect &&
    css`
      background: ${COLORS.black};

      & > h2 {
        color: ${COLORS.white};
      }
      & > .icon {
        background: ${COLORS.purple};
      }
      & > .arrow {
        background: ${COLORS.white};
      }
      & > .dot {
        background: ${COLORS.black};
      }
    `}

  ${({ isNotification }) =>
    isNotification &&
    css`
      &:hover {
        & > .icon {
          background: ${COLORS.purple};
        }
      }
    `}

  @media screen and (max-width: ${MEDIA.tablet}) {
    min-width: 3rem;
  }

  border-left: 1px solid ${COLORS.black};
`;

export const Icon = styled.div`
  height: 2.063rem;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
  @media screen and (max-width: ${MEDIA.tablet}) {
    height: 1.25rem;
  }
`;
export const Arrow = styled.div<{ isFocused: boolean }>`
  height: 0.75rem;
  aspect-ratio: 1/1;

  display: none;
  margin: 0 ${SPACES.l};
  background: ${COLORS.black};

  transform: rotate(${({ isFocused }) => (isFocused ? '200grad' : '0grad')});

  @media screen and (min-width: 769px) {
    display: flex;
  }
`;

export const IconUser = styled.div<{ isSelect: boolean }>`
  height: 2.063rem;
  aspect-ratio: 1/1;
  margin-left: ${SPACES.l};
  background: ${({ isSelect }) => (isSelect ? COLORS.white : COLORS.black)};
`;

export const Icon2 = styled(Icon)<{ isNavigation: boolean }>`
  background: ${({ isNavigation }) => (isNavigation ? COLORS.purple : COLORS.black)};
  height: ${({ isNavigation }) => (isNavigation ? '1rem' : '1.25rem')};

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: flex;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;
  margin-left: ${SPACES.l};

  object-fit: cover;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const Name = styled(Text)`
  margin: 0 0 0 ${SPACES.xxs};
`;

export const Btn = styled(Button)`
  width: 10rem;
  margin-right: ${SPACES.xxs};
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    width: 5.5rem;
  }
  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const DivDot = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 33.5%;
  right: 32%;

  padding: 2px;
  background: ${COLORS.white};
`;

export const Dot = styled.div`
  height: 0.625rem;
  aspect-ratio: 1/1;
  border-radius: 50%;

  background: ${COLORS.purpleHover};
  @media screen and (max-width: ${MEDIA.tablet}) {
    height: 0.35rem;
  }
`;
