import React from 'react';

import { Icons, Png } from '../../../../assets';
import { dateTransform, FilePath } from '../../../../utils';
import { IReviewCard } from '../../types';
import * as Styled from './review-card.styled';

export const ReviewCard = ({ review }: IReviewCard) => {
  const text = review.is_worked ? 'worked with' : 'has not worked with';

  const { avatar, first_name, last_name } = review.from_user;

  return (
    <Styled.Container>
      <Styled.SpaceBetween>
        <Styled.Div>
          <Styled.UserImage src={avatar || Png.defaultBlack} alt='user-avatar' />
          <div>
            <Styled.SubTitle>
              {first_name} {last_name}
            </Styled.SubTitle>
            <Styled.UserRole>
              {first_name} {text} {review?.name}
            </Styled.UserRole>
          </div>
        </Styled.Div>
        <Styled.Div className='star'>
          <Styled.Text>{review.rating}</Styled.Text>
          <img src={FilePath(Icons.starIcon)} alt='star' />
        </Styled.Div>
      </Styled.SpaceBetween>
      <Styled.DescriptionContainer>
        <Styled.Text>{review.description}</Styled.Text>

        <Styled.Date>{dateTransform(review.createdAt)}</Styled.Date>
      </Styled.DescriptionContainer>
    </Styled.Container>
  );
};
