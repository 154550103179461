import React, { ReactNode } from 'react';

import { ArrowBack } from '../../../common/component';
import * as Styled from './page.styled';

export interface IHeaderPage {
  onBack: () => void;
  title: string;
  content: ReactNode;
  buttonOptions?: {
    onClick: () => void;
    content: string;
  };
}

export const HeaderPage = ({ onBack, content, title, buttonOptions }: IHeaderPage) => (
  <>
    <ArrowBack onClick={onBack} />
    <Styled.CommonHeaderContainer>
      <div>
        <Styled.CommonTitle>{title}</Styled.CommonTitle>
        <Styled.CommonText>{content}</Styled.CommonText>
      </div>
      {buttonOptions ? (
        <Styled.CommonBtn
          content={buttonOptions.content}
          variant='primaryInverse'
          onClick={buttonOptions.onClick}
        />
      ) : null}
    </Styled.CommonHeaderContainer>
  </>
);
