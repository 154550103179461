import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../button/button';
import { Input, InputAvatar, InputCalendar, InputMask, InputMatchedWords } from '../inputs';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommonForm = styled(Form)`
  width: 100%;
`;

export const TextContainer = styled.div<{ isSpace?: boolean }>`
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${COLORS.black};

  padding: ${SPACES.m} 0;

  ${Center};
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};
`;

export const EditBnt = styled(Button)`
  text-transform: uppercase;
  max-width: 5.5rem;
  height: 2.5rem;
`;

export const Text = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const SubTitle = styled(Text)`
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: ${SPACES.xxl} 0;
`;

const InputCss = css`
  width: 48%;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100%;
  }
`;

export const CommonInput = styled(Input)`
  ${InputCss};
`;

export const CommonInputCalendar = styled(InputCalendar)`
  ${InputCss};
`;

export const CommonInputMatchedWords = styled(InputMatchedWords)`
  ${InputCss};
`;

export const CommonInputAvatar = styled(InputAvatar)`
  ${InputCss};
`;

export const CommonInputMask = styled(InputMask)`
  width: 100%;
`;

export const Div = styled.div`
  ${InputCss};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Hide = styled(Text)<{ readOnly: boolean }>`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.bold};

  opacity: ${({ readOnly }) => (readOnly ? '0.4' : '1')};

  color: ${COLORS.purple};

  cursor: ${({ readOnly }) => (readOnly ? 'initial' : 'pointer')};
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'initial')};

  &:hover {
    color: ${COLORS.purpleHover};
  }
`;
