import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { ArrowBack, Drawer, Loader, Portal } from '../../../common/component';
import {
  ButtonContainer,
  Categories,
  IconsCard,
  StarAndTitle
} from '../../../common/component/public-page/public-page';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket, useScrollStart } from '../../../common/hooks';
import { getFavoriteById, toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { ReviewContainer } from '../../../review/component';
import { getTalentById } from '../../hooks';
import { TalentCreateExperience } from '../talent-create-experience/talent-create-experience';
import { TalentCreate } from '../talent-create/talent-create';
import * as Styled from './talent-public.styled';
import { FilePath } from '../../../../utils';

export const TalentPublic = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);
  useScrollStart();

  const id = search.split('=')[1];

  const [editComponent, setEditComponent] = useState<null | string>(null);

  const { isLoading, data } = getTalentById(id);

  const isUser = user?.id === data?.talent.user_id ?? false;

  const { mutate: createFavorite } = toggleFavorite(id, data?.talent.user_id, socket);
  const { isLoading: isLoadingFavorite, data: dataFavorite } = getFavoriteById(
    id,
    'Talents',
    !isUser
  );

  const abouts = [
    {
      icon: FilePath(Icons.phoneIcon),
      title: 'Phone Number',
      text: data?.talent.is_hide_phone ? data?.talent.phone : '+*-***-***-***'
    },
    { icon: FilePath(Icons.emailIcon), title: 'Email Address', text: data?.talent.email },
    { icon: FilePath(Icons.locationIcon), title: 'Location', text: data?.talent.location },
    {
      icon: FilePath(Icons.reteIcon),
      title: 'Hourly Rate',
      text: data?.talent.hourly_rate ? (
        <>
          <img src={FilePath(Icons.dollar)} alt='dollar' />
          {data?.talent?.hourly_rate}/h
        </>
      ) : null
    }
  ];

  const onEdit = (str: string | null) => {
    setEditComponent(str);
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toastContainer.success({ title: 'LINK COPIED!' });
  };

  const onBack = () => {
    history.goBack();
  };

  const onFavorite = async () => {
    await createFavorite({
      role: 'Talents',
      user_id: user?.id,
      owner_id: data?.talent.id as string,
      to_user_id: data?.talent.user_id
    });
  };

  if (isLoading || isLoadingFavorite) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  const isFavorite = dataFavorite?.favorite === null;
  const favorite = isFavorite ? FilePath(Icons.favoriteIcon2) : FilePath(Icons.favoriteIcon);
  const avatar = data?.talent.avatar
    ? data.talent.avatar
    : data?.talent.user?.is_avatar && data?.talent?.user.avatar
    ? data?.talent?.user?.avatar
    : FilePath(Png.defaultBlack);
  const experiences = data?.talent.experiences;

  const categories: [] =
    typeof data?.talent.categories === 'object'
      ? data.talent.categories
      : JSON.parse(data?.talent.categories ?? '');

  const isExperiences =
    isUser || user?.role_site === 'admin' || !!(experiences && experiences.length);

  return (
    <Styled.Container>
      <ArrowBack
        onClick={onBack}
        isButton={{
          flag: true,
          isUser,
          isHiring: true,
          favorite,
          onCopyLink,
          onFavorite,
          onEdit
        }}
      />

      <Styled.InfoContainer>
        <Styled.ImageOne src={avatar} isLength={false} />
        <Styled.InfoContent>
          <Styled.Title>
            {data?.talent?.first_name} {data?.talent?.last_name}
          </Styled.Title>

          <Styled.TextContainer2 isSpace isBorder margin='0'>
            <StarAndTitle text={data?.talent.occupation} rating={data?.talent.rating} />

            <ButtonContainer
              isUser={isUser}
              onFavorite={onFavorite}
              favorite={favorite}
              isAdmin={user?.role_site === 'admin'}
              isFavorite={isFavorite}
              onCopyLink={onCopyLink}
              onEdit={onEdit.bind(this, 'edit')}
            />
          </Styled.TextContainer2>

          <Styled.TextContainer margin='-0.313rem 0 0  0' padding={`${SPACES.l} 0 ${SPACES.m} 0`}>
            <Styled.SubTitle>About</Styled.SubTitle>
          </Styled.TextContainer>

          <IconsCard abouts={abouts} styled={Styled.IconsCardStyled} />
        </Styled.InfoContent>
      </Styled.InfoContainer>

      {data?.talent?.description && (
        <>
          <Styled.TextContainer margin={`${SPACES.l} 0 ${SPACES.xxxxl} 0`}>
            <Styled.SubTitle>Description</Styled.SubTitle>
          </Styled.TextContainer>

          <Styled.Text>{data?.talent?.description}</Styled.Text>
        </>
      )}

      {categories?.length && (
        <>
          <Styled.TextContainer margin={`${SPACES.xxxxxl} 0 ${SPACES.xxxxl} 0`}>
            <Styled.SubTitle>Categories</Styled.SubTitle>
          </Styled.TextContainer>

          <Categories categories={data?.talent.categories} />
        </>
      )}

      {isExperiences && (
        <Styled.TextContainer isSpace>
          <Styled.SubTitle>Experience</Styled.SubTitle>

          {(isUser || user?.role_site === 'admin') && (
            <Styled.EditBnt
              content='Edit'
              variant='inverse2'
              startIcon={Icons.editIcon}
              widthIcon={SPACES.sm}
              pads={`${SPACES.xxs} ${SPACES.xs}`}
              onClick={onEdit.bind(this, 'experience')}
            />
          )}
        </Styled.TextContainer>
      )}

      {experiences &&
        experiences.map((ex, index) => {
          const icons = [
            { icon: Icons.jobIcon, text: ex.company },
            { icon: Icons.calendarIcon, text: `${ex.start_date} - ${ex.end_date}` },
            {}
          ];
          return (
            <Styled.ExperienceContainer key={index}>
              <Styled.ExperienceTitle>{ex.occupation}</Styled.ExperienceTitle>

              <Styled.ExperienceInfo>
                {icons.map(({ icon, text }, i) => (
                  <Styled.Div2 key={i}>
                    <Styled.CopyEvent
                      style={{
                        WebkitMaskImage: `url(${icon})`,
                        WebkitMaskSize: '100% 100%',
                        maskImage: `url(${icon})`,
                        background: COLORS.green
                      }}
                    />
                    <Styled.Text>{text}</Styled.Text>
                  </Styled.Div2>
                ))}
              </Styled.ExperienceInfo>

              <Styled.Text>{ex.description}</Styled.Text>

              {index !== experiences.length - 1 && <Styled.Line className='line' />}
            </Styled.ExperienceContainer>
          );
        })}

      <ReviewContainer
        socket={socket}
        full_name={`${data?.talent.first_name} ${data?.talent.last_name}`}
        user_id={user?.id}
        key_refetch={APP_KEYS.QUERY_KEYS.GET_TALENT}
        id={id}
        to_user_id={data?.talent.user_id}
        rating={data?.talent.rating}
        reviews={data?.talent.reviews}
        component='Talents'
      />

      {editComponent && (
        <Drawer
          onClose={onEdit.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'edit' && <TalentCreate isEdit onCancel={onEdit.bind(this, null)} />}
            {editComponent === 'experience' && (
              <TalentCreateExperience isEdit onCancel={onEdit.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
