import React, { MouseEvent } from 'react';

import { Icons, Png } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { FilePath, newUtil } from '../../../../utils';
import { AvatarImage, Categories, IconsCard, StarContainer } from '../../../common/component/card';
import { toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { IEventPlanerCard } from '../../types';
import * as Styled from './event-planer-card.styled';

export const EventPlanerCard = ({ data, isUserFavorite = false, socket }: IEventPlanerCard) => {
  const { user } = useHomeLayoutContext();

  const isUser = user?.id && +user?.id === +data.user_id;
  const favorite = isUserFavorite ? FilePath(Icons.favoriteIcon) : FilePath(Icons.favoriteIcon2);
  const icons = [
    { icon: FilePath(Icons.jobIcon), text: data.occupation ?? '', background: COLORS.green },
    { icon: FilePath(Icons.locationIcon), text: data.location ?? '', background: COLORS.blue },
    {
      icon: FilePath(Icons.reteIcon),
      text: data.hourly_rate ? (
        <Styled.CenterDiv>
          <img src={FilePath(Icons.dollar)} alt='dollar' />
          {data.hourly_rate}/h
        </Styled.CenterDiv>
      ) : (
        ''
      ),
      background: COLORS.purple
    }
  ];

  const main_image = data.avatar
    ? data.avatar
    : data?.user?.is_avatar && data?.user.avatar
    ? data?.user?.avatar
    : FilePath(Png.defaultBlack);

  const { mutate: createFavorite } = toggleFavorite(
    undefined,
    data?.user_id,
    socket,
    'EventPlaners'
  );

  const onFavorite = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    await createFavorite({
      role: 'EventPlaners',
      user_id: user?.id,
      owner_id: data.id,
      to_user_id: data?.user_id
    });
  };

  return (
    <Styled.Container>
      <AvatarImage
        image={main_image}
        favorite={favorite}
        onFavorite={onFavorite}
        isUser={isUser}
        height='15rem'
      />

      <Styled.Content>
        <Styled.ContainerBetween>
          <Styled.Title className='title'>
            {data.first_name} {data.last_name}
          </Styled.Title>
          <Styled.NewContainer>
            {newUtil(data?.createdAt, 0, 4) && <Styled.New>NEW</Styled.New>}

            <StarContainer rating={data.rating} />
          </Styled.NewContainer>
        </Styled.ContainerBetween>

        <IconsCard icons={icons} />
        <Styled.Description>{data?.description}</Styled.Description>
        <Categories categories={data?.categories} id={data?.id} />
      </Styled.Content>
    </Styled.Container>
  );
};
