export const passwordError: string[] = [
  'LOWERCASE LETTER',
  'CAPITAL LETTER',
  'SPECIAL CHATACTER',
  '8 CHARACTERS'
];

export const InputConst = {
  name: {
    name: 'name',
    label: 'Name',
    placeholder: 'Name'
  },
  first_name: {
    name: 'first_name',
    label: 'First Name',
    placeholder: 'First Name'
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
    placeholder: 'Last Name'
  },
  occupation: {
    name: 'occupation',
    label: 'Occupation',
    placeholder: 'Occupation'
  },
  location: {
    name: 'location',
    label: 'Choose Location',
    placeholder: 'Location'
  },
  venue: {
    name: 'venue',
    label: 'Address/Venue',
    placeholder: 'Address/Venue'
  },
  status: {
    name: 'status',
    label: 'Event Status',
    placeholder: 'Event Status'
  },
  description: {
    name: 'description',
    label: 'Description',
    placeholder: 'Description'
  },
  categories: {
    name: 'categories',
    label: 'Categories',
    placeholder: 'Enter to add...'
  },
  start_time: {
    name: 'start_time',
    label: 'Start Time'
  },
  end_time: {
    name: 'end_time',
    label: 'End Time'
  },
  start_data: {
    nameDate: 'start_data',
    labelDate: 'Start Date'
  },
  end_data: {
    nameDate: 'end_data',
    labelDate: 'End Date'
  },
  hourly_rate: {
    name: 'hourly_rate',
    label: 'Hourly Rate',
    placeholder: 'Hourly Rate'
  },
  email: {
    name: 'email',
    label: 'Email Address',
    placeholder: 'jamesjohn@mail.com'
  },
  phone: {
    name: 'phone',
    label: 'Phone Number',
    placeholder: 'Phone Number'
  }
};
