import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../button/button';
import { Fonts } from '../inputs/input/input.styled';

export const Container = styled.div<{ isDelete: boolean; styled: any }>`
  width: 60%;
  max-width: 60rem;
  height: ${({ isDelete }) => (isDelete ? '50%' : '80%')};
  background-color: ${COLORS.white};
  border-radius: ${SPACES.l};
  overflow: hidden;

  position: relative;

  z-index: 12;

  ${({ styled }) => styled}

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    height: 90%;
  }
`;
export const StyledPopupLayout = css`
  max-width: 30rem;
`;

export const Container2 = styled.div<{ height?: string; styleds?: any }>`
  width: 60%;
  max-width: 60rem;
  height: ${({ height }) => height ?? '60%'};
  background-color: ${COLORS.white};
  border-radius: ${SPACES.l};
  overflow: hidden;

  position: relative;

  z-index: 4;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
  }

  ${({ styleds }) => styleds}
`;

export const TitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.black};
  padding: ${SPACES.xxxxxxls};

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: ${SPACES.xxxxl} ${SPACES.l};
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};
  color: ${COLORS.black};

  gap: ${SPACES.xxl};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.l};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;

export const Icon = styled.div`
  height: 0.875rem;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
  cursor: pointer;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 3rem 3rem 14rem 3rem;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: ${COLORS.white};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.purple};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: ${SPACES.l} ${SPACES.l} 12rem ${SPACES.l};
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: ${SPACES.xxxxxxls};
  background: ${COLORS.white};

  z-index: 10;
  display: flex;
  justify-content: space-between;

  &:nth-child(1) {
    display: none;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: ${SPACES.l};

    & .cancel {
      display: none;
    }
  }
`;

export const BtnContainer2 = styled.div`
  display: flex;
  gap: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const Btn = styled(Button)<{ isDelete?: boolean }>`
  width: ${({ isDelete }) => (isDelete ? '100%' : 'fit-content')};
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;

    & .cancel {
      display: none;
    }
  }
`;

export const Btn2 = styled(Btn)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    &:first-child {
      display: flex;
    }
  }
`;

export const DeleteTitle = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};
  color: ${COLORS.black};

  margin-bottom: ${SPACES.m};
`;

export const DeleteText = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const ErrorText = styled.div`
  ${Fonts};
  color: ${COLORS.mainRed};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  display: flex;
  align-items: center;
`;
