import React, { MouseEvent } from 'react';

import { Icons, Png } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { FilePath, newUtil } from '../../../../utils';
import { AvatarImage, Categories, IconsCard, StarContainer } from '../../../common/component/card';
import { toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { IVenueCard } from '../../types';
import * as Styled from './venue-card.styled';

export const VenueCard = ({ data, isUserFavorite = false, socket }: IVenueCard) => {
  const { user } = useHomeLayoutContext();

  const isUser = user?.id && +user?.id === +data.user_id;

  const main_image = data.main_image ? data.main_image : FilePath(Png.defaultBlack);
  const favorite = isUserFavorite ? FilePath(Icons.favoriteIcon) : FilePath(Icons.favoriteIcon2);
  const icons = [
    { icon: FilePath(Icons.venueIcon), text: 'Event Space', background: COLORS.green },
    { icon: FilePath(Icons.locationIcon), text: data.location ?? '', background: COLORS.blue }
  ];

  const onSpaces = () => {
    const spaces = data?.spaces;

    const small = spaces
      ?.filter((s) => s.capacity === 'Small')
      .sort((a, b) => a.standing_capacity - b.standing_capacity);
    const medium = spaces
      ?.filter((s) => s.capacity === 'Medium')
      .sort((a, b) => a.standing_capacity - b.standing_capacity);
    const large = spaces
      ?.filter((s) => s.capacity === 'Large')
      .sort((a, b) => a.standing_capacity - b.standing_capacity);

    const size = (arr: any[]) => {
      if (!arr?.length) {
        return '-';
      }
      if (arr.length === 1) {
        return arr[0].standing_capacity;
      }
      return `${arr[0].standing_capacity} - ${arr[arr.length - 1].standing_capacity}`;
    };

    const arr = [
      {
        length: small?.length ?? 0,
        text: `Small · ${small?.length}`,
        size: size(small?.filter((s) => s.standing_capacity.length))
      },
      {
        length: medium?.length ?? 0,
        text: `Medium · ${medium?.length}`,
        size: size(medium?.filter((s) => s.standing_capacity.length))
      },
      {
        length: large?.length ?? 0,
        text: `Large · ${large?.length}`,
        size: size(large?.filter((s) => s.standing_capacity.length))
      }
    ];

    return arr.filter((a) => a?.length !== 0);
  };

  const spaces = onSpaces();

  const { mutate: createFavorite } = toggleFavorite(undefined, data?.user_id, socket, 'Venues');

  const onFavorite = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    await createFavorite({
      role: 'Venues',
      user_id: user?.id,
      owner_id: data.id,
      to_user_id: data?.user_id
    });
  };

  return (
    <Styled.Container>
      <AvatarImage
        image={main_image}
        favorite={favorite}
        onFavorite={onFavorite}
        height='15rem'
        isUser={isUser}
      />

      <Styled.Content>
        <Styled.ContainerBetween>
          <Styled.Title className='title'>{data.name}</Styled.Title>
          <Styled.NewContainer>
            {newUtil(data?.createdAt, 0, 4) && <Styled.New>NEW</Styled.New>}
            <StarContainer rating={data.rating} />
          </Styled.NewContainer>
        </Styled.ContainerBetween>

        <IconsCard icons={icons} />

        {spaces && spaces?.length ? (
          <>
            <Styled.Text2>SPACES & CAPACITY</Styled.Text2>
            <Styled.Content3>
              {spaces?.length
                ? spaces.map(({ text, size }, index) => (
                    <Styled.Div key={index} className='full'>
                      <Styled.Icon
                        className='icon'
                        style={{
                          WebkitMaskImage: `url(${FilePath(Icons.capacity)})`,
                          WebkitMaskSize: '100% 100%',
                          maskImage: `url(${FilePath(Icons.capacity)})`,
                          background: COLORS.green
                        }}
                      />
                      <Styled.Capacity>
                        <Styled.Text>{text}</Styled.Text>
                        <Styled.Text>{size}</Styled.Text>
                      </Styled.Capacity>
                    </Styled.Div>
                  ))
                : null}
            </Styled.Content3>
          </>
        ) : null}

        <Categories categories={data?.categories} id={data?.id} />
      </Styled.Content>
    </Styled.Container>
  );
};
