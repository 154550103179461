import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../button/button';

export const Details = styled.details<{ styled: any }>`
  width: 100%;

  padding: ${SPACES.l} 0;

  & > summary::-webkit-details-marker {
    display: none;
  }

  ${({ styled }) => styled};
`;

export const Summary = styled.summary`
  list-style: none;
  display: flex;
  justify-content: space-between;
  
 overflow: auto;
  
  font-family: ${FONTS.FAMILIES.slussen}';
    font-style: normal;
    font-weight: ${FONTS.WEIGHTS.bold};
    font-size: ${FONTS.SIZES.l};
    line-height: ${FONTS.SIZES.xxxxl};
    color: ${COLORS.black};
        
  & > img {
    transition: all 0.35s ease-out;
  }
`;

export const Span = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${SPACES.xxxxl};
`;

export const FirstLayoutStyled = css`
  & > .children {
    @media screen and (max-width: ${MEDIA.tablet}) {
      padding: ${SPACES.l};
    }
  }
`;

export const Btn = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`;
