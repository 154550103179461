import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SHADOWS, SPACES } from '../../../../../theme';
import { IInputMask, IInputProps } from '../../../types';

export const OptionBlock = styled.div<Partial<IInputMask>>`
  width: ${({ width }) => (width ? `${width}!important` : '100%')};
  opacity: ${({ readOnly }) => (readOnly ? '0.4' : '1')};

  margin-left: ${({ ml }) => ml ?? '0'};
  margin-right: ${({ mr }) => mr ?? '0'};
  margin-bottom: ${({ mb }) => mb ?? '0'};
  margin-top: ${({ mt }) => mt ?? '0'};
  position: relative;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100%;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100% !important;
  }
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const Label = styled.label<{ required?: boolean; isError?: boolean; width?: string }>`
  display: block;

  ${Fonts}

  text-transform: capitalize;

  color: ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        right: 0;
        top: 0;
      }
    `}
`;

export const LabelOptional = styled.label`
  ${Fonts}
  color: ${COLORS.gray};
  margin-left: 2px;
`;

export const Error = styled.div`
  position: absolute;
  left: ${SPACES.xxxxxs};
  bottom: -${SPACES.xl};
  font-size: ${FONTS.SIZES.m};
  color: ${COLORS.mainRed};
`;

const commonStyles = css<IInputProps>`
  margin: 0;
  padding: ${({ innerPads }) => innerPads ?? `${SPACES.xs} ${SPACES.m}`};

  border: 1px solid ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};
  border-radius: ${SPACES.xxs};
  box-shadow: ${SHADOWS.xxs};
  background-color: ${COLORS.white};

  &:not(:focus-within) {
    cursor: pointer;
  }

  &::placeholder {
    color: ${({ placeholderColor, theme }) => placeholderColor ?? theme.COLORS.semiTransparentGrey};
  }

  &:focus-within {
    border: 1px solid ${COLORS.purple};
    outline: 1px solid ${COLORS.purple};
  }
`;

export const StyledMaskedInput = styled(InputMask).attrs(({ height, readOnly }: IInputProps) => ({
  height,
  readOnly
}))`
  width: 100% !important;
  height: ${({ height }) => height ?? '100%'};

  ${commonStyles}
  &:focus-within {
    outline: ${({ readOnly }) => readOnly && 'none'};
  }

  background: ${({ readOnly }) => readOnly && COLORS.white};
  margin-top: ${({ gapFromLabel }) => gapFromLabel ?? SPACES.xxxs};

  cursor: ${({ readOnly }) => (readOnly ? 'initial' : 'pointer')};
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'initial')};
`;
