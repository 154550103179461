import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 6rem);

  padding-left: ${SPACES.l};
  padding-bottom: 9.375rem;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    padding-left: 0;
    padding-bottom: 1.375rem;
    height: calc(100% - 2rem);
  }
`;

export const ArrowBackComponent = css`
  display: none;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: flex;
    margin: 0 0 ${SPACES.xxxxl} 0;
  }
`;

export const Text = styled.div`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const Text1 = styled(Text)`
  color: ${COLORS.gray};
  display: flex;
  gap: 4px;
`;

export const Title = styled(Text)`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: ${FONTS.SIZES.xxxxxxl_};
  line-height: ${FONTS.SIZES.xxxxxxxl};

  text-transform: uppercase;

  margin: ${SPACES.l} 0 ${SPACES.l} 0;
`;

export const Btn = styled(Button)`
  max-width: 12.5rem;
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.l};
    max-width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;

    align-items: flex-start;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.black};
  margin: ${SPACES.xxxxl} 0;
`;

export const Div = styled.div`
  cursor: pointer;

  &:hover {
    & > .line {
      border-top: 1px solid ${COLORS.purple};
      margin: ${SPACES.xxxxl} 0;
    }
  }
`;
