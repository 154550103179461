import type { XYCoord } from 'dnd-core';
import React, { useState } from 'react';
import type { FC } from 'react';
import { useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDrag, useDrop } from 'react-dnd';
import * as uuid from 'uuid';

import { Icons } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { DragItem, IImageCard } from '../../types';
import { Drawer } from '../drawer';
import { PopupLayout } from '../popup-layout';
import { Portal } from '../portal';
import { Slider } from '../slider';
import * as Styled from './gallery.styled';
import { FilePath } from '../../../../utils';

export const ItemTypes = {
  CARD: 'card'
};

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move'
};

export const ImageCard: FC<IImageCard> = ({
  id,
  index,
  path,
  moveCard,
  file,
  setCards,
  onDelete
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor: any) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const src = file ? URL.createObjectURL(file) : path || undefined;

  const cropRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onToggleDrawer = (flag: boolean) => {
    setModalOpen(flag);
  };

  const handleSave = async () => {
    if (cropRef) {
      // @ts-ignore
      const dataUrl = cropRef?.current.getImage().toDataURL();

      const result = await fetch(dataUrl);

      const blob = await result.blob();

      const changeFile = new File([blob], file.name, { type: blob.type });

      setCards((prev: any) => {
        prev.splice(index, 1, { id: uuid.v4(), file: changeFile });
        return [...prev];
      });

      onToggleDrawer(false);
    }
  };

  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const imageRef = useRef(null);

  const handleImageLoad = () => {
    if (imageRef.current) {
      // @ts-ignore
      const { height, width } = imageRef.current;

      // We save the size of the photo
      setImageWidth(width);
      setImageHeight(height);
    }
  };

  const isValid = () => {
    if (imageWidth && imageHeight) {
      if (imageWidth > imageHeight) {
        return false;
      }

      if (imageWidth > 600 && imageWidth > imageHeight) {
        return true;
      }
    }
    return true;
  };

  const [slideWidthValue, setSlideWidthValue] = useState<any>(isValid() ? 600 : 250);

  const onSlide2 = (value: any) => {
    setSlideWidthValue(value);
  };

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Styled.Item index={index} ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <Styled.Edit onClick={onToggleDrawer.bind(this, true)} index={index}>
        <Styled.EditIcon
          index={index}
          className='icon'
          style={{
            WebkitMaskImage: `url(${FilePath(Icons.editIcon)})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${FilePath(Icons.editIcon)})`
          }}
        />
      </Styled.Edit>

      <Styled.Delete onClick={onDelete.bind(this, index)} index={index}>
        <Styled.EditIcon
          index={index}
          className='icon'
          style={{
            WebkitMaskImage: `url(${FilePath(Icons.close)})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${FilePath(Icons.close)})`
          }}
        />
      </Styled.Delete>

      <Styled.Img src={src} alt='image' />

      {modalOpen && (
        <Drawer
          onClose={onToggleDrawer.bind(this, false)}
          open={modalOpen}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            <PopupLayout
              title='Edit Photo'
              saveText='Save Changes'
              onCancel={onToggleDrawer.bind(this, false)}
              onSave={file ? handleSave : onToggleDrawer.bind(this, false)}
              styled={!file && Styled.PopupLayoutStyled}
              style={{
                zIndex: 100,
                background: 'rgba(0, 0, 0, 0.61)'
              }}
            >
              {file ? (
                <>
                  <Styled.AvatarEditorDiv isValid={isValid()}>
                    <Styled.AvatarEditor isValid={isValid()}>
                      <img
                        ref={imageRef}
                        src={src}
                        alt='Фото'
                        onLoad={handleImageLoad}
                        style={{ display: !imageWidth ? 'block' : 'none' }}
                      />
                      <AvatarEditor
                        ref={cropRef}
                        image={src}
                        width={slideWidthValue}
                        height={416}
                        style={{
                          objectFit: 'contain',
                          border: `2px dashed ${COLORS.white}`,
                          resize: 'horizontal',
                          overflow: 'auto'
                        }}
                        border={10}
                        borderRadius={0}
                        color={[14, 13, 13, 0.4]}
                        scale={1}
                        rotate={0}
                      />
                    </Styled.AvatarEditor>
                  </Styled.AvatarEditorDiv>

                  <Slider
                    min={250}
                    max={800}
                    value={slideWidthValue}
                    onChange={onSlide2}
                    text='Zoom'
                  />
                </>
              ) : null}

              {!file ? (
                <Styled.TextPopup>
                  This image was previously uploaded. In order to crop it - please delete and
                  re-upload the new image to have the crop tool appear for it.
                </Styled.TextPopup>
              ) : null}
            </PopupLayout>
          </Portal>
        </Drawer>
      )}
    </Styled.Item>
  );
};
