import { Slider } from '@mui/material';
import { styled as styledMaterial } from '@mui/material/styles';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  gap: 10px;
  overflow: hidden;
`;
const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};
`;

export const Title = styled.p`
  ${Fonts};

  font-size: ${FONTS.SIZES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};

  margin: ${SPACES.xxxxxxls} 0 ${SPACES.l} 0;
`;

export const TextPopup = styled.p`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.normal};
`;

export const DivNoImage = styled.div`
  ${Center};
  flex-direction: column;
`;
export const Text = styled.p`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.normal};

  & span {
    font-size: ${FONTS.SIZES.xl};
    font-weight: ${FONTS.WEIGHTS.bold};
    line-height: ${FONTS.SIZES.xxxxxxl_};
    color: ${COLORS.purple};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    text-align: center;
    padding: 0 ${SPACES.l};
  }
`;

export const Item = styled.div<{ index: number }>`
  cursor: move;
  position: relative;
  padding: 0 !important;

  border-radius: 8px;

  ${({ index }) =>
    index === 0
      ? css`
          margin: 0 auto;
          width: 100%;
          height: 400px;
        `
      : css`
          width: 7.5rem;
          height: 4.625rem;
          display: flex;
          align-items: flex-start;
        `}

  ${Center};

  background: ${COLORS.black} !important;
`;

export const Close = styled.img<{ index: number }>`
  ${({ index }) =>
    index === 0
      ? css`
          width: 0.75rem;
          aspect-ratio: 1/1;
        `
      : css`
          width: 0.5rem;
          aspect-ratio: 1/1;
        `}
`;

export const EditIcon = styled.div<{ index: number }>`
  ${({ index }) =>
    index === 0
      ? css`
          width: 0.75rem;
          aspect-ratio: 1/1;
        `
      : css`
          width: 0.5rem;
          aspect-ratio: 1/1;
        `}
  background: ${COLORS.black};
`;

export const Edit = styled.div<{ index: number }>`
  height: ${({ index }) => (index === 0 ? '48px' : '24px')};
  aspect-ratio: 1/1;

  cursor: pointer;

  ${Center}
  background: ${COLORS.white50};
  border-radius: 8px;

  position: absolute;
  top: 4%;
  right: ${({ index }) => (index === 0 ? '10%' : '30%')};

  color: ${COLORS.white};
`;

export const Delete = styled(Edit)`
  right: ${({ index }) => (index === 0 ? '2%' : '4%')};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const InputContainer = styled.div<{ isLength: boolean; isError?: boolean }>`
  width: ${({ isLength }) => (isLength ? '5.5rem' : '100%')};
  height: ${({ isLength }) => (isLength ? '4.625rem' : '25rem')};
  position: relative;

  border: 1px dashed ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};

  ${Center}

  border-radius: 8px;

  input {
    cursor: pointer;

    position: absolute;
    width: 95%;
    height: 100%;
    opacity: 0;
    overflow: hidden;

    z-index: 2;
  }
`;

export const ErrorInfoContainer = styled.div<{ mb?: string }>`
  display: flex;
  align-items: center;
  position: absolute;

  left: 0;
  bottom: -17px
    ${({ mb }) =>
      mb &&
      css`
        margin-bottom: ${mb};
      `};
`;

export const ErrorInfoText = styled.div`
  box-sizing: border-box;
  font-family: ${FONTS.FAMILIES.inter};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  color: ${COLORS.mainRed};
`;

export const ContainerPopup = styled.div`
  width: 100%;
  height: 100%;

  background: rgba(14, 13, 13, 0.88);

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  position: relative;

  padding: ${SPACES.xxxxxxls} 0;

  z-index: 20;
`;

export const Div2 = styled.div`
  display: flex;
  justify-content: center;

  width: 100% !important;
  position: relative !important;
  max-height: 550px;

  & > img {
    height: auto;
    object-fit: contain;
  }
`;

export const Image = styled.img`
  height: 100% !important;
  object-fit: contain;
`;

export const Carousel = styled.div`
  width: 90%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
  }
`;

export const FirstImage = styled.img`
  margin: 0 auto;
  min-height: 40.5rem;
  max-height: 40.5rem;
  border-radius: 8px;

  object-fit: cover;
`;

export const DivImage = styled.div`
  display: flex;
  margin-top: 6.5rem;
  gap: ${SPACES.xxxxl};
  justify-content: center;

  @media screen and (max-width: ${MEDIA.tablet}) {
    overflow-x: auto;
    padding: 0 2rem 0 5rem;

    &::-webkit-scrollbar {
      width: 0.1px;
      background-color: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: node;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

export const ArrowBackComponent = css`
  margin: 0 0 ${SPACES.xxxxxxls} 0;
  color: ${COLORS.white};

  & > div > .icon {
    background: ${COLORS.white};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin: 0 0 6rem ${SPACES.l};
  }
`;

export const ImgCarousel = styled.img<{ isFocus: boolean }>`
  width: fit-content;
  border: ${({ isFocus }) => (isFocus ? `2px solid ${COLORS.purple}` : 'none')};
  border-radius: 4px;
`;

export const Count = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.white};
`;

export const AvatarEditorDiv = styled.div<{ isValid: boolean }>`
  width: 100%;

  margin-bottom: ${SPACES.xxxxxxl_};

  background: ${COLORS.black};
  border-radius: 8px;

  ${({ isValid }) =>
    isValid &&
    css`
      ${Center};
    `}
`;

export const AvatarEditor = styled.div<{ isValid: boolean }>`
  ${Center};
`;

export const PopupLayoutStyled = css`
  height: fit-content;

  & > .children {
    min-height: 100px !important;
    padding: 3rem 3rem 9rem 3rem;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    height: 45% !important;
    & > .children {
      padding: ${SPACES.l} ${SPACES.l} 4rem ${SPACES.l};
    }
  }
`;
