import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import io from 'socket.io-client';

import { APP_KEYS } from '../constants';

let socket: any = null;

export const useConnectionSocket = (id?: string) => {
  const client = useQueryClient();

  useEffect(() => {
    if (id) {
      socket = io(process.env.REACT_APP_SERVER_URL ?? '', {
        transports: ['websocket', 'polling'],
        query: {
          user_id: id
        }
      });

      // socket.on('connect', () => console.log(`Client connected: ${socket.id}`));

      socket.on('handler_notification', async () => {
        await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ALL_NOTIFICATION);
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
      };
    }
  }, [id]);

  return { socket };
};
