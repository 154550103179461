import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const Container = styled.div`
  width: 100%;
  background: ${COLORS.white};

  display: flex;
  flex-direction: column;
`;

export const SpaceBetween = styled.div`
  width: 100%;

  ${Center};
  justify-content: space-between;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    & ~ .star {
      gap: ${SPACES.xxs};
    }
  }
`;

export const UserImage = styled.img`
  width: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;
`;

export const SubTitle = styled.p`
  ${Fonts}
  font-weight: ${FONTS.WEIGHTS.bold};
  gap: 3px;
`;

export const Text = styled.p`
  ${Fonts}
`;

export const Date = styled.p`
  ${Fonts}

  margin-top: ${SPACES.l};
  color: ${COLORS.gray};
`;

export const UserRole = styled.span`
  ${Fonts};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
`;

export const DescriptionContainer = styled.div`
  margin-top: ${SPACES.xxl};
  padding-left: 4.5rem;
`;
