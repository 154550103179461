import styled, { css } from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import { CommonIcon, DivRole } from '../../../common/component/public-page';

export const IconRole = styled(CommonIcon)``;
export const DivRoleCommon = styled(DivRole)``;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  min-height: 400px;

  margin: 0 auto;
  padding-bottom: 2rem;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.black};
`;

export const IconsCardStyled = css`
  width: 100%;
  display: flex;
  gap: ${SPACES.xxxxxxls} !important;

  flex-wrap: wrap;

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.xxl};
  }

  & > .width {
    gap: ${SPACES.l};
  }
`;

export const EventContainer = styled.div<{ isCursor?: boolean }>`
  width: 100%;
  height: fit-content;
  border: 1px solid ${COLORS.black};
  background: ${COLORS.white};
  border-radius: 8px;

  ${({ isCursor }) =>
    isCursor &&
    css`
      cursor: pointer;
    `}

  ${Center};
  justify-content: space-between;

  margin: ${SPACES.xxxxl} 0;

  &:hover {
    border-color: ${COLORS.purple};

    & > .to {
      border-left-color: ${COLORS.purple};
    }
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;
    margin: ${SPACES.s} 0;
  }
`;

export const Btn = styled(Button)`
  text-transform: uppercase;
`;

export const ContainerTo = styled.div`
  width: 104px;
  height: 106px;

  ${Center};

  padding: ${SPACES.l};

  & > .date {
    display: none;
  }

  border-left: 1px solid ${COLORS.black};

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    border-left: none;
    border-top: 1px solid ${COLORS.black};
    display: flex;
    justify-content: space-between;

    & > .date {
      display: flex;
    }
  }
`;

export const Div = styled.div`
  width: calc(100% - 104px);
  ${Center};
  justify-content: space-between;
  padding: ${SPACES.xxxxl};

  & > .date {
    display: flex;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;

    padding: ${SPACES.l};

    & > .date {
      display: none;
    }
  }
`;

export const UserDiv = styled(Div)<{ isBorder?: boolean; isWidth?: boolean }>`
  width: 45%;
  border-right: ${({ isBorder }) => (isBorder ? 'none' : `1px solid ${COLORS.black}`)};
  border-bottom: none;

  display: flex;

  &:first-child {
    width: 55%;
  }

  &.user {
    cursor: pointer;
  }

  &:hover {
    & > div > div > .name {
      color: ${COLORS.purpleHover};
    }
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;

    padding: ${SPACES.s};

    border-right: none;

    &:first-child {
      width: 100%;
      border-bottom: 1px solid ${COLORS.black};
    }
    &:last-child {
      padding-top: 0;
    }
  }
`;

export const IconStar = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.star};
`;

export const Image = styled.img`
  height: 1rem;
  aspect-ratio: 1/1;
`;

export const DivRole2 = styled.div`
  ${Center};
  height: 3.5rem;
  aspect-ratio: 1/1;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;

  &:hover {
    border-color: ${COLORS.purple};
  }
`;

export const Div3 = styled.div`
  ${Center};
  gap: 10px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;
