import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { userService } from '../../../../services';
import { IAuthError, IGetUser } from '../../../../types';
import { PopupLayout } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage, IToken } from '../../../common/types';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { validationSchemaUpdateUserInfo } from '../../validation/shema';
import { EditUserInfo } from './edit-user-info';
import * as Styled from './edit-user-info.styled';

export interface IEditUserInfoPopup {
  onCancel: () => void;
}

export const EditUserInfoPopup = ({ onCancel }: IEditUserInfoPopup) => {
  const client = useQueryClient();
  const history = useHistory();
  const { user } = useHomeLayoutContext();

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async (data: IMessage | IToken) => {
    if ('token' in data) {
      localStorage.setItem(APP_KEYS.STORAGE_KEYS.TEST_TOKEN, data.token);
      localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
      history.push(APP_KEYS.ROUTER_KEYS.CHECK_EMAIL);
    }

    if ('message' in data) {
      toastContainer.success({ title: data.message });

      await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_USER);
    }

    onCancel();
  };

  const { mutate: updateUserInfo } = useMutation<any, AxiosError<IAuthError>, Partial<IGetUser>>(
    (data: Partial<IGetUser>) => userService.updateUserInfo(data),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = (data: Partial<IGetUser>) => {
    updateUserInfo(data);
  };

  return (
    <Formik
      initialValues={{
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone !== '' ? user?.phone : 'Phone Number ',
        company_name: user?.company_name
      }}
      enableReinitialize
      validationSchema={validationSchemaUpdateUserInfo}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Styled.CommonForm2>
          <PopupLayout
            title='Edit Account Info'
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            <Styled.Title>Tell Us About Yourself</Styled.Title>
            <Styled.SubTitle>
              You will need to re-fill your profile and this may affect functionality in the future.
            </Styled.SubTitle>

            <EditUserInfo />
          </PopupLayout>
        </Styled.CommonForm2>
      )}
    </Formik>
  );
};
