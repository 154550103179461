export const useIsHyperLink = (title: string, text: any) => {
  if (title === 'Phone Number') {
    return { flag: true, on: () => (window.location.href = `tel:${text}`) };
  }
  if (title === 'Email Address') {
    return { flag: true, on: () => (window.location.href = `mailto:${text}`) };
  } else {
    return { flag: false, on: () => {} };
  }
};
