import { Formik, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { SPACES } from '../../../../theme';
import { IJobTypeFormik } from '../../../../types';
import {
  Button,
  ContactInformation,
  Input,
  InputMatchedWords,
  InputTextArea,
  PopupLayout
} from '../../../common/component';
import { InputConst, Supplier_Vendor_Talent_Const } from '../../../common/constants';
import * as CommonStyled from '../../../event/component/event-public/event-public.styled';
import { getAllEventByUserId } from '../../../event/hooks';
import { Location } from '../../../home-layout/constants';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { createJobBy, editJobById, getJobById } from '../../hooks';
import { ICreateJob } from '../../types';
import { validationSchemaCreateJob } from '../../validation/shema';
import * as Styled from './create-job.styled';
import { FilePath } from '../../../../utils';

export const CreateJob = ({ onCancel, onDelete, isEdit = false }: ICreateJob) => {
  const { user } = useHomeLayoutContext();
  const { search } = useLocation();

  const [isShow, setShow] = useState(true);

  const inputDays = [
    { name: 'start_date', label: 'Start Date', placeholder: 'Start Date' },
    { name: 'end_date', label: 'End Date', placeholder: 'End Date' }
  ];

  const onDeleteEvent = (setValues: any, index: number) => () => {
    setValues((v: any) => {
      const value = getIn(v, 'events');

      value.splice(index, 1);
      return { ...v, events: [...value] };
    });
  };

  const onEvent = (_values: any): string[] => {
    const values: string[] = getIn(_values, 'events');
    const copyValues = [...values];

    return copyValues.splice(0, isShow ? 1 : values.length);
  };

  const id = search.split('=')[1];

  const { data: jobData } = getJobById(id, { enabled: isEdit });
  const { mutate: createJob } = createJobBy();
  const { mutate: editJob } = editJobById(id);

  const isd = isEdit ? jobData?.job.user_id : user?.id;

  const { data, refetch } = getAllEventByUserId(isd);

  useEffect(() => {
    if (jobData?.job.user_id) {
      refetch();
    }
  }, [jobData?.job.user_id]);

  const events = data?.events;

  const onSubmit = (_data: IJobTypeFormik) => {
    const _events = _data.events
      ? _data.events.map((e: string) => {
          const event = events && events.find((a) => a.name === e);
          return event?.id;
        })
      : [];

    const categories = JSON.stringify(_data.categories);

    isEdit
      ? editJob({ ..._data, events: _events, categories })
      : createJob({ ..._data, events: _events, categories });

    onCancel();
  };

  const jobEvents = jobData?.job.events ? jobData?.job?.events : '';
  const jobEvents2 = jobEvents
    ? jobEvents.map((j: number) => {
        const e = events?.find((ev) => +ev.id === j);
        return e?.name;
      })
    : [];

  const categories = jobData?.job.categories ? jobData?.job.categories : [];

  return (
    <Formik
      initialValues={{
        job_title: isEdit ? (jobData?.job.job_title as string) : '',
        location: isEdit ? (jobData?.job.location as string) : '',
        address: isEdit ? (jobData?.job.address as string) : '',
        start_date: isEdit ? (jobData?.job.start_date as string) : '',
        end_date: isEdit ? (jobData?.job.end_date as string) : '',
        status: isEdit ? (jobData?.job.status as string) : '',
        hourly_rate: isEdit ? (jobData?.job.hourly_rate as string) : '',
        categories: isEdit ? categories : [],
        description: isEdit ? (jobData?.job.description as string) : '',
        events: isEdit ? jobEvents2.filter((j: any) => typeof j === 'string') : []
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchemaCreateJob}
    >
      {({ values, setValues, touched, errors }) => (
        <Styled.CommonForm>
          <PopupLayout
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            title={isEdit ? 'Edit Event' : 'Create a Job Posting'}
            saveText={isEdit ? 'Save Changes' : 'post job'}
            isEdit={isEdit}
            onDelete={onDelete}
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            <Styled.TextContainer>
              <Styled.SubTitle>Job Information</Styled.SubTitle>
            </Styled.TextContainer>

            <Styled.UserInfoContainer>
              <Input
                mb={SPACES.l}
                height='3.5rem'
                name='job_title'
                label='Job Title'
                placeholder='Job Title'
              />

              <Styled.CommonInputDropdownCroup
                width='48%'
                matchedWords={Location}
                isFilter
                isFilterData
                item='cities'
                titleItem='state'
                height='3.5rem'
                mt='0'
                {...InputConst.location}
              />

              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                width='100%'
                {...InputConst.venue}
                name='address'
              />

              {inputDays.map(({ label, name, placeholder }, index) => (
                <Styled.CommonInputCalendar
                  key={index}
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  width='48%'
                  height='3.5rem'
                  mb={SPACES.l}
                />
              ))}

              <Styled.CommonInputMatchedWords
                matchedWords={['Hiring', 'Closed']}
                readOnlyKeyboard
                height='3.5rem'
                width='48%'
                isInput
                {...InputConst.status}
              />

              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                width='100%'
                type='number'
                {...InputConst.hourly_rate}
              />
            </Styled.UserInfoContainer>

            {events?.length ? (
              <>
                <Styled.TextContainer>
                  <Styled.SubTitle>Event Information</Styled.SubTitle>
                </Styled.TextContainer>

                <InputMatchedWords
                  matchedWords={events?.length ? events?.map((e) => e.name) : []}
                  name='events'
                  placeholder='Enter the name of an existing event'
                  height='3.5rem'
                  width='100%'
                  label='Events'
                  isFilter
                  isInput
                  isChip
                  mt={SPACES.xxxxl}
                />

                {getIn(values, 'events').length
                  ? onEvent(values).map((value: string, index: number) => {
                      const event = events && events.find((e) => e.name === value);

                      return (
                        <Styled.EventContainer key={index}>
                          <Styled.Event>
                            <Styled.Div className='first'>
                              <Styled.EventImage src={event?.main_image} alt='event image' />
                              <Styled.Div2>
                                <Styled.SubTitle>{event?.name}</Styled.SubTitle>
                                <Styled.LocationText>
                                  {event?.location}, {event?.venue}
                                </Styled.LocationText>
                              </Styled.Div2>
                            </Styled.Div>
                            <Styled.Div className='last'>
                              <Styled.DivRole2>
                                <Styled.IconRole
                                  className='icon'
                                  style={{
                                    WebkitMaskImage: `url(${FilePath(Icons.calendarIcon)})`,
                                    WebkitMaskSize: '100% 100%',
                                    maskImage: `url(${FilePath(Icons.calendarIcon)})`
                                  }}
                                />
                              </Styled.DivRole2>

                              <div className='date'>
                                <CommonStyled.SubTitle>Date</CommonStyled.SubTitle>
                                <CommonStyled.UserRole>
                                  <Styled.Text>
                                    {event?.start_data} - {event?.end_data}
                                  </Styled.Text>
                                </CommonStyled.UserRole>
                              </div>

                              <Styled.Text className='text'>
                                {event?.start_data} - {event?.end_data}
                              </Styled.Text>

                              <Styled.IconRole
                                style={{
                                  WebkitMaskImage: `url(${FilePath(Icons.calendarIcon)})`,
                                  WebkitMaskSize: '100% 100%',
                                  maskImage: `url(${FilePath(Icons.calendarIcon)})`
                                }}
                              />
                            </Styled.Div>
                          </Styled.Event>

                          <Styled.DeleteContainer>
                            <Button
                              onClick={onDeleteEvent(setValues, index)}
                              type='button'
                              content=''
                              variant='inverse3'
                              startIcon={FilePath(Icons.deleteIcon)}
                              widthIcon={SPACES.sm}
                              pads={`${SPACES.l} ${SPACES.xs}`}
                            />
                          </Styled.DeleteContainer>
                        </Styled.EventContainer>
                      );
                    })
                  : null}

                {getIn(values, 'events').length > 1 && (
                  <Styled.Btn
                    onClick={() => setShow(!isShow)}
                    type='button'
                    content={isShow ? `show ${getIn(values, 'events').length - 1} more` : 'Hide'}
                    variant='primaryInverse'
                  />
                )}
              </>
            ) : null}

            <Styled.TextContainer>
              <Styled.SubTitle>Additional Information</Styled.SubTitle>
            </Styled.TextContainer>

            <InputMatchedWords
              matchedWords={Supplier_Vendor_Talent_Const}
              height='3.5rem'
              width='100%'
              isFilter
              isChip
              mt={SPACES.xxxxl}
              {...InputConst.categories}
            />

            <InputTextArea
              name='description'
              placeholder=''
              rows={10}
              label='Bio'
              maxLength={5000}
              mt={SPACES.xxxxl}
            />

            <ContactInformation user={user} />
          </PopupLayout>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
