import { Icons } from '../../../assets';
import { FilePath } from '../../../utils';

export const roles = [
  { text: 'EVENTS', icon: FilePath(Icons.eventIcon), table: 'Events', tab: 'events' },
  {
    text: 'VENDORS',
    icon: FilePath(Icons.vendorIcon),
    table: 'Vendors',
    tab: 'vendors'
  },
  {
    text: 'TALENT',
    icon: FilePath(Icons.talentIcon),
    table: 'Talents',
    tab: 'talents'
  },
  { text: 'VENUE', icon: FilePath(Icons.venueIcon), table: 'Venues', tab: 'venues' },
  { text: 'JOB POSTINGS', icon: FilePath(Icons.jobIcon), table: 'Jobs', tab: 'jobs_posting' },
  { text: 'EVENT PLANNERS', icon: FilePath(Icons.talentIcon), table: 'EventPlaners', tab: 'event_planners' }
];
