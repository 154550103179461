import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../theme';
import { Loader, NotFoundItem } from '../common/component';
import { APP_KEYS } from '../common/constants';
import { EventPlanerCard } from '../event-planer/component';
import { EventCard } from '../event/component';
import { Tads } from '../home-layout/component/tabs/tads';
import { urlUtil } from '../home-layout/utils';
import { JobCard } from '../job/component';
import { TalentCard } from '../talent/component';
import { VendorCard } from '../vendor/component';
import { VenueCard } from '../venue/component';
import * as Styled from './favorites.styled';
import { getAllFavorites } from './hooks';

export const Favorites = () => {
  const history = useHistory();

  const [value, setValue] = useState('events');

  const onClick = (tab: string) => {
    setValue(tab);
  };

  const Date: { [key: string]: any } = {
    events: {
      Component: EventCard,
      props: {},
      link: 'PUBLIC_EVENT',
      role: 'Events',
      data: 'Event'
    },
    vendors: {
      Component: VendorCard,
      props: {},
      link: 'PUBLIC_VENDOR',
      role: 'Vendors',
      data: 'Vendor'
    },
    talents: {
      Component: TalentCard,
      data: 'Talent',
      props: {},
      link: 'PUBLIC_TALENT',
      role: 'Talents'
    },
    venues: {
      Component: VenueCard,
      props: {},
      link: 'PUBLIC_VENUE',
      role: 'Venues',
      data: 'Venue'
    },
    jobs_posting: {
      Component: JobCard,
      props: { isStatus: false, isFavorite: true },
      link: 'PUBLIC_JOB',
      role: 'Jobs',
      data: 'Job'
    },
    event_planners: {
      Component: EventPlanerCard,
      props: {},
      link: 'PUBLIC_EVENT_PLANER',
      role: 'EventPlaners',
      data: 'EventPlaner'
    }
  };

  const { Component, props, data, link, role } = Date[value];

  const onHistory = (_link: string, id: string) => () => {
    // @ts-ignore
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS[_link])}?id=${id}`);
  };

  const { isLoading, data: favoritesData, refetch } = getAllFavorites(role, true);

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  return (
    <Styled.Container>
      <Styled.Title> Favorites</Styled.Title>

      <Tads value={value} onClick={onClick} styles={Styled.TadsStyles} />

      {favoritesData?.favorites.length ? <Styled.Line /> : null}

      <Styled.EventContainer length={!!favoritesData?.favorites.length}>
        {favoritesData?.favorites.length ? (
          favoritesData?.favorites.map((d: any) => (
            <Styled.DivEvent key={d.id}>
              <Styled.DivEvent2 onClick={onHistory(link, d[data]?.id)}>
                {d[data] && <Component data={d[data]} {...props} isUserFavorite />}
              </Styled.DivEvent2>
              <Styled.Line className='line' />
            </Styled.DivEvent>
          ))
        ) : (
          <NotFoundItem
            title='No Favorites Yet'
            subTitle='You can add an item yo your favorites by clicking the heart.'
          />
        )}
      </Styled.EventContainer>
    </Styled.Container>
  );
};
