import axios from 'axios';

import { APP_KEYS } from '../module/common/constants';
import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import { IGetAllEventPlaners, IGetAllTalentsParameter, IGetEventPlaner } from '../types';

class EventPlanerService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getEventPlaners(
    data: Partial<IGetAllTalentsParameter>
  ): Promise<ExpectedFromQuery<IGetAllEventPlaners>> {
    return this.httpService.post<IGetAllEventPlaners, Partial<IGetAllTalentsParameter>>(
      '/event_planer/all',
      data
    );
  }

  async getEventPlaner(id: string): Promise<ExpectedFromQuery<IGetEventPlaner>> {
    return this.httpService.get<IGetEventPlaner>(`/event_planer?event_planer_id=${id}`);
  }

  async editEventPlaner(data: any, id: string): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/event_planer?event_planer_id=${id}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }
}

const factory = new HttpFactoryService();
export const eventPlanerService = new EventPlanerService(factory.createAuthHttpService());
