import { AxiosError } from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Png } from '../../../../assets';
import { notificationService } from '../../../../services';
import { COLORS } from '../../../../theme';
import { IAuthError } from '../../../../types';
import { FilePath, postAgo } from '../../../../utils';
import { Drawer, Loader } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { Iuuid1 } from '../../../common/types';
import { useHomeLayoutContext } from '../../hooks';
import { urlUtil } from '../../utils';
import * as Styled from './notification.styled';

export interface INotifications {
  onClose: () => void;
}

const dataRole: { [key: string]: any } = {
  'EVENT PLANNER': {
    link: 'PUBLIC_EVENT_PLANER'
  },
  VENDOR: {
    link: 'PUBLIC_VENDOR'
  },
  VENUE: {
    link: 'PUBLIC_VENUE'
  },
  TALENT: {
    link: 'PUBLIC_TALENT'
  }
};

export const Notifications = ({ onClose }: INotifications) => {
  const history = useHistory();
  const client = useQueryClient();

  const { user } = useHomeLayoutContext();

  const { isLoading, data } = useQuery(APP_KEYS.QUERY_KEYS.GET_ALL_NOTIFICATION, () =>
    notificationService.allNotification()
  );

  const onSuccess = async () => {
    await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_NOTIFICATION]);

    await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_USER]);
  };

  const onSuccess2 = async () => {
    await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_USER]);
  };

  const { mutate: updateIsReadNotification } = useMutation<
    any,
    AxiosError<IAuthError>,
    { system_notification_ids: any }
  >(
    (_data: { system_notification_ids: any }) =>
      notificationService.updateIsReadNotification(_data),
    {
      onSuccess
    }
  );

  const { mutate: redSystemNotifications } = useMutation<
    any,
    AxiosError<IAuthError>,
    { notification_id: Iuuid1 }
  >((_data: { notification_id: Iuuid1 }) => notificationService.redSystemNotifications(_data), {
    onSuccess: onSuccess2
  });

  const onAllRead = () => {
    const system_notification_ids = data?.notifications
      .filter(
        (s) =>
          s.description && !(new Date().getMonth() - new Date(s.createdAt ?? '').getMonth() >= 2)
      )
      .filter((a) => !user?.system_notification_ids?.map((s) => s.id).includes(a.id))
      .map((v) => ({ id: v.id, createdAt: new Date() }));

    const a = user?.system_notification_ids ?? [];

    // @ts-ignore
    const _system_notification_ids = [...system_notification_ids, ...a];

    updateIsReadNotification({ system_notification_ids: _system_notification_ids });
  };

  const onToUser = (userRole?: string, id?: string) => {
    // @ts-ignore
    const { link } = dataRole[userRole];

    // @ts-ignore
    const a = `${urlUtil(APP_KEYS.ROUTER_KEYS[link])}?id=${id}`;

    history.push(a);
    onClose();
  };

  const onToReview = (userRole?: string, id?: string) => {
    onToUser(userRole, id);
  };

  const onRedSystemNotifications = (link?: string, notification_id?: string) => {
    window.open(link, '_blank');

    // @ts-ignore
    redSystemNotifications({ notification_id });
  };

  const text1 = (
    role?: string,
    userRole?: string,
    id?: string,
    link?: string,
    notification_id?: string,
    link_text?: string
  ) => {
    let text;
    switch (role) {
      case undefined:
        text = (
          <>
            {' '}
            Read for{' '}
            <span
              onClick={onRedSystemNotifications.bind(this, link, notification_id)}
              className='link'
            >
              {link_text ?? 'more information'} .
            </span>
          </>
        );
        break;
      case 'Favorite':
        text = ' just added you to their favorites.';
        break;
      default:
        text = (
          <>
            {' '}
            left you a{' '}
            <span onClick={onToReview.bind(this, userRole, id)} className='link'>
              review!
            </span>
          </>
        );
    }

    return text;
  };

  const onIsRead = (id?: string, createdAt?: string) => {
    if (new Date().getMonth() - new Date(createdAt ?? '').getMonth() >= 2) return false;
    if (!user?.system_notification_ids) return true;

    return !user?.system_notification_ids.find((v) => v.id === id);
  };

  return (
    <>
      <Drawer
        onClose={onClose}
        open
        slidePosition='right'
        contentPosition='right'
        style={{ background: 'none' }}
      >
        <Styled.SettingContainer className='setting' onClick={(e) => e.stopPropagation()}>
          <Styled.Div>
            <Styled.Title>NOTIFICATIONS</Styled.Title>
            <Styled.SubTitle onClick={onAllRead}>MARK ALL READ</Styled.SubTitle>
          </Styled.Div>
          <Styled.Line2 />

          <Styled.Scroll>
            {isLoading && <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />}
            {!isLoading &&
              data?.notifications.map((value, index) => {
                const isRole = !!value?.role;

                const isRoleUser = value?.from_user?.role !== 'EVENT PLANNER';

                const isRead = isRole ? !value.is_read : onIsRead(value?.id, value?.createdAt);

                const pnd =
                  value?.from_user && value?.from_user.avatar
                    ? value?.from_user.avatar
                    : FilePath(Png.defaultBlack);
                const from_user = value?.from_user ?? {};
                const to_user = value?.to_user ?? {};

                return (
                  <Styled.FavoritesOrReview key={index}>
                    {isRead && <Styled.Dot />}

                    <Styled.Info>
                      <Styled.Avatar src={pnd} alt='avatar' />

                      <Styled.TextContainer>
                        <Styled.Text isRole={isRole} isRoleUser={isRoleUser || isRole}>
                          <span
                            onClick={() =>
                              isRoleUser ||
                              (isRole && onToUser(from_user?.role, from_user?.owner_id))
                            }
                          >
                            {isRole ? from_user.first_name : 'New Update!'}
                          </span>
                          {text1(
                            value?.role,
                            to_user?.role,
                            to_user?.owner_id,
                            value.link,
                            value?.id,
                            value.link_text
                          )}
                        </Styled.Text>
                        <Styled.Time>{postAgo(value?.createdAt)} ago</Styled.Time>

                        {value?.description && (
                          <Styled.Description>{value.description}</Styled.Description>
                        )}
                      </Styled.TextContainer>
                    </Styled.Info>

                    {index !== data?.notifications.length - 1 && <Styled.Line />}
                  </Styled.FavoritesOrReview>
                );
              })}
          </Styled.Scroll>
        </Styled.SettingContainer>
      </Drawer>
    </>
  );
};
