import { Form } from 'formik';
import styled from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Input, InputMask } from '../../../common/component';

export const CommonForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: ${SPACES.xxl} 0;
`;

export const CommonForm2 = styled(Form)`
  width: 100%;
`;

export const CommonInput = styled(Input)`
  width: 48%;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100%;
  }
`;

export const Div = styled.div`
  width: 48%;
  height: fit-content;
  gap: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CommonInputMask = styled(InputMask)`
  height: 3.5rem;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100% !important;
  }
`;

export const SubTitle = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const Hide = styled(SubTitle)<{ readOnly: boolean }>`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.bold};

  color: ${COLORS.purple};

  opacity: ${({ readOnly }) => (readOnly ? '0.4' : '1')};
  cursor: ${({ readOnly }) => (readOnly ? 'initial' : 'pointer')};

  &:hover {
    color: ${COLORS.purpleHover};
  }
`;

export const Title = styled(SubTitle)`
  font-size: ${FONTS.SIZES.xxxxl};
  font-weight: ${FONTS.WEIGHTS.bold};
  line-height: ${FONTS.SIZES.xxxxxxl_};
  margin-bottom: 16px;
`;
