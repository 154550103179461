import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { vendorService } from '../../../services';
import { IAuthError } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { urlUtil } from '../../home-layout/utils';

const onError = (_err: AxiosError<IAuthError>, history: any) => {
  const err = _err.response?.data as IAuthError;

  toastContainer.error({ title: err.message ?? _err.message });
  history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
};

export const getVendorById = (id: string) => {
  const history = useHistory();

  return useQuery([APP_KEYS.QUERY_KEYS.GET_VENDOR, id], () => vendorService.getVendor(id), {
    onError: (err: AxiosError<IAuthError>) => onError(err, history)
  });
};

export const getAllVendors = (data: any, values: any, page: number, value: string) =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.GET_ALL_VENDORS, values, page],
    () => vendorService.getVendors(data),
    {
      enabled: value === 'vendors'
    }
  );
