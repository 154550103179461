import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { authService } from '../../../../services';
import { COLORS, SPACES } from '../../../../theme';
import { IAuthError, IForgotPassword } from '../../../../types';
import { Input, Loader } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessageEmail } from '../../../common/types';
import { AuthLayout } from '../../common/component/auth-layout/auth-layout';
import * as CommonStyled from '../../common/styles/common.styled';
import { validationSchemaForgotPassword } from '../../validation/shema';
import * as Styled from './forgot-password.styled';

export const ForgotPassword = () => {
  const history = useHistory();

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message, email }: IMessageEmail) => {
    toastContainer.success({ title: message });
    history.push(APP_KEYS.ROUTER_KEYS.CHECK_EMAIL_FORGOT);
    localStorage.setItem(APP_KEYS.STORAGE_KEYS.USER_EMAIL, email);
  };

  const { mutate: forgotPassword, isLoading } = useMutation<
    any,
    AxiosError<IAuthError>,
    IForgotPassword
  >((req: IForgotPassword) => authService.forgotPassword(req), {
    onSuccess,
    onError
  });

  const onSubmit = (data: IForgotPassword) => {
    forgotPassword(data);
  };

  const onClick = () => {
    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  return (
    <AuthLayout onClick={onClick} isScroll isScrollMobile>
      <Styled.Div>
        <Formik
          initialValues={{
            email: ''
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchemaForgotPassword}
        >
          {({ isValid }) => (
            <Form>
              <CommonStyled.CommonDiv>
                <Styled.TitleT>FORGOT PASSWORD</Styled.TitleT>
                <CommonStyled.SubTitle>
                  Please enter the email address you’d like your password reset information sent to.
                </CommonStyled.SubTitle>

                <Input
                  mt={SPACES.xxxxxxls}
                  mb={SPACES.xxxxxxl}
                  name='email'
                  label='Email Address'
                  placeholder='Email Address'
                  height='3.5rem'
                />

                <CommonStyled.Btn>
                  <Styled.Btn
                    content={
                      !isLoading ? (
                        'Next'
                      ) : (
                        <Loader size='small' color={COLORS.purple} height='auto' />
                      )
                    }
                    type='submit'
                    variant='primary'
                    width='7.5rem'
                    disabled={!isValid || isLoading}
                  />
                </CommonStyled.Btn>
              </CommonStyled.CommonDiv>
            </Form>
          )}
        </Formik>
      </Styled.Div>
    </AuthLayout>
  );
};
