import { getIn, useFormikContext } from 'formik';
import React from 'react';

import { SPACES } from '../../../../theme';
import { Input } from '../../../common/component';
import { inputs } from '../../constants';
import * as Styled from './edit-user-info.styled';

export const EditUserInfo = ({ readOnly = false }: { readOnly?: boolean }) => {
  const { values, setValues } = useFormikContext();

  return (
    <Styled.CommonForm>
      {inputs.map(({ name, label }, index) => {
        if (name === 'phone') {
          return (
            <Styled.Div>
              <Styled.CommonInputMask
                mask='+9-999-999-9999'
                placeholder='Phone Number'
                name='phone'
                readOnly={readOnly}
                height='3.5rem'
                label='Phone Number'
                isHidePhone
                mb={SPACES.xxxxs}
              />

              <Styled.Hide
                readOnly={readOnly}
                onClick={() => {
                  !readOnly &&
                    setValues((prev: any) => ({
                      ...prev,
                      is_hide_phone: !getIn(prev, 'is_hide_phone')
                    }));
                }}
              >
                {!getIn(values, 'is_hide_phone') ? 'SHOW PHONE NUMBER' : 'HIDE PHONE NUMBER'}
              </Styled.Hide>
            </Styled.Div>
          );
        }
        return (
          <Styled.CommonInput
            key={index}
            mb={SPACES.l}
            height='3.5rem'
            name={name}
            label={label}
            width='100%'
            readOnly={readOnly}
          />
        );
      })}

      <Input
        mb={SPACES.xxxxl}
        height='3.5rem'
        name='company_name'
        label='Company Name'
        width='100%'
        readOnly={readOnly}
      />
    </Styled.CommonForm>
  );
};
