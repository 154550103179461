import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaEditTalent = Yup.object().shape({
  first_name: validateSchema.text,
  last_name: validateSchema.text,

  occupation: validateSchema.text,
  location: validateSchema.text,
  is_hide_phone: Yup.boolean(),

  phone: validateSchema.phone,
  email: validateSchema.email,
  hourly_rate: validateSchema.hourly_rate.nullable(),

  description: validateSchema.text,
  categories: validateSchema.categories
});

export const validationSchemaEditTalentExperience = Yup.object().shape({
  experiences: Yup.array().of(
    Yup.object().shape({
      occupation: validateSchema.text,
      company: validateSchema.text,
      start_date: validateSchema.text,
      end_date: validateSchema.text.when('start_date', (start: any, schema) => {
        return schema.test('end_date', 'invalid date', function (value) {
          const { start_date } = this.parent;
          const startDateObj = new Date(start_date);
          const endDateObj = new Date(value);

          return endDateObj >= startDateObj;
        });
      }),
      description: validateSchema.text
    })
  )
});
