import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { adminService } from '../../../../services';
import { COLORS } from '../../../../theme';
import { IAuthError, IDeleteObject } from '../../../../types';
import { transformDate } from '../../../../utils';
import {
  DeleteLayout,
  Drawer,
  Loader,
  NotFoundItem,
  Pagination,
  Portal
} from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage } from '../../../common/types';
import { Tads } from '../../../home-layout/component/tabs/tads';
import { roles } from '../../../home-layout/constants';
import { urlUtil } from '../../../home-layout/utils';
import { HeaderPage } from '../../common/page';
import * as Styled from './objects.styled';

const _data: { [key: string]: string } = {
  events: 'PUBLIC_EVENT',
  talents: 'PUBLIC_TALENT',
  vendors: 'PUBLIC_VENDOR',
  venues: 'PUBLIC_VENUE',
  jobs_posting: 'PUBLIC_JOB',
  event_planners: 'PUBLIC_EVENT_PLANER'
};

export const Objects = () => {
  const history = useHistory();
  const client = useQueryClient();

  const [value, setValue] = useState('events');
  const [page, setPage] = useState<number>(1);
  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [userId, setUserId] = useState<null | string | number>(null);

  const { table } = roles.filter((v) => v.tab === value)[0];

  const onBack = () => {
    history.goBack();
  };

  const onClick = (str: string) => {
    setValue(str);
    setPage(1);
  };

  const onToPost = (id: string | number) => {
    // @ts-ignore
    const link = _data[value];

    // @ts-ignore
    const a = `${urlUtil(APP_KEYS.ROUTER_KEYS[link])}?id=${id}`;

    history.push(a);
  };

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });
    await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ADMIN_OBJECTS);
    setEditComponent(null);
  };

  const { mutate: getDeleteObject } = useMutation<any, AxiosError<IAuthError>, IDeleteObject>(
    (data: IDeleteObject) => adminService.getDeleteObject(data),
    {
      onSuccess,
      onError
    }
  );

  const onDelete = (str: string | null) => {
    setEditComponent(str);
  };

  const onDeletePost = () => {
    // @ts-ignore
    getDeleteObject({ id: userId, table });
  };

  const { isLoading, data } = useQuery([APP_KEYS.QUERY_KEYS.GET_ADMIN_OBJECTS, page, table], () =>
    adminService.getAllObjects(page, table)
  );

  const isEventsOrJobs = table === 'Events' || table === 'Jobs'

  return (
    <Styled.Container>
      <HeaderPage
        onBack={onBack}
        title='ALL Posts'
        content={
          <>
            Created{' '}
            <Styled.Text>
              {data?.count} {table}
            </Styled.Text>
          </>
        }
      />

      <Styled.NawTab>
        <Tads value={value} onClick={onClick.bind(this)} />
      </Styled.NawTab>

      {data?.objects && data?.objects.length && !isLoading ? (
        <Styled.TableContainer>
          <StyledTable.Table>
            <StyledTable.Head>
              <StyledTable.Row>
                <StyledTable.HeadRow>Object Title</StyledTable.HeadRow>

                <StyledTable.HeadRow>First Name</StyledTable.HeadRow>
                <StyledTable.HeadRow>Last Name</StyledTable.HeadRow>
                <StyledTable.HeadRow>Email</StyledTable.HeadRow>
                <StyledTable.HeadRow>Role</StyledTable.HeadRow>

                {
                  !isEventsOrJobs ? (
                    <StyledTable.HeadRow className='link'>Verify</StyledTable.HeadRow>
                  ): null
                }

                <StyledTable.HeadRow>Date</StyledTable.HeadRow>
                {isEventsOrJobs ? (
                  <StyledTable.HeadRow className='edit' />
                ) : null}
              </StyledTable.Row>
            </StyledTable.Head>
            <StyledTable.Body>
              {data.objects
                ? data.objects.map(
                  ({
                     user: { first_name, last_name, email, role, createdAt: createdAtUser, is_email_verify },
                     object_name,
                     id, createdAt
                   }) => {
                    const _createdAt = transformDate(['Events', 'Jobs'].includes(table) ? createdAt : createdAtUser);

                    return (
                      <StyledTable.Row key={id} onClick={onToPost.bind(this, id)}>
                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={object_name}
                          >
                            {object_name}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={first_name}
                          >
                            {first_name}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={last_name}
                          >
                            {last_name}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={email}
                          >
                            {email}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={role}
                          >
                            {role}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        {
                          !isEventsOrJobs ? (
                            <StyledTable.Data className='link'>
                              <StyledTable.ItemLabel
                                className='tooltip'
                                linesToTruncate={1}
                                tooltipText={is_email_verify ? 'Yes' : 'No'}
                              >
                                {is_email_verify ? 'Yes' : 'No'}
                              </StyledTable.ItemLabel>
                            </StyledTable.Data>
                          ): null
                        }

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={_createdAt}
                          >
                            {_createdAt}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        {isEventsOrJobs ? (
                          <StyledTable.Data className='edit'>
                            <StyledTable.EditButton
                              onClick={(e) => {
                                e.stopPropagation();
                                onDelete('delete');
                                setUserId(id);
                              }}
                            >
                              <StyledTable.Icon
                                className='icon'
                                style={{
                                  WebkitMaskImage: `url(${Icons.deleteIcon})`,
                                  WebkitMaskSize: '100% 100%',
                                  maskImage: `url(${Icons.deleteIcon})`
                                }}
                              />
                            </StyledTable.EditButton>
                          </StyledTable.Data>
                        ) : null}
                      </StyledTable.Row>
                    );
                  }
                )
                : null}
            </StyledTable.Body>
          </StyledTable.Table>

          <Styled.PaginationContainer>
            {data?.count && data.count > 20 ? (
              <Pagination
                totalCount={data.count}
                pageSize={20}
                siblingCount={1}
                onPageChange={(num: number) => setPage(num)}
                currentPage={page}
              />
            ) : null}
          </Styled.PaginationContainer>
        </Styled.TableContainer>
      ) : null}

      {!data?.objects.length && !isLoading ? (
        <Styled.ErrorContainer>
          <NotFoundItem
            isSearch
            title='No Results Found'
            subTitle='It seems we can’t find any results based on your search.'
          />
        </Styled.ErrorContainer>
      ) : null}
      {isLoading ? (
        <Styled.ErrorContainer>
          <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
        </Styled.ErrorContainer>
      ) : null}

      {editComponent && (
        <Drawer
          onClose={onDelete.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'delete' && (
              <DeleteLayout onSave={onDeletePost} onCancel={onDelete.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
