import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid1 } from '../module/common/types';
import { IAllIBanner } from '../types';

class BannerService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async allBanners(): Promise<ExpectedFromQuery<IAllIBanner>> {
    return this.httpService.get<IAllIBanner>('/banner/all');
  }

  async redBanner({ id }: Iuuid1): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, {}>(`/banner/red-banner?banner_id=${id}`, {});
  }
}

const factory = new HttpFactoryService();
export const bannerService = new BannerService(factory.createAuthHttpService());
