import styled from 'styled-components';

import { FONTS, MEDIA, SPACES } from '../../../../theme';
import {
  Center,
  CommonContainer,
  CommonContainerBetween,
  CommonContent,
  CommonDescription,
  CommonDolar,
  CommonIcon,
  CommonNew,
  CommonNewContainer,
  CommonText,
  CommonTitle,
  Fonts
} from '../../../common/component/card';

export const Container = styled(CommonContainer)``;
export const Icon = styled(CommonIcon)``;
export const Content = styled(CommonContent)``;
export const ContainerBetween = styled(CommonContainerBetween)``;
export const Description = styled(CommonDescription)``;
export const Title = styled(CommonTitle)``;
export const NewContainer = styled(CommonNewContainer)``;
export const New = styled(CommonNew)``;
export const Dolar = styled(CommonDolar)``;
export const Text = styled(CommonText)``;

export const Capacity = styled.div`
  display: flex;
  gap: 3.5rem;
  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Text2 = styled.p`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${SPACES.s};
  line-height: ${FONTS.SIZES.l};

  margin-top: 16px;
`;

export const Content3 = styled.div`
  width: 100%;
  ${Center};
  justify-content: flex-start;
  gap: ${SPACES.l};

  flex-wrap: wrap;

  margin: ${SPACES.l} 0;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin: ${SPACES.l} 0 0 0;
  }
`;

export const Div = styled.div`
  ${Center};
  gap: ${SPACES.xs};

  @media screen and (max-width: ${MEDIA.tablet}) {
    &.full {
      width: 100%;
    }
  }
`;
