import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { adminService } from '../../../../services';
import { COLORS } from '../../../../theme';
import { IAuthError, IUser } from '../../../../types';
import { transformDate } from '../../../../utils';
import {
  DeleteLayout,
  Drawer,
  Loader,
  NotFoundItem,
  Pagination,
  Portal
} from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage, Iuuid } from '../../../common/types';
import { urlUtil } from '../../../home-layout/utils';
import { HeaderPage } from '../../common/page';
import * as Styled from './users.styled';

const _data: { [key: string]: string } = {
  VENDOR: 'PUBLIC_VENDOR',
  VENUE: 'PUBLIC_VENUE',
  TALENT: 'PUBLIC_TALENT',
  'EVENT PLANNER': 'PUBLIC_EVENT_PLANER'
};

export const Users = () => {
  const history = useHistory();
  const client = useQueryClient();

  const [page, setPage] = useState<number>(1);
  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [userId, setUserId] = useState<null | string>(null);

  const onBack = () => {
    history.goBack();
  };

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });
    await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ADMIN_USERS);

    setEditComponent(null);
  };

  const { mutate: getDeleteUser } = useMutation<any, AxiosError<IAuthError>, Iuuid>(
    (data: Iuuid) => adminService.getDeleteUser(data),
    {
      onSuccess,
      onError
    }
  );

  const { isLoading, data } = useQuery([APP_KEYS.QUERY_KEYS.GET_ADMIN_USERS, page], () =>
    adminService.getAllUsers(page)
  );

  const onToUser = (id: string | number, role: string) => {
    const link = _data[role];

    // @ts-ignore
    const a = `${urlUtil(APP_KEYS.ROUTER_KEYS[link])}?id=${id}`;

    history.push(a);
  };

  const onDelete = (str: string | null) => {
    setEditComponent(str);
  };

  const onDeleteUser = () => {
    // @ts-ignore
    getDeleteUser({ id: userId });
  };

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  return (
    <Styled.Container>
      <HeaderPage
        onBack={onBack}
        title='ALL USERS'
        content={<Styled.Text>Accounts Created ( {data?.users.length} )</Styled.Text>}
      />
      {data?.users.length ? (
        <Styled.TableContainer>
          <StyledTable.Table>
            <StyledTable.Head>
              <StyledTable.Row>
                <StyledTable.HeadRow>First Name</StyledTable.HeadRow>
                <StyledTable.HeadRow>Last Name</StyledTable.HeadRow>
                <StyledTable.HeadRow>Email</StyledTable.HeadRow>
                <StyledTable.HeadRow>Role</StyledTable.HeadRow>

                <StyledTable.HeadRow>Date</StyledTable.HeadRow>
                <StyledTable.HeadRow className='edit' />
              </StyledTable.Row>
            </StyledTable.Head>
            <StyledTable.Body>
              {data?.users.length
                ? data?.users?.map(
                    ({ first_name, last_name, email, role, createdAt, id, owner_id }: IUser) => {
                      const _createdAt = transformDate(createdAt);
                      return (
                        <StyledTable.Row key={id} onClick={onToUser.bind(this, owner_id, role)}>
                          {/* item label */}
                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={first_name}
                            >
                              {first_name}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={last_name}
                            >
                              {last_name}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={email}
                            >
                              {email}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={role}
                            >
                              {role}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          <StyledTable.Data className='title'>
                            <StyledTable.ItemLabel
                              className='tooltip'
                              linesToTruncate={1}
                              tooltipText={_createdAt}
                            >
                              {_createdAt}
                            </StyledTable.ItemLabel>
                          </StyledTable.Data>

                          {/* edit item button */}
                          <StyledTable.Data className='edit'>
                            <StyledTable.EditButton
                              onClick={(e) => {
                                e.stopPropagation();
                                onDelete('delete');
                                setUserId(id);
                              }}
                            >
                              <StyledTable.Icon
                                className='icon'
                                style={{
                                  WebkitMaskImage: `url(${Icons.deleteIcon})`,
                                  WebkitMaskSize: '100% 100%',
                                  maskImage: `url(${Icons.deleteIcon})`
                                }}
                              />
                            </StyledTable.EditButton>
                          </StyledTable.Data>
                        </StyledTable.Row>
                      );
                    }
                  )
                : null}
            </StyledTable.Body>
          </StyledTable.Table>

          <Styled.PaginationContainer>
            {data?.count && data.count > 20 ? (
              <Pagination
                totalCount={data.count}
                pageSize={20}
                siblingCount={1}
                onPageChange={(num: number) => setPage(num)}
                currentPage={page}
              />
            ) : null}
          </Styled.PaginationContainer>
        </Styled.TableContainer>
      ) : (
        <Styled.ErrorContainer>
          <NotFoundItem
            isSearch
            title='No Results Found'
            subTitle='It seems we can’t find any results based on your search.'
          />
        </Styled.ErrorContainer>
      )}

      {editComponent && (
        <Drawer
          onClose={onDelete.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'delete' && (
              <DeleteLayout onSave={onDeleteUser} onCancel={onDelete.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
