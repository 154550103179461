import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { FONTS, MEDIA, SPACES } from '../../../../theme';
import {
  CommonContainer,
  CommonErrorContainer,
  CommonTableContainer,
  CommonText,
  CommonTitle,
  Fonts
} from '../../common/page';

export const Container = styled(CommonContainer)``;
export const Text = styled(CommonText)``;
export const Title = styled(CommonTitle)``;
export const TableContainer = styled(CommonTableContainer)``;
export const ErrorContainer = styled(CommonErrorContainer)``;

export const CommonForm = styled(Form)`
  width: 100%;
`;

export const Popup = css`
  height: 70%;
`;

export const PopupLayoutStyled = css`
  height: fit-content;
  max-width: 30rem;

  & > .children {
    min-height: 100px !important;
    padding: 3rem 3rem 9rem 3rem;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    height: 45% !important;
    & > .children {
      padding: ${SPACES.l} ${SPACES.l} 4rem ${SPACES.l};
    }
  }
`;

export const TextPopup = styled.p`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.normal};
`;
