import styled from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../theme';
import {
  Center,
  CommonContainer,
  CommonContainerBetween,
  CommonContent,
  CommonDescription,
  CommonIcon,
  CommonNew,
  CommonNewContainer,
  CommonText,
  CommonTitle
} from '../../../common/component/card';

export const Container = styled(CommonContainer)``;
export const Icon = styled(CommonIcon)``;
export const Content = styled(CommonContent)``;
export const ContainerBetween = styled(CommonContainerBetween)``;
export const Description = styled(CommonDescription)``;
export const Title = styled(CommonTitle)``;
export const NewContainer = styled(CommonNewContainer)``;
export const New = styled(CommonNew)``;

export const Status = styled(CommonText)<{ isColor: boolean }>`
  position: relative;
  ${Center};

  gap: ${SPACES.m};

  &:after {
    display: flex;
    content: '';
    width: 12px;
    height: 12px;

    background: ${COLORS.green};
    background: ${({ isColor }) => (isColor ? COLORS.green : COLORS.semiRed)};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;
