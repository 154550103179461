import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaEditEventPlaner = Yup.object().shape({
  first_name: validateSchema.text,
  last_name: validateSchema.text,

  occupation: validateSchema.text,
  location: validateSchema.text,

  phone: validateSchema.phone,
  email: validateSchema.email,
  hourly_rate: validateSchema.hourly_rate.nullable(),

  description: validateSchema.text,
  categories: validateSchema.categories
});
