import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { SPACES } from '../../../../theme';
import { IUser } from '../../../../types';
import { SettingsConst } from '../../../account-settings/account-settings';
import { actionData, actionData2 } from '../../../account-settings/component';
import { Drawer } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { useDisableScroll } from '../../../common/hooks';
import { Links } from '../../constants';
import { urlUtil } from '../../utils';
import { icons } from '../footer/footer';
import * as Styled from './navigation.styled';
import { FilePath } from '../../../../utils';

export interface INavigation {
  onClose: () => void;
  user?: IUser;
}

export const Navigation = ({ onClose, user }: INavigation) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const client = useQueryClient();

  const onClick = (url: string) => {
    history.push(urlUtil(url));
    onClose();
  };

  const onLogout = async () => {
    await localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);

    client.removeQueries(APP_KEYS.QUERY_KEYS.GET_USER);
  };

  const onClickSetting = (str: string) => {
    const link = SettingsConst[str];
    const url = `${APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING}?page=${link}`;
    str === 'Logout' ? onLogout() : onClick(url);

    onClose();
  };

  const links: { [key: string]: any } = {
    VENDOR: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENDOR}?id=${user?.owner_id}`,
    VENUE: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENUE}?id=${user?.owner_id}`,
    TALENT: `${APP_KEYS.ROUTER_KEYS.PUBLIC_TALENT}?id=${user?.owner_id}`,
    'EVENT PLANNER': `${APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT_PLANER}?id=${user?.owner_id}`
  };

  const onViewProfile = () => {
    // @ts-ignore
    const link = links[user?.role];

    onClose();
    history.push(urlUtil(link));
  };

  const { disableScroll, enableScroll } = useDisableScroll();

  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);

  const texts = [
    {
      text: 'Discover',
      link: APP_KEYS.ROUTER_KEYS.PAGE,
      path: APP_KEYS.ROUTER_KEYS.PAGE
    },
    {
      text: 'Account',
      link: `${APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING}?page=main`,
      path: APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING
    }
  ];

  return (
    <>
      {window.innerWidth > 768 && (
        <Drawer
          onClose={onClose}
          open
          slidePosition='right'
          contentPosition='right'
          style={{ background: 'none' }}
        >
          <Styled.SettingContainer className='setting' onClick={(e) => e.stopPropagation()}>
            <Styled.UserInfoContainer>
              <Styled.UserImage
                src={user?.is_avatar && user?.avatar ? user?.avatar : FilePath(Png.defaultBlack)}
                alt='user-avatar'
                onClick={onViewProfile}
              />
              <Styled.UserInfoContainer isColumn>
                <Styled.UserName onClick={onViewProfile}>
                  {user?.first_name} {user?.last_name}
                </Styled.UserName>
                {user?.role_site !== 'admin' && (
                  <Styled.UserRole onClick={onViewProfile}>{user?.role}</Styled.UserRole>
                )}
              </Styled.UserInfoContainer>
            </Styled.UserInfoContainer>
            <Styled.Btn2
              content='View Profile'
              type='button'
              variant='primaryInverse'
              onClick={onViewProfile}
              width='100%'
              height='2.5rem'
              mt={SPACES.l}
              mb={SPACES.l}
            />

            <Styled.Line2 />

            {actionData.map(({ text, icon }, index) => (
              <Styled.Role key={index} onClick={onClickSetting.bind(this, text)}>
                <Styled.IconRole
                  className='icon'
                  style={{
                    WebkitMaskImage: `url(${icon})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${icon})`
                  }}
                />
                <Styled.RoleText>{text}</Styled.RoleText>
              </Styled.Role>
            ))}

            {user?.role_site === 'admin' ? (
              <Styled.Role onClick={onClickSetting.bind(this, 'Admin Panel')}>
                <Styled.IconRole
                  className='icon'
                  style={{
                    WebkitMaskImage: `url(${FilePath(Icons.jobIcon)})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${FilePath(Icons.jobIcon)})`
                  }}
                />
                <Styled.RoleText>Admin Panel</Styled.RoleText>
              </Styled.Role>
            ) : null}

            <Styled.Line2 />

            {actionData2.map(({ text, icon }, index) => (
              <Styled.Role key={index} onClick={onClickSetting.bind(this, text)}>
                <Styled.IconRole
                  className='icon'
                  style={{
                    WebkitMaskImage: `url(${icon})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${icon})`
                  }}
                />
                <Styled.RoleText>{text}</Styled.RoleText>
              </Styled.Role>
            ))}
          </Styled.SettingContainer>
        </Drawer>
      )}

      <Styled.Container
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Styled.TextContainer>
          {texts.map(({ text, path, link }, index) => (
            <Styled.TextContent key={index} onClick={onClick.bind(this, link)}>
              <Styled.Text>{text}</Styled.Text>
              {pathname.includes(path) ? <Styled.Line /> : null}
            </Styled.TextContent>
          ))}
          <Styled.TextContent>
            <Styled.Text1 href={Links.support} target='_blank'>
              <Styled.Text>CONTACT US</Styled.Text>
            </Styled.Text1>
          </Styled.TextContent>
        </Styled.TextContainer>

        <Styled.Div>
          <Styled.IconDiv>
            {icons.map(({ icon, href }, index) => (
              <Styled.IconContainer key={index} href={href} target='_blank'>
                <Styled.Icon
                  style={{
                    WebkitMaskImage: `url(${icon})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${icon})`
                  }}
                />
              </Styled.IconContainer>
            ))}
          </Styled.IconDiv>

          <Styled.TextContent2>
            <Styled.Text1 href={Links.privacy_policy} target='_blank'>
              Privacy Policy
            </Styled.Text1>
            -
            <Styled.Text1 href={Links.privacy_policy} target='_blank'>
              Terms & Conditions
            </Styled.Text1>
          </Styled.TextContent2>
        </Styled.Div>
      </Styled.Container>
    </>
  );
};
