import React, { KeyboardEvent } from 'react';

interface IProps {
  setSelectedHint: React.Dispatch<React.SetStateAction<number | null>>;
  setInputValue: (str: string) => void;
  matchedWords: string[];
  selectedHint: number | null;
  item?: string;
}

export const searchKeyPressControl =
  ({ setSelectedHint, setInputValue, matchedWords, selectedHint, item = '' }: IProps) =>
  (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedHint((prev) => (prev !== null && prev >= 0 ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedHint((prev) => (prev !== null && prev < matchedWords.length ? prev - 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (matchedWords.length > 0 && selectedHint !== null) {
        // @ts-ignore
        const setData = matchedWords[selectedHint][item];

        item ? setInputValue(setData) : setInputValue(matchedWords[selectedHint]);
      }
      setSelectedHint(null);
    }
  };

export const searchKeyPressControl2 =
  ({ setSelectedHint, setInputValue, matchedWords, selectedHint }: IProps) =>
  (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedHint((prev) => (prev !== null && prev >= 0 ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedHint((prev) => (prev !== null && prev < matchedWords.length ? prev - 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (matchedWords.length > 0 && selectedHint !== null) {
        setInputValue(matchedWords[selectedHint]);
      }
      setSelectedHint(null);
    }
  };
