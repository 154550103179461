import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { favoriteService } from '../../../services';
import { IAuthError, ICreateFavorite, IMessageFavorite } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { urlUtil } from '../../home-layout/utils';

const onError = (_err: AxiosError<IAuthError>, history: any) => {
  const err = _err.response?.data as IAuthError;

  toastContainer.error({ title: err.message ?? _err.message });
  history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
};

export const toggleFavorite = (
  id?: string,
  to_user_id?: string | number | undefined,
  socket?: any,
  role?: string
) => {
  const history = useHistory();
  const client = useQueryClient();

  const onSuccess = async ({ message, data: _data }: IMessageFavorite) => {
    toastContainer.success({
      title: message,
      isUndo: !!_data ?? false,
      onUndo: () => onUndo(_data)
    });

    const queryKey = id
      ? [APP_KEYS.QUERY_KEYS.GET_FAVORITE, id]
      : [APP_KEYS.QUERY_KEYS.GET_ALL_FAVORITES, role];

    await client.invalidateQueries(queryKey);
    await socket.emit('send_notification', { to_user_id });
  };

  const mutation = useMutation<any, AxiosError<IAuthError>, ICreateFavorite>(
    (createData: ICreateFavorite) => favoriteService.createFavorite(createData),
    {
      onSuccess,
      onError: (err: AxiosError<IAuthError>) => onError(err, history)
    }
  );

  function onUndo(_data: any) {
    mutation.mutate({ ..._data, to_user_id });
  }

  return mutation;
};
