import axios from 'axios';

import { APP_KEYS } from '../module/common/constants';
import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import {
  IEditTalent,
  IEditTalentExperiences,
  IGetAllTalents,
  IGetAllTalentsParameter,
  IGetTalent
} from '../types';

class TalentService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getTalents(
    data: Partial<IGetAllTalentsParameter>
  ): Promise<ExpectedFromQuery<IGetAllTalents>> {
    return this.httpService.post<IGetAllTalents, Partial<IGetAllTalentsParameter>>(
      '/talent/all',
      data
    );
  }

  async getMyTalent(): Promise<ExpectedFromQuery<{ id: string }>> {
    return this.httpService.get<{ id: string }>('/talent/my');
  }

  async getTalent(id: string): Promise<ExpectedFromQuery<IGetTalent>> {
    return this.httpService.get<IGetTalent>(`/talent?talent_id=${id}`);
  }

  async getTalentExperiences(id: string): Promise<ExpectedFromQuery<IEditTalentExperiences>> {
    return this.httpService.get<IEditTalentExperiences>(`/talent/experiences?talent_id=${id}`);
  }

  async editTalentExperiences(data: IEditTalent): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, IEditTalent>(`/talent/experiences`, data);
  }

  async editTalent(data: any, id: string): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/talent?talent_id=${id}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }
}

const factory = new HttpFactoryService();
export const talentService = new TalentService(factory.createAuthHttpService());
