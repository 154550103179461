import styled from 'styled-components';

import { COLORS, FONTS } from '../../../../theme';
import { CommonSubTitle, CommonText } from '../../../common/component/card';
import { Fonts } from '../../../common/component/card';
import {
  CommonFormCreate,
  CommonInputCalendarCreate,
  CommonInputCreate,
  CommonInputDropdownCroupCreate,
  CommonInputMaskCreate,
  CommonTextContainer,
  CommonTime,
  CommonUserInfoContainer,
  InputCss
} from '../../../common/component/create';

export const CommonForm = styled(CommonFormCreate)``;
export const TextContainer = styled(CommonTextContainer)``;
export const Text = styled(CommonText)``;
export const SubTitle = styled(CommonSubTitle)``;
export const UserInfoContainer = styled(CommonUserInfoContainer)``;
export const CommonInput = styled(CommonInputCreate)``;
export const CommonInputCalendar = styled(CommonInputCalendarCreate)``;
export const CommonInputDropdownCroup = styled(CommonInputDropdownCroupCreate)``;
export const CommonInputMask = styled(CommonInputMaskCreate)`
  width: 100%;
`;
export const Time = styled(CommonTime)``;

export const Div = styled.div`
  ${InputCss};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Hide = styled(Text)`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.bold};

  color: ${COLORS.purple};

  cursor: pointer;

  &:hover {
    color: ${COLORS.purpleHover};
  }
`;
