import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  height: calc(100% - 6rem);

  padding: ${SPACES.l} 0 9.375rem ${SPACES.l};

  background: ${COLORS.white};

  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding-left: 0;
  }
`;

export const ArrowBackComponent = css`
  display: none;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: flex;
    margin: 0 0 ${SPACES.xxxxl} 0;
  }
`;

export const Text = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const Title = styled(Text)`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: ${FONTS.SIZES.xxxxxxl_};
  line-height: ${FONTS.SIZES.xxxxxxxl};

  text-transform: uppercase;

  margin-bottom: ${SPACES.l};
`;

export const SubTitle = styled(Text)`
  font-weight: ${FONTS.WEIGHTS.bold};
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div<{ isSpace?: boolean; margin?: string }>`
  width: 100%;
  position: relative;
  padding: ${SPACES.l} 0;
  border-bottom: 1px solid ${COLORS.black};

  margin: ${({ margin }) => margin ?? '0'};

  ${Center}
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};
`;
export const EditBnt = styled(Button)`
  text-transform: uppercase;
  max-width: 5.5rem;
  height: 2.5rem;
`;

export const EditBnt2 = styled(Button)`
  text-transform: uppercase;

  width: 15rem;
  height: 3.5rem;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
  }
`;

export const ContainerNotification = styled.div`
  padding: ${SPACES.xxxxxxls} 0;

  ${Center}
  justify-content: space-between;

  border-bottom: 1px solid ${COLORS.black};
`;

export const NotificationSubTitle = styled(Text)`
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};

  margin-bottom: ${SPACES.s};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.l};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;

export const NotificationText = styled(Text)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.s};
    line-height: ${FONTS.SIZES.l};
  }
`;
