import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import {
  CommonContainer,
  CommonIcon,
  CommonImageOne,
  CommonInfoContainer,
  CommonInfoContent,
  CommonSubTitle,
  CommonText,
  CommonTextContainer,
  CommonTextContainer2,
  CommonTitle,
  Fonts
} from '../../../common/component/public-page';

export const TextContainer = styled(CommonTextContainer)``;
export const TextContainer2 = styled(CommonTextContainer2)``;
export const Title = styled(CommonTitle)``;
export const SubTitle = styled(CommonSubTitle)``;
export const Text = styled(CommonText)``;
export const Container = styled(CommonContainer)``;
export const InfoContainer = styled(CommonInfoContainer)``;
export const InfoContent = styled(CommonInfoContent)``;
export const ImageOne = styled(CommonImageOne)``;

export const CopyEvent = styled(CommonIcon)`
  background: ${COLORS.black};

  &:hover {
    background: ${COLORS.purple};
  }
`;

export const EditBnt = styled(Button)`
  max-width: 5.5rem;
  text-transform: uppercase;
  height: 2.5rem;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};
`;

export const Div2 = styled(Div)`
  gap: ${SPACES.xxs};
`;

export const IconsCardStyled = css`
  & > .width {
    width: 48%;
  }

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    & > .width {
      width: 100%;
    }
  }
`;

export const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExperienceTitle = styled.p`
  ${Fonts};

  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};
`;
export const ExperienceInfo = styled.div`
  display: flex;
  gap: ${SPACES.l};

  margin: ${SPACES.l} 0;
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.black};
  margin: ${SPACES.xxxxxxls} 0;
`;
