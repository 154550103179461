import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaSingUp = Yup.lazy((values) =>
  Yup.object().shape({
    role: validateSchema.name,
    first_name: validateSchema.name,
    last_name: validateSchema.name,
    company_name: values.role === 'TALENT'
      ? validateSchema.text.optional()
      : validateSchema.text,
    email: validateSchema.email,
    phone: validateSchema.phone,
    create_password: validateSchema.password,
    confirm_password: validateSchema.password.oneOf(
      [Yup.ref('create_password')],
      'The password confirmation does not match'
    )
  })
);

export const validationSchemaLogin = Yup.object().shape({
  email: validateSchema.email,
  password: validateSchema.password
});

export const validationSchemaForgotPassword = Yup.object().shape({
  email: validateSchema.email
});

export const validationSchemaResetPassword = Yup.object().shape({
  create_password: validateSchema.password,
  confirm_password: validateSchema.password.oneOf(
    [Yup.ref('create_password')],
    'The password confirmation does not match'
  )
});
