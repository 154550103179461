import styled from 'styled-components';

import { ReactComponent as Vector } from '../../../../assets/icons/vector.svg';
import { SPACES } from '../../../../theme';

export const Image = styled(Vector)`
  position: absolute;
  top: -200px;
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BtnContainer = styled.div`
  display: flex;
  gap: ${SPACES.xxxxxxls};
`;
