import styled, { css } from 'styled-components';

import { ReactComponent as NotFoundResultIcon } from '../../../../assets/icons/no-results.svg';
import { ReactComponent as NotFoundIcon } from '../../../../assets/icons/not-found.svg';
import { COLORS, FONTS, SPACES } from '../../../../theme';

export const Container = styled.div`
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const NotFound = styled(NotFoundIcon)`
  height: 8rem;
  aspect-ratio: 1/1;
`;

export const NotFoundSearch = styled(NotFoundResultIcon)`
  height: 8rem;
  aspect-ratio: 1/1;
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const NotFoundText = styled.p`
  ${Fonts};
  text-align: center;
`;

export const NotFoundTitle = styled.p`
  ${Fonts};

  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};

  margin: ${SPACES.l} 0;
`;
