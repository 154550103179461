import styled from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../button/button';

export const Container = styled.div<{ styled: any }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: ${SPACES.xxxxxxls} 0;

  cursor: pointer;
  gap: ${SPACES.m};

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};

  ${({ styled }) => styled};

  @media screen and (max-width: ${MEDIA.tablet_s}) {
    padding-left: 0;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.m};
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  gap: ${SPACES.m};
`;

export const Div2 = styled(Div)`
  display: none;
  gap: ${SPACES.xxxl};
  @media screen and (max-width: ${MEDIA.tablet}) {
    display: flex;
  }
`;

export const Icon = styled.img`
  width: 14px;
  height: 14px;

  background: ${COLORS.black};
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.blue};
`;

export const EditBnt = styled(Button)`
  text-transform: uppercase;
  height: 2.5rem;
`;

export const CopyEvent = styled(IconRole)`
  background: ${COLORS.black};

  &:hover {
    background: ${COLORS.purple};
  }
`;
