import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  padding: ${SPACES.xxxxxxl};
  margin: ${SPACES.xxxxxxl} 0;

  border: 1px solid ${COLORS.black};
  background: ${COLORS.white};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${SPACES.l};

    padding: ${SPACES.l};
    margin: ${SPACES.xxxxl} 0;
  }
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const Title = styled.p`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};

  margin-bottom: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.l};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;

export const Text = styled.p`
  ${Fonts};
`;

export const Btn = styled(Button)`
  text-transform: uppercase;
  max-width: 200px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    max-width: 100%;
  }
`;

export const Icon = styled.div`
  height: 1.25rem;
  aspect-ratio: 1/1;
  background: ${COLORS.white};
`;

export const Div = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

export const DivReview = styled.div`
  &:last-child > .line {
    display: none;
  }
`;

export const Line = styled.div`
  width: 100%;

  width: calc(100% - 4.5rem);
  position: relative;

  border-top: 1px solid ${COLORS.black};
  margin: ${SPACES.xxxxl} 0 ${SPACES.xxxxxxls} 4.5rem;
`;
