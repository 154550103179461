import styled from 'styled-components';

import { ReactComponent as Location } from '../../../../assets/icons/location-icon.svg';
import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import { Center, CommonSubTitle, CommonText } from '../../../common/component/card';
import {
  CommonFormCreate,
  CommonInputCalendarCreate,
  CommonInputCreate,
  CommonInputDropdownCroupCreate,
  CommonInputMatchedWordsCreate,
  CommonTextContainer,
  CommonUserInfoContainer
} from '../../../common/component/create';
import { DivRole } from '../../../common/component/public-page';

export const CommonForm = styled(CommonFormCreate)``;
export const TextContainer = styled(CommonTextContainer)``;
export const Text = styled(CommonText)``;
export const SubTitle = styled(CommonSubTitle)``;
export const UserInfoContainer = styled(CommonUserInfoContainer)``;
export const CommonInput = styled(CommonInputCreate)``;
export const CommonInputCalendar = styled(CommonInputCalendarCreate)``;
export const CommonInputMatchedWords = styled(CommonInputMatchedWordsCreate)``;
export const CommonInputDropdownCroup = styled(CommonInputDropdownCroupCreate)``;

export const LocationIcon = styled(Location)`
  color: ${COLORS.black};
`;

export const EventContainer = styled.div`
  width: 100%;
  ${Center};

  gap: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    gap: ${SPACES.l};
  }
`;

export const DeleteContainer = styled.div`
  width: 3.5rem;
  aspect-ratio: 1/1;
  ${Center};

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    height: 1.5rem;
  }
`;

export const Event = styled.div`
  width: calc(100% - 80px);

  ${Center};
  justify-content: space-between;

  margin: ${SPACES.l} 0;

  padding: ${SPACES.xxxxl};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;

  & > .last > .date {
    display: none;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;

    padding: ${SPACES.s} 0;

    & .first {
      width: 100%;
      padding: 0 ${SPACES.s} ${SPACES.s} ${SPACES.s};
      border-bottom: 1px solid ${COLORS.black};
    }

    & .last {
      width: 100%;
      padding: ${SPACES.s} ${SPACES.s} 0 ${SPACES.s};
      border-bottom: 1px solid ${COLORS.black};
      border-bottom: none;

      & .text {
        display: none;
      }
      & .date {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
`;

export const EventImage = styled.img`
  width: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;
`;

export const LocationText = styled(Text)`
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.l};
`;

export const Div = styled.div`
  display: flex;
  gap: ${SPACES.l};
`;

export const Div2 = styled(Div)`
  flex-direction: column;
  gap: ${SPACES.xxs};
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.green};

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const DivRole2 = styled(DivRole)`
  display: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: flex;

    & > .icon {
      display: flex;
    }
  }
`;

export const Btn = styled(Button)`
  text-transform: uppercase;
`;
