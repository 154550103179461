import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SHADOWS, SPACES } from '../../../../../theme';
import { IInputProps, IWProps } from '../../../types';

export const defaultSvgStyles = {
  color: COLORS.semiWhite,
  height: '1.25rem',
  width: '1.25rem',
  className: 'inputIcon'
};

export const defaultEndSvgStyles = {
  color: COLORS.semiWhite,
  height: '1.5rem',
  width: '1.5rem',
  className: 'endIcon'
};

const commonStyles = css<IInputProps>`
  margin: 0;
  padding: ${({ innerPads }) => innerPads ?? `${SPACES.xs} ${SPACES.m}`};

  border: 1px solid ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};
  border-radius: ${SPACES.xxs};
  box-shadow: ${SHADOWS.xxs};
  background-color: ${COLORS.white};

  &:not(:focus-within) {
    cursor: pointer;
  }

  &::placeholder {
    color: ${({ placeholderColor, theme }) => placeholderColor ?? theme.COLORS.semiTransparentGrey};
  }

  &:focus-within {
    border: 1px solid ${COLORS.purple};
    outline: 1px solid ${COLORS.purple};
  }
`;

export const Wrapper = styled.div<IWProps>`
  position: relative;
  width: 100%;

  opacity: ${({ readOnly }) => (readOnly ? '0.4' : '1')};

  margin-left: ${({ ml }) => ml ?? '0'};
  margin-right: ${({ mr }) => mr ?? '0'};
  margin-bottom: ${({ mb }) => mb ?? '0'};
  margin-top: ${({ mt }) => mt ?? '0'};

  & .inputIcon {
    position: absolute;
    top: 50%;
    left: ${SPACES.l};
    transform: translateY(-50%);
  }

  & .endIcon {
    position: absolute;
    top: 50%;
    right: ${SPACES.l};
    transform: translateY(-50%);
    transform: ${({ isLabel }) => (isLabel ? 'translateY(-10%)' : 'translateY(-50%)')};

    pointer-events: none;
  }
`;

export const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const Label = styled.label<{ required?: boolean; isError: boolean }>`
  position: relative;
  display: block;
  ${Fonts}
  text-transform: capitalize;

  color: ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        right: 0;
        top: 0;
      }
    `}
`;

export const LabelOptional = styled.label`
  ${Fonts}
  color: ${COLORS.gray};
  margin-left: 2px;
`;

export const Input = styled.input.attrs(({ gapFromLabel, height, readOnly }: IInputProps) => ({
  gapFromLabel,
  height,
  readOnly
}))`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '100%'};

  ${commonStyles}

  &:focus-within {
    outline: ${({ readOnly }) => readOnly && 'none'};
  }

  background: ${({ readOnly }) => readOnly && COLORS.white};
  margin-top: ${({ gapFromLabel }) => gapFromLabel ?? SPACES.xxxs};
  position: relative;

  caret-color: ${COLORS.purple};

  cursor: ${({ readOnly }) => (readOnly ? 'initial' : 'pointer')};
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'initial')};

  &[type='number'] {
    appearance: none;
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }
`;

export const Input2 = styled.input<IInputProps>`
  position: relative;
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '100%'};

  ${commonStyles}

  outline: none;
  transition: 0.5s;

  &&:valid ~ label,
  &&:focus ~ label {
    color: ${COLORS.black};
    transform: translateX(10px) translateY(-7px);
    font-size: ${SPACES.xs};
    padding: 0 ${SPACES.xs};
    background: ${COLORS.white};
    border-left: 1px solid ${COLORS.semiWhite};
    border-right: 1px solid ${COLORS.semiWhite};
    letter-spacing: 0.2em;
  }
`;

export const Label2 = styled.label<{ pt?: string; pb?: string; pl?: string; pr?: string }>`
  position: absolute;
  left: 0;
  padding-left: ${({ pl }) => pl ?? SPACES.xs};
  padding-right: ${({ pr }) => pr ?? SPACES.xs};
  padding-bottom: ${({ pb }) => pb ?? SPACES.xs};
  padding-top: ${({ pt }) => pt ?? SPACES.xs};
  pointer-events: none;
  color: rgba(22, 22, 22, 0.25);
  transition: 0.5s;

  ${Fonts}
`;

export const Error = styled.div`
  position: absolute;
  left: ${SPACES.xxxxxs};
  bottom: -${SPACES.xxxxl_};
  font-size: ${FONTS.SIZES.s};
  color: ${COLORS.mainRed};
`;

export const ErrorPasswordContainer = styled.div`
  width: 100%;
  position: absolute;
  left: ${SPACES.xxxxxs};
  bottom: -${SPACES.xxxs};
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    flex-wrap: wrap;
    position: relative;
  }
  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-wrap: wrap;
    position: relative;
  }
`;

export const ErrorPassword = styled.div<{ isError: boolean; isSuccess: boolean }>`
  ${Fonts}
  color:  ${({ isError }) => (isError ? COLORS.mainRed : COLORS.gray)};
  ${({ isSuccess }) =>
    isSuccess &&
    css`
      color: ${COLORS.green};
    `}

  display: flex;
  gap: 2px;
`;

const visibilityIcon = css`
  color: ${COLORS.black};
  cursor: pointer;
  position: absolute;
  // top: 3.125rem;
  top: 2.4rem;
  right: ${SPACES.xs};
`;

export const Visibility = styled(VisibilityIcon)<{ isError: boolean; isCommonError: boolean }>`
  ${visibilityIcon};
`;

export const VisibilityOff = styled(VisibilityOffIcon)<{
  isError: boolean;
  isCommonError: boolean;
}>`
  ${visibilityIcon};
`;
export const Icon = styled.div<{ isError: boolean; isSuccess: boolean }>`
  width: 13px;
  aspect-ratio: 1/1;
  background: ${({ isError }) => (isError ? COLORS.mainRed : COLORS.gray)};

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      background: ${COLORS.green};
    `}
`;

export const CloseIcon = styled.div`
  width: 13px;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
  cursor: pointer;
  pointer-events: initial !important;
`;
