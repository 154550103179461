import { getIn, useFormikContext } from 'formik';
import React from 'react';

import * as Styled from './check-box-formik.styled';

export interface ICheckBoxFormik {
  name: string;
  label: string;
}

export const CheckBoxFormik = ({ name, label }: ICheckBoxFormik) => {
  const { values, setValues } = useFormikContext();

  const value = getIn(values, name);
  const checked = value?.includes(label);

  const onChange = () => {
    setValues((v: any) => {
      const prevValue = getIn(v, name);
      if (prevValue.includes(label)) {
        prevValue.splice(prevValue.indexOf(label), 1);
      } else {
        prevValue.push(label);
      }
      return { ...v, [name]: prevValue };
    });
  };

  return (
    <Styled.Label>
      <Styled.Input name={name} type='checkbox' checked={!!checked} onChange={onChange} />
      <Styled.Span className='text'>
        <span>{label}</span>
      </Styled.Span>
    </Styled.Label>
  );
};
