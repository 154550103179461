import axios from 'axios';

import { APP_KEYS } from '../module/common/constants';
import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import { IGetAllEvents, IGetAllEventsParameter, IGetEvent } from '../types';

class EventService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getEvents(
    data: Partial<IGetAllEventsParameter>
  ): Promise<ExpectedFromQuery<IGetAllEvents>> {
    return this.httpService.post<IGetAllEvents, Partial<IGetAllEventsParameter>>(
      '/event/all',
      data
    );
  }

  async getMyEvents(): Promise<ExpectedFromQuery<IGetAllEvents>> {
    return this.httpService.get<IGetAllEvents>('/event/my-all');
  }

  async userEvents(id?: number | string): Promise<ExpectedFromQuery<IGetAllEvents>> {
    return this.httpService.get<IGetAllEvents>(`/event/user-events?event_id=${id}`);
  }

  async getEvent(id: string): Promise<ExpectedFromQuery<IGetEvent>> {
    return this.httpService.get<IGetEvent>(`/event?event_id=${id}`);
  }

  async deleteEvent(id: string): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/event?event_id=${id}`);
  }

  async createEvent(data: FormData): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/event`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }

  async editEvent(data: FormData, id: string): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/event?event_id=${id}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }
}

const factory = new HttpFactoryService();
export const eventService = new EventService(factory.createAuthHttpService());
