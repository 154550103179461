import styled from 'styled-components';

import { Center, CommonSubTitle, CommonText } from '../../../common/component/card';
import {
  CommonFormCreate,
  CommonInputCalendarCreate,
  CommonInputCreate,
  CommonInputDropdownCroupCreate,
  CommonInputMaskCreate,
  CommonTextContainer,
  CommonTime,
  CommonUserInfoContainer,
  InputCss
} from '../../../common/component/create';

export const CommonForm = styled(CommonFormCreate)``;
export const TextContainer = styled(CommonTextContainer)``;
export const Text = styled(CommonText)``;
export const SubTitle = styled(CommonSubTitle)``;
export const UserInfoContainer = styled(CommonUserInfoContainer)``;
export const CommonInput = styled(CommonInputCreate)``;
export const CommonInputCalendar = styled(CommonInputCalendarCreate)``;
export const CommonInputDropdownCroup = styled(CommonInputDropdownCroupCreate)``;
export const Time = styled(CommonTime)``;
export const CommonInputMask = styled(CommonInputMaskCreate)``;
