import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

export const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommonContainer = styled.div`
  width: 90%;
  height: 100%;
  min-height: 400px;

  margin: 0 auto;
  padding-bottom: 2rem;

  position: relative;
`;

export const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const CommonText = styled.div`
  ${Fonts};

  display: flex;
  gap: ${SPACES.l};
`;

export const CommonTitle = styled.p`
  ${Fonts};
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: ${FONTS.SIZES.xxxxxxl_};
  line-height: ${FONTS.SIZES.xxxxxxxl};

  margin-bottom: ${SPACES.l};
`;

export const CommonHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;

    align-items: flex-start;
  }
`;

export const CommonTableContainer = styled.div`
  width: 100%;
  border: 1px solid ${COLORS.black};
  border-radius: ${SPACES.l};
  margin-top: ${SPACES.xxxxl};
`;

export const CommonErrorContainer = styled.div`
  width: 100%;
  margin-top: ${SPACES.xxxxl};
  min-height: 50%;

  ${Center};
`;

export const CommonBtn = styled(Button)`
  max-width: 17.5rem;

  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.l};
    max-width: 100%;
  }
`;
