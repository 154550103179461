import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { APP_KEYS } from '../common/constants';
import * as Styled from './account-settings.styled';
import { MyEvent, MyJob, Setting, UserSettingCommon } from './component';
import { AdminPanel } from './component/admin-panel/admin-panel';

export const SettingsConst: { [key: string]: string } = {
  'My Job Postings': 'jobs',
  'My Events': 'events',
  Settings: 'settings',
  'Admin Panel': 'admin',
  main: 'main'
};

export const SettingsConst2: { [key: string]: string } = {
  jobs: 'My Job Postings',
  events: 'My Events',
  settings: 'Settings',
  admin: 'Admin Panel',
  main: 'main'
};

export const AccountSettings = () => {
  const client = useQueryClient();
  const { search } = useLocation();

  const searchParams = search.split('=')[1];
  const param = SettingsConst2[searchParams];

  useEffect(() => {
    client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_USER).then();
  }, []);

  return (
    <Styled.Container>
      <Styled.Title>Account</Styled.Title>

      <Styled.CommonContainer>
        {window.innerWidth > 950 || (window.innerWidth < 950 && param === 'main') ? (
          <Styled.FilterContainer>
            <UserSettingCommon />
          </Styled.FilterContainer>
        ) : null}

        <Styled.ContentContainer>
          {param === 'Settings' && <Setting />}
          {param === 'My Job Postings' || (window.innerWidth > 950 && param === 'main') ? (
            <MyJob />
          ) : null}
          {param === 'My Events' && <MyEvent />}
          {param === 'Admin Panel' && <AdminPanel />}
        </Styled.ContentContainer>
      </Styled.CommonContainer>
    </Styled.Container>
  );
};
