import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { favoriteService } from '../../../services';
import { IAuthError } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { urlUtil } from '../../home-layout/utils';

const onError = (_err: AxiosError<IAuthError>, history: any) => {
  const err = _err.response?.data as IAuthError;

  toastContainer.error({ title: err.message ?? _err.message });
  history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
};

export const getFavoriteById = (id: string, role: string, enabled: boolean) => {
  const history = useHistory();

  return useQuery(
    [APP_KEYS.QUERY_KEYS.GET_FAVORITE, id],
    () => favoriteService.getFavorite(id, role),
    {
      onError: (err: AxiosError<IAuthError>) => onError(err, history),
      enabled
    }
  );
};

export const getAllFavorites = (role: string, flag: boolean) =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ALL_FAVORITES, role], () =>
    favoriteService.getFavorites(role, flag)
  );
