import { AxiosError } from 'axios';
import { Formik, getIn } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { userService } from '../../../../services';
import { SPACES } from '../../../../theme';
import { IAuthError, IGetUser, IUser } from '../../../../types';
import { inputs } from '../../../account-settings/constants';
import { roles } from '../../../auth/constant';
import { validationSchemaUserInfo } from '../../../event/validation/shema';
import { APP_KEYS } from '../../constants';
import { IMessage, IToken } from '../../types';
import { toastContainer } from '../toast';
import * as Styled from './contact-information.styled';
import { FilePath } from '../../../../utils';

export interface IContactInformation {
  user: IUser | undefined;
  title?: string;
}

export const ContactInformation = ({ user, title }: IContactInformation) => {
  const client = useQueryClient();
  const history = useHistory();
  const formData = new FormData();

  const [isEdit, setIsEdit] = useState(true);

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async (data: IMessage | IToken) => {
    if ('token' in data) {
      localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, data.token);
      history.push(APP_KEYS.ROUTER_KEYS.CHECK_EMAIL);
    }

    if ('message' in data) {
      toastContainer.success({ title: data.message });

      await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_USER);
    }
  };

  const { mutate: updateUserInfo } = useMutation<any, AxiosError<IAuthError>, Partial<IGetUser>>(
    (data: Partial<IGetUser>) => userService.updateUserInfo(data),
    {
      onSuccess,
      onError
    }
  );

  const { mutate: updateAvatar } = useMutation<any, AxiosError<IAuthError>, any>(
    (data: any) => userService.updateAvatar(data),
    {
      onSuccess,
      onError
    }
  );

  const onSaveAvatar = (file: File) => {
    formData.append('avatar', file);

    updateAvatar(formData);
  };

  const onSubmit = (data: any) => {
    delete data.role;

    updateUserInfo(data);
    setIsEdit(true);
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
          email: user?.email,
          phone: user?.phone,
          role: user?.role,
          is_hide_phone: user?.is_hide_phone
        }}
        onSubmit={() => {}}
        enableReinitialize
        validationSchema={validationSchemaUserInfo}
      >
        {({ values, setValues }) => (
          <Styled.CommonForm>
            <Styled.TextContainer isSpace>
              <Styled.SubTitle>{title ?? 'About'}</Styled.SubTitle>
              <Styled.EditBnt
                content={!isEdit ? 'Save' : 'Edit'}
                type='button'
                variant='inverse2'
                startIcon={!isEdit ? FilePath(Icons.success) : FilePath(Icons.editIcon)}
                widthIcon={SPACES.sm}
                onClick={() => {
                  isEdit ? setIsEdit(!isEdit) : onSubmit(values);
                }}
              />
            </Styled.TextContainer>

            <Styled.UserInfoContainer>
              <Styled.CommonInputAvatar
                label='Profile Photo'
                width='48%'
                readOnly={isEdit}
                isSave={false}
                avatar={user?.avatar}
                onSaveAvatar={onSaveAvatar}
                mb={SPACES.l}
              />

              <Styled.CommonInputMatchedWords
                matchedWords={roles.map((role) => role.text)}
                name='role'
                placeholder='Choose Location'
                height='3.5rem'
                width='48%'
                label='Role'
                readOnly
              />

              {inputs.map(({ name, label }, index) => {
                if (name === 'phone') {
                  return (
                    <Styled.Div key={index}>
                      <Styled.CommonInputMask
                        key={index}
                        mask='+9-999-999-9999'
                        placeholder='Phone Number'
                        name='phone'
                        height='3.5rem'
                        label='Phone Number'
                        readOnly={isEdit}
                        isHidePhone
                        mb={SPACES.xxxxs}
                      />
                      <Styled.Hide
                        readOnly={isEdit}
                        onClick={() => {
                          setValues((prev: any) => ({
                            ...prev,
                            is_hide_phone: !getIn(prev, 'is_hide_phone')
                          }));
                        }}
                      >
                        {!getIn(values, 'is_hide_phone')
                          ? 'SHOW PHONE NUMBER'
                          : 'HIDE PHONE NUMBER'}
                      </Styled.Hide>
                    </Styled.Div>
                  );
                }
                return (
                  <Styled.CommonInput
                    key={index}
                    mb={SPACES.l}
                    height='3.5rem'
                    name={name}
                    label={label}
                    width='100%'
                    readOnly={isEdit}
                  />
                );
              })}
            </Styled.UserInfoContainer>
          </Styled.CommonForm>
        )}
      </Formik>
    </>
  );
};
