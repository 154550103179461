import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../../assets';
import { APP_KEYS } from '../../../../common/constants';
import { Links } from '../../../../home-layout/constants';
import * as Styled from './header.styled';
import { FilePath } from '../../../../../utils';

export const Header = () => {
  const history = useHistory();

  const onLink = () => {
    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const onRedirect = () => {
    window.open(Links.contact_us);
  };

  return (
    <Styled.HeaderStyled>
      <Styled.Logo src={FilePath(Icons.logo)} alt='logo' />
      <Styled.Div>
        <Styled.Btn
          content='Contact Us'
          type='button'
          variant='inverse2'
          onClick={onRedirect}
          width='fit-content'
        />

        <Styled.Btn
          content='LOG IN'
          type='button'
          variant='primary'
          onClick={onLink}
          width='7.5rem'
        />
      </Styled.Div>
    </Styled.HeaderStyled>
  );
};
