import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

import { Icons, Png } from '../../../../../assets';
import { IWProps } from '../../../types';
import { Drawer } from '../../drawer';
import { PopupLayout } from '../../popup-layout';
import { Portal } from '../../portal';
import * as Styled from './input-avatar.styled';
import { FilePath } from '../../../../../utils';

export interface IInputAvatar extends IWProps {
  label?: string;
  width?: string;
  avatar?: string | null;
  onSaveAvatar: (file: File) => void;
  isSave?: boolean;
}

export const InputAvatar = ({
  label,
  readOnly = false,
  isSave = true,
  avatar,
  width,
  onSaveAvatar,
  ...props
}: IInputAvatar) => {
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const cropRef = useRef(null);
  const [slideValue, setSlideValue] = useState<any>(10);
  const [src, setSrc] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setModalOpen(true);
      setSrc(URL.createObjectURL(file));
    }
  };

  const onSave = () => {
    avatarFile && onSaveAvatar(avatarFile);
    setAvatarFile(null);
  };

  const onClose = () => {
    setModalOpen(false);
  };

  const handleSave = async () => {
    if (cropRef) {
      // @ts-ignore
      const dataUrl = cropRef?.current.getImage().toDataURL();

      const result = await fetch(dataUrl);

      const blob = await result.blob();

      const file = new File([blob], 'avatar.jpg', { type: blob.type });

      // if (!isSave && file) {
      //   setAvatarFile(file);
      //   onSaveAvatar(file)
      // }
      //
      // if (file && isSave) setAvatarFile(file);

      if (file) setAvatarFile(file);
      if (!isSave && file) onSaveAvatar(file);

      setSlideValue(10);
      onClose();
    }
  };

  const test = (e: any) => {
    setSlideValue(e.target.value);
  };

  return (
    <Styled.Wrapper width={width} readOnly={readOnly} {...props}>
      {label && <Styled.Label htmlFor='avatar'>{label}</Styled.Label>}

      <Styled.ImageContainer isSpace>
        <Styled.Div>
          <Styled.Img
            src={
              avatarFile
                ? avatarFile
                  ? URL.createObjectURL(avatarFile)
                  : undefined
                : avatar || FilePath(Png.defaultBlack)
            }
            alt='user-avatar'
          />
          <Styled.Text>{avatarFile ? avatarFile.name : 'Your Avatar'}</Styled.Text>
        </Styled.Div>
        {avatarFile && isSave ? (
          <Styled.SaveText onClick={onSave}>Save</Styled.SaveText>
        ) : (
          <Styled.EditIcon src={FilePath(Icons.editIcon)} alt='edit' />
        )}

        {!readOnly && (
          <input id='field-upload' type='file' accept='image/*' onChange={onChangeAvatar} />
        )}
      </Styled.ImageContainer>

      {modalOpen && (
        <Drawer onClose={onClose} open={modalOpen} slidePosition='bottom' contentPosition='bottom'>
          <Portal>
            <PopupLayout
              title='Edit Photo'
              saveText='Save Changes'
              onCancel={onClose}
              onSave={handleSave}
            >
              <Styled.AvatarEditorDiv>
                <Styled.AvatarEditor>
                  <AvatarEditor
                    ref={cropRef}
                    image={src}
                    style={{ width: '100%', height: '100%' }}
                    border={10}
                    borderRadius={0}
                    color={[14, 13, 13, 0.4]}
                    scale={slideValue / 10}
                    rotate={0}
                  />
                </Styled.AvatarEditor>
              </Styled.AvatarEditorDiv>

              <Styled.CustomSliderDiv>
                <span>-</span>

                <Styled.CustomSlider
                  min={10}
                  max={50}
                  sx={{
                    margin: '0 auto',
                    width: '80%',
                    color: 'cyan'
                  }}
                  size='medium'
                  defaultValue={slideValue}
                  value={slideValue}
                  onChange={(e) => test(e)}
                />
                <span>+</span>
              </Styled.CustomSliderDiv>
            </PopupLayout>
          </Portal>
        </Drawer>
      )}
    </Styled.Wrapper>
  );
};
