import React, { MouseEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { IUser } from '../../../../types';
import { APP_KEYS } from '../../../common/constants';
import { Links } from '../../constants';
import { useHomeLayoutContext } from '../../hooks';
import { urlUtil } from '../../utils';
import { Navigation } from '../navigation/navigation';
import { Notifications } from '../notifications/notifications';
import * as Styled from './header.styled';
import { FilePath } from '../../../../utils';

export const Header = ({ user }: { user?: IUser }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { user: _user, menu, setMenu } = useHomeLayoutContext();

  const [focused, setFocused] = useState<null | string>('');

  const isNavigation = focused === 'setting' || menu;

  const isLocation = (url: string) => {
    if (url === '/account-setting' && search === '?page=main') {
      return false;
    }

    return pathname.includes(url);
  };

  const onLink = () => {
    history.push(APP_KEYS.ROUTER_KEYS.HOME + APP_KEYS.ROUTER_KEYS.PAGE);
  };

  const onLogo = () => {
    window.open(APP_KEYS.ROUTER_KEYS.HOME + APP_KEYS.ROUTER_KEYS.PAGE, '_blank');
  };

  const onRedirect = () => {
    window.open(Links.contact_us);
  };

  const onClick = (url: string) => {
    history.push(urlUtil(url));
  };

  const onFocused = (e: MouseEvent<HTMLDivElement | HTMLElement>, str: string | null) => {
    e.stopPropagation();
    setFocused(str);
  };

  const isSetting = !focused && isLocation(APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING);
  const isVisible = !isNavigation || (isNavigation && window.innerWidth > 600);

  return (
    <Styled.HeaderStyled onClick={(e) => onFocused(e, null)}>
      <Styled.Div>
        <Styled.Logo onClick={onLogo} src={FilePath(Icons.logo)} alt='logo' />

        <Styled.TextContent>
          <Styled.Text onClick={onLink}>DISCOVER</Styled.Text>
          {isLocation(APP_KEYS.ROUTER_KEYS.PAGE) ? <Styled.Line2 /> : null}
        </Styled.TextContent>
      </Styled.Div>

      <Styled.Div>
        <Styled.Btn
          content='Contact Us'
          type='button'
          variant='inverse2'
          onClick={onRedirect}
          width='7.5rem'
        />

        {isVisible && (
          <Styled.Div2
            isSelect={!focused && isLocation(APP_KEYS.ROUTER_KEYS.FAVORITES)}
            onClick={onClick.bind(this, APP_KEYS.ROUTER_KEYS.FAVORITES)}
            isNotification
          >
            <Styled.Icon
              className='icon'
              style={{
                WebkitMaskImage: `url(${FilePath(Icons.favoriteIcon)})`,
                WebkitMaskSize: '100% 100%',
                maskImage: `url(${FilePath(Icons.favoriteIcon)})`
              }}
            />
          </Styled.Div2>
        )}

        {isVisible && (
          <Styled.Div2
            className='notification'
            isSelect={focused === 'notification'}
            isNotification
            onClick={(e) => onFocused(e, focused ? null : 'notification')}
          >
            <Styled.Icon
              className='icon'
              style={{
                WebkitMaskImage: `url(${FilePath(Icons.notification)})`,
                WebkitMaskSize: '100% 100%',
                maskImage: `url(${FilePath(Icons.notification)})`
              }}
            />

            {_user?.count_notifications ? (
              <Styled.DivDot className='dot'>
                <Styled.Dot />
              </Styled.DivDot>
            ) : null}
          </Styled.Div2>
        )}

        <Styled.Div2
          isSelect={!focused && isLocation(APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING)}
          onClick={(e) => onFocused(e, focused ? null : 'setting')}
        >
          <Styled.Image
            src={user?.avatar ? user?.avatar : isSetting ? FilePath(Png.defaultWhite) : FilePath(Png.defaultBlack)}
            alt='avatar'
          />

          <Styled.Icon2
            isNavigation={isNavigation}
            className='icon'
            style={{
              WebkitMaskImage: `url(${isNavigation ? FilePath(Icons.close) : FilePath(Icons.menu)})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${isNavigation ? FilePath(Icons.close) : FilePath(Icons.menu)})`
            }}
          />

          <Styled.Name>{user?.first_name}</Styled.Name>

          <Styled.Arrow
            isFocused={focused === 'setting'}
            className='arrow'
            style={{
              WebkitMaskImage: `url(${FilePath(Icons.arrowBottom2)})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${FilePath(Icons.arrowBottom2)})`
            }}
          />

          {isNavigation && (
            <Navigation
              onClose={() => {
                setMenu(false);
                setFocused(null);
              }}
              user={user}
            />
          )}
          {focused === 'notification' && <Notifications onClose={() => setFocused(null)} />}
        </Styled.Div2>
      </Styled.Div>
    </Styled.HeaderStyled>
  );
};
