import React, { useState } from 'react';

import { roles } from '../../constants';
import * as Styled from './tabs.styled';

export interface ITads {
  value: string;
  onClick: (value: string) => void;
  resetForm?: any;
  styles?: any;
}

export const Tads = ({ onClick, value, resetForm, styles }: ITads) => {
  const [isVisibleIndex, setIsVisibleIndex] = useState(0);

  const prev = (isIndex?: boolean) => {
    const menuWrapper = document.querySelector('.menu-wrapper');

    if (menuWrapper) {
      menuWrapper.scrollLeft -= menuWrapper?.children[isVisibleIndex - 1]?.clientWidth;
      !isIndex && setIsVisibleIndex((p) => (p -= 1));
    }
  };

  const next = (isIndex?: boolean) => {
    const menuWrapper = document.querySelector('.menu-wrapper');

    if (menuWrapper) {
      menuWrapper.scrollLeft += menuWrapper?.children[isVisibleIndex]?.clientWidth;

      !isIndex && setIsVisibleIndex((p) => (p += 1));
    }
  };

  const onTab = (tab: string, index: number) => {
    onClick(tab);
    resetForm && resetForm();

    if (index === 0 && window.innerWidth > 600) {
      setIsVisibleIndex(1);
    }

    if (index === roles.length - 1 && window.innerWidth > 600) {
      next(true);
    } else {
      setIsVisibleIndex(index);

      if (index < isVisibleIndex) {
        prev(true);
      }
      if (index > 1 && index > isVisibleIndex && window.innerWidth > 600) {
        next(true);
      }
      if (index > 0 && index > isVisibleIndex && window.innerWidth < 600) {
        next(true);
      }
    }
  };
  return (
    <Styled.Container styles={styles}>
      <Styled.RoleContainer className='menu-wrapper'>
        {roles.map(({ text, icon, tab }, index) => {
          const isValue = value === tab;

          return (
            <Styled.Role key={index} onClick={onTab.bind(this, tab, index)} isValue={isValue}>
              <Styled.IconRole
                className='icon'
                style={{
                  WebkitMaskImage: `url(${icon})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${icon})`
                }}
              />
              <Styled.RoleText
                isEventPlaner={text === 'EVENT PLANNERS'}
                isJob={text === 'JOB POSTINGS'}
              >
                {text}
              </Styled.RoleText>
            </Styled.Role>
          );
        })}
      </Styled.RoleContainer>
    </Styled.Container>
  );
};
