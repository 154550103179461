import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { FilePath, isHiringDate } from '../../../../utils';
import { ArrowBack, Button, DeleteLayout, Drawer, Loader, Portal } from '../../../common/component';
import {
  ButtonContainer,
  Categories,
  IconsCard,
  TextContainer
} from '../../../common/component/public-page/public-page';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket, useScrollStart } from '../../../common/hooks';
import * as CommonStyled from '../../../event/component/event-public/event-public.styled';
import { getFavoriteById, toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { urlUtil } from '../../../home-layout/utils';
import { deleteJobById, getJobById } from '../../hooks';
import { CreateJob } from '../create-job/create-job';
import * as Styled2 from '../create-job/create-job.styled';
import * as Styled from './job-public.styled';

export const JobPublic = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);
  useScrollStart();

  const id = search.split('=')[1];

  const [isShow, setShow] = useState(true);
  const [editComponent, setEditComponent] = useState<null | string>(null);

  const { isLoading, data } = getJobById(id);
  const { mutate: deleteJob } = deleteJobById(id);

  const isUser = user?.id === data?.job.user_id;

  const { isLoading: isLoadingFavorite, data: dataFavorite } = getFavoriteById(id, 'Jobs', !isUser);
  const { mutate: createFavorite } = toggleFavorite(id, data?.job.user_id, socket);

  const abouts = [
    {
      icon: FilePath(Icons.calendarIcon),
      title: 'Date & Time',
      text: `${data?.job.start_date}-${data?.job.end_date}`
    },
    { icon: FilePath(Icons.locationIcon), title: 'Location', text: data?.job.location },
    {
      icon: FilePath(Icons.reteIcon),
      title: 'Hourly Rate',
      text: (
        <>
          <img src={FilePath(Icons.dollar)} alt='dollar' />
          {data?.job?.hourly_rate}/h
        </>
      )
    }
  ];
  const isFavorite = dataFavorite?.favorite === null;
  const favorite = isFavorite ? FilePath(Icons.favoriteIcon2) : FilePath(Icons.favoriteIcon);
  const isHiring = data?.job.status === 'Hiring' && isHiringDate(data?.job.end_date);

  const onBack = () => {
    history.goBack();
  };

  const onJobs = () => {
    const values = data?.events ? data?.events : [];
    const copyValues = [...values];

    return copyValues.splice(0, isShow ? 1 : values.length);
  };

  const onEdit = (str: string | null) => {
    setEditComponent(str);
  };

  const onEventTo = (_id: string) => () => {
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT)}?id=${_id}`);
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toastContainer.success({ title: 'LINK COPIED!' });
  };

  const onToUser = () => {
    const links: { [key: string]: any } = {
      VENDOR: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENDOR}?id=${data?.user.owner_id}`,
      VENUE: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENUE}?id=${data?.user.owner_id}`,
      TALENT: `${APP_KEYS.ROUTER_KEYS.PUBLIC_TALENT}?id=${data?.user.owner_id}`,
      'EVENT PLANNER': `${APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT_PLANER}?id=${data?.user.owner_id}`
    };

    // @ts-ignore
    const link = links[data?.user.role];

    history.push(urlUtil(link));
  };

  const onFavorite = async () => {
    await createFavorite({
      role: 'Jobs',
      user_id: user?.id,
      owner_id: data?.job.id as string,
      to_user_id: data?.job.user_id
    });
  };

  const onDelete = () => {
    deleteJob();
  };

  if (isLoading || isLoadingFavorite) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  return (
    <Styled.Container>
      <ArrowBack
        onClick={onBack}
        isButton={{
          flag: true,
          isUser,
          isHiring: true,
          favorite,
          onCopyLink,
          onFavorite,
          onEdit
        }}
      />

      <CommonStyled.Title>{data?.job.job_title}</CommonStyled.Title>

      <TextContainer isSpace isBorder margin='0' padding={`0 0 ${SPACES.l} 0 `}>
        <CommonStyled.Div>
          <CommonStyled.Status isColor={isHiring}>
            {isHiring ? data?.job.status : 'Closed'}
          </CommonStyled.Status>
        </CommonStyled.Div>

        <ButtonContainer
          isUser={isUser}
          onFavorite={onFavorite}
          favorite={favorite}
          isAdmin={user?.role_site === 'admin'}
          isFavorite={isFavorite}
          onCopyLink={onCopyLink}
          onEdit={onEdit.bind(this, 'edit')}
        />
      </TextContainer>

      <TextContainer text='Job Information' />

      <IconsCard styled={Styled.IconsCardStyled} abouts={abouts} />

      <TextContainer
        text='Employer'
        isBorder
        margin={`${SPACES.l} 0 0 0 `}
        padding={`${SPACES.l} 0 0 0 `}
      />

      <Styled.EventContainer>
        <Styled.UserDiv onClick={onToUser} className='user'>
          <Styled2.Div>
            <Styled2.EventImage
              src={data?.user?.avatar ? data?.user?.avatar : Png.defaultBlack}
              alt='user-avatar'
            />
            <Styled2.Div2>
              <Styled2.SubTitle className='name'>
                {data?.user?.first_name} {data?.user?.last_name}
              </Styled2.SubTitle>
              <Styled2.LocationText>{data?.user?.role} </Styled2.LocationText>
            </Styled2.Div2>
          </Styled2.Div>
          <Styled2.Div>
            <Styled2.Text>5.0</Styled2.Text>

            <Styled.IconStar
              style={{
                WebkitMaskImage: `url(${FilePath(Icons.starIcon)})`,
                WebkitMaskSize: '100% 100%',
                maskImage: `url(${FilePath(Icons.starIcon)})`
              }}
            />
          </Styled2.Div>
        </Styled.UserDiv>

        <Styled.UserDiv isWidth className='icon'>
          <Styled2.Div2>
            <Styled2.SubTitle>Phone Number</Styled2.SubTitle>
            <Styled2.LocationText>{data?.user?.phone} </Styled2.LocationText>
          </Styled2.Div2>

          <a href={`tel:${data?.user?.phone}`}>
            <Styled.DivRole2>
              <Styled.IconRole
                style={{
                  WebkitMaskImage: `url(${FilePath(Icons.phoneIcon)})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${FilePath(Icons.phoneIcon)})`
                }}
              />
            </Styled.DivRole2>
          </a>
        </Styled.UserDiv>

        <Styled.UserDiv isBorder isWidth>
          <Styled2.Div2>
            <Styled2.SubTitle>Email Address</Styled2.SubTitle>
            <Styled2.LocationText>{data?.user?.email} </Styled2.LocationText>
          </Styled2.Div2>

          <a href={`mailto:${data?.user?.email}`}>
            <Styled.DivRole2>
              <Styled.IconRole
                style={{
                  WebkitMaskImage: `url(${FilePath(Icons.emailIcon)})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${FilePath(Icons.emailIcon)})`
                }}
              />
            </Styled.DivRole2>
          </a>
        </Styled.UserDiv>
      </Styled.EventContainer>

      {onJobs().length ? (
        <TextContainer
          text='Event Information'
          isBorder
          margin={`${SPACES.l} 0 0 0 `}
          padding={`${SPACES.l} 0 0 0 `}
        />
      ) : null}

      {onJobs().length
        ? onJobs().map((event, i) => (
            <Styled.EventContainer key={i} isCursor onClick={onEventTo(event.id)}>
              <Styled.Div>
                <Styled2.Div>
                  <Styled2.EventImage src={event?.main_image} alt='event image' />
                  <Styled2.Div2>
                    <Styled2.SubTitle>{event?.name}</Styled2.SubTitle>
                    <Styled2.LocationText>
                      {event?.location}, {event?.venue}
                    </Styled2.LocationText>
                  </Styled2.Div2>
                </Styled2.Div>
                <Styled2.Div className='date'>
                  <Styled2.Text>
                    {event?.start_data} - {event?.end_data}
                  </Styled2.Text>

                  <Styled2.IconRole
                    style={{
                      WebkitMaskImage: `url(${FilePath(Icons.calendarIcon)})`,
                      WebkitMaskSize: '100% 100%',
                      maskImage: `url(${FilePath(Icons.calendarIcon)})`
                    }}
                  />
                </Styled2.Div>
              </Styled.Div>

              <Styled.ContainerTo className='to'>
                <CommonStyled.Div className='date'>
                  <Styled.DivRoleCommon>
                    <Styled2.IconRole
                      style={{
                        WebkitMaskImage: `url(${FilePath(Icons.calendarIcon)})`,
                        WebkitMaskSize: '100% 100%',
                        maskImage: `url(${FilePath(Icons.calendarIcon)})`
                      }}
                    />
                  </Styled.DivRoleCommon>
                  <div>
                    <CommonStyled.SubTitle>Date</CommonStyled.SubTitle>
                    <CommonStyled.UserRole>
                      {event?.start_data} - ${event?.end_data}
                    </CommonStyled.UserRole>
                  </div>
                </CommonStyled.Div>

                <Button
                  width='3.5rem'
                  height='3.5rem'
                  onClick={onEventTo(event.id)}
                  type='button'
                  content={<Styled.Image src={FilePath(Icons.arrowUp)} alt='arrowUp' />}
                  variant='primaryInverse'
                  pads='0'
                />
              </Styled.ContainerTo>
            </Styled.EventContainer>
          ))
        : null}

      {data?.events && data?.events.length > 1 ? (
        <Styled.Btn
          onClick={() => setShow(!isShow)}
          type='button'
          content={isShow ? `show ${data?.events && data?.events.length - 1} more` : 'Hide'}
          variant='primaryInverse'
        />
      ) : null}

      <TextContainer text='Categories' />
      <Categories categories={data?.job.categories} />
      <TextContainer text='Description' />
      <CommonStyled.Text>{data?.job?.description}</CommonStyled.Text>

      {editComponent && (
        <Drawer
          onClose={onEdit.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'edit' && (
              <CreateJob
                onDelete={onEdit.bind(this, 'delete')}
                isEdit
                onCancel={onEdit.bind(this, null)}
              />
            )}
            {editComponent === 'delete' && (
              <DeleteLayout onSave={onDelete} onCancel={onEdit.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
