import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { venueService } from '../../../services';
import { IAuthError, IEditVenue } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { IMessage } from '../../common/types';

const onError = (_err: AxiosError<IAuthError>) => {
  const err = _err.response?.data as IAuthError;
  toastContainer.error({ title: err.message ?? _err.message });
};

const onSuccess = async ({ message }: IMessage, client: any, id: string) => {
  toastContainer.success({ title: message });
  await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_VENUE, id]);
};

const onSuccessEdit = async (data: IMessage, client: any, id: string) => {
  await onSuccess(data, client, id);
  await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ALL_VENUES);
};

const onSuccessEditExperiences = async (data: IMessage, client: any, id: string) => {
  await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_VENUE_EXPERIENCES, id]);
  await onSuccessEdit(data, client, id);
};

export const editVenueById = (id: string) => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, any>(
    (editData: any) => venueService.editVenue(editData, id),
    {
      onSuccess: (data) => onSuccessEdit(data, client, id),
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const editVenueExperiencesById = (id: string) => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IEditVenue>(
    (editData: IEditVenue) => venueService.editVenueSpaces(editData),
    {
      onSuccess: (data) => onSuccessEditExperiences(data, client, id),
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};
