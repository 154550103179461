import styled from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { SubTitle } from '../../../auth/component/sing-up/sing-up.styled';
import { Button } from '../../../common/component';

export const Container = styled.div`
  display: none;
  padding: ${SPACES.xxxxl};

  width: 198vw !important;
  height: 100vh;

  position: absolute;
  top: 5.5rem;
  z-index: 17;
  padding: 3rem;

  background: ${COLORS.white};

  @media screen and (max-width: ${MEDIA.tablet}) {
    top: 3.5rem;
    display: flex;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContent = styled(TextContainer)`
  align-items: flex-start;
  margin-bottom: ${SPACES.xxxxxxl};
`;

export const Text = styled.p`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: ${FONTS.SIZES.xxxxl__};
  line-height: ${FONTS.SIZES.xxxxxxl};
  color: ${COLORS.black};

  text-transform: uppercase;
`;
export const Line = styled.div`
  width: 2.5rem;
  height: 0.375rem;

  background: ${COLORS.purple};
  border-radius: 4px;
`;

export const Line2 = styled.div`
  width: calc(100% + 3rem);
  margin: ${SPACES.xxs} 0 ${SPACES.xxs} -${SPACES.xxxxl};
  height: 1px;
  border-top: 1px solid ${COLORS.black};
`;

export const IconDiv = styled.div`
  display: flex;
  gap: ${SPACES.l};

  flex-wrap: wrap;
`;

export const Div = styled.div`
  width: 46%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: absolute;

  top: 70vh;
`;

export const TextContent2 = styled.div`
  margin-top: ${SPACES.xxxxl};

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
`;

export const Text1 = styled.a`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};

  text-decoration: none;
`;

export const IconContainer = styled.a`
  width: 3rem;
  aspect-ratio: 1/1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;

  border: 1px solid ${COLORS.black};

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
export const Icon = styled.div`
  height: 1.25rem;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const SettingContainer = styled.div`
  display: flex;

  flex-direction: column;

  padding: ${SPACES.xxxxl} ${SPACES.xxxxl} ${SPACES.xxs} ${SPACES.xxxxl};

  width: 20rem;
  position: absolute;
  top: 6.25rem;
  right: ${SPACES.xxxxl};
  z-index: 30;

  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const UserInfoContainer = styled.div<{ isColumn?: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
`;

export const UserImage = styled.img`
  height: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;

  object-fit: cover;
  cursor: pointer;
`;

export const UserRole = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
  cursor: pointer;
`;

export const UserName = styled(UserRole)`
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  gap: 10px;
  cursor: pointer;
`;

export const Btn2 = styled(Button)`
  text-transform: uppercase;
`;

export const Role = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + ${SPACES.xxxxl});

  max-height: 3rem;
  margin-bottom: 3px;
  margin-left: -${SPACES.s};

  padding: ${SPACES.sm};
  gap: ${SPACES.l};

  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: #f5f6fe;

    & > div {
      background: ${COLORS.purple};
    }
  }
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const RoleText = styled(SubTitle)``;
