import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;
  ${Center};
`;

export const ForgotText = styled.span<{ isColor?: boolean }>`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  text-transform: uppercase;

  display: flex;
  gap: 5px;

  color: ${({ isColor }) => (isColor ? COLORS.purple : COLORS.black)};
  cursor: ${({ isColor }) => (isColor ? 'pointer' : 'unset')};

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-bottom: ${({ isColor }) => (isColor ? '10px' : '0')};
    font-size: ${FONTS.SIZES.m};
  }
`;

export const ForgotText2 = styled(ForgotText)`
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
`;

export const BtnContainer = styled.div`
  width: 100%;
  ${Center}
  justify-content: flex-end;
  margin-top: ${SPACES.s};
`;

export const Btn = styled(Button)`
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    margin-bottom: ${SPACES.xxxxl};
  }
`;

export const Test = styled.div`
  width: 100%;
  ${Center}
  justify-content: space-between;

  margin-top: ${SPACES.xxxxxxl_};

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column-reverse;
  }
`;
