import { AxiosError } from 'axios';
import { Formik, getIn } from 'formik';
import React, { MouseEvent, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { adminService } from '../../../../services';
import { COLORS, SPACES } from '../../../../theme';
import { IAuthError, ICreateBanners, IEditBanners } from '../../../../types';
import { transformDate } from '../../../../utils';
import {
  Drawer,
  Input,
  Loader,
  NotFoundItem,
  PopupLayout,
  Portal
} from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage, Iuuid } from '../../../common/types';
import { HeaderPage } from '../../common/page';
import { validationSchemaCreateBanner } from '../../validation/shema';
import * as Styled from './banners.styled';

export const Banners = () => {
  const history = useHistory();
  const client = useQueryClient();

  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [editId, setEditId] = useState<null | string | number>(null);

  const onAddBanners = (str: string | null) => {
    setEditComponent(str);
    setEditId(null);
  };

  const onBack = () => {
    history.goBack();
  };

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });
    await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ADMIN_BANNERS);

    setEditComponent(null);
    setEditId(null);
  };

  const { isLoading, data } = useQuery([APP_KEYS.QUERY_KEYS.GET_ADMIN_BANNERS], () =>
    adminService.getAllBanners()
  );

  const { isLoading: isLoadingBanner, data: dataBanner } = useQuery(
    [APP_KEYS.QUERY_KEYS.GET_ADMIN_BANNER, editId],
    () => adminService.getBanner({ id: editId }),
    { enabled: !!editId }
  );

  const { mutate: createBanners } = useMutation<any, AxiosError<IAuthError>, ICreateBanners>(
    (_data: ICreateBanners) => adminService.createBanners(_data),
    {
      onSuccess,
      onError
    }
  );

  const { mutate: getDeleteBanner } = useMutation<any, AxiosError<IAuthError>, Iuuid>(
    (_data: Iuuid) => adminService.getDeleteBanner(_data),
    {
      onSuccess,
      onError
    }
  );

  const { mutate: editBanners } = useMutation<any, AxiosError<IAuthError>, IEditBanners>(
    (_data: IEditBanners) => adminService.editBanners(_data),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = (_data: any) => {
    editId ? editBanners({ banner_id: editId, ..._data }) : createBanners(_data);
  };

  const onEditBanner = (e: MouseEvent<HTMLButtonElement>, id: number | string) => {
    e.stopPropagation();

    setEditComponent('create');
    setEditId(id);
  };

  const onDeleteBanner = () => {
    // @ts-ignore
    getDeleteBanner({ id: editId });
  };

  function isValidDate(dateString: string | undefined): boolean {
    if (!dateString) return false;
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  }

  const findLast = () => {
    // @ts-ignore
    const newDate = [...data?.banners];
    return (
      newDate &&
      newDate.sort((a: any, b: any) => {
        if (isValidDate(a.createdAt) && isValidDate(b.createdAt)) {
          // @ts-ignore
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        return 0;
      })[0]
    );
  };

  const lastPost = data?.banners && findLast();

  return (
    <Styled.Container>
      <HeaderPage
        onBack={onBack}
        title='ALL Banners'
        content={
          <>
            Created <Styled.Text>{data?.count} Banners</Styled.Text>
          </>
        }
        buttonOptions={{
          content: 'Post New Banner',
          onClick: onAddBanners.bind(this, 'create')
        }}
      />
      {!isLoading && data?.banners.length ? (
        <Styled.TableContainer>
          <StyledTable.Table>
            <StyledTable.Head>
              <StyledTable.Row>
                <StyledTable.HeadRow>Link</StyledTable.HeadRow>
                <StyledTable.HeadRow>Link text</StyledTable.HeadRow>
                <StyledTable.HeadRow>Text</StyledTable.HeadRow>
                <StyledTable.HeadRow>Date</StyledTable.HeadRow>
                <StyledTable.HeadRow className='edit' />
              </StyledTable.Row>
            </StyledTable.Head>
            <StyledTable.Body>
              {data.banners
                ? data.banners.map(({ text, updatedAt, link, id, link_text }) => {
                    const _createdAt = transformDate(updatedAt);
                    const isActive = lastPost.id === id;

                    return (
                      <StyledTable.Row className={isActive ? 'active' : ''} key={id}>
                        <StyledTable.Data
                          className='title'
                          onClick={() => window.open(link, '_blank')}
                        >
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={link}
                          >
                            {link}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={link_text ?? ''}
                          >
                            {link_text ?? '-'}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={text}
                          >
                            {text}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={_createdAt}
                          >
                            {_createdAt}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='edit'>
                          <StyledTable.EditButton onClick={(e) => onEditBanner(e, id)}>
                            <StyledTable.Icon
                              className='icon'
                              style={{
                                WebkitMaskImage: `url(${Icons.editIcon})`,
                                WebkitMaskSize: '100% 100%',
                                maskImage: `url(${Icons.editIcon})`
                              }}
                            />
                          </StyledTable.EditButton>
                        </StyledTable.Data>
                      </StyledTable.Row>
                    );
                  })
                : null}
            </StyledTable.Body>
          </StyledTable.Table>
        </Styled.TableContainer>
      ) : null}

      {!isLoading && !data?.banners.length ? (
        <Styled.ErrorContainer>
          <NotFoundItem
            isSearch
            title='No Results Found'
            subTitle='It seems we can’t find any results based on your search.'
          />
        </Styled.ErrorContainer>
      ) : null}

      {isLoading ? (
        <Styled.ErrorContainer>
          <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
        </Styled.ErrorContainer>
      ) : null}

      {editComponent && (
        <Drawer
          onClose={onAddBanners.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'create' && (
              <Formik
                initialValues={{
                  text: editId ? dataBanner?.banner.text : '',
                  link: editId ? dataBanner?.banner.link : '',
                  link_text: editId ? dataBanner?.banner.link_text : 'more information'
                }}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={validationSchemaCreateBanner}
              >
                {({ isValid, values, touched, errors }) => (
                  <Styled.CommonForm>
                    <PopupLayout
                      onCancel={onAddBanners.bind(this, null)}
                      onSave={() => {}}
                      type='submit'
                      disabled={!isValid}
                      title={editId ? 'Edit Banner' : 'Create a Banner'}
                      saveText={editId ? 'Save Changes' : 'post banner'}
                      styled={Styled.Popup}
                      isEdit={!!editId}
                      onDelete={onDeleteBanner}
                      isValid={
                        Object.keys(touched).length ? Object.keys(errors).length === 0 : true
                      }
                    >
                      {isLoadingBanner && (
                        <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
                      )}
                      <Input
                        mb={SPACES.l}
                        height='3.5rem'
                        name='text'
                        label='Text'
                        placeholder='Text'
                      />

                      <Input
                        mb={SPACES.l}
                        height='3.5rem'
                        name='link'
                        label='Link'
                        placeholder='Link'
                        isOptional
                      />

                      {getIn(values, 'link')?.length ? (
                        <Input
                          mb={SPACES.l}
                          height='3.5rem'
                          name='link_text'
                          label='Link text'
                          placeholder='Link Text'
                        />
                      ) : null}
                    </PopupLayout>
                  </Styled.CommonForm>
                )}
              </Formik>
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
