import styled from 'styled-components';

import { MEDIA } from '../../../../theme';
import { Center, CommonSubTitle, CommonText } from '../../../common/component/card';
import {
  CommonFormCreate,
  CommonInputCalendarCreate,
  CommonInputCreate,
  CommonInputDropdownCroupCreate,
  CommonInputHoursCreate,
  CommonInputMatchedWordsCreate,
  CommonTextContainer,
  CommonTime,
  CommonUserInfoContainer
} from '../../../common/component/create';

export const CommonForm = styled(CommonFormCreate)``;
export const TextContainer = styled(CommonTextContainer)``;
export const Text = styled(CommonText)``;
export const SubTitle = styled(CommonSubTitle)``;
export const UserInfoContainer = styled(CommonUserInfoContainer)``;
export const CommonInput = styled(CommonInputCreate)``;
export const CommonInputCalendar = styled(CommonInputCalendarCreate)``;
export const CommonInputDropdownCroup = styled(CommonInputDropdownCroupCreate)``;
export const CommonInputHours = styled(CommonInputHoursCreate)``;

export const Time = styled(CommonTime)`
  @media screen and (max-width: ${MEDIA.laptop_m}) {
    flex-direction: column;
  }
`;
