import { Formik } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { COLORS, SPACES } from '../../../../theme';
import {
  Gallery,
  Input,
  InputMatchedWords,
  InputTextArea,
  Loader,
  PopupLayout
} from '../../../common/component';
import { InputConst, Supplier_Vendor_Talent_Const } from '../../../common/constants';
import { transformData } from '../../../common/utils/transform-data';
import { Location } from '../../../home-layout/constants';
import { editVendorById, getVendorById } from '../../hooks';
import { ICreatVendorCreate } from '../../types';
import { validationSchemaEditVendor } from '../../validation/shema';
import * as Styled from './vendor-create.styled';

export const VendorCreate = ({ onCancel, isEdit = false }: ICreatVendorCreate) => {
  const formData = new FormData();
  const { search } = useLocation();

  const id = search.split('=')[1];

  const { isLoading, data } = getVendorById(id);
  const { mutate: editVendor } = editVendorById(id);

  const onEdit = async (editData: any) => {
    transformData(editData, formData);
    await editVendor(formData);
  };

  const onSubmit = async (_data: any) => {
    await onEdit(_data);
    onCancel();
  };

  const gallery = isEdit
    ? data?.vendor?.images
      ? data?.vendor?.images?.sort((a, b) => a.index - b.index)
      : []
    : [];

  const categories = isEdit ? (data?.vendor.categories ? data?.vendor.categories : []) : [];

  return (
    <Formik
      initialValues={{
        gallery,
        name: isEdit ? data?.vendor.name : '',
        profession: isEdit ? data?.vendor.profession : '',
        website: isEdit ? data?.vendor.website : '',
        location: isEdit ? data?.vendor.location : '',
        address: isEdit ? data?.vendor.address : '',
        phone: isEdit ? data?.vendor.phone : '+1',
        email: isEdit ? data?.vendor.email : '',
        description: isEdit ? data?.vendor.description : '',
        categories
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchemaEditVendor}
    >
      {({ touched, errors }) => (
        <Styled.CommonForm>
          <PopupLayout
            title='Edit Vendor Profile'
            saveText='Save Changes'
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            {isLoading && <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />}

            <Gallery name='gallery' gallery={gallery} />

            <Styled.TextContainer isSpace>
              <Styled.SubTitle>About</Styled.SubTitle>
            </Styled.TextContainer>

            <Styled.UserInfoContainer>
              <Input
                mt={SPACES.l}
                mb={SPACES.l}
                height='3.5rem'
                {...InputConst.name}
                label='Business name'
              />

              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                name='profession'
                label='Profession'
                placeholder='Photographer'
                width='100%'
              />
              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                name='website'
                label='Website'
                placeholder='www.jamesjohn.com'
                width='100%'
              />

              <Styled.CommonInputDropdownCroup
                matchedWords={Location}
                height='3.5rem'
                width='48%'
                isFilter
                isFilterData
                item='cities'
                titleItem='state'
                {...InputConst.location}
              />

              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                name='address'
                label='Address'
                placeholder='1234 McAllister St, San Francisco'
                width='100%'
              />

              <Styled.CommonInputMask
                mask='+9-999-999-9999'
                height='3.5rem'
                mb={SPACES.xxxxxxls}
                {...InputConst.phone}
              />

              <Styled.CommonInput
                mb={SPACES.l}
                type='email'
                height='3.5rem'
                width='100%'
                {...InputConst.email}
              />
            </Styled.UserInfoContainer>

            <Styled.TextContainer>
              <Styled.SubTitle>Additional Information</Styled.SubTitle>
            </Styled.TextContainer>

            <InputMatchedWords
              matchedWords={Supplier_Vendor_Talent_Const}
              height='3.5rem'
              width='100%'
              isFilter
              isChip
              isFilterData
              mt={SPACES.xxxxl}
              {...InputConst.categories}
            />

            <InputTextArea
              rows={7}
              maxLength={1000}
              mt={SPACES.xxxxl}
              {...InputConst.description}
            />
          </PopupLayout>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
