export const COLORS = {
  white: '#ffffff',
  white50: '#F9F9F9',
  white100: '#F3F3F3',
  white200: '#F5F6FE',
  white300: '#ECECEC',

  mainRed: '#ec091f',
  semiRed: '#CF5F5F',
  star: '#E3A75A',

  black: '#0E0D0D',
  purple: '#8390F5',
  purpleHover: '#7282F4',
  semiPurple: '#5A6DD8',
  secondaryPurple: '#7786d9',
  secondaryPurpleHover: '#a9b4e3',
  blue: '#4BA0D7',
  gray: '#6B6775',
  gray150: '#403F3F',
  green: '#4FBB92',

  semiWhite: '#d0d5dd',

  gray50: '#F9FAFB',
  gray100: '#EAECF0',
  semiTransparentWhite: '#344054',
  baseGrey: '#F2F4F7',
  secondaryRed: '#83141e',
  lightPink: '#FBC1C3'
};
