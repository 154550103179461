import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  padding: 0 5rem;

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: 0 1rem;
  }
`;

export const PopupLayoutStyled = css`
  max-width: 45rem;
  height: 80%;

  & > div > .btn {
    padding: ${SPACES.l} ${SPACES.xxxxxxl};
  }

  & > div > div > .btn {
    padding: ${SPACES.l} ${SPACES.xxxxxxl};
  }
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};

  text-align: center;
`;
export const Text = styled.p`
  ${Fonts};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.m};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;
export const Title = styled.p`
  ${Fonts};

  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};

  margin-bottom: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.xxl};
    line-height: ${FONTS.SIZES.xxxxxxl_};
  }
`;

export const Image = styled.img`
  width: 8rem;
  aspect-ratio: 1/1;
  margin-bottom: ${SPACES.xxxxxxl_};
`;
