import { useEffect } from 'react';

export const useScrollStart = () => {
  useEffect(() => {
    const el = document.querySelector('[data-element="content"]') as HTMLDivElement;

    if (el) {
      el.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }
  }, []);
};
