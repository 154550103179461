import { useField } from 'formik';
import React, { forwardRef, useRef } from 'react';

import { useAutoResizeTextArea } from '../../../hooks';
import { IInputTextareaProps } from '../../../types';
import * as Styled from './input-text-area.styled';

export const InputTextArea = forwardRef<HTMLDivElement, IInputTextareaProps>(
  (
    { rows, label, name, resizable = true, placeholder, margin, maxLength, readOnly, ...props },
    ref
  ) => {
    const [field, { touched, error, value }] = useField(name);

    const isError = !!error && touched;

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useAutoResizeTextArea(resizable ? textAreaRef.current : null, field.value, rows);

    return (
      <Styled.InputContainer margin={margin || ''} ref={ref} {...props}>
        {label && (
          <Styled.Label isError={isError} htmlFor={name}>
            {label}
          </Styled.Label>
        )}

        <Styled.Input
          ref={resizable ? textAreaRef : null}
          isError={isError}
          rows={rows}
          id={name}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={readOnly}
          {...field}
        />

        {maxLength ? (
          <Styled.MaxLength>
            <Styled.MaxLengthText>
              {value?.length}/{maxLength}
            </Styled.MaxLengthText>
          </Styled.MaxLength>
        ) : null}

        {isError && error !== 'is required' ? (
          <Styled.ErrorInfoContainer>
            <Styled.ErrorInfoText>{error}</Styled.ErrorInfoText>
          </Styled.ErrorInfoContainer>
        ) : null}
      </Styled.InputContainer>
    );
  }
);
