import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../theme';
import { SubTitle } from '../auth/component/sing-up/sing-up.styled';

export const Container = styled.div`
  width: 85%;
  height: 100%;

  margin: 0 auto;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    width: 100%;
  }
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

const Hover = css`
  background: ${COLORS.black};

  & > p {
    color: ${COLORS.white};
  }

  & > div {
    background: ${COLORS.purple};
  }
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  ${Fonts}
  text-transform: uppercase;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxxxl};
  line-height: 3.5rem;
  padding-top: ${SPACES.xxxxxxls};
`;

export const TadsStyles = css`
  margin-top: ${SPACES.l};
`;

export const RoleContainer = styled.div`
  width: 100%;

  margin-top: ${SPACES.l};

  display: flex;
  overflow-x: auto;
  position: relative;

  gap: ${SPACES.xxxxl};

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const Role = styled.div<{ isValue: boolean }>`
  ${Center}
  width: calc(fit-content + ${SPACES.xxxxl});

  max-height: 3.5rem;

  padding: ${SPACES.xxxxl};
  gap: 10px;

  background: ${COLORS.white};

  border-radius: 8px;
  border: 1px solid ${COLORS.black};
  cursor: pointer;

  ${({ isValue }) =>
    isValue &&
    css`
      ${Hover}
    `}

  &:hover {
    ${Hover}
  }
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const RoleText = styled(SubTitle)`
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const EventContainer = styled.div<{ length: boolean }>`
  width: 100%;
  min-height: calc(100vh - 17rem);

  background: ${COLORS.white};

  margin-top: ${SPACES.xxxxl};
  padding-bottom: 4.25rem;
  padding-right: ${SPACES.xs};

  ${({ length }) =>
    !length &&
    css`
      padding: 0;
      ${Center};
    `}

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: ${COLORS.white};
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const DivEvent = styled.div`
  &:last-child > .line {
    display: none;
  }
`;
export const DivEvent2 = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.black};
  margin: ${SPACES.xxxxxxls} 0;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin: ${SPACES.l} 0;
  }
`;
