export const Event_Const = [
  'Awards & Ceremony',
  'Gala / Wedding',
  'Concert & Musical Events',
  'Festivals',
  'Mobile Tours',
  'Special Occasions',
  'Corporate Events',
  'Trade Shows & Expo Activations',
  'Workshops',
  'Product Launch',
  'Networking & Conferences',
  'Fundraisers',
  'Cocktail & Networking Events',
  'Holiday Party',
  'Opening Party',
  'Sporting Events',
  'Team Building',
  'PR Events',
  'Virtual Events',
  'Other'
];

export const Supplier_Vendor_Talent_Const = [
  'Audio & Staging',
  'Speakers',
  'Staff/General Labor',
  'Tent & Chair Rentals',
  'Transportation & Bus Services',
  'Lighting & Electricity',
  'Floral & Design',
  'Photography/Photo Booths/Videography',
  'Gifts & Swag',
  'Graphics/Printing/Signage',
  'Set Design',
  'AV Rental & Production',
  'Balloon Design Services',
  'Cakes/Desserts Catering',
  'Catering',
  'Cleaning Services',
  'DJs / MC / Hosts',
  'Entertainment & Talent',
  'Food Trucks',
  'Experiential Design',
  'Fabrication',
  'Brand Ambassadors',
  'Security',
  'Influencers',
  'Logistics',
  'Meeting Planner',
  'Other'
];

export const Venues = [
  'Indoor/Outdoor Event Space',
  'Restaurant / Bar / Club / Brewery / Distillery',
  'Vineyard & Winery',
  'Stadium / Performance Space',
  'Event Space (raw/open)',
  'Museum / Gallery',
  'Hotel',
  'Convention Center',
  'Conference & Meeting Space',
  'Boats / Yachts',
  'Country Clubs',
  'Other'
];
