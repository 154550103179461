import React, { useEffect, useRef, useState } from 'react';

import { Icons } from '../../../../assets';
import { useCategories } from '../../hooks';
import { IAvatarImage, ICategories, IIcons } from '../../types';
import * as Styled from './card.styled';
import { FilePath } from '../../../../utils';

export const Categories = ({ categories, id = 'categories', children }: ICategories) => {
  const { length, categoriesVisible, categoriesRef } = useCategories(categories);

  return (
    <Styled.ContentSpace>
      <Styled.Content ref={categoriesRef}>
        {categoriesVisible.length
          ? categoriesVisible?.map((text, index) => (
              <Styled.Chip key={index + id}>{text}</Styled.Chip>
            ))
          : null}
        {length ? <Styled.Chip>+{length}</Styled.Chip> : null}
      </Styled.Content>
      {children}
    </Styled.ContentSpace>
  );
};

export const IconsCard = ({ icons }: IIcons) => (
  <Styled.ContentIcon>
    {icons
      .filter((a) => a.text !== null && a.text !== '')
      .map(({ icon, text, background }, index) => (
        <Styled.Div key={index}>
          <Styled.CommonIcon
            className='icon'
            style={{
              WebkitMaskImage: `url(${icon})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${icon})`,
              background
            }}
          />
          <Styled.TextIcons>{text}</Styled.TextIcons>
        </Styled.Div>
      ))}
  </Styled.ContentIcon>
);

export const AvatarImage = ({ image, isUser, onFavorite, favorite, height }: IAvatarImage) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const [imageHeight, setImageHeight] = useState<null | number>(null);
  const [imageWidth, setImageWidth] = useState<null | number>(null);

  const [isFull, setFull] = useState<boolean>(false);

  const handleImageLoad = () => {
    if (imageRef.current) {
      // @ts-ignore
      const { height, width } = imageRef.current;

      // We save the size of the photo
      setImageHeight(height);
      setImageWidth(width);

      imageRef.current.classList.add('none');
    }
  };

  const isValid = () => {
    if (imageHeight && imageWidth) {
      if (window.innerWidth < 768 && imageWidth < imageHeight) {
        return false;
      }
      const size = window.innerWidth > 600 ? (height ? 500 : 400) : height ? 240 : 200;
      if (imageHeight < size) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (imageHeight) {
      const size = height ? 190 : 150;
      if (+imageHeight >= size) {
        setFull(true);
      } else {
        setFull(false);
      }
    }
  }, [imageHeight]);

  return (
    <>
      <Styled.ImgLoad
        ref={imageRef}
        src={image}
        alt='test_image'
        onLoad={handleImageLoad}
      />

      {!isValid() ? (
        <Styled.ImageOneDiv imgHeight={height}>
          <Styled.Image isFull={isFull} src={image} imgHeight={height} alt='photo' />
        </Styled.ImageOneDiv>
      ) : (
        <Styled.Image src={image} imgHeight={height} alt='photo' />
      )}

      {!isUser && (
        <Styled.ImageContainer onClick={onFavorite}>
          <Styled.FavoriteIcon2
            style={{
              WebkitMaskImage: `url(${favorite})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${favorite})`
            }}
          />
        </Styled.ImageContainer>
      )}
    </>
  );
};

export const StarContainer = ({ rating }: { rating: number | undefined }) => (
  <Styled.DivStar>
    <Styled.CommonText className='text'>{rating}</Styled.CommonText>
    <img src={FilePath(Icons.starIcon)} alt='star' />
  </Styled.DivStar>
);
