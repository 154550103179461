import { Formik, getIn } from 'formik';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { COLORS, SPACES } from '../../../../theme';
import {
  Input,
  InputAvatar,
  InputMatchedWords,
  InputTextArea,
  Loader,
  PopupLayout
} from '../../../common/component';
import { InputConst, Supplier_Vendor_Talent_Const } from '../../../common/constants';
import { Location } from '../../../home-layout/constants';
import { editTalentById, getTalentById } from '../../hooks';
import { ITalentCreate } from '../../types';
import { validationSchemaEditTalent } from '../../validation/shema';
import * as Styled from './talent-create.styled';

export const TalentCreate = ({ onCancel, isEdit = false }: ITalentCreate) => {
  const formData = new FormData();
  const { search } = useLocation();

  const id = search.split('=')[1];

  const [file, setFile] = useState<File | null | any>(null);

  const { isLoading, data } = getTalentById(id);
  const { mutate: editVendor } = editTalentById(id);

  const onEdit = async (editData: any) => {
    Object.entries(editData).forEach((i: any) => {
      if (i[0] === 'categories') {
        const a = JSON.stringify(i[1]);
        formData.append(i[0], a);

        return null;
      }
      formData.append(i[0], i[1]);
    });

    file && formData.append('avatar', file);

    await editVendor(formData);
  };

  const onSubmit = async (_data: any) => {
    await onEdit(_data);
    onCancel();
  };

  const categories = isEdit ? (data?.talent.categories ? data?.talent.categories : []) : [];

  const input = [InputConst.first_name, InputConst.last_name, InputConst.occupation];

  const onSaveAvatar = async (_file: File) => {
    setFile(_file);
  };

  return (
    <Formik
      initialValues={{
        first_name: isEdit ? data?.talent.first_name : '',
        last_name: isEdit ? data?.talent.last_name : '',

        occupation: isEdit ? data?.talent.occupation : '',
        location: isEdit ? data?.talent.location ?? '' : '',
        is_hide_phone: isEdit ? data?.talent.is_hide_phone ?? false : false,

        phone: isEdit ? data?.talent.phone : '+1',
        email: isEdit ? data?.talent.email : '',
        hourly_rate: isEdit ? data?.talent.hourly_rate : '',
        description: isEdit ? data?.talent.description : '',
        categories
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchemaEditTalent}
    >
      {({ setValues, values, errors, touched }) => (
        <Styled.CommonForm>
          <PopupLayout
            title='Edit Profile'
            saveText='Save Changes'
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            {isLoading && <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />}

            <Styled.TextContainer isSpace>
              <Styled.SubTitle>About</Styled.SubTitle>
            </Styled.TextContainer>

            <Styled.UserInfoContainer>
              <InputAvatar
                label='Profile Photo'
                width='100%'
                avatar={data?.talent.avatar}
                onSaveAvatar={onSaveAvatar}
                mb={SPACES.l}
                isSave={false}
              />

              {input.map((value, index) => (
                <Styled.CommonInput key={index} {...value} mb={SPACES.l} height='3.5rem' />
              ))}

              <Styled.CommonInputDropdownCroup
                width='48%'
                matchedWords={Location}
                isFilter
                isFilterData
                item='cities'
                titleItem='state'
                height='3.5rem'
                mt='0'
                {...InputConst.location}
              />

              <Styled.Div>
                <Styled.CommonInputMask
                  mask='+9-999-999-9999'
                  height='3.5rem'
                  isHidePhone
                  mb={SPACES.xxxxs}
                  {...InputConst.phone}
                />

                <Styled.Hide
                  onClick={() => {
                    setValues((prev: any) => ({
                      ...prev,
                      is_hide_phone: !getIn(prev, 'is_hide_phone')
                    }));
                  }}
                >
                  {!getIn(values, 'is_hide_phone') ? 'SHOW PHONE NUMBER' : 'HIDE PHONE NUMBER'}
                </Styled.Hide>
              </Styled.Div>

              <Styled.CommonInput
                mb={SPACES.l}
                type='email'
                height='3.5rem'
                width='100%'
                {...InputConst.email}
              />

              <Input
                mt={SPACES.l}
                mb={SPACES.l}
                type='number'
                height='3.5rem'
                width='100%'
                {...InputConst.hourly_rate}
              />
            </Styled.UserInfoContainer>

            <Styled.TextContainer>
              <Styled.SubTitle>Additional Information</Styled.SubTitle>
            </Styled.TextContainer>

            <InputMatchedWords
              matchedWords={Supplier_Vendor_Talent_Const}
              height='3.5rem'
              width='100%'
              isFilter
              isChip
              mt={SPACES.xxxxl}
              {...InputConst.categories}
            />

            <InputTextArea
              rows={7}
              maxLength={1000}
              mt={SPACES.xxxxl}
              {...InputConst.description}
            />
          </PopupLayout>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
