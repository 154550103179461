import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { authService } from '../../../../services';
import { COLORS, SPACES } from '../../../../theme';
import { IAuthError, ILogin } from '../../../../types';
import { Input, Loader } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IToken } from '../../../common/types';
import { Links } from '../../../home-layout/constants';
import { AuthLayout } from '../../common/component/auth-layout/auth-layout';
import * as CommonStyled from '../../common/styles/common.styled';
import { validationSchemaLogin } from '../../validation/shema';
import * as Styled from './login.styled';

export const Login = () => {
  const history = useHistory();

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });

    if (err.errorCode === 124) {
      const token = err.error.split(':')[1];
      history.push({
        pathname: APP_KEYS.ROUTER_KEYS.CHECK_EMAIL,
        state: { token }
      });
    }
  };

  const onSuccess = async ({ token }: IToken) => {
    localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, token);

    history.push(APP_KEYS.ROUTER_KEYS.HOME);
  };

  const { mutate: login, isLoading } = useMutation<any, AxiosError<IAuthError>, ILogin>(
    (req: ILogin) => authService.login(req),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = async (loginData: ILogin) => {
    login(loginData);
  };

  const onClick = () => {
    window.open(Links.lending, '_blank');
  };

  const onSingUp = () => {
    history.push(APP_KEYS.ROUTER_KEYS.SING_UP);
  };

  const onForgotPassword = () => {
    history.push(APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD);
  };

  return (
    <AuthLayout onClick={onClick} isScroll width='42rem' isScrollMobile>
      <Styled.Div>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={async (values, { setValues, setTouched }) => {
            await onSubmit(values);
            setValues((v: any) => ({ ...v, password: '' }));
            setTouched({ password: false });
          }}
          validationSchema={validationSchemaLogin}
        >
          {({ isValid }) => (
            <CommonStyled.CommonForm>
              <CommonStyled.CommonDiv>
                <CommonStyled.Title>Welcome</CommonStyled.Title>
                <CommonStyled.SubTitle>
                  To access your account, please log in using your email address and password.
                </CommonStyled.SubTitle>

                <Input
                  mt='1.925rem'
                  name='email'
                  label='Email Address'
                  placeholder='Email Address'
                  height='3.5rem'
                />

                <Input
                  mt={SPACES.xxxxl}
                  name='password'
                  label='Password'
                  type='password'
                  placeholder='Your Password'
                  height='3.5rem'
                />

                <Styled.BtnContainer>
                  <Styled.ForgotText2 isColor onClick={onForgotPassword}>
                    FORGOT PASSWORD
                  </Styled.ForgotText2>
                </Styled.BtnContainer>

                <Styled.Test>
                  <Styled.ForgotText>
                    DON’T have an account?
                    <Styled.ForgotText isColor onClick={onSingUp}>
                      SIGN UP
                    </Styled.ForgotText>
                  </Styled.ForgotText>

                  <Styled.Btn
                    content={
                      !isLoading ? (
                        'Next'
                      ) : (
                        <Loader size='small' color={COLORS.purple} height='auto' />
                      )
                    }
                    type='submit'
                    variant='primary'
                    width='7.5rem'
                    disabled={!isValid || isLoading}
                  />
                </Styled.Test>
              </CommonStyled.CommonDiv>
            </CommonStyled.CommonForm>
          )}
        </Formik>
      </Styled.Div>
    </AuthLayout>
  );
};
