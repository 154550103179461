import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { authService } from '../../../../services';
import { SPACES } from '../../../../theme';
import { IAuthError, IForgotPassword } from '../../../../types';
import { Button } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessageEmail } from '../../../common/types';
import { AuthLayout } from '../../common/component/auth-layout/auth-layout';
import * as CommonStyled from '../../common/styles/common.styled';
import * as Styled from './check-email.styled';

export const ChekEmailForgotPassword = () => {
  const history = useHistory();

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessageEmail) => {
    toastContainer.success({ title: message });

    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.USER_EMAIL);
  };

  const { mutate: forgotPassword } = useMutation<any, AxiosError<IAuthError>, IForgotPassword>(
    (req: IForgotPassword) => authService.forgotPassword(req),
    {
      onSuccess,
      onError
    }
  );

  const onClick = () => {
    history.push(APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD);
  };

  const onResendLink = () => {
    const email = localStorage.getItem(APP_KEYS.STORAGE_KEYS.USER_EMAIL) as string;
    forgotPassword({ email });
  };

  return (
    <AuthLayout onClick={onClick} isScroll isScrollMobile>
      <Styled.Div>
        <CommonStyled.CommonDiv>
          <CommonStyled.Title>check Your Email</CommonStyled.Title>
          <CommonStyled.SubTitle>
            We have sent an email to the email address associated with your account regarding a
            password reset request.
          </CommonStyled.SubTitle>

          <Styled.BtnContainer>
            <Button
              content='RESEND LINK'
              type='button'
              variant='inverse3'
              onClick={onResendLink}
              mt={SPACES.xxxxxxls}
            />
          </Styled.BtnContainer>
        </CommonStyled.CommonDiv>
      </Styled.Div>
    </AuthLayout>
  );
};
