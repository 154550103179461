import { getIn, useFormikContext } from 'formik';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import React from 'react';

import { IInputHours } from '../../../types';
import * as Styled from './input-hours.styled';
import './test.css';

export const InputHours = ({ name, label, height, readOnly = false, ...props }: IInputHours) => {
  const { setValues, touched, errors, values, setTouched } = useFormikContext();

  const value = getIn(values, name);
  const touche = getIn(touched, name);
  const error = getIn(errors, name);

  const time = value ? new Date(`2023-06-15T${value}`) : new Date();
  const isError = !!error && touche;

  const onChange = (e: any) => {
    const _time = e._d.toString().split(' ')[4];
    setValues((v: any) => ({ ...v, [name]: _time }));
    setTouched({ [name]: true });
  };

  const onFocus = () => {
    setTouched({ [name]: true });
  };

  return (
    <Styled.OptionBlock readOnly={readOnly} {...props} onFocus={onFocus}>
      <Styled.Label isError={isError}>{label}</Styled.Label>

      <Styled.StyleInput
        height={height}
        isError={isError}
        placeholder='Select Time'
        use12Hours
        showSecond={false}
        focusOnOpen
        format='hh:mm A'
        onChange={onChange}
        readOnly={readOnly}
        defaultValue={moment(time)}
      />

      {isError ? (
        <Styled.ErrorInfoContainer>
          <Styled.ErrorInfoText>{error}</Styled.ErrorInfoText>
        </Styled.ErrorInfoContainer>
      ) : null}
    </Styled.OptionBlock>
  );
};
