import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { COLORS } from '../../theme';
import { CheckEmail, ChekEmailForgotPassword, ForgotPassword, Login, ResetPassword, SingUp } from '../auth/component';
import { Loader } from '../common/component';
import { APP_KEYS } from '../common/constants';
import { userProtectedPage, userPublicPage } from '../common/hocs';
import { HomeLayout } from '../home-layout/home-layout';
import { HomeLayoutContextProvider } from '../home-layout/providers/home-layout.provider';

// const CheckEmail = React.lazy(() =>
//   import('../auth/component').then((module) => ({
//     default: module.CheckEmail
//   }))
// );
//
// const ChekEmailForgotPassword = React.lazy(() =>
//   import('../auth/component').then((module) => ({
//     default: module.ChekEmailForgotPassword
//   }))
// );
// const ForgotPassword = React.lazy(() =>
//   import('../auth/component').then((module) => ({
//     default: module.ForgotPassword
//   }))
// );
//
// const ResetPassword = React.lazy(() =>
//   import('../auth/component').then((module) => ({
//     default: module.ResetPassword
//   }))
// );
//
// const SingUp = React.lazy(() =>
//   import('../auth/component').then((module) => ({
//     default: module.SingUp
//   }))
// );

const HomeLayoutWithProvider = () => (
  <HomeLayoutContextProvider>
    <HomeLayout />
  </HomeLayoutContextProvider>
);

export const MainRouter = () => (
  <Router>
    <Switch>
      <React.Suspense
        fallback={<Loader size='medium' height='auto' margin='50%' color={COLORS.purple} />}
      >
        <Route exact path={APP_KEYS.ROUTER_KEYS.SING_UP} component={userPublicPage(SingUp)} />
        <Route
          exact
          path={APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}
          component={userPublicPage(ForgotPassword)}
        />
        <Route
          exact
          path={APP_KEYS.ROUTER_KEYS.CHECK_EMAIL_FORGOT}
          component={userPublicPage(ChekEmailForgotPassword)}
        />
        <Route
          exact
          path={APP_KEYS.ROUTER_KEYS.RESET_PASSWORD}
          component={userPublicPage(ResetPassword)}
        />
        <Route
          exact
          path={APP_KEYS.ROUTER_KEYS.CHECK_EMAIL}
          component={userPublicPage(CheckEmail)}
        />

        <Route exact path={APP_KEYS.ROUTER_KEYS.LOGIN} component={userPublicPage(Login)} />

        <Route
          exact
          path='/discover/:page?'
          component={userProtectedPage(HomeLayoutWithProvider)}
        />
      </React.Suspense>
    </Switch>
  </Router>
);
