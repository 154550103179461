import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../theme';
import { Center } from '../card';
import {
  Input,
  InputCalendar,
  InputDropdownCroup,
  InputHours,
  InputMask,
  InputMatchedWords
} from '../inputs';

export const CommonUserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: ${SPACES.xxl} 0;
`;

export const CommonTextContainer = styled.div<{ isSpace?: boolean }>`
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${COLORS.black};

  padding: ${SPACES.m} 0;

  ${Center};
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};
`;

export const CommonFormCreate = styled(Form)`
  width: 100%;
`;

export const InputCss = css`
  width: 48%;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100%;
  }
`;

export const CommonInputCalendarCreate = styled(InputCalendar)`
  ${InputCss};
`;
export const CommonInputHoursCreate = styled(InputHours)`
  ${InputCss};
`;

export const CommonInputDropdownCroupCreate = styled(InputDropdownCroup)`
  ${InputCss};
`;

export const CommonInputMatchedWordsCreate = styled(InputMatchedWords)`
  ${InputCss};
`;
export const CommonInputCreate = styled(Input)`
  ${InputCss};
`;

export const CommonInputMaskCreate = styled(InputMask)`
  ${InputCss};
`;

export const CommonTime = styled.div`
  ${InputCss};
  ${Center};
  align-items: flex-start;

  justify-content: space-between;
`;
