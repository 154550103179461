import React from 'react';
import { useHistory } from 'react-router-dom';

import { APP_KEYS } from '../constants';

export const userProtectedPage = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const history = useHistory();

    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    if (typeof window !== 'undefined') {
      if (!token) {
        history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
        return null;
      }
      // @ts-ignore
      return <WrappedComponent {...(props as T)} />;
    }

    return null;
  };

  Component.displayName = `${displayName}`;

  return Component;
};
