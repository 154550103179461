import React, { ReactNode } from 'react';

import { Icons } from '../../../../assets';
import * as Styled from './popup-layout.styled';
import { FilePath } from '../../../../utils';

export interface IFirstLayout {
  children: ReactNode;
  onCancel: () => void;
  title: string;
  height?: string;
  style?: any;
}

export const FirstLayout = ({ children, onCancel, title, height, style }: IFirstLayout) => (
  <div className='confirm_purchase2 above-all'>
    <Styled.Container2 height={height} styleds={style}>
      <Styled.TitleContainer>
        <Styled.Div>
          <Styled.Icon
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
            style={{
              WebkitMaskImage: `url(${FilePath(Icons.arrowBack)})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${FilePath(Icons.arrowBack)})`
            }}
          />
          {title}
        </Styled.Div>
      </Styled.TitleContainer>
      <Styled.Content className='children'>{children}</Styled.Content>
    </Styled.Container2>
  </div>
);
