import styled from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';

export const ContentDiv = styled.div`
  display: flex;
`;

export const ContentDivColumn = styled(ContentDiv)`
  flex-direction: column;
`;

export const Icon = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
`;

export const Undo = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.purple};

  text-transform: uppercase;
  cursor: pointer;

  margin-left: ${SPACES.xxxxl};
`;
