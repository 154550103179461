import React, { MouseEvent } from 'react';

import { Icons, Png } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { FilePath, newUtil } from '../../../../utils';
import { AvatarImage, Categories, IconsCard, StarContainer } from '../../../common/component/card';
import { toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { IVendorCard } from '../../types';
import * as Styled from './vendor-card.styled';

export const VendorCard = ({ data, isUserFavorite = false, socket }: IVendorCard) => {
  const { user } = useHomeLayoutContext();

  const isUser = user?.id && +user?.id === +data.user_id;

  const main_image = data.main_image ? data.main_image : Png.defaultBlack;
  const favorite = isUserFavorite ? FilePath(Icons.favoriteIcon) : FilePath(Icons.favoriteIcon2);
  const icons = [
    { icon: FilePath(Icons.jobIcon), text: data.profession ?? '', background: COLORS.green },
    { icon: FilePath(Icons.locationIcon), text: data.location ?? '', background: COLORS.blue }
  ];

  const { mutate: createFavorite } = toggleFavorite(undefined, data?.user_id, socket, 'Vendors');

  const onFavorite = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    await createFavorite({
      role: 'Vendors',
      user_id: user?.id,
      owner_id: data.id,
      to_user_id: data?.user_id
    });
  };

  return (
    <Styled.Container>
      <AvatarImage
        image={main_image}
        height='15rem'
        favorite={favorite}
        onFavorite={onFavorite}
        isUser={isUser}
      />

      <Styled.Content>
        <Styled.ContainerBetween>
          <Styled.Title className='title'>{data.name}</Styled.Title>
          <Styled.NewContainer>
            {newUtil(data?.createdAt, 0, 4) && <Styled.New>NEW</Styled.New>}

            <StarContainer rating={data.rating} />
          </Styled.NewContainer>
        </Styled.ContainerBetween>

        <IconsCard icons={icons} />
        <Styled.Description>{data?.description}</Styled.Description>
        <Categories categories={data?.categories} id={data?.id} />
      </Styled.Content>
    </Styled.Container>
  );
};
