import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { authService } from '../../../../services';
import { IAuthError, ISingUp, ISingUpFormik } from '../../../../types';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IToken } from '../../../common/types';
import { AuthLayout } from '../../common/component/auth-layout/auth-layout';
import * as CommonStyled from '../../common/styles/common.styled';
import { validationSchemaSingUp } from '../../validation/shema';
import { StepOne } from './step-one/step-one';
import { StepTow } from './step-tow/step-tow';

export const SingUp = () => {
  const history = useHistory();

  const [step, setStep] = useState<number>(1);

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ token }: IToken) => {
    toastContainer.success({ title: 'Welcome! You have signed up successfully' });

    history.push({
      pathname: APP_KEYS.ROUTER_KEYS.CHECK_EMAIL,
      state: { token }
    });
  };

  const { mutate: singUp, isLoading } = useMutation<any, AxiosError<IAuthError>, ISingUp>(
    (req: ISingUp) => authService.signUp(req),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = async (data: ISingUpFormik) => {
    const password = data.confirm_password as string;

    delete data.confirm_password;
    delete data.create_password;

    await singUp({ ...data, password });
  };

  const onClick = () => {
    step === 2 ? setStep(1) : history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  return (
    <AuthLayout onClick={onClick} isScroll={step === 1} width='42rem'>
      <Formik
        initialValues={{
          role: '',
          first_name: '',
          last_name: '',
          company_name: '',
          email: '',
          phone: '+1',
          create_password: '',
          confirm_password: ''
        }}
        onSubmit={async (values, { setValues, setTouched }) => {
          await onSubmit(values);
          setValues((v: any) => ({ ...v, create_password: '', confirm_password: '' }));
          setTouched({ create_password: false, confirm_password: false });
        }}
        validationSchema={validationSchemaSingUp}
      >
        <CommonStyled.CommonForm2 isStep={step === 1}>
          {step === 1 ? (
            <StepOne setStep={setStep} />
          ) : (
            <StepTow setStep={setStep} isLoader={isLoading} />
          )}
        </CommonStyled.CommonForm2>
      </Formik>
    </AuthLayout>
  );
};
