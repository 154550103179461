export const Location = [
  { cities: 'All Locations', state: '' },

  { cities: 'Daytona Beach', state: 'Florida' },
  { cities: 'Fort Lauderdale', state: 'Florida' },
  { cities: 'Gainesville', state: 'Florida' },
  { cities: 'Jacksonville', state: 'Florida' },
  { cities: 'Miami', state: 'Florida' },
  { cities: 'Orlando', state: 'Florida' },
  { cities: 'Pensacola', state: 'Florida' },
  { cities: 'Sarasota', state: 'Florida' },
  { cities: 'St. Petersburg', state: 'Florida' },
  { cities: 'Tallahassee', state: 'Florida' },
  { cities: 'Tampa', state: 'Florida' },
  { cities: 'West Palm Beach', state: 'Florida' },

  { cities: 'Alpharetta', state: 'Georgia' },
  { cities: 'Athens', state: 'Georgia' },
  { cities: 'Atlanta', state: 'Georgia' },
  { cities: 'Duluth', state: 'Georgia' },
  { cities: 'Johns Creek', state: 'Georgia' },
  { cities: 'Kennesaw', state: 'Georgia' },
  { cities: 'Marietta', state: 'Georgia' },
  { cities: 'Peachtree City', state: 'Georgia' },
  { cities: 'Roswell', state: 'Georgia' },
  { cities: 'Sandy Springs', state: 'Georgia' }
];
