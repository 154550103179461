import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid1 } from '../module/common/types';
import { IAllNotification } from '../types';

class NotificationService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async allNotification(): Promise<ExpectedFromQuery<IAllNotification>> {
    return this.httpService.get<IAllNotification>('/notification');
  }

  async updateIsReadNotification(data: {
    system_notification_ids: any;
  }): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, {}>('/notification', data);
  }

  async redSystemNotifications({
    notification_id
  }: {
    notification_id: Iuuid1;
  }): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, {}>('/notification/red-system', { notification_id });
  }
}

const factory = new HttpFactoryService();
export const notificationService = new NotificationService(factory.createAuthHttpService());
