import { Formik } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { COLORS, SPACES } from '../../../../theme';
import {
  ContactInformation,
  Gallery,
  Input,
  InputMatchedWords,
  InputTextAreaEditor,
  Loader,
  PopupLayout
} from '../../../common/component';
import { Event_Const, InputConst } from '../../../common/constants';
import { transformData } from '../../../common/utils/transform-data';
import { Location } from '../../../home-layout/constants';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { createEvent, editEventById, getEventById } from '../../hooks';
import { ICreateEvent } from '../../types';
import { validationSchemaCreateEvent } from '../../validation/shema';
import * as Styled from './create-event.styled';

export const CreateEvent = ({
  onCancel,
  onDelete,
  isEdit = false,
  isRepost = false
}: ICreateEvent) => {
  const formData = new FormData();
  const { search } = useLocation();
  const { user } = useHomeLayoutContext();

  const id = search.split('=')[1];

  const { isLoading, data } = getEventById(id, { enabled: isEdit });

  const { mutate } = createEvent();

  const { mutate: editEvent } = editEventById(id);

  const onCreate = async (createData: any) => {
    Object.entries(createData).forEach((i: any) => {
      if (i[0] !== 'gallery' && i[0] !== 'categories') {
        formData.append(i[0], i[1]);
        return null;
      }
      if (i[0] === 'categories') {
        const a = JSON.stringify(i[1]);

        formData.append(i[0], a);

        return null;
      }
      for (let j = 0; j < i[1].length; j++) {
        const fileElement = i[1][j];
        formData.append(`${i[0]}${j}`, fileElement.file);
      }
    });

    await mutate(formData);
  };

  const onEdit = async (editData: any) => {
    transformData(editData, formData);
    await editEvent(formData);
  };

  const onSubmit = async (_data: any) => {
    isEdit ? await onEdit(_data) : await onCreate(_data);
    onCancel();
  };

  const gallery = isEdit
    ? data?.event?.images
      ? data?.event?.images?.sort((a, b) => a.index - b.index)
      : []
    : [];

  return (
    <>
      <Formik
        initialValues={{
          gallery,
          name: isEdit ? data?.event.name : '',
          start_data: isEdit ? (isRepost ? '' : data?.event.start_data) : '',
          end_data: isEdit ? (isRepost ? '' : data?.event.end_data) : '',
          start_time: isEdit ? (isRepost ? '09:00:00' : data?.event.start_time) : '09:00:00',
          end_time: isEdit ? (isRepost ? '10:00:00' : data?.event.end_time) : '10:00:00',
          location: isEdit ? data?.event.location : '',
          venue: isEdit ? data?.event.venue : '',
          status: isEdit ? (isRepost ? 'Hiring' : data?.event.status) : '',
          description: isEdit ? data?.event.description : '',
          categories: isEdit ? data?.event.categories : []
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchemaCreateEvent}
      >
        {({ touched, errors }) => (
          <Styled.CommonForm>
            <PopupLayout
              title={isEdit ? 'Edit Event' : 'Create an Event'}
              saveText={isEdit ? 'Save Changes' : 'Post event'}
              onCancel={onCancel}
              onSave={() => {}}
              type='submit'
              isEdit={isEdit}
              onDelete={onDelete}
              isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
            >
              {isLoading && (
                <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
              )}

              <Gallery name='gallery' gallery={gallery} />

              <Styled.TextContainer isSpace>
                <Styled.SubTitle>About</Styled.SubTitle>
              </Styled.TextContainer>

              <Styled.UserInfoContainer>
                <Input mt={SPACES.l} mb={SPACES.l} height='3.5rem' {...InputConst.name} />

                {[
                  {
                    ...InputConst.start_time,
                    ...InputConst.start_data
                  },
                  {
                    ...InputConst.end_time,
                    ...InputConst.end_data
                  }
                ].map(({ name, label, labelDate, nameDate }, index) => (
                  <Styled.Time key={index}>
                    <Styled.CommonInputCalendar
                      name={nameDate}
                      label={labelDate}
                      placeholder='Date'
                      width='48%'
                      height='3.5rem'
                      mb={SPACES.l}
                    />
                    <Styled.CommonInputHours
                      name={name}
                      label={label}
                      width='48%'
                      height='3.5rem'
                      mb={SPACES.l}
                    />
                  </Styled.Time>
                ))}

                <Styled.CommonInputDropdownCroup
                  {...InputConst.location}
                  matchedWords={Location}
                  height='3.5rem'
                  width='48%'
                  isFilter
                  isFilterData
                  item='cities'
                  titleItem='state'
                />

                <Styled.CommonInput
                  mb={SPACES.l}
                  height='3.5rem'
                  width='100%'
                  {...InputConst.venue}
                />

                <InputMatchedWords
                  matchedWords={['Hiring', 'Closed']}
                  height='3.5rem'
                  width='100%'
                  readOnlyKeyboard
                  {...InputConst.status}
                />
              </Styled.UserInfoContainer>

              <Styled.TextContainer>
                <Styled.SubTitle>Additional Information</Styled.SubTitle>
              </Styled.TextContainer>

              <InputTextAreaEditor
                rows={7}
                maxLength={1000}
                mt={SPACES.xxxxl}
                {...InputConst.description}
                description={data?.event.description}
              />

              <InputMatchedWords
                matchedWords={Event_Const}
                height='3.5rem'
                width='100%'
                isChip
                mt={SPACES.xxxxl}
                {...InputConst.categories}
              />

              <ContactInformation user={user} title='Your Contact Details' />
            </PopupLayout>
          </Styled.CommonForm>
        )}
      </Formik>
    </>
  );
};
