import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import {
  Center,
  CommonContainer,
  CommonSubTitle,
  CommonText,
  CommonTextContainer,
  CommonTextContainer2,
  CommonTitle,
  Fonts
} from '../../../common/component/public-page';

export const TextContainer = styled(CommonTextContainer)``;
export const TextContainer2 = styled(CommonTextContainer2)``;
export const Title = styled(CommonTitle)``;
export const SubTitle = styled(CommonSubTitle)``;
export const Text = styled(CommonText)``;
export const Container = styled(CommonContainer)``;

export const Small = styled.small`
  font-size: ${FONTS.SIZES.s};
  font-weight: ${FONTS.WEIGHTS.medium};
`;

export const UserRole = styled.span`
  ${Fonts};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
`;

export const Div = styled.div`
  display: flex;
  align-items: center;

  gap: ${SPACES.l};
  
  
    &.click {
  cursor: pointer;
  
  &:hover {
    border-color: ${COLORS.purple};

    & > .role {
     border-color: ${COLORS.purple};
    }
    
    & > div > .text {
     color: ${COLORS.purpleHover};
    }
  }
`;

export const Div2 = styled(Div)`
  gap: ${SPACES.xxs};
`;
export const Div3 = styled(Div)`
  display: flex;
  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const DivRole = styled.div`
  ${Center};
  height: 3.5rem;
  aspect-ratio: 1/1;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.blue};
`;

export const IconEvent = styled(IconRole)`
  background: ${COLORS.green};
`;

export const DivCopy = styled.div`
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: ${COLORS.white200};
    & > .icon {
      background: ${COLORS.semiPurple};
    }
  }
`;

export const CopyEvent = styled(IconRole)`
  background: ${COLORS.black};

  cursor: pointer;

  &:hover {
    background: ${COLORS.purple};
  }
`;

export const EditBnt = styled(Button)`
  text-transform: uppercase;
  height: 2.5rem;
  max-width: 5.5rem;
`;

export const Status = styled(Text)<{ isColor: boolean }>`
  position: relative;
  ${Center};

  gap: ${SPACES.m};

  &:before {
    display: flex;
    content: '';
    width: 12px;
    height: 12px;

    background: ${COLORS.green};
    background: ${({ isColor }) => (isColor ? COLORS.green : COLORS.semiRed)};
  }
`;

export const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExperienceTitle = styled.p`
  ${Fonts};

  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxl};
  line-height: ${FONTS.SIZES.xxxxxxl_};
`;
export const ExperienceInfo = styled.div`
  display: flex;
  gap: ${SPACES.l};

  margin: ${SPACES.l} 0;

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-wrap: wrap;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.black};
  margin: ${SPACES.xxxxxxls} 0;
`;

export const IconsCardStyled = css`
  & > .width {
    width: 30%;
  }

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    & > .width {
      width: 100%;
    }
  }
`;
