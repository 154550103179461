import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { SPACES } from '../../../../theme';
import { APP_KEYS } from '../../../common/constants';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { urlUtil } from '../../../home-layout/utils';
import { SettingsConst, SettingsConst2 } from '../../account-settings';
import * as Styled from './user-setting-common.styled';
import { FilePath } from '../../../../utils';

export const actionData = [
  { text: 'My Job Postings', icon: FilePath(Icons.jobIcon) },
  { text: 'My Events', icon: FilePath(Icons.eventIcon2) }
];

export const actionData2 = [
  { text: 'Settings', icon: FilePath(Icons.settingIcon) },
  { text: 'Logout', icon: FilePath(Icons.logoutIcon) }
];

export const UserSettingCommon = () => {
  const history = useHistory();
  const client = useQueryClient();
  const { user } = useHomeLayoutContext();

  const { search } = useLocation();

  const searchParams = search.split('=')[1];
  const param = SettingsConst2[searchParams];

  const onLogout = () => {
    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
    client.removeQueries(APP_KEYS.QUERY_KEYS.GET_USER);
  };

  const onClick = (str: string) => {
    const link = SettingsConst[str];
    const url = `${APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING}?page=${link}`;

    str === 'Logout' ? onLogout() : history.push(urlUtil(url));
  };

  const links: { [key: string]: any } = {
    VENDOR: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENDOR}?id=${user?.owner_id}`,
    VENUE: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENUE}?id=${user?.owner_id}`,
    TALENT: `${APP_KEYS.ROUTER_KEYS.PUBLIC_TALENT}?id=${user?.owner_id}`,
    'EVENT PLANNER': `${APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT_PLANER}?id=${user?.owner_id}`
  };

  const onViewProfile = () => {
    // @ts-ignore
    const link = links[user?.role];

    history.push(urlUtil(link));
  };

  return (
    <>
      <Styled.Title>Account</Styled.Title>

      <Styled.BorderContainer>
        <Styled.UserInfoContainer>
          <Styled.UserImage
            src={user?.is_avatar && user?.avatar ? user?.avatar : FilePath(Png.defaultBlack)}
            alt='user-avatar'
          />
          <Styled.UserInfoContainer isColumn>
            <Styled.UserName>
              {user?.first_name} {user?.last_name}
            </Styled.UserName>
            {user?.role_site !== 'admin' && <Styled.UserRole>{user?.role}</Styled.UserRole>}
          </Styled.UserInfoContainer>
        </Styled.UserInfoContainer>

        <Styled.Btn
          content='View Profile'
          type='button'
          variant='primaryInverse2'
          onClick={onViewProfile}
          width='100%'
          height='2.5rem'
          mt={SPACES.l}
        />
      </Styled.BorderContainer>

      <Styled.BorderContainer isPadding>
        {actionData.map(({ text, icon }, index) => {
          const isValue =
            param === text ||
            (window.innerWidth > 950 && param === 'main' && text === 'My Job Postings');

          return (
            <Styled.Role key={index} onClick={onClick.bind(this, text)} isValue={isValue}>
              <Styled.IconRole
                className='icon'
                style={{
                  WebkitMaskImage: `url(${icon})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${icon})`
                }}
              />
              <Styled.RoleText>{text}</Styled.RoleText>
            </Styled.Role>
          );
        })}

        {user?.role_site === 'admin' && (
          <Styled.Role
            onClick={onClick.bind(this, 'Admin Panel')}
            isValue={param === 'Admin Panel'}
          >
            <Styled.IconRole
              className='icon'
              style={{
                WebkitMaskImage: `url(${FilePath(Icons.jobIcon)})`,
                WebkitMaskSize: '100% 100%',
                maskImage: `url(${FilePath(Icons.jobIcon)})`
              }}
            />
            <Styled.RoleText>Admin Panel</Styled.RoleText>
          </Styled.Role>
        )}

        <Styled.Line />

        {actionData2.map(({ text, icon }, index) => {
          const isValue = param === text;

          return (
            <Styled.Role key={index} onClick={onClick.bind(this, text)} isValue={isValue}>
              <Styled.IconRole
                className='icon'
                style={{
                  WebkitMaskImage: `url(${icon})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${icon})`
                }}
              />
              <Styled.RoleText>{text}</Styled.RoleText>
            </Styled.Role>
          );
        })}
      </Styled.BorderContainer>
      <Styled.Test>©2023 ESPO</Styled.Test>
    </>
  );
};
