import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import {
  Center,
  CommonSubTitle,
  CommonTitle,
  CommonUserRole,
  Fonts
} from '../../../common/component/public-page';

export const UserRole = styled(CommonUserRole)``;
export const Title = styled(CommonTitle)``;
export const SubTitle = styled(CommonSubTitle)``;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  min-height: 400px;

  margin: 0 auto;
  padding-bottom: 2rem;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: none;
  }
`;

export const DivEvent = styled.div`
  width: 100%;
  min-height: 22rem;

  ${Center};
`;

export const Text = styled.div`
  ${Fonts}
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const UserPlaner = styled.div<{ isRole: boolean }>`
  width: 100%;
  border: 1px solid ${COLORS.black};
  background: ${COLORS.white};
  border-radius: 8px;

  ${({ isRole }) =>
    !isRole &&
    css`
      &:hover {
        border: 1px solid ${COLORS.purple};
        cursor: pointer;

        & > div > div > .title {
          color: ${COLORS.purpleHover};
        }
      }
    `}

  ${Center};
  justify-content: space-between;

  padding: ${SPACES.xxxxl};
`;

export const UserImage = styled.img`
  width: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};
`;

export const Div2 = styled(Div)`
  gap: ${SPACES.xxs};
`;

export const IconsCardStyled = css`
  width: 100%;
  display: flex;
  gap: ${SPACES.xxxxxxls} !important;

  flex-wrap: wrap;

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.xxl};
  }

  & > .width {
    gap: ${SPACES.l};
  }
`;

export const EventDiv = styled.div`
  &:hover {
    & > .line {
      border-top: 1px solid ${COLORS.purple};
    }
  }
`;

export const EventDiv2 = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const Status = styled(Text)<{ isColor: boolean }>`
  position: relative;
  ${Center};

  gap: ${SPACES.m};

  &:before {
    display: flex;
    content: '';
    width: 12px;
    height: 12px;

    background: ${COLORS.green};
    background: ${({ isColor }) => (isColor ? COLORS.green : COLORS.semiRed)};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.l};
  }
`;
