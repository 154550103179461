import React, { MouseEvent } from 'react';

import { Icons } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { FilePath, isHiringDateAndTime, newUtil, postAgoEvent } from '../../../../utils';
import { Description } from '../../../common/component';
import { AvatarImage, Categories, IconsCard } from '../../../common/component/card';
import { toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { IEventCard } from '../../types/event-card';
import * as Styled from './event-card.styled';

export const EventCard = ({
  data,
  isStatus = false,
  isPosted = false,
  isUserFavorite = false,
  socket
}: IEventCard) => {
  const { user } = useHomeLayoutContext();

  const isUser = user?.id === data.user_id;

  const icons = [
    {
      icon: FilePath(Icons.calendarIcon),
      text: `${data.start_data} - ${data.end_data}`,
      background: COLORS.green
    },
    { icon: FilePath(Icons.locationIcon), text: data.location, background: COLORS.blue }
  ];

  const { mutate: createFavorite } = toggleFavorite(undefined, data?.user_id, socket, 'Events');

  const onFavorite = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    await createFavorite({
      role: 'Events',
      user_id: user?.id,
      owner_id: data.id,
      to_user_id: data?.user_id
    });
  };

  const favorite = isUserFavorite ? FilePath(Icons.favoriteIcon) : FilePath(Icons.favoriteIcon2);
  const isHiring = data?.status === 'Hiring' && isHiringDateAndTime(data?.end_data, data?.end_time);

  return (
    <Styled.Container>
      <AvatarImage
        image={data?.main_image}
        favorite={favorite}
        onFavorite={onFavorite}
        isUser={isUser}
      />

      <Styled.Content>
        <Styled.ContainerBetween>
          <Styled.Title className='title'>{data.name}</Styled.Title>
          <Styled.NewContainer>
            {newUtil(data?.createdAt, 0, 4) && <Styled.New>NEW</Styled.New>}

            {isStatus && (
              <Styled.Status isColor={isHiring}>{isHiring ? data?.status : 'Closed'}</Styled.Status>
            )}
          </Styled.NewContainer>
        </Styled.ContainerBetween>

        <IconsCard icons={icons} />
        <Description description={data?.description} isCard />
        <Categories categories={data.categories} id={data.id}>
          {isPosted && <span> {postAgoEvent(data?.createdAt)}</span>}
        </Categories>
      </Styled.Content>
    </Styled.Container>
  );
};
