export const inputs = [
  { name: 'first_name', label: 'First Name' },
  { name: 'last_name', label: 'Last Name' },
  { name: 'email', label: 'Email Address' },
  { name: 'phone', label: 'Phone Number' }
];

export const inputsPassword = [
  { name: 'current_password', label: 'Current Password', placeholder: 'Your Current Password' },
  { name: 'create_password', label: 'New Password', placeholder: 'New Password' },
  { name: 'confirm_password', label: 'Confirm New Password', placeholder: 'New Password' }
];

export const notifications = [
  {
    title: 'SYSTEM NOTIFICATIONS',
    subTitle: 'Turn on or off all notifications about, favourites, reviews, and updates. ',
    name: 'is_system_notification'
  },
  {
    title: 'EMAIL NOTIFICATIONS',
    subTitle: 'Turn on or off all marketing and promotional email notifications from ESPO',
    name: 'is_email_notification'
  }
];
