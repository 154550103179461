import React, { ReactNode } from 'react';

import { ArrowBack } from '../arrow-back/arrow-back';
import { Header } from '../header/header';
import * as Styled from './auth-layout.styled';

export interface IAuthLayout {
  children: ReactNode;
  onClick: () => void;
  isScroll?: boolean;
  isScrollMobile?: boolean;
  width?: string;
}

export const AuthLayout = ({
  onClick,
  children,
  isScroll = false,
  isScrollMobile,
  width
}: IAuthLayout) => (
  <Styled.Container>
    <Header />
    <Styled.Content isScroll={isScroll} isScrollMobile={isScrollMobile}>
      <ArrowBack onClick={onClick} style={Styled.Arrow} />

      <Styled.Content1 width={width}>{children}</Styled.Content1>
    </Styled.Content>
  </Styled.Container>
);
