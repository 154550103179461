import { getIn, useFormikContext } from 'formik';
import React from 'react';

import * as StyledCommon from '../../../common/styles/common.styled';
import { roles } from '../../../constant';
import { IStepOne } from '../../../types';
import * as Styled from './step-one.styled';

export const StepOne = ({ setStep }: IStepOne) => {
  const name = 'role';

  const { values, setValues } = useFormikContext();
  const value = getIn(values, name);

  const onClick = (str: string) => {
    setValues((v: any) => ({ ...v, [name]: str }));
  };

  return (
    <StyledCommon.CommonDiv>
      <StyledCommon.Title>Role</StyledCommon.Title>

      <StyledCommon.SubTitle>
        This will provide your business and services with the proper information to be displayed on
        your ESPO Hub profile.
      </StyledCommon.SubTitle>

      <Styled.RoleContainer>
        {roles.map(({ text, icon }, index) => {
          const isValue = value === text;

          return (
            <Styled.Role key={index} onClick={onClick.bind(this, text)} isValue={isValue}>
              <Styled.IconContainer>
                <Styled.Icon
                  className='icon'
                  style={{
                    WebkitMaskImage: `url(${icon})`,
                    WebkitMaskSize: '100% 100%',
                    maskImage: `url(${icon})`
                  }}
                />
              </Styled.IconContainer>
              <Styled.RoleText>{text}</Styled.RoleText>
            </Styled.Role>
          );
        })}
      </Styled.RoleContainer>

      <StyledCommon.Btn>
        <Styled.Btn
          content='Next'
          type='button'
          variant='primary'
          onClick={() => setStep(2)}
          width='7.5rem'
          mt='3rem'
          disabled={!value}
        />
      </StyledCommon.Btn>
    </StyledCommon.CommonDiv>
  );
};
