import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import { IGetAllJobParameter, IGetAllJobs, IGetJob, IJobType, IJobTypeFormik } from '../types';

class JobService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getJobs(data: Partial<IGetAllJobParameter>): Promise<ExpectedFromQuery<IGetAllJobs>> {
    return this.httpService.post<IGetAllJobs, Partial<IGetAllJobParameter>>('/job/all', data);
  }

  async getMyJobs(): Promise<ExpectedFromQuery<IGetAllJobs>> {
    return this.httpService.get<IGetAllJobs>('/job/my-all');
  }

  async createJob(data: IJobTypeFormik): Promise<ExpectedFromQuery<IJobType>> {
    return this.httpService.post<IJobType, IJobTypeFormik>('/job', data);
  }

  async getJob(id: string): Promise<ExpectedFromQuery<IGetJob>> {
    return this.httpService.get<IGetJob>(`/job?job_id=${id}`);
  }

  async editJob(data: IJobTypeFormik, id: string): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, IJobTypeFormik>(`/job?job_id=${id}`, data);
  }

  async deleteJob(id: string): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/job?job_id=${id}`);
  }
}

const factory = new HttpFactoryService();
export const jobService = new JobService(factory.createAuthHttpService());
