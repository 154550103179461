export const Icons = {
  logo: require('./logo.svg').default,
  logo2: require('./logo2.svg').default,
  vector: require('./vector.svg').default,
  eventIcon: require('./event-icon.svg').default,
  talentIcon: require('./talent-icon.svg').default,
  vendorIcon: require('./vendor-icon.svg').default,
  venueIcon: require('./venue-icon.svg').default,
  success: require('./success.svg').default,
  arrowBack: require('./arrow-back.svg').default,
  arrowBottom: require('./arrow-bottom.svg').default,
  arrowRight2: require('./arrow-right2.svg').default,
  arrowBottom2: require('./arrow-bottom2.svg').default,
  favoriteIcon: require('./favorite-icon.svg').default,
  favoriteIcon2: require('./favorite-icon2.svg').default,
  notification: require('./notification.svg').default,
  menu: require('./menu.svg').default,
  searchIcon: require('./search-icon.svg').default,
  filterIcon: require('./filter-icon.svg').default,
  locationIcon: require('./location-icon.svg').default,
  settingIcon: require('./setting.svg').default,
  eventIcon2: require('./event-icon2.svg').default,
  jobIcon: require('./job-icon.svg').default,
  logoutIcon: require('./logout-icon.svg').default,
  editIcon: require('./edit-icon.svg').default,
  userIcon: require('./user-avatar.svg').default,
  arrow: require('./arrow.svg').default,
  arrowUp: require('./arrow-up.svg').default,
  close: require('./close.svg').default,
  reteIcon: require('./rete-icon.svg').default,
  notFoundIcon: require('./not-found.svg').default,
  calendarIcon: require('./calendar-icon.svg').default,
  addIcon: require('./add-icon.svg').default,
  starIcon: require('./star-icon.svg').default,
  copyIcon: require('./copi-iocn.svg').default,
  deleteIcon: require('./delete-icon.svg').default,
  phoneIcon: require('./phone-icon.svg').default,
  emailIcon: require('./email-icon.svg').default,
  repostIcon: require('./repost-icon.svg').default,
  sortIcon: require('./sort-icon.svg').default,
  errorIcon: require('./errorIcon.svg').default,
  infoIcon: require('./info.svg').default,
  messageIcon: require('./messege-icon.svg').default,
  websiteIcon: require('./website-icon.svg').default,
  userIcon2: require('./user-icon.svg').default,
  profieIcon: require('./profie-icon.svg').default,
  facebook: require('./facebook.svg').default,
  twitter: require('./twitter.svg').default,
  instagram: require('./instagram.svg').default,
  linkedin: require('./linkedin.svg').default,
  plus: require('./plus.svg').default,
  capacity: require('./capacity.svg').default,
  square: require('./square.svg').default,
  standing_capacity: require('./standing_capacity.svg').default,
  seating_capacity: require('./seating_capacity.svg').default,
  dollar: require('./dollar.svg').default,
  noImages: require('./no-images.svg').default,
  calendar: require('./calendar.svg').default
};
