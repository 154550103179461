import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Input, InputCalendar } from '../../../common/component';

export const CommonForm = styled(Form)`
  width: 100%;
`;

export const Text = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

const InputCss = css`
  width: 48%;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    width: 100%;
  }
`;

export const CommonInputCalendar = styled(InputCalendar)`
  ${InputCss};
`;

export const CommonInput = styled(Input)`
  ${InputCss};
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AccordionStyled = css`
  background: ${COLORS.gray50};
  padding: ${SPACES.l} ${SPACES.xxxxl};

  margin: ${SPACES.xxxxl} 0;

  display: flex;
  align-items: center;

  & > .children {
    margin-top: ${SPACES.xxxxl};
  }

  & > .summary > div > .propIcon {
    height: 1rem;
    aspect-ratio: 1/1;

    cursor: pointer;
  }
`;

export const AddExperiences = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed ${COLORS.black};
  border-radius: ${SPACES.l};

  cursor: pointer;

  padding: ${SPACES.m} 0;

  gap: ${SPACES.m};

  & > p {
    text-transform: uppercase;
    font-weight: ${FONTS.WEIGHTS.bold};
  }

  &:hover {
    border: 1px dashed ${COLORS.semiPurple};
    background: rgba(131, 144, 245, 0.08);

    & > p {
      color: ${COLORS.semiPurple};
    }

    & > .icon {
      background: ${COLORS.semiPurple};
    }
  }
`;

export const Icon = styled.div`
  width: 1.5rem;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;
