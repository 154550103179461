import React from 'react';

import { IButtonProps, IButtonWithIconProps, TNavLink } from '../../types';
import * as Styled from './button.styled';

export const Button = ({
  content,
  type,
  id,
  variant = 'primary',
  startIcon,
  endIcon,
  onClick,
  ...restProps
}: IButtonProps) => (
  <Styled.StyledButton
    id={id}
    type={type ?? 'button'}
    variant={variant}
    {...restProps}
    onClick={(e) => {
      e.stopPropagation();
      onClick && onClick();
    }}
  >
    {startIcon && (
      <Styled.Icon2
        className='start'
        {...restProps}
        style={{
          WebkitMaskImage: `url(${startIcon})`,
          WebkitMaskSize: '100% 100%',
          maskImage: `url(${startIcon})`
        }}
      />
    )}
    {content}
    {endIcon && <Styled.Icon src={endIcon} alt='alt.icon' {...restProps} />}
  </Styled.StyledButton>
);

Button.AsNavLink = ({ content, variant = 'primary', ...restProps }: TNavLink) => (
  <Styled.NavLink variant={variant} {...restProps}>
    {content}
  </Styled.NavLink>
);

Button.WithTextAndIcon = ({ children, ...restProps }: IButtonWithIconProps) => (
  <Styled.StyledButton {...restProps}>{children}</Styled.StyledButton>
);
