import styled, { css } from 'styled-components';

import { COLORS, FONTS } from '../../../../../theme';

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const Title = styled.p`
  box-sizing: border-box;
  ${Fonts};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxl};
  color: ${COLORS.gray150};

  padding: 4px 0 4px 8px;
  margin: 16px 0;
`;
