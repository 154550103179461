import { getIn, useFormikContext } from 'formik';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons } from '../../../../../assets';
import { SPACES } from '../../../../../theme';
import { useClickOutside } from '../../../hooks';
import { IWProps, TIconTypeProps } from '../../../types';
import { searchKeyPressControl } from '../../../utils';
import { Drawer } from '../../drawer';
import { FirstLayout } from '../../popup-layout';
import { Portal } from '../../portal';
import { InputHint } from './input-hint';
import * as Styled from './input-matched-words.styled';
import { FilePath } from '../../../../../utils';

export interface IInputMatchedWordsProps extends IWProps {
  name: string;
  placeholder?: string;
  matchedWords: string[];
  isFilter?: boolean;
  isChip?: boolean;
  isDontChange?: boolean;
  isInput?: boolean;
  width?: string;
  height?: string;
  withIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconsStyles?: TIconTypeProps;
  innerPads?: string;
  label?: string;
  readOnly?: boolean;
  readOnlyKeyboard?: boolean;
  focused?: boolean;
  isEdit?: boolean;
  isFilterData?: boolean;
  isCache?: boolean;
  isNewWindow?: boolean;
}

export const InputMatchedWords = ({
  name,
  placeholder,
  matchedWords,
  isFilter = false,
  isFilterData = false,
  isChip = false,
  isDontChange = false,
  isInput = false,
  readOnly = false,
  readOnlyKeyboard = false,
  isEdit = false,
  width,
  height,
  withIcon,
  iconsStyles,
  label,
  innerPads,
  isNewWindow = false,
  isCache = false,
  ...props
}: IInputMatchedWordsProps) => {
  const { values, handleChange, setValues, touched, setTouched, errors, setErrors } =
    useFormikContext();
  const location = useLocation();
  const history = useHistory();

  const value = getIn(values, name);
  const touche = getIn(touched, name);
  const error = getIn(errors, name);

  const svgStyles = {
    ...Styled.defaultSvgStyles,
    ...iconsStyles,
    top: label ? '3.25rem' : '65%'
  };
  const pads = withIcon ? `${SPACES.xs} ${SPACES.xxxxxxl}` : innerPads;

  const [selectedHint, setSelectedHint] = useState<number | null>(null);
  const [input, setInput] = useState<string>(isEdit ? value ?? '' : '');

  const [focused, setFocused] = useState(false);

  const filterItem = isChip || isFilter ? input : value;
  const filterData = matchedWords?.filter((v) =>
    v?.toLowerCase()?.includes(filterItem?.trim().toLowerCase())
  );

  const isValueComplete = isFilterData && !matchedWords.filter((a) => a.includes(input)).length;
  const is = isFilterData ? isValueComplete : touche;

  const data = isFilter && is ? filterData : matchedWords;

  const onSetTouched = (flag: boolean) => {
    setTouched({ ...touched, [name]: flag });

    setFocused(flag);
  };

  const customSetValues = (_data: any) => {
    // @ts-ignore
    values.spaces[name.split('[')[1].split(']')[0]][name.split('.')[1]] = _data;
  };

  const onSetValues = (str: string) => {
    if (isFilter && !isChip) {
      setInput(str);
      setValues((v: any) => ({ ...v, [name]: str }));
    }

    if (isChip) {
      if (value.length <= 9) {
        setValues((v: any) => ({ ...v, [name]: [...value, str] }));
      }
    } else {
      name.includes('spaces')
        ? customSetValues(str)
        : setValues((v: any) => ({ ...v, [name]: str }));
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);

    const isIncludes = matchedWords.filter((a) => a.includes(e.target.value)).length;

    !isIncludes && setErrors({ [name]: 'No Options found' });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      !isChip && setValues((v: any) => ({ ...v, [name]: input }));
    }

    if (e.key === 'Enter' && value) {
      setTouched({ ...touched, [name]: false });
      setFocused(false);
    }
  };

  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (focused) {
      setFocused(false);
    }
  });

  const setValue = (str: string, ind: number) => {
    onSetTouched(false);
    setSelectedHint(ind);

    isChip ? setInput('') : setInput(str);
    !value?.includes(str) && onSetValues(str);
  };

  const deleteItem = (srt: string) => {
    const index = value.indexOf(srt);

    if (index > -1) {
      // only splice array when item is found
      value.splice(index, 1);
      setValues((v: any) => ({ ...v, [name]: [...value] }));
    }
  };

  useEffect(() => {
    if (isCache) {
      const searchParams = new URLSearchParams(location.search);
      if (value) {
        searchParams.set(name, value);
      } else if (searchParams.has(name)) searchParams.delete(name);

      const newSearch = searchParams.toString();

      history.push({ search: newSearch });
    }
  }, [value, isCache]);

  const isError = !!error && touche;

  return (
    <Styled.InputBlock width={width} focused={focused} readOnly={readOnly} ref={ref} {...props}>
      {label && (
        <Styled.Label isError={!focused && isError} htmlFor='avatar'>
          {label}
        </Styled.Label>
      )}
      <Styled.Input
        autoComplete='off'
        isError={isError}
        width={width}
        height={height}
        name={name}
        value={isChip || isFilter ? (isCache && value.length ? value : input) : value}
        id={name}
        innerPads={pads}
        readOnly={readOnly || readOnlyKeyboard}
        placeholder={placeholder}
        onClick={onSetTouched.bind(this, !focused)}
        onChange={(e) => {
          isChip || isFilter ? onChange(e) : isDontChange ? () => {} : handleChange(e);
        }}
        onKeyUp={handleKeyUp}
        onKeyDown={searchKeyPressControl({
          selectedHint,
          setInputValue: onSetValues,
          setSelectedHint,
          matchedWords: data
        })}
        touche={touche}
      />

      {withIcon && React.createElement(withIcon, svgStyles)}

      <Styled.Arrow
        focused={focused}
        isLabel={!!label}
        isNewWindow={isNewWindow}
        src={isNewWindow ? FilePath(Icons.arrowRight2) : FilePath(Icons.arrowBottom)}
        alt='arrowIcon'
      />

      {focused && data?.length > 0 && !isNewWindow && (
        <Styled.SuggestedBlock>
          {data.map((str, ind) => {
            return (
              <InputHint
                str={str}
                index={ind}
                key={`${ind}-${value}-${selectedHint}`}
                setValue={setValue}
                selected={
                  isChip || isFilterData
                    ? value
                      ? value.includes(str)
                      : ind === selectedHint
                    : ind === selectedHint
                }
                isChip={isChip}
              />
            );
          })}
        </Styled.SuggestedBlock>
      )}

      {isNewWindow && focused && data?.length > 0 && window.innerWidth < 950 && (
        <Drawer
          onClose={onSetTouched.bind(this, !focused)}
          open={focused}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            <FirstLayout
              title={placeholder ?? ''}
              onCancel={onSetTouched.bind(this, !focused)}
              height='fit-content'
              style={Styled.FirstLayoutStyled}
            >
              <div className='children' onClick={(e) => e.stopPropagation()}>
                <Styled.SuggestedBlock2>
                  {data.map((str, ind) => {
                    return (
                      <InputHint
                        isNewWindow={isNewWindow}
                        str={str}
                        index={ind}
                        key={`${ind}-${value}-${selectedHint}`}
                        setValue={setValue}
                        selected={
                          isChip || isFilterData
                            ? value
                              ? value.includes(str)
                              : ind === selectedHint
                            : ind === selectedHint
                        }
                        isChip={isChip}
                      />
                    );
                  })}
                </Styled.SuggestedBlock2>
              </div>
              <Styled.Btn
                mt={SPACES.l}
                className='btn'
                onClick={onSetTouched.bind(this, !focused)}
                type='button'
                content='save'
                variant='primary'
              />
            </FirstLayout>
          </Portal>
        </Drawer>
      )}

      {isError && error !== 'is required' ? (
        <Styled.ErrorInfoContainer>
          <Styled.ErrorInfoText>{error}</Styled.ErrorInfoText>
        </Styled.ErrorInfoContainer>
      ) : null}

      {isChip && !isInput && value?.length ? (
        <Styled.ChipContainer>
          {value.map((str: string, index: number) => (
            <Styled.Chip onClick={deleteItem.bind(this, str)} key={index}>
              {str}
              <Styled.Close
                style={{
                  WebkitMaskImage: `url(${Icons.close})`,
                  WebkitMaskSize: '100% 100%',
                  maskImage: `url(${Icons.close})`
                }}
              />
            </Styled.Chip>
          ))}
        </Styled.ChipContainer>
      ) : null}
    </Styled.InputBlock>
  );
};
