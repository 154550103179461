import axios from 'axios';

import { APP_KEYS } from '../module/common/constants';
import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import {
  IEditVenue,
  IEditVenueExperiences,
  IGetAllVenueParameter,
  IGetAllVenues,
  IGetVenue
} from '../types';

class VenueService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getVenues(data: Partial<IGetAllVenueParameter>): Promise<ExpectedFromQuery<IGetAllVenues>> {
    return this.httpService.post<IGetAllVenues, Partial<IGetAllVenueParameter>>('/venue/all', data);
  }

  async getMyVenue(): Promise<ExpectedFromQuery<{ id: string }>> {
    return this.httpService.get<{ id: string }>('/venue/my');
  }

  async getVenue(id: string): Promise<ExpectedFromQuery<IGetVenue>> {
    return this.httpService.get<IGetVenue>(`/venue?venue_id=${id}`);
  }

  async getVenueSpaces(id: string): Promise<ExpectedFromQuery<IEditVenueExperiences>> {
    return this.httpService.get<IEditVenueExperiences>(`/venue/spaces?venue_id=${id}`);
  }

  async editVenueSpaces(data: IEditVenue): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, IEditVenue>(`/venue/spaces`, data);
  }

  async editVenue(data: any, id: string): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/venue?venue_id=${id}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }
}

const factory = new HttpFactoryService();
export const venueService = new VenueService(factory.createAuthHttpService());
