import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React, { MouseEvent, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { adminService } from '../../../../services';
import { COLORS, SPACES } from '../../../../theme';
import { IAuthError, ICreateNotifications, IEditNotification } from '../../../../types';
import { FilePath, transformDate } from '../../../../utils';
import {
  Drawer,
  Input,
  InputTextArea,
  Loader,
  NotFoundItem,
  PopupLayout,
  Portal
} from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage, Iuuid } from '../../../common/types';
import { HeaderPage } from '../../common/page';
import { validationSchemaCreateNotifications } from '../../validation/shema';
import * as Styled from './notifications.styled';

export const Notifications = () => {
  const history = useHistory();
  const client = useQueryClient();

  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [editId, setEditId] = useState<null | string | number>(null);
  const [isSurePost, setIsSurePost] = useState<boolean>(false);

  const onAddBanners = (str: string | null) => {
    setEditComponent(str);
    setEditId(null);
  };

  const onBack = () => {
    history.goBack();
  };

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });
    await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ADMIN_NOTIFICATIONS);
    setEditComponent(null);
    setEditId(null);
  };

  const { isLoading, data } = useQuery([APP_KEYS.QUERY_KEYS.GET_ADMIN_NOTIFICATIONS], () =>
    adminService.getAllNotifications()
  );

  const { isLoading: isLoadingNotification, data: dataNotification } = useQuery(
    [APP_KEYS.QUERY_KEYS.GET_ADMIN_NOTIFICATION, editId],
    () => adminService.getNotification({ id: editId }),
    { enabled: !!editId }
  );

  const { mutate: createNotifications } = useMutation<
    any,
    AxiosError<IAuthError>,
    ICreateNotifications
  >((_data: ICreateNotifications) => adminService.createNotifications(_data), {
    onSuccess,
    onError
  });

  const { mutate: editNotification } = useMutation<any, AxiosError<IAuthError>, IEditNotification>(
    (_data: IEditNotification) => adminService.editNotification(_data),
    {
      onSuccess,
      onError
    }
  );

  const { mutate: deleteNotification } = useMutation<any, AxiosError<IAuthError>, Iuuid>(
    (_data: Iuuid) => adminService.deleteNotification(_data),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = (_data: any) => {
    if (isSurePost) {
      editId ? editNotification({ notification_id: editId, ..._data }) : createNotifications(_data);
      setEditComponent(null);
      setIsSurePost(false);
    } else {
      setIsSurePost(true);
    }
  };

  const onEditBanner = (e: MouseEvent<HTMLButtonElement>, id: number | string) => {
    e.stopPropagation();

    setEditComponent('create');
    setEditId(id);
  };

  const onDeleteBanner = () => {
    // @ts-ignore
    deleteNotification({ id: editId });
  };

  return (
    <Styled.Container>
      <HeaderPage
        onBack={onBack}
        title='ALL Notifications'
        content={
          <>
            Created <Styled.Text>{data?.count} Notifications</Styled.Text>
          </>
        }
        buttonOptions={{
          content: 'Post New Notification',
          onClick: onAddBanners.bind(this, 'create')
        }}
      />

      {!isLoading && data?.notifications.length ? (
        <Styled.TableContainer>
          <StyledTable.Table>
            <StyledTable.Head>
              <StyledTable.Row>
                <StyledTable.HeadRow>Link</StyledTable.HeadRow>
                <StyledTable.HeadRow>Link text</StyledTable.HeadRow>
                <StyledTable.HeadRow>Description</StyledTable.HeadRow>
                <StyledTable.HeadRow>Date</StyledTable.HeadRow>
                <StyledTable.HeadRow className='edit' />
              </StyledTable.Row>
            </StyledTable.Head>
            <StyledTable.Body>
              {data.notifications
                ? data.notifications.map(({ description, updatedAt, link, id, link_text }) => {
                    const _createdAt = transformDate(updatedAt);

                    return (
                      <StyledTable.Row key={id}>
                        <StyledTable.Data
                          className='title'
                          onClick={() => window.open(link, '_blank')}
                        >
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={link}
                          >
                            {link}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={link_text ?? ''}
                          >
                            {link_text ?? '-'}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={description}
                          >
                            {description}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='title'>
                          <StyledTable.ItemLabel
                            className='tooltip'
                            linesToTruncate={1}
                            tooltipText={_createdAt}
                          >
                            {_createdAt}
                          </StyledTable.ItemLabel>
                        </StyledTable.Data>

                        <StyledTable.Data className='edit'>
                          <StyledTable.EditButton onClick={(e) => onEditBanner(e, id)}>
                            <StyledTable.Icon
                              className='icon'
                              style={{
                                WebkitMaskImage: `url(${FilePath(Icons.editIcon)})`,
                                WebkitMaskSize: '100% 100%',
                                maskImage: `url(${FilePath(Icons.editIcon)})`
                              }}
                            />
                          </StyledTable.EditButton>
                        </StyledTable.Data>
                      </StyledTable.Row>
                    );
                  })
                : null}
            </StyledTable.Body>
          </StyledTable.Table>
        </Styled.TableContainer>
      ) : null}

      {!isLoading && !data?.notifications.length ? (
        <Styled.ErrorContainer>
          <NotFoundItem
            isSearch
            title='No Results Found'
            subTitle='It seems we can’t find any results based on your search.'
          />
        </Styled.ErrorContainer>
      ) : null}

      {isLoading ? (
        <Styled.ErrorContainer>
          <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
        </Styled.ErrorContainer>
      ) : null}

      {editComponent && (
        <Drawer
          onClose={onAddBanners.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'create' && (
              <Formik
                initialValues={{
                  link: editId ? dataNotification?.notification.link : '',
                  description: editId ? dataNotification?.notification.description : '',
                  link_text: editId ? dataNotification?.notification.link_text : 'more information'
                }}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={validationSchemaCreateNotifications}
              >
                {({ isValid, values, touched, errors }) => (
                  <Styled.CommonForm>
                    <PopupLayout
                      onCancel={onAddBanners.bind(this, null)}
                      onSave={() => {}}
                      type='submit'
                      disabled={!isValid}
                      styled={Styled.Popup}
                      title={editId ? 'Edit Notification' : 'Create a Notification'}
                      saveText={editId ? 'Save Changes' : 'post notification'}
                      isEdit={!!editId}
                      onDelete={onDeleteBanner}
                    >
                      {isLoadingNotification && (
                        <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
                      )}
                      <Input
                        mb={SPACES.l}
                        height='3.5rem'
                        name='link'
                        label='Link'
                        placeholder='Link'
                      />
                      <Input
                        mb={SPACES.l}
                        height='3.5rem'
                        name='link_text'
                        label='Link text'
                        placeholder='Link Text'
                      />

                      <InputTextArea
                        name='description'
                        placeholder='Description'
                        rows={1}
                        label='Description'
                        maxLength={1000}
                        mt={SPACES.xxxxl}
                      />
                    </PopupLayout>

                    {isSurePost && (
                      <Portal>
                        <PopupLayout
                          type='submit'
                          title='Are you sure ?'
                          saveText='Post'
                          onCancel={() => setIsSurePost(false)}
                          onSave={() => onSubmit(values)}
                          styled={Styled.PopupLayoutStyled}
                          isValid={
                            Object.keys(touched).length ? Object.keys(errors).length === 0 : true
                          }
                          style={{
                            zIndex: 100,
                            background: 'rgba(0, 0, 0, 0.61)'
                          }}
                        >
                          <Styled.TextPopup>
                            Are you sure you want to send this notification to all users? This
                            action cannot be reverted after posting.
                          </Styled.TextPopup>
                        </PopupLayout>
                      </Portal>
                    )}
                  </Styled.CommonForm>
                )}
              </Formik>
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
