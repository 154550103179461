import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { ArrowBack, Drawer, GalleryPopup, Loader, Portal } from '../../../common/component';
import {
  ButtonContainer,
  Categories,
  IconsCard,
  ImageGeller,
  StarAndTitle
} from '../../../common/component/public-page/public-page';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket, useScrollStart } from '../../../common/hooks';
import { getFavoriteById, toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { ReviewContainer } from '../../../review/component';
import { getVendorById } from '../../hooks';
import { VendorCreate } from '../vendor-create/vendor-create';
import * as Styled from './vendor-public.styled';
import { FilePath } from '../../../../utils';

export const VendorPublic = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);
  useScrollStart();

  const id = search.split('=')[1];

  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [index, setIndex] = useState(0);

  const { isLoading, data } = getVendorById(id);

  const isUser = user?.id === data?.vendor.user_id ?? false;

  const { isLoading: isLoadingFavorite, data: dataFavorite } = getFavoriteById(
    id,
    'Vendors',
    !isUser
  );
  const { mutate: createFavorite } = toggleFavorite(id, data?.vendor.user_id, socket);

  const abouts = [
    {
      icon: FilePath(Icons.websiteIcon),
      title: 'Website',
      text: data?.vendor.website
    },
    { icon: FilePath(Icons.phoneIcon), title: 'Phone Number', text: data?.vendor.phone },
    { icon: FilePath(Icons.emailIcon), title: 'Email Address', text: data?.vendor.email },
    { icon: FilePath(Icons.locationIcon), title: 'Location', text: data?.vendor.location }
  ];

  const onGallery = (str: string | null) => {
    setEditComponent(str);
  };
  const onImage = (str: string | null, i: number) => {
    setEditComponent(str);
    setIndex(i);
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toastContainer.success({ title: 'LINK COPIED!' });
  };

  const onBack = () => {
    history.goBack();
  };

  const onFavorite = async () => {
    await createFavorite({
      role: 'Vendors',
      user_id: user?.id,
      owner_id: data?.vendor.id as string,
      to_user_id: data?.vendor.user_id
    });
  };

  if (isLoading || isLoadingFavorite) {
    return <Loader size="medium" height="auto" margin="20%" color={COLORS.purple} />;
  }

  const isFavorite = dataFavorite?.favorite === null;
  const favorite = isFavorite ? Icons.favoriteIcon2 : Icons.favoriteIcon;

  const categories: [] =
    typeof data?.vendor.categories === 'object'
      ? data.vendor.categories
      : JSON.parse(data?.vendor.categories ?? '');

  // @ts-ignore
  const dataImages = data?.vendor?.images.length
    ? data?.vendor?.images?.sort((a, b) => a.index - b.index)
    : [];

  return (
    <Styled.Container>
      <ArrowBack
        onClick={onBack}
        isButton={{
          flag: true,
          isUser,
          isHiring: true,
          favorite,
          onCopyLink,
          onFavorite,
          onEdit: onGallery
        }}
      />

      <ImageGeller images={dataImages} onClick={onImage} text="gallery" />

      <Styled.Title>{data?.vendor?.name}</Styled.Title>

      <Styled.TextContainer2 isSpace isBorder>
        <StarAndTitle text={data?.vendor.profession} rating={data?.vendor.rating} />

        <ButtonContainer
          isUser={isUser}
          onFavorite={onFavorite}
          favorite={favorite}
          isAdmin={user?.role_site === 'admin'}
          isFavorite={isFavorite}
          onCopyLink={onCopyLink}
          onEdit={onGallery.bind(this, 'edit')}
        />
      </Styled.TextContainer2>

      <Styled.TextContainer>
        <Styled.SubTitle>About</Styled.SubTitle>
      </Styled.TextContainer>

      <IconsCard abouts={abouts} styled={Styled.IconsCardStyled} />

      {data?.vendor?.description && (
        <>
          <Styled.TextContainer>
            <Styled.SubTitle>Description</Styled.SubTitle>
          </Styled.TextContainer>

          <Styled.Text>{data?.vendor?.description}</Styled.Text>
        </>
      )}

      {categories?.length && (
        <>
          <Styled.TextContainer>
            <Styled.SubTitle>Categories</Styled.SubTitle>
          </Styled.TextContainer>

          <Categories categories={categories} />
        </>
      )}

      <ReviewContainer
        socket={socket}
        full_name={data?.vendor?.name}
        user_id={user?.id}
        key_refetch={APP_KEYS.QUERY_KEYS.GET_VENDOR}
        id={id}
        to_user_id={data?.vendor.user_id}
        rating={data?.vendor.rating}
        reviews={data?.vendor.reviews}
        component="Vendors"
      />

      {editComponent && (
        <Drawer
          onClose={onGallery.bind(this, null)}
          open={!!editComponent}
          slidePosition="bottom"
          contentPosition="bottom"
        >
          <Portal>
            {editComponent === 'gallery' && (
              <GalleryPopup
                onClose={onImage.bind(this, null, 0)}
                index={index}
                images={dataImages}
              />
            )}

            {editComponent === 'edit' && (
              <VendorCreate isEdit onCancel={onGallery.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
