import React from 'react';

import { ISwitch } from '../../../types';
import * as Styled from './switch.styled';

export const Switch = ({ isChecked, onClick, name }: ISwitch) => (
  <Styled.CheckBoxWrapper>
    <Styled.CheckBox id={name} type='checkbox' checked={isChecked} onChange={onClick} />
    <Styled.CheckBoxLabel htmlFor={name} />
  </Styled.CheckBoxWrapper>
);
