import styled from 'styled-components';

import { Icons } from '../../assets';
import { COLORS, FONTS, MEDIA, SPACES } from '../../theme';
import { FilePath } from '../../utils';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: ${COLORS.purple};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.black};
  }
`;

export const Content = styled.div<{ isScroll: boolean; isScrollMobile?: boolean }>`
  width: 100%;
  position: relative;
  height: fit-content;
  min-height: 100%;

  position: relative;
  z-index: 1;

  &:before {
    width: 100%;
    height: calc(100% - 10.5rem);
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.08;
    content: '';

    background-image: url(${FilePath(Icons.vector)});
  }

  padding: 0 ${SPACES.xxxxxxl_};

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: 0 ${SPACES.l};
  }
`;

export const Banner = styled.div`
  background: ${COLORS.black};
  color: ${COLORS.white};
  padding: ${SPACES.s} ${SPACES.xxxxxxls};
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0.625rem;

  text-transform: uppercase;

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};

  & span {
    font-size: inherit;
    color: ${COLORS.purple};
    cursor: pointer;
  }

  & div {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    align-items: center;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    justify-content: flex-start;
    padding: ${SPACES.s} ${SPACES.l};

    & > .icon {
      right: ${SPACES.l};
    }
  }
`;

export const Icon = styled.div`
  height: 1.25rem;
  aspect-ratio: 1/1;
  background: ${COLORS.green};
`;

export const IconClose = styled(Icon)`
  height: 0.75rem;
  background: ${COLORS.white};
  cursor: pointer;

  position: absolute;

  right: ${SPACES.xxxxxxls};
  top: 50%;

  transform: translate(-100%, -50%);

  &:hover {
    background: ${COLORS.purple};
    transform: translate(-100%, -50%) scale(0.8);
  }
`;
