import axios from 'axios';

import { APP_KEYS } from '../module/common/constants';
import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import { IGetAllVendorParameter, IGetAllVendors, IGetVendor } from '../types';

class VendorService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getVendors(
    data: Partial<IGetAllVendorParameter>
  ): Promise<ExpectedFromQuery<IGetAllVendors>> {
    return this.httpService.post<IGetAllVendors, Partial<IGetAllVendorParameter>>(
      '/vendor/all',
      data
    );
  }

  async getMyVendor(): Promise<ExpectedFromQuery<{ id: string }>> {
    return this.httpService.get<{ id: string }>('/vendor/my');
  }

  async getVendor(id: string): Promise<ExpectedFromQuery<IGetVendor>> {
    return this.httpService.get<IGetVendor>(`/vendor?vendor_id=${id}`);
  }

  async editVendor(data: any, id: string): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/vendor?vendor_id=${id}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }
}

const factory = new HttpFactoryService();
export const vendorService = new VendorService(factory.createAuthHttpService());
