import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { authService } from '../../../../services';
import { COLORS, SPACES } from '../../../../theme';
import { IAuthError, IResetPassword, IResetPasswordFormik } from '../../../../types';
import { Input, Loader } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage } from '../../../common/types';
import { AuthLayout } from '../../common/component/auth-layout/auth-layout';
import * as CommonStyled from '../../common/styles/common.styled';
import { validationSchemaResetPassword } from '../../validation/shema';
import * as Styled from './reset-password.styled';

export const ResetPassword = () => {
  const history = useHistory();
  const { search } = useLocation();

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });

    if (err?.errorCode === 150) history.push(APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD);
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });

    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const { mutate: resetPassword, isLoading } = useMutation<
    any,
    AxiosError<IAuthError>,
    IResetPassword
  >((req: IResetPassword) => authService.resetPassword(req), {
    onSuccess,
    onError
  });

  const onSubmit = (data: IResetPasswordFormik) => {
    resetPassword({ password: data.confirm_password, code: search.split('=')[1] });
  };

  const onClick = () => {
    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  return (
    <AuthLayout onClick={onClick} isScroll isScrollMobile width='42rem'>
      <Formik
        initialValues={{
          create_password: '',
          confirm_password: ''
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchemaResetPassword}
      >
        {({ isValid }) => (
          <CommonStyled.CommonForm>
            <CommonStyled.CommonDiv>
              <Styled.TitleT>reset PASSWORD</Styled.TitleT>
              <CommonStyled.SubTitle>
                Once you have reset your password, you can log in to your account.
              </CommonStyled.SubTitle>

              <Input
                mt={SPACES.xxxxl}
                mb={SPACES.xxxxl}
                name='create_password'
                label='New Password'
                type='password'
                placeholder='New Password'
                height='3.5rem'
              />

              <Input
                name='confirm_password'
                label='Confirm New Password'
                type='password'
                placeholder='Confirm New Password'
                height='3.5rem'
              />

              <CommonStyled.Btn>
                <Styled.Btn
                  content={
                    !isLoading ? (
                      'SEND'
                    ) : (
                      <Loader size='small' color={COLORS.purple} height='auto' />
                    )
                  }
                  type='submit'
                  variant='primary'
                  width='7.5rem'
                  disabled={!isValid || isLoading}
                />
              </CommonStyled.Btn>
            </CommonStyled.CommonDiv>
          </CommonStyled.CommonForm>
        )}
      </Formik>
    </AuthLayout>
  );
};
