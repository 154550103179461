import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage } from '../module/common/types';
import { ICreateReview } from '../types';

class ReviewService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async createReview(data: ICreateReview): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, ICreateReview>('/review', data);
  }
}

const factory = new HttpFactoryService();
export const reviewService = new ReviewService(factory.createAuthHttpService());
