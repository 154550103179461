import { Formik } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { COLORS, SPACES } from '../../../../theme';
import {
  Gallery,
  InputMatchedWords,
  InputTextArea,
  Loader,
  PopupLayout
} from '../../../common/component';
import { InputConst, Venues } from '../../../common/constants';
import { transformData } from '../../../common/utils/transform-data';
import { Location } from '../../../home-layout/constants';
import { editVenueById, getVenueById } from '../../hooks';
import { IVenueCreate } from '../../types';
import { validationSchemaEditVendor } from '../../validation/shema';
import * as Styled from './venue-create.styled';

export const VenueCreate = ({ onCancel, isEdit = false }: IVenueCreate) => {
  const formData = new FormData();
  const { search } = useLocation();

  const id = search.split('=')[1];

  const { isLoading, data } = getVenueById(id);
  const { mutate: editVenue } = editVenueById(id);

  const onEdit = async (editData: any) => {
    transformData(editData, formData);
    await editVenue(formData);
  };

  const onSubmit = async (_data: any) => {
    await onEdit(_data);
    onCancel();
  };

  const gallery = isEdit
    ? data?.venue?.images
      ? data?.venue?.images?.sort((a, b) => a.index - b.index)
      : []
    : [];

  const categories = isEdit ? (data?.venue.categories ? data?.venue.categories : []) : [];

  const { name, description, manager, location, address, phone, email } = data?.venue ?? {};

  return (
    <Formik
      initialValues={{
        gallery,
        name: isEdit ? name ?? '' : '',
        manager: isEdit ? manager ?? '' : '',
        location: isEdit ? location ?? '' : '',
        address: isEdit ? address ?? address : '',
        phone: isEdit ? phone ?? '' : '+1',
        email: isEdit ? email ?? '' : '',
        description: isEdit ? description ?? '' : '',
        categories
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchemaEditVendor}
    >
      {({ touched, errors }) => (
        <Styled.CommonForm>
          <PopupLayout
            title='Edit Venue Profile'
            saveText='Save Changes'
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            {isLoading && <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />}

            <Gallery name='gallery' gallery={gallery} />

            <Styled.TextContainer isSpace>
              <Styled.SubTitle>About</Styled.SubTitle>
            </Styled.TextContainer>

            <Styled.UserInfoContainer>
              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                width='100%'
                {...InputConst.name}
                label='Business name'
              />

              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                name='manager'
                label='Manager'
                placeholder='Manager'
                width='100%'
              />

              <Styled.CommonInputDropdownCroup
                matchedWords={Location}
                height='3.5rem'
                width='48%'
                isFilter
                isFilterData
                item='cities'
                titleItem='state'
                {...InputConst.location}
              />

              <Styled.CommonInput
                mb={SPACES.l}
                height='3.5rem'
                name='address'
                label='Address/Venue'
                placeholder='1234 McAllister St, San Francisco'
                width='100%'
              />

              <Styled.CommonInputMask
                mask='+9-999-999-9999'
                height='3.5rem'
                mb={SPACES.xxxxxxls}
                {...InputConst.phone}
              />

              <Styled.CommonInput
                mb={SPACES.l}
                type='email'
                height='3.5rem'
                width='100%'
                {...InputConst.email}
              />
            </Styled.UserInfoContainer>

            <Styled.TextContainer>
              <Styled.SubTitle>Additional Information</Styled.SubTitle>
            </Styled.TextContainer>

            <InputMatchedWords
              matchedWords={Venues}
              height='3.5rem'
              width='100%'
              isFilter
              isChip
              mt={SPACES.xxxxl}
              {...InputConst.categories}
            />

            <InputTextArea
              rows={7}
              maxLength={1000}
              mt={SPACES.xxxxl}
              {...InputConst.description}
            />
          </PopupLayout>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
