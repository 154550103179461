import React from 'react';

import { Icons } from '../../../../../assets';
import { FilePath } from '../../../../../utils';
import * as Styled from './arrow-back.styled';

export interface IArrowBack {
  onClick: () => void;
  style?: any;
}

export const ArrowBack = ({ onClick, style }: IArrowBack) => (
  <Styled.Container onClick={onClick} styled={style}>
    <Styled.Icon src={FilePath(Icons.arrowBack)} alt='arrow-back' />
    BACK
  </Styled.Container>
);
