import { getIn, useFormikContext } from 'formik';
import React, { forwardRef, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';

import { IInputTextareaProps } from '../../../types';
import * as CommonStyled from '../input-text-area/input-text-area.styled';
import * as Styled from './input-text-area-editor.styled';

export const InputTextAreaEditor = forwardRef<HTMLDivElement, IInputTextareaProps>(
  ({ description, rows, label, name, placeholder, margin, maxLength, readOnly, ...props }, ref) => {
    const { setValues, errors, touched, setTouched } = useFormikContext();

    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    const isError = !!error && touch;

    const [text, setText] = React.useState('');

    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link']
      ]
    };

    const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];

    const quillRef = useRef(null);

    useEffect(() => {
      setValues((v: any) => ({ ...v, [name]: text }));
    }, [text]);

    useEffect(() => {
      setText(description ?? '');
    }, [description]);

    const onChange = (_text: any) => {
      setText(_text);
    };

    const onBlur = () => {
      setTouched({ ...touched, [name]: true });
    };

    return (
      <CommonStyled.InputContainer margin={margin || ''} ref={ref} {...props}>
        {label && (
          <CommonStyled.Label isError={isError} htmlFor={name}>
            {label}
          </CommonStyled.Label>
        )}

        <Styled.InputReactQuill
          isError={isError}
          ref={quillRef}
          theme='snow'
          placeholder='Type here'
          value={text}
          onChange={onChange}
          modules={modules}
          formats={formats}
          onBlur={onBlur}
        />

        {maxLength ? (
          <CommonStyled.MaxLength>
            <CommonStyled.MaxLengthText>
              {text?.length}/{maxLength}
            </CommonStyled.MaxLengthText>
          </CommonStyled.MaxLength>
        ) : null}

        {isError && error !== 'is required' ? (
          <CommonStyled.ErrorInfoContainer>
            <CommonStyled.ErrorInfoText>{error}</CommonStyled.ErrorInfoText>
          </CommonStyled.ErrorInfoContainer>
        ) : null}
      </CommonStyled.InputContainer>
    );
  }
);
