import styled, { css } from 'styled-components';

import { MEDIA, SPACES } from '../../../../../theme';
import { Button } from '../../../../common/component';

const Center = css`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div<{ mt?: string }>`
  margin-top: ${({ mt }) => mt ?? '0'};

  ${Center}
  gap: 24px;

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    flex-direction: column;
  }
`;

export const Test = styled.div`
  max-width: 672px;
  height: fit-content;
  min-height: 300px;

  margin: 20px auto;
  padding: ${SPACES.l};
`;

export const Btn = styled(Button)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    margin-bottom: ${SPACES.xxxxxxls};
  }
`;
