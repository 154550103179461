import ReactQuill from 'react-quill';
import styled from 'styled-components';

import { COLORS, SPACES } from '../../../../../theme';

export const InputReactQuill = styled(ReactQuill)<{ isError: boolean }>`
  width: 100%;
  margin: ${SPACES.xxxxs} 0 0 0;
  background: ${COLORS.white};
  border: 1px solid ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};

  border-radius: 0.5rem;
  outline: none;
  resize: vertical;

  transition: height 0.5s ease;

  &:focus-within {
    outline: 1px solid ${COLORS.baseGrey};
  }

  & > .ql-toolbar.ql-snow {
    border: none;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid ${({ isError }) => (isError ? COLORS.mainRed : COLORS.black)};
  }

  & > .ql-container {
    border: none;
    border-radius: 0 0 0.5rem 0.5rem;
  }

  &:disabled {
    background-color: ${COLORS.baseGrey};
    color: ${COLORS.baseGrey};
  }
`;
