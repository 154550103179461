import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaCreateBanner = Yup.object().shape({
  text: validateSchema.text,
  link: validateSchema.text.optional(),
  link_text: validateSchema.text.optional()
});

export const validationSchemaCreateNotifications = Yup.object().shape({
  link: validateSchema.text.url('is required'),
  link_text: validateSchema.text,
  description: validateSchema.text
});
