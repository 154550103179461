import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { SubTitle } from '../../../auth/component/sing-up/sing-up.styled';
import { Button } from '../../../common/component';

export const Title = styled.span`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: ${FONTS.SIZES.xxxxl__};
  line-height: ${FONTS.SIZES.xxxxxxl};
  text-transform: uppercase;

  color: ${COLORS.black};
  display: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: flex;
  }
`;

export const BorderContainer = styled.div<{ isPadding?: boolean }>`
  width: 100%;
  height: fit-content;
  background: ${COLORS.white};

  border: 1px solid ${COLORS.black};
  border-radius: 8px;
  padding: ${({ isPadding }) => (isPadding ? `${SPACES.l} ${SPACES.s}` : SPACES.xxxxl)};
  margin-bottom: ${SPACES.xxxxl};
  margin-top: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    border: none;
    padding: ${SPACES.sm} 0;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid ${COLORS.black};
  }
`;

export const UserInfoContainer = styled.div<{ isColumn?: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
`;

export const UserImage = styled.img`
  height: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;

  object-fit: cover;
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;

export const UserRole = styled.p`
  ${Fonts};
`;

export const UserName = styled(UserRole)`
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  gap: 10px;
`;

export const Role = styled.div<{ isValue: boolean }>`
  display: flex;
  align-items: center;
  width: calc(fit-content + ${SPACES.xxxxl});

  max-height: 3rem;

  padding: ${SPACES.xs};
  gap: ${SPACES.l};

  border-radius: 8px;
  cursor: pointer;

  ${({ isValue }) =>
    isValue &&
    css`
      ${Hover}
    `}
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const RoleText = styled(SubTitle)``;

export const Line = styled.div`
  width: calc(100% + ${SPACES.xxxxl});
  margin: ${SPACES.s} 0 ${SPACES.s} -${SPACES.s};
  height: 1px;
  border-top: 1px solid ${COLORS.black};

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    margin: ${SPACES.s} 0;
  }
`;

export const Btn = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
`;

const Hover = css`
  background: ${COLORS.black};

  & > p {
    color: ${COLORS.white};
  }

  & > div {
    background: ${COLORS.purple};
  }
`;

export const Test = styled.div`
  display: none;

  ${Fonts};

  text-align: center;
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxxxl};
  font-weight: ${FONTS.WEIGHTS.normal};

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${SPACES.xxxxxxls} 0 ${SPACES.xxxxl} 0;
  }
`;
