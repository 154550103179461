import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Icons } from '../../../../assets';
import { SPACES } from '../../../../theme';
import { Drawer } from '../drawer';
import { FirstLayout } from '../popup-layout';
import { Portal } from '../portal';
import * as Styled from './accordion.styled';
import { FilePath } from '../../../../utils';

export interface IAccordion {
  title: string;
  children: ReactNode;
  index: number;
  style?: any;
  icon?: any;
  onClickIcon?: () => void;
  onClick?: () => void;
  isOpen?: boolean;
  isNewWindow?: boolean;
}

export const Accordion = ({
  title,
  children,
  index = 0,
  style,
  icon,
  onClickIcon,
  isNewWindow,
  onClick,
  isOpen
}: IAccordion) => {
  const ref = useRef<HTMLDetailsElement | null>(null);

  const [isOpenDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isOpen && index === 0 && ref.current) {
      ref.current.open = true;
    }
  }, [isOpen, index]);

  const toggleDetails = () => {
    onClick && onClick();
    setOpenDrawer(!isOpenDrawer);
  };

  return (
    <Styled.Details ref={ref} id={`details${index}`} styled={style} onClick={toggleDetails}>
      <Styled.Summary className='summary'>
        <Styled.Span>{title}</Styled.Span>
        <Styled.IconContainer>
          {icon && <img onClick={onClickIcon} className='propIcon' src={icon} alt='icon' />}

          <img
            className={isNewWindow ? 'icon2' : 'icon'}
            src={isNewWindow ? FilePath(Icons.arrowRight2) : FilePath(Icons.arrowBottom)}
            alt='arrow'
          />
        </Styled.IconContainer>
      </Styled.Summary>

      <div className='children'>{children}</div>

      {isNewWindow && isOpenDrawer && window.innerWidth < 950 && (
        <Drawer
          onClose={toggleDetails}
          open={isOpenDrawer}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            <FirstLayout
              title={title}
              onCancel={toggleDetails}
              height='fit-content'
              style={Styled.FirstLayoutStyled}
            >
              <div className='children' onClick={(e) => e.stopPropagation()}>
                {children}
              </div>
              <Styled.Btn
                mt={SPACES.l}
                className='btn'
                onClick={toggleDetails}
                type='button'
                content='save'
                variant='primary'
              />
            </FirstLayout>
          </Portal>
        </Drawer>
      )}
    </Styled.Details>
  );
};
