import React from 'react';

import * as Styled from './input-matched-words.styled';

interface IProps {
  str: string;
  selected: boolean;
  setValue: (str: string, index: number) => void;
  isChip: boolean;
  index: number;
  padding?: any;
  isNewWindow?: boolean;
}

export const InputHint = ({
  str,
  setValue,
  selected,
  isChip,
  index,
  padding,
  isNewWindow
}: IProps) => {
  const applyHint = () => {
    setValue(str, index);
  };

  return (
    <Styled.HintOption
      isNewWindow={isNewWindow}
      padding={padding}
      onClick={applyHint}
      selected={selected}
      isChip={isChip}
    >
      {str}
    </Styled.HintOption>
  );
};
