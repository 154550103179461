import React, { MouseEvent } from 'react';

import { Icons } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { FilePath, newUtil, postAgoEvent } from '../../../../utils';
import { Categories, IconsCard } from '../../../common/component/card';
import { toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { IJobCard } from '../../types';
import * as Styled from './job-card.styled';

export const JobCard = ({
  data,
  isStatus = true,
  isUserFavorite = false,
  isFavorite = false,
  socket
}: IJobCard) => {
  const { user } = useHomeLayoutContext();

  const icons = [
    {
      icon: FilePath(Icons.calendarIcon),
      text: `${data?.start_date}-${data?.end_date}`,
      background: COLORS.green
    },
    { icon: FilePath(Icons.locationIcon), text: data?.location, background: COLORS.blue },
    {
      icon: FilePath(Icons.reteIcon),
      text: (
        <Styled.CenterDiv>
          <img src={FilePath(Icons.dollar)} alt='dollar' />
          {data.hourly_rate}/h
        </Styled.CenterDiv>
      ),
      background: COLORS.purple
    }
  ];

  const { mutate: createFavorite } = toggleFavorite(undefined, data?.user_id, socket, 'Jobs');
  const onFavorite = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    await createFavorite({
      role: 'Jobs',
      user_id: user?.id,
      owner_id: data.id,
      to_user_id: data?.user_id
    });
  };

  const favorite = isUserFavorite ? Icons.favoriteIcon : Icons.favoriteIcon2;

  const isUser = user?.id && +data.user_id === +user?.id;

  return (
    <Styled.Container>
      <Styled.ContainerBetween>
        <Styled.Title className='title'>{data.job_title}</Styled.Title>

        <Styled.NewContainer>
          {newUtil(data?.createdAt, 0, 4) && <Styled.New>NEW</Styled.New>}
          {isStatus && (
            <Styled.Status isColor={data.status === 'Hiring'}>{data.status}</Styled.Status>
          )}
          {isFavorite && !isUser && (
            <Styled.FavoriteIcon
              isUserFavorite={isUserFavorite}
              onClick={onFavorite}
              style={{
                WebkitMaskImage: `url(${favorite})`,
                WebkitMaskSize: '100% 100%',
                maskImage: `url(${favorite})`
              }}
            />
          )}
        </Styled.NewContainer>
      </Styled.ContainerBetween>

      <IconsCard icons={icons} />

      <Styled.Description>{data.description}</Styled.Description>

      <Categories categories={data?.categories} id={data?.id}>
        <Styled.Span>{postAgoEvent(data?.createdAt)}</Styled.Span>
      </Categories>
    </Styled.Container>
  );
};
