import styled from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../../theme';

export const CheckBoxWrapper = styled.div`
  position: relative;
  width: 5rem;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 2.5rem;
    height: 1.5rem;
  }
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 5rem;
  height: 3rem;
  border-radius: 8px;
  background: ${COLORS.gray};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 4px;
    height: 2rem;
    aspect-ratio: 1/1;

    margin: ${SPACES.xxs};
    background: ${COLORS.white};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 2.5rem;
    height: 1.5rem;

    &::after {
      margin: 4px;
      height: 1rem;
    }
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 5rem;
  height: 3rem;
  &:checked + ${CheckBoxLabel} {
    background: ${COLORS.purple};
    &::after {
      content: '';
      display: block;
      border-radius: 4px;
      height: 2rem;
      aspect-ratio: 1/1;
      margin-left: 2.5rem;
      transition: 0.2s;
    }
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    &:checked + ${CheckBoxLabel} {
      background: ${COLORS.purple};
      &::after {
        height: 1rem;
        margin-left: 1.25rem;
      }
    }
  }
`;
