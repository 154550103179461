import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../../theme';
import { Button } from '../../../../common/component';
import { SubTitle } from '../sing-up.styled';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoleContainer = styled.div`
  ${Center}

  flex-wrap: wrap;
  justify-content: space-between;

  gap: ${SPACES.xxxxl};

  margin-top: ${SPACES.xxxxxxls};
`;

const Hover = css`
  background: ${COLORS.black};

  & > p {
    color: ${COLORS.white};
  }

  & > div > div {
    background: ${COLORS.purple};
  }
`;

export const Role = styled.div<{ isValue: boolean }>`
  ${Center}
  justify-content: flex-start;

  width: 19.25rem;
  max-height: 6.5rem;

  @media screen and (max-width: ${MEDIA.tablet_s}) {
    width: 100%;
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
  }

  padding: ${SPACES.xxxxl};

  border-radius: 8px;
  border: 1px solid ${COLORS.black};
  cursor: pointer;

  ${({ isValue }) =>
    isValue &&
    css`
      ${Hover}
    `}

  &:hover {
    ${Hover}
  }
`;

export const IconContainer = styled.div`
  ${Center}

  width: 3.5rem;
  height: 3.5rem;
  border-radius: 8px;

  margin-right: ${SPACES.l};

  border: 1px solid ${COLORS.purple};
`;

export const RoleText = styled(SubTitle)`
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const Icon = styled.div`
  width: 20px;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const Test = styled.div`
  max-width: 672px;
  height: fit-content;
  min-height: 300px;

  overflow-y: scroll;

  margin: auto auto;
  padding: 10px;
`;

export const Btn = styled(Button)`
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    margin-bottom: ${SPACES.xxxxxxls};
  }
`;
