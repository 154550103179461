import styled, { css } from 'styled-components';

import { Icons } from '../../../../../assets';
import { MEDIA, SPACES } from '../../../../../theme';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const Content = styled.div<{ isScroll: boolean; isScrollMobile?: boolean }>`
  width: 100%;
  position: relative;
  height: calc(100% - 5.5rem);

  position: relative;
  z-index: 1;
  overflow-y: ${({ isScroll }) => (isScroll ? 'hidden' : 'auto')};
  overflow-x: hidden;

  &:before {
    z-index: -1;
    position: absolute;
    left: 0;
    top: -200px;
    opacity: 0.08;
    content: url(${Icons.vector});
  }

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    overflow-y: ${({ isScrollMobile }) => (isScrollMobile ? 'hidden' : 'auto')};
  }
`;

export const Arrow = css`
  margin: ${SPACES.xxxxxxls} 0 0 0;
`;

export const Content1 = styled.div<{ width?: string }>`
  max-width: ${({ width }) => (width ? width : '40rem')};

  height: calc(100% - 3.5rem);
  position: relative;

  display: flex;

  margin: 0 auto;
  padding: 0 ${SPACES.l};
`;
