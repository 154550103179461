import styled from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';
import {
  Center,
  CommonContainerBetween,
  CommonDescription,
  CommonNew,
  CommonNewContainer,
  CommonTitle,
  Fonts
} from '../../../common/component/card';

export const ContainerBetween = styled(CommonContainerBetween)``;
export const Description = styled(CommonDescription)``;
export const Title = styled(CommonTitle)``;
export const NewContainer = styled(CommonNewContainer)``;
export const New = styled(CommonNew)``;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    & > div > .title {
      color: ${COLORS.purpleHover};
    }
  }
`;

export const Span = styled.span`
  width: 130px;
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${SPACES.s};
  line-height: ${FONTS.SIZES.l};
`;

export const Status = styled.div<{ isColor: boolean }>`
  ${Fonts};
  display: flex;
  align-items: center;
  position: relative;
  ${Center};

  gap: ${SPACES.m};

  &:after {
    display: flex;
    content: '';
    width: 12px;
    height: 12px;

    background: ${COLORS.green};
    background: ${({ isColor }) => (isColor ? COLORS.green : COLORS.semiRed)};
  }
`;

export const FavoriteIcon = styled.div<{ isUserFavorite: boolean }>`
  width: 20px;
  aspect-ratio: 1/1;
  background: ${({ isUserFavorite }) => (isUserFavorite ? COLORS.purple : COLORS.black)};
`;

export const CenterDiv = styled.div`
  display: flex;
  align-items: center;
`;
