import { FormikValues, getIn } from 'formik';
import React from 'react';

import { CheckBoxFormik } from '../../common/component';
import { Event_Const, Supplier_Vendor_Talent_Const, Venues } from '../../common/constants';
import * as Styled from '../component/component-layout/component-layout.styled';

const ratings = ['Highest Rating', 'Lowest Rating'];
const prices = ['Price Highest', 'Price Lowest'];

export const filtersConst = {
  events: [],
  vendors: ratings,
  talents: [...ratings, ...prices],
  venues: ratings,
  jobs_posting: prices,
  event_planners: [...ratings, ...prices]
};

const input = (name: string, placeholder: string) => (
  <Styled.CommonInput
    name={name}
    label=''
    placeholder={placeholder}
    width='100%'
    height='3.5rem'
    type='number'
    isCache
  />
);

const inputCalendar = (name: string, placeholder: string) => (
  <Styled.CommonInputCalendar
    name={name}
    label=''
    placeholder={placeholder}
    width='48%'
    height='3.5rem'
    isCache
    isIcon={false}
  />
);

const componentCheckBox = (array: string[], name: string) => (
  <Styled.InputCheckBoxContainer isBottom>
    {array.map((value, index) => (
      <CheckBoxFormik key={index} label={value} name={name} />
    ))}
  </Styled.InputCheckBoxContainer>
);

const dateContainer = () => (
  <Styled.InputContainer>
    {inputCalendar('start_date', 'Start')}
    <Styled.Line3 className='line' />
    {inputCalendar('end_date', 'End')}
  </Styled.InputContainer>
);
const rateContainer = () => (
  <Styled.InputContainer>
    {input('min', 'Min')}
    <Styled.Line3 className='line' />
    {input('max', 'Max')}
  </Styled.InputContainer>
);

const star = ['1 Star', '2 Star', '3 Star', '4 Star', '5 Star'];

const initValue = {
  location: '',
  search: '',
  rating: 'Highest Rating'
};

export const initValueEvent = {
  ...initValue,
  start_date: '',
  end_date: '',
  status: [],
  type: []
};

export const initValueVendor = {
  ...initValue,
  min: '',
  max: '',
  star: [],
  type: []
};

export const initValueTalent = {
  ...initValue,
  min: '',
  max: '',
  star: [],
  type: []
};

export const initValueVenue = {
  ...initValue,
  min: '',
  max: '',
  star: [],
  size: [],
  type: []
};

export const initValueJob = {
  ...initValue,
  start_date: '',
  end_date: '',
  min: '',
  max: '',
  type: []
};

export const filtersEvent = (values: FormikValues) => {
  const typeLength = getIn(values, 'type')?.length;
  const statusLength = getIn(values, 'status')?.length;

  return [
    {
      title: 'Dates',
      component: dateContainer()
    },
    {
      title: statusLength ? `Hiring Status - ${statusLength}` : 'Hiring Status',
      component: componentCheckBox(['Hiring', 'Closed'], 'status')
    },

    {
      title: typeLength ? `Type - ${typeLength}` : 'Type',
      component: componentCheckBox(Event_Const, 'type')
    }
  ];
};

export const filtersVendor = (values: FormikValues) => {
  const typeLength = getIn(values, 'type')?.length;
  const starLength = getIn(values, 'star')?.length;
  return [
    {
      title: starLength ? `Rating - ${starLength}` : 'Rating',
      component: componentCheckBox(star, 'star')
    },
    {
      title: typeLength ? `Type - ${typeLength}` : 'Type',
      component: componentCheckBox(Supplier_Vendor_Talent_Const, 'type')
    }
  ];
};

export const filtersTalent = (values: FormikValues) => {
  const typeLength = getIn(values, 'type')?.length;
  const starLength = getIn(values, 'star')?.length;

  return [
    {
      title: 'Price Range',
      component: rateContainer()
    },

    {
      title: starLength ? `Rating - ${starLength}` : 'Rating',
      component: componentCheckBox(star, 'star')
    },

    {
      title: typeLength ? `Type - ${typeLength}` : 'Type',
      component: componentCheckBox(Supplier_Vendor_Talent_Const, 'type')
    }
  ];
};

export const filtersVenues = (values: FormikValues) => {
  const typeLength = getIn(values, 'type')?.length;
  const starLength = getIn(values, 'star')?.length;
  const sizeLength = getIn(values, 'size')?.length;
  return [
    {
      title: starLength ? `Rating - ${starLength}` : 'Rating',
      component: componentCheckBox(star, 'star')
    },
    {
      title: sizeLength ? `Size - ${sizeLength}` : 'Size',
      component: componentCheckBox(['Small', 'Medium', 'Large'], 'size')
    },

    {
      title: typeLength ? `Type - ${typeLength}` : 'Type',
      component: componentCheckBox(Venues, 'type')
    }
  ];
};

export const filtersJob = (values: FormikValues) => {
  const typeLength = getIn(values, 'type')?.length;

  return [
    {
      title: 'Dates',
      component: dateContainer()
    },
    {
      title: 'Hourly Rate',
      component: rateContainer()
    },

    {
      title: typeLength ? `Type - ${typeLength}` : 'Type',
      component: componentCheckBox(Supplier_Vendor_Talent_Const, 'type')
    }
  ];
};
