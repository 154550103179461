import styled, { css } from 'styled-components';

import { COLORS, FONTS, SHADOWS, SPACES, TRANSITIONS } from '../../../../theme';

// makes table cells of even size
const layoutCss = css`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const tHeadRowAndTDataCommonStyle = css`
  padding: ${`${SPACES.l} ${SPACES.xxxxl}`};
`;

const cellControlledSizes = css`
  &.cell {
    width: 12.5rem;
  }
`;

// ================= table components START ====================//

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Head = styled.thead`
  ${layoutCss}
  border-bottom: 1px solid ${COLORS.black};

  position: sticky;
  top: 0;
  z-index: 10;

  background: ${COLORS.white};
  border-radius: ${SPACES.l} ${SPACES.l} 0 0;
`;

export const Body = styled.tbody`
  ${layoutCss}
`;

export const HeadRow = styled.th`
  ${tHeadRowAndTDataCommonStyle}

  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.s};
  text-align: left;
  color: ${COLORS.black};

  &.link {
    width: 4.375rem;
      padding: 0 ${SPACES.l} ;
  }

  &.edit {
    width: 1.875rem;
    padding: 0;
  }

  ${cellControlledSizes}
`;

export const Row = styled.tr`
  ${layoutCss}

  &.active {
    background-color: ${COLORS.green} !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.black};
  }

  &:last-child {
    border-radius: ${SPACES.l};
  }

  &:nth-child(even) {
    background-color: ${COLORS.gray50};
  }
`;

export const Data = styled.td`
  ${tHeadRowAndTDataCommonStyle}

  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  vertical-align: middle;
  color: ${COLORS.black};

  &.link {
    width: 4.375rem;
        padding: 0 ${SPACES.l} ;

  }

  &.edit {
    width: 1.875rem;
    padding: 0;
  }

  &.title {
    position: relative;
    padding: ${SPACES.l} 0 ${SPACES.l} ${SPACES.xxxxl};

    &:hover {
      cursor: pointer;
    }
  }

  ${cellControlledSizes}
`;
// ================= table components END ====================//

// ================= helper components START ====================//

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImagePlaceholderThumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;
  margin-left: ${({ theme }) => theme.SPACES.s};

  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.COLORS.gray200};
  cursor: pointer;
`;

// ================== icons END =================================//

// ================== item card fields START ====================//

const truncateStringStyles = css<{ linesToTruncate?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ linesToTruncate }) => linesToTruncate ?? 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const labelPriceCommonStyles = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.LINE_HEIGHTS.normal300};
  color: ${COLORS.black};
`;

export const ItemImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-left: ${SPACES.s};

  object-position: center;
  object-fit: cover;
  overflow: hidden;

  border-radius: 50%;
  cursor: pointer;
`;

export const ItemLabel = styled.strong<{ linesToTruncate?: number; tooltipText: string }>`
  display: block;
  ${truncateStringStyles}
  ${labelPriceCommonStyles}
  cursor: pointer;

  &.tooltip::after {
    position: absolute;
    bottom: 70%;
    left: 40%;
    width: max-content;
    max-width: 12rem;
    content: ${({ tooltipText }) => `'${tooltipText}'`};
    padding: ${`${SPACES.xxs} ${SPACES.xs}`};
    font-weight: ${FONTS.WEIGHTS.normal};
    font-size: ${FONTS.SIZES.s};
    border: 1px solid ${COLORS.black};
    border-radius: ${SPACES.m};

    background-color: ${COLORS.white};
    box-shadow: ${SHADOWS.xs};

    visibility: hidden;
    z-index: 11;

    transition: visibility ${`${TRANSITIONS.duration.fast} ${TRANSITIONS.function.linear}`};
  }

  ${({ tooltipText }) =>
    tooltipText.length >= 17 &&
    css`
      &.tooltip:hover::after {
        visibility: visible;
      }
    `}
`;

export const ItemPrice = styled.strong`
  ${labelPriceCommonStyles}
`;

export const GenderAgeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACES.xxsm};
`;

export const EditButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;

  cursor: pointer;

  &:hover > .icon {
    background: ${COLORS.purple};
  }
`;

export const Icon = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;
