import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';

export const CommonForm = styled(Form)`
  width: 100%;
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div<{ isSpace?: boolean }>`
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${COLORS.black};

  padding: ${SPACES.m} 0;

  ${Center};
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const Text = styled.p`
  ${Fonts}
`;

export const SubTitle = styled(Text)`
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${SPACES.xxxxl};

  margin-top: ${SPACES.xxxxl};
`;

export const Btn = styled.div<{ isSelect: boolean }>`
  width: 100%;
  text-transform: uppercase;

  padding: ${SPACES.l} 0;

  ${Center};
  ${Fonts};

  font-weight: ${FONTS.WEIGHTS.bold};

  border: 1px solid ${COLORS.black};
  border-radius: 8px;
  background: ${({ isSelect }) => (isSelect ? COLORS.black : COLORS.white)};
  color: ${({ isSelect }) => (!isSelect ? COLORS.black : COLORS.white)};
  cursor: pointer;
`;

export const StarRatingDiv = styled.div`
  display: flex;
  width: 100%;
  ${Center};

  gap: ${SPACES.l};
  padding: ${SPACES.xxxxxxls} 0;

  input[type='radio'] {
    display: none;
  }
`;

export const Icon = styled.div<{ isSelect: boolean }>`
  height: 2.5rem;
  aspect-ratio: 1/1;
  background: ${({ isSelect }) => (isSelect ? COLORS.star : COLORS.white300)};

  &:hover {
    background: ${COLORS.star};
  }
`;
