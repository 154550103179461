import { FieldArray, Formik, getIn } from 'formik';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { Accordion, Input, InputTextArea, Loader, PopupLayout } from '../../../common/component';
import { editVenueExperiencesById, getVenueExperiencesById } from '../../hooks';
import { IVenueCreate } from '../../types';
import { validationSchemaEditVenueExperience } from '../../validation/shema';
import * as Styled from './venue-create-spaces.styled';

export const VenueCreateSpaces = ({ onCancel, isEdit = false }: IVenueCreate) => {
  const { search } = useLocation();

  const id = search.split('=')[1];

  const { isLoading, data, refetch } = getVenueExperiencesById(id);
  const { mutate: editTalentExperiences } = editVenueExperiencesById(id);

  useEffect(() => {
    refetch();
  }, []);

  const onSubmit = async (_data: any) => {
    editTalentExperiences({
      spaces: _data.spaces,
      venue_id: id
    });
    onCancel();
  };

  const init = {
    name: '',
    capacity: '',
    square: '',
    standing_capacity: '',
    seating_capacity: '',
    description: '',
    venue_id: id
  };

  return (
    <Formik
      initialValues={{
        spaces: isEdit ? (data?.spaces.length ? data?.spaces : [init]) : [init]
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchemaEditVenueExperience}
    >
      {({ values, touched, errors }) => (
        <Styled.CommonForm>
          <FieldArray name='spaces'>
            {({ remove, unshift }) => (
              <PopupLayout
                title='Edit Venue Spaces'
                saveText='Save Changes'
                onCancel={onCancel}
                onSave={() => {}}
                type='submit'
                isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
              >
                {isLoading && (
                  <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
                )}

                <Styled.AddExperiences
                  onClick={() => {
                    getIn(values, 'spaces').length < 5 && unshift(init);
                  }}
                >
                  <Styled.Icon
                    className='icon'
                    style={{
                      WebkitMaskImage: `url(${Icons.plus})`,
                      WebkitMaskSize: '100% 100%',
                      maskImage: `url(${Icons.plus})`
                    }}
                  />
                  <Styled.Text>add new space</Styled.Text>
                </Styled.AddExperiences>

                {getIn(values, 'spaces').map((value: any, index: number) => (
                  <Accordion
                    key={index}
                    isOpen
                    index={index}
                    icon={Icons.deleteIcon}
                    onClickIcon={() => remove(index)}
                    title={getIn(values, `spaces[${index}].name`)}
                    style={Styled.AccordionStyled}
                  >
                    <Styled.UserInfoContainer>
                      <Input
                        mb={SPACES.l}
                        height='3.5rem'
                        label='Name of Space'
                        name={`spaces[${index}].name`}
                        placeholder='Name of Space'
                        width='100%'
                      />

                      <Styled.Div>
                        <Styled.CommonInputMatchedWords
                          matchedWords={['Small', 'Medium', 'Large']}
                          name={`spaces[${index}].capacity`}
                          placeholder='Size'
                          height='3.5rem'
                          width='100%'
                          label='Size'
                          isDontChange
                        />

                        <Styled.CommonInput
                          height='3.5rem'
                          label='Square Footage'
                          name={`spaces[${index}].square`}
                          placeholder='Square Footage'
                          width='100%'
                          type='number'
                        />

                        <Styled.CommonInput
                          height='3.5rem'
                          label='Standing Capacity'
                          name={`spaces[${index}].standing_capacity`}
                          placeholder='Standing Capacity'
                          width='100%'
                          type='number'
                        />

                        <Styled.CommonInput
                          height='3.5rem'
                          label='Seating Capacity'
                          name={`spaces[${index}].seating_capacity`}
                          placeholder='Seating Capacity'
                          width='100%'
                          type='number'
                        />
                      </Styled.Div>
                    </Styled.UserInfoContainer>

                    <InputTextArea
                      name={`spaces[${index}].description`}
                      placeholder='Description'
                      rows={3}
                      label='Description'
                      maxLength={1000}
                      mt={SPACES.xxxxl}
                    />
                  </Accordion>
                ))}
              </PopupLayout>
            )}
          </FieldArray>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
