import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

export const Footer = styled.footer`
  width: 100%;

  padding: 3rem;
  position: relative;
  z-index: 9;

  background: ${COLORS.black};
  color: ${COLORS.white};

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: ${SPACES.l};
  }
`;

export const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;
    align-items: start;
    gap: ${SPACES.l};
  }
`;

export const Div2 = styled(Div)`
  margin-top: 48px;

  @media screen and (max-width: ${MEDIA.laptop_m}) {
    flex-direction: column-reverse;
    align-items: start;
    gap: 2rem;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACES.l};
`;

export const Logo = styled.img`
  width: 8.125rem;
  height: 2.5rem;

  cursor: pointer;
`;

export const IconDiv = styled.div`
  display: flex;
  gap: ${SPACES.l};

  flex-wrap: wrap;
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
`;

export const Title = styled.p`
  ${Fonts};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.m};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;
export const Text = styled.span`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.semi_bold};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.s};
    line-height: ${FONTS.SIZES.l};
  }
`;

export const TEst = styled(Text)`
  @media screen and (max-width: ${MEDIA.laptop_m}) {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(0);
  }

  @media screen and (min-device-width: ${MEDIA.laptop_m}) and (max-device-width: ${MEDIA.laptop_xl}) {
    position: absolute;
    left: 50%;

    transform: translate(-50%, 0);
  }
`;

export const Span = styled.span`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.semi_bold};

  color: ${COLORS.purple};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.s};
    line-height: ${FONTS.SIZES.l};
  }
`;

export const A = styled.a`
  text-decoration: none;
  color: ${COLORS.white};
  cursor: pointer;

  &:hover {
    color: ${COLORS.purpleHover};
  }
`;

export const IconContainer = styled.a`
  width: 3rem;
  aspect-ratio: 1/1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    background: ${COLORS.purple};
  }
`;
