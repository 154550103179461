import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaCreateJob = Yup.object().shape({
  job_title: validateSchema.text,
  location: validateSchema.text,
  address: validateSchema.text,
  start_date: validateSchema.text,
  end_date: validateSchema.text.when('start_date', (start: any, schema) => {
    return schema.test('end_date', 'invalid date', function (value) {
      const { start_date } = this.parent;
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(value);

      return endDateObj >= startDateObj;
    });
  }),
  status: validateSchema.text,
  hourly_rate: validateSchema.hourly_rate,
  categories: validateSchema.categories,
  description: validateSchema.text
});
