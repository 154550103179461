import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery } from '../module/common/types';
import { ICreateFavorite, IGetFavorite, IGetFavorites, IMessageFavorite } from '../types';

class FavoriteService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getFavorites(role: string, flag: boolean): Promise<ExpectedFromQuery<IGetFavorites>> {
    return this.httpService.get<IGetFavorites>(`/favorite/all?role=${role}&flag=${flag}`);
  }

  async getFavorite(id: string, role: string): Promise<ExpectedFromQuery<IGetFavorite>> {
    return this.httpService.get<IGetFavorite>(`/favorite?owner_id=${id}&role=${role}`);
  }

  async createFavorite(data: ICreateFavorite): Promise<ExpectedFromQuery<IMessageFavorite>> {
    return this.httpService.post<IMessageFavorite, ICreateFavorite>('/favorite', data);
  }
}

const factory = new HttpFactoryService();
export const favoriteService = new FavoriteService(factory.createAuthHttpService());
