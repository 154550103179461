import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: ${FONTS.SIZES.xxxxxxl};
  line-height: 1.4;

  text-transform: uppercase;

  color: ${COLORS.black};

  margin-bottom: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.xxxxxl_};
  }
`;

export const CommonForm = styled(Form)`
  width: 100%;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
`;

export const CommonDiv = styled.div`
  background: ${COLORS.white};
  width: 100%;
  height: fit-content;
`;

export const CommonForm2 = styled(Form)<{ isStep: boolean }>`
  width: 100%;

  ${({ isStep }) =>
    isStep
      ? css`
          display: flex;
          justify-content: center;
          flex-direction: column;
          @media screen and (max-width: ${MEDIA.tablet}) {
            justify-content: flex-start;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        `
      : css`
          margin-top: 2.25rem;
          margin-bottom: 2.25rem;
        `}
`;

export const SubTitle = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.m};
  }
`;

export const Btn = styled.div`
  width: 100%;
  ${Center}
  justify-content: flex-end;
`;
