import { APP_KEYS } from '../../common/constants';

export const urlUtil = (url: string) => {
  return `${APP_KEYS.ROUTER_KEYS.HOME}${url}`;
};

export const urlEventUtil = (url: string) => {
  return `${APP_KEYS.ROUTER_KEYS.HOME}${APP_KEYS.ROUTER_KEYS.PAGE}${url}`;
};

export const isPageExist = (page: string | undefined): boolean => {
  if (!page) return false;

  const pageWithRoot = `/${page}`;
  const doesExist =
    pageWithRoot === APP_KEYS.ROUTER_KEYS.FAVORITES ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PAGE ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PUBLIC_JOB ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PUBLIC_VENDOR ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PUBLIC_VENUE ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PUBLIC_TALENT ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT_PLANER ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.ADMIN_USERS ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.ADMIN_BANNERS ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.ADMIN_NOTIFICATIONS ||
    pageWithRoot === APP_KEYS.ROUTER_KEYS.ADMIN_OBJECTS;

  return doesExist;
};
