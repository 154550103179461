import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { eventService } from '../../../../services';
import { COLORS } from '../../../../theme';
import { ArrowBack, Drawer, Loader, NotFoundItem, Portal } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { CreateEvent, EventCard } from '../../../event/component';
import { urlUtil } from '../../../home-layout/utils';
import * as CommonStyled from '../my-job/my-job.styled';
import * as Styled from './my-event.styled';

export const MyEvent = () => {
  const history = useHistory();
  const [editComponent, setEditComponent] = useState<null | string>(null);

  const onAddJob = (str: string | null) => {
    setEditComponent(str);
  };
  const onBack = () => {
    history.goBack();
  };

  const onPushPublicEvent = (id: string) => {
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT)}?id=${id}`);
  };

  const { isLoading, data, refetch } = useQuery(APP_KEYS.QUERY_KEYS.GET_ALL_EVENTS, () =>
    eventService.getMyEvents()
  );

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  return (
    <Styled.Container>
      <ArrowBack style={Styled.ArrowBackComponent} onClick={onBack} />
      <CommonStyled.HeaderContainer>
        <div>
          <CommonStyled.Title>My Events</CommonStyled.Title>
          <CommonStyled.Text1>
            Created <CommonStyled.Text>{data?.events.length} Events</CommonStyled.Text>
          </CommonStyled.Text1>
        </div>
        <CommonStyled.Btn
          content='Add New Event'
          variant='primaryInverse'
          onClick={onAddJob.bind(this, 'create')}
        />
      </CommonStyled.HeaderContainer>
      <CommonStyled.Line />

      {data?.events?.length ? (
        data?.events.map((event, index) => (
          <Styled.Div key={index}>
            <Styled.Div2 onClick={onPushPublicEvent.bind(this, event.id)}>
              <EventCard data={event} isStatus />
            </Styled.Div2>
            <CommonStyled.Line className='line' />
          </Styled.Div>
        ))
      ) : (
        <NotFoundItem
          title='No Posts Yet'
          subTitle='Your posts will appear here and you can view them anytime.'
        />
      )}

      {editComponent && (
        <Drawer
          onClose={onAddJob.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'create' && <CreateEvent onCancel={onAddJob.bind(this, null)} />}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
