import { HttpFactoryService, HttpService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, IMessageEmail, IToken } from '../module/common/types';
import { IForgotPassword, ILogin, IResetPassword, ISingUp } from '../types';

class AuthService {
  constructor(private httpService: HttpService) {}

  async signUp(data: ISingUp): Promise<ExpectedFromQuery<IToken>> {
    return this.httpService.post<IToken, ISingUp>('/auth/sing-up', data);
  }

  async login(data: ILogin): Promise<ExpectedFromQuery<IToken>> {
    return this.httpService.post<IToken, ILogin>('/auth/login', data);
  }

  async forgotPassword(data: IForgotPassword): Promise<ExpectedFromQuery<IMessageEmail>> {
    return this.httpService.post<IMessageEmail, IForgotPassword>('/auth/forgot-password', data);
  }

  async resetPassword(data: IResetPassword): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, IResetPassword>('/auth/reset-password', data);
  }
}

const factory = new HttpFactoryService();
export const authService = new AuthService(factory.createHttpService());
