export const Links = {
  facebook: 'https://www.facebook.com/profile.php?id=100086516649504',
  instagram: 'https://www.instagram.com/espohub',
  linkedin: 'https://www.linkedin.com/company/espo-hub/',
  contact_us: 'https://www.espohub.com/contact-us',
  privacy_policy: 'https://www.espohub.com/privacy-policy',
  terms_conditions: 'https://www.espohub.com/terms-conditions',
  support: 'https://www.espohub.com/contact-us',
  lending: 'https://www.espohub.com/',
  designed: 'https://www.orizon.co/'
};
