import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../button/button';
import { IProps } from './public-page';

export const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.black};
`;

export const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const CommonContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  min-height: 400px;

  margin: 0 auto;
  padding-bottom: 2rem;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const CommonTextContainer = styled.div<IProps>`
  width: 100%;
  position: relative;
  border-bottom: ${({ isBorder }) => (isBorder ? 'none' : `1px solid ${COLORS.black}`)};

  padding: ${({ padding }) => padding ?? `${SPACES.m} 0`};
  margin: ${({ margin }) => margin ?? `${SPACES.l} 0`};

  ${Center};
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};
`;

export const CommonTextContainer2 = styled(CommonTextContainer)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.l};
    flex-direction: column;
    align-items: flex-start;

    justify-content: flex-start;

    margin: ${({ margin }) => margin ?? `${SPACES.l} 0 ${SPACES.s} 0`};
  }
`;

export const CommonText = styled.p`
  ${Fonts}
`;

export const CommonTitle = styled.p`
  ${Fonts}
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxxxl};
  line-height: 3.5rem;

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.xxxxl};
    line-height: ${FONTS.SIZES.xxxxxxl_};

    margin-top: ${SPACES.xxl};
  }
`;

export const CommonSubTitle = styled.p`
  ${Fonts}
  font-weight: ${FONTS.WEIGHTS.bold};
  gap: 3px;
`;

export const AboutDiv = styled.div<{ styles?: any }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  margin-top: ${SPACES.l};
  gap: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    flex-direction: column;
    gap: ${SPACES.xxl};
  }

  ${({ styles }) => styles}
`;

export const AboutDiv2 = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};
  
  &.click {
  cursor: pointer;
  
  &:hover {
    border-color: ${COLORS.purple};

    & > .role {
     border-color: ${COLORS.purple};
    }
    
    & > div > .text {
     color: ${COLORS.purpleHover};
    }
  }
 

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    width: 100%;
  }
`;

export const DivRole = styled.div`
  ${Center};
  height: 3.5rem;
  aspect-ratio: 1/1;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;
`;

export const CommonIcon = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.blue};
`;
export const CommonUserRole = styled.div`
  ${Fonts};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  display: flex;
  align-items: center;

  & img {
    height: 0.8rem;
  }
`;

export const ImageGeleri = styled.div`
  width: 100%;
  height: 416px;
  display: flex;

  flex-wrap: wrap;
  gap: ${SPACES.xxxxl_};
  margin-bottom: ${SPACES.xxxxxxls};

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.l};

    flex-direction: column;
    flex-wrap: unset;
    height: fit-content;
  }
`;

export const ImageGeleriScroll = styled.div`
  height: 416px;

  display: flex;
  flex-direction: column;

  overflow-x: auto;

  gap: ${SPACES.xxxxl_};

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: row;
    height: fit-content;
    overflow-y: auto;
  }
`;

export const ImageOneDiv = styled.div<{ isLength: any }>`
  width: ${({ isLength }) => (isLength ? 'calc(100% - 106px)' : '100%')};
  height: 100%;

  cursor: ${({ isLength }) => (isLength ? 'pointer' : 'initial')};

  border-radius: 8px;
  background: ${COLORS.black};
  ${Center};

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    max-height: 15rem;
  }
`;

export const ImageOne2 = styled.img<{ isLength: any }>`
  max-width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    max-height: 15rem;
  }
`;

export const ImageOne = styled.img<{ isLength: any }>`
  width: ${({ isLength }) => (isLength ? 'calc(100% - 106px)' : '100%')};

  height: 100%;

  cursor: ${({ isLength }) => (isLength ? 'pointer' : 'initial')};

  border-radius: 8px;
  object-fit: cover;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    max-height: 15rem;
  }
`;

export const ImageDiv = styled.div<{ index: number }>`
  width: 5rem;
  min-width: 5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  border-radius: 8px !important;
  z-index: 10;
  cursor: pointer;
  background: ${COLORS.black};
  ${Center};
`;

export const Image = styled.img<{ index: number }>`
  height: 100%;
  min-height: 56px;
  cursor: pointer;
`;

export const ChipContent = styled.div`
  width: 100%;
  ${Center};
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.xxs};
  }
`;
export const Chip = styled.div`
  width: fit-content;
  cursor: pointer;

  gap: ${SPACES.xs};

  ${Center};
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.semi_bold};

  padding: ${SPACES.xxs} ${SPACES.s};
  background: ${COLORS.white100};
  border-radius: 4px;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};
`;

export const Div0 = styled(Div)`
  align-items: flex-start;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.l};
  }
`;

export const Div2 = styled(Div)`
  gap: ${SPACES.xxs};
`;

export const Div4 = styled(Div)`
  margin-left: ${SPACES.l};
  align-items: flex-start;
  flex: 1;
`;

export const Div3 = styled(Div)`
  display: flex;
  @media screen and (max-width: ${MEDIA.tablet}) {
    display: none;
  }
`;

export const EditBnt = styled(Button)`
  max-width: 5.5rem;
  text-transform: uppercase;
  height: 2.5rem;
`;

export const CopyEvent = styled(CommonIcon)`
  background: ${COLORS.black};

  &:hover {
    background: ${COLORS.purple};
  }
`;

export const DivCopy = styled.div`
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: ${COLORS.white200};
    & > .icon {
      background: ${COLORS.semiPurple};
    }
  }
`;

export const CommonInfoContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;

  gap: ${SPACES.xxxxl_};

  @media screen and (max-width: ${MEDIA.tablet}) {
    flex-direction: column;
    gap: 0;
  }
`;

export const CommonInfoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${SPACES.l} 0 ${SPACES.xxxxxxls} ${SPACES.l};
`;

export const CommonImageOne = styled.img<{ isLength: any }>`
  width: 17.5rem;
  height: 22.5rem;
  object-fit: cover;
  border-radius: 8px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100%;
    height: 15rem;
  }
`;
