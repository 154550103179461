import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaUpdateUserInfo = Yup.object().shape({
  first_name: validateSchema.name.optional(),
  last_name: validateSchema.name.optional(),
  company_name: validateSchema.text.optional(),
  email: validateSchema.email.optional(),
  phone: validateSchema.phone.optional()
});

export const validationSchemaUpdateUserPassword = Yup.object().shape({
  current_password: validateSchema.password,
  create_password: validateSchema.password,
  confirm_password: validateSchema.password.oneOf(
    [Yup.ref('create_password')],
    'The password confirmation does not match'
  )
});
