import jwt_decode from 'jwt-decode';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { APP_KEYS } from '../constants';

export interface IJwtDecode {
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  type: string;
  user_info: { [key: string]: string };
}

export const userProtectedPageAdmin = <T extends object>(
  WrappedComponent: React.ComponentType<T>
) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const history = useHistory();

    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    const decoded = jwt_decode<IJwtDecode>(token ?? '');

    if (typeof window !== 'undefined') {
      if (!token) {
        history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
        return null;
      }

      if (decoded.type !== 'admin') {
        history.push(APP_KEYS.ROUTER_KEYS.HOME + APP_KEYS.ROUTER_KEYS.PAGE);
        return null;
      }

      // @ts-ignore
      return <WrappedComponent {...(props as T)} />;
    }

    return null;
  };

  Component.displayName = `${displayName}`;

  return Component;
};
