import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { eventPlanerService } from '../../../services';
import { IAuthError } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { IMessage } from '../../common/types';

const onError = (_err: AxiosError<IAuthError>) => {
  const err = _err.response?.data as IAuthError;

  toastContainer.error({ title: err.message ?? _err.message });
};

const onSuccess = async ({ message }: IMessage, client: any, id: string) => {
  toastContainer.success({ title: message });
  await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_EVENT_PLANER, id]);
};

export const editEventPlanerById = (id: string) => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, any>(
    (editData: any) => eventPlanerService.editEventPlaner(editData, id),
    {
      onSuccess: (data) => onSuccess(data, client, id),
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};
