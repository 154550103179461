import axios from 'axios';

import { APP_KEYS } from '../module/common/constants';
import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, IToken } from '../module/common/types';
import { ICheckEmail, IGetUser, IUpdateNotification, IUpdatePassword } from '../types';

class UserService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async checkEmail(data: ICheckEmail): Promise<ExpectedFromQuery<IToken>> {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/user/check-email`,
      { code: data.code },
      {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      }
    );
    return response.data;
  }

  async resendLink(token: any): Promise<ExpectedFromQuery<IMessage>> {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/user/resend-link`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }

  async updateNotification(data: IUpdateNotification): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post('/user/update-notification', data);
  }

  async getUser(): Promise<ExpectedFromQuery<IGetUser>> {
    return this.httpService.get<IGetUser>('/user');
  }

  async updateUserInfo(data: Partial<IGetUser>): Promise<ExpectedFromQuery<IMessage | IToken>> {
    return this.httpService.post('/user', data);
  }

  async updateUserPassword(data: IUpdatePassword): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put('/user', data);
  }

  async updateAvatar(data: any): Promise<ExpectedFromQuery<IMessage>> {
    const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/user/update-avatar`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  }
}

const factory = new HttpFactoryService();
export const userService = new UserService(factory.createAuthHttpService());
