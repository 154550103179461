import { Form } from 'formik';
import styled, { css } from 'styled-components';

import {
  CommonContainer,
  CommonErrorContainer,
  CommonTableContainer,
  CommonText,
  CommonTitle
} from '../../common/page';

export const Container = styled(CommonContainer)``;
export const Text = styled(CommonText)``;
export const Title = styled(CommonTitle)``;
export const TableContainer = styled(CommonTableContainer)``;
export const ErrorContainer = styled(CommonErrorContainer)``;

export const CommonForm = styled(Form)`
  width: 100%;
`;

export const Popup = css`
  height: 70%;
`;
