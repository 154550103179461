import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SHADOWS, SPACES } from '../../../../theme';

export const Container = styled.div`
  width: 100%;
  height: 37.5rem;

  padding-left: ${SPACES.l};
  padding-bottom: 9.375rem;

  overflow-y: auto;

  background-color: ${COLORS.white};

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding-left: 0;
  }
`;

export const ArrowBackComponent = css`
  display: none;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: flex;
    margin: 0 0 ${SPACES.xxxxl} 0;
  }
`;

export const Div = styled.div`
  &:last-child > .line {
    display: none;
  }
`;

export const Div2 = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: ${SPACES.xxxxl};
  margin-top: ${SPACES.xxxxl};
`;

const Center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
`;

export const Card = styled.div`
  width: 50%;
  padding: ${() => SPACES.xxl};
  border: 1px solid ${COLORS.black};
  border-radius: ${SPACES.s};
  box-shadow: ${SHADOWS.m};

  ${Center};
  justify-content: space-between;
`;

export const Card2 = styled(Card)`
  width: 100%;

  &:hover {
    border: 1px solid ${COLORS.purple};
    cursor: pointer;
  }
`;

export const Title = styled.span`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const StyledAccordion = css`
  border: 1px solid ${COLORS.black};
  border-radius: ${SPACES.s};
  padding: ${SPACES.xxl};

  margin: ${SPACES.xxxxl} 0;
`;
