import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { adminService } from '../../../../services';
import { COLORS } from '../../../../theme';
import { ArrowBack, Loader } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { urlUtil } from '../../../home-layout/utils';
import * as CommonStyled from '../my-job/my-job.styled';
import * as Styled from './admin-panel.styled';

export const AdminPanel = () => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const { isLoading, data } = useQuery(APP_KEYS.QUERY_KEYS.GET_ADMIN_INFO, () =>
    adminService.getAdminInfo()
  );

  const accordions = [
    { title: `Users (${data?.count_users})`, link: 'ADMIN_USERS' },
    { title: `Posts (${data?.all_posts})`, link: 'ADMIN_OBJECTS' },
    { title: 'Banners', link: 'ADMIN_BANNERS' },
    { title: 'Notifications', link: 'ADMIN_NOTIFICATIONS' }
  ];

  const goTo = (_link: string) => {
    // @ts-ignore
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS[_link])}`);
  };

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  return (
    <Styled.Container>
      <ArrowBack style={Styled.ArrowBackComponent} onClick={onBack} />
      <CommonStyled.HeaderContainer>
        <div>
          <CommonStyled.Title>Admin Panel</CommonStyled.Title>
        </div>
      </CommonStyled.HeaderContainer>

      <Styled.UserInfoContainer>
        {accordions.map(({ title, link }, index) => (
          <Styled.Card2 key={index} onClick={goTo.bind(this, link)}>
            <Styled.Title>{title}</Styled.Title>
          </Styled.Card2>
        ))}
      </Styled.UserInfoContainer>
    </Styled.Container>
  );
};
