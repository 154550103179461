export const newUtil = (date: string, minDay: number, maxDay: number) => {
  const createdAt = new Date(date).getDate();
  const dateNow = new Date().getDate();

  const sun = dateNow - createdAt;

  return sun >= minDay && sun <= maxDay;
};

export const isHiringDate = (_endDate?: string) => {
  const endDate = new Date(_endDate ?? '').getDate();
  const dateNow = new Date().getDate();

  return dateNow < endDate;
};

export const isHiringDateAndTime = (_endDate?: string, endTime?: string) => {
  const endDateAndTime = new Date(_endDate + ' ' + endTime);
  const currentDate = new Date();

  return endDateAndTime > currentDate;
};

const checkDate = (date: string) => {
  const createdAt = new Date(date).getTime();
  const currentTime = Date.now();

  const timeDifference = currentTime - createdAt;

  const millisecondsPerHour = 60 * 60 * 1000;
  const millisecondsPerDay = 24 * millisecondsPerHour;
  const millisecondsPerMonth = 30 * millisecondsPerDay;
  const millisecondsPerYear = 12 * millisecondsPerMonth;

  if (timeDifference < millisecondsPerDay) {
    return { time: Math.floor(timeDifference / millisecondsPerHour), flag: 'hours' };
  }
  if (timeDifference < millisecondsPerMonth) {
    return { time: Math.floor(timeDifference / millisecondsPerDay), flag: 'days' };
  }
  if (timeDifference < millisecondsPerYear) {
    return { time: Math.floor(timeDifference / millisecondsPerMonth), flag: 'months' };
  }

  return { time: Math.floor(timeDifference / millisecondsPerYear), flag: 'years' };
};

export const postAgo = (date: string) => {
  const { time, flag } = checkDate(date);

  const returnText: { [key: string]: string } = {
    hours: `${time} hours`,
    days: `${time} days`,
    months: `${time} months`,
    years: `${time} years`
  };

  return returnText[flag];
};

export const postAgoEvent = (date: string) => {
  const { time, flag } = checkDate(date);

  const returnText: { [key: string]: string } = {
    hours: time <= 1 ? 'JUST NOW' : `POSTED ${time}H AGO`,
    days: `POSTED ${time}D AGO`,
    months: `POSTED ${time}M AGO`,
    years: `POSTED ${time}Y AGO`
  };

  return returnText[flag];
};

export const transformDate = (_date: string): string => {
  const date = new Date(_date);

  return date.toString().split(' ').splice(1, 4).join(' ');
};
