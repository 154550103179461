import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid, Iuuid1 } from '../module/common/types';
import {
  IAdminInfo,
  IAllBanners,
  IAllNotifications,
  IAllObjects,
  IAllUsers,
  IBanner,
  ICreateBanners,
  ICreateNotifications,
  IDeleteObject,
  IEditBanners,
  IEditNotification,
  IGetNotification
} from '../types';

class AdminService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async getAdminInfo(): Promise<ExpectedFromQuery<IAdminInfo>> {
    return this.httpService.get<IAdminInfo>('/admin');
  }

  async getDeleteUser({ id }: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/admin?user_id=${id}`);
  }

  async getDeleteObject({ id, table }: IDeleteObject): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/admin/object?owner_id=${id}&table=${table}`);
  }

  async getAllUsers(page: number): Promise<ExpectedFromQuery<IAllUsers>> {
    return this.httpService.get<IAllUsers>(`/admin/all-users?page=${page}`);
  }

  async getAllObjects(page: number, value: string): Promise<ExpectedFromQuery<IAllObjects>> {
    return this.httpService.get<IAllObjects>(`/admin/all-objects?page=${page}&table=${value}`);
  }

  async getAllNotifications(): Promise<ExpectedFromQuery<IAllNotifications>> {
    return this.httpService.get<IAllNotifications>(`/admin/all-notifications`);
  }
  async createNotifications(data: ICreateNotifications): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, ICreateNotifications>(`/admin/notification`, data);
  }
  async editNotification(data: IEditNotification): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, IEditNotification>(`/admin/notification`, data);
  }
  async getNotification({ id }: Iuuid1): Promise<ExpectedFromQuery<IGetNotification>> {
    return this.httpService.get<IGetNotification>(`/admin/notification?notification_id=${id}`);
  }
  async deleteNotification({ id }: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/admin/notification?notification_id=${id}`);
  }

  async getAllBanners(): Promise<ExpectedFromQuery<IAllBanners>> {
    return this.httpService.get<IAllBanners>(`/admin/all-banners`);
  }
  async createBanners(data: ICreateBanners): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, ICreateBanners>(`/admin/banner`, data);
  }
  async editBanners(data: IEditBanners): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, IEditBanners>(`/admin/banner`, data);
  }
  async getBanner({ id }: Iuuid1): Promise<ExpectedFromQuery<IBanner>> {
    return this.httpService.get<IBanner>(`/admin/banner?banner_id=${id}`);
  }
  async getDeleteBanner({ id }: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/admin/banner?banner_id=${id}`);
  }
}

const factory = new HttpFactoryService();
export const adminService = new AdminService(factory.createAuthHttpService());
