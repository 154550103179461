import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaEditVendor = Yup.object().shape({
  gallery: validateSchema.gallery,

  name: validateSchema.text,
  manager: validateSchema.text,
  location: validateSchema.text,
  address: validateSchema.text,

  phone: validateSchema.phone,
  email: validateSchema.email,

  categories: validateSchema.categories,
  description: validateSchema.text
});

export const validationSchemaEditVenueExperience = Yup.object().shape({
  spaces: Yup.array().of(
    Yup.object().shape({
      venue_id: validateSchema.text,
      name: validateSchema.text,
      capacity: validateSchema.text,
      square: validateSchema.text.optional(),
      standing_capacity: validateSchema.text.optional(),
      seating_capacity: validateSchema.text.optional(),
      description: validateSchema.text
    })
  )
});
