import { FieldArray, Formik, getIn } from 'formik';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { IEditTalentExperiences } from '../../../../types';
import { Accordion, InputTextArea, Loader, PopupLayout } from '../../../common/component';
import { InputConst } from '../../../common/constants';
import { editTalentExperiencesById, getTalentExperiencesById } from '../../hooks';
import { ITalentCreate } from '../../types';
import { validationSchemaEditTalentExperience } from '../../validation/shema';
import * as Styled from './talent-create-experience.styled';
import { FilePath } from '../../../../utils';

export const TalentCreateExperience = ({ onCancel, isEdit = false }: ITalentCreate) => {
  const { search } = useLocation();

  const id = search.split('=')[1];

  const { isLoading, data, refetch } = getTalentExperiencesById(id);
  const { mutate: editTalentExperiences } = editTalentExperiencesById(id);

  useEffect(() => {
    refetch();
  }, []);

  const onSubmit = async (_data: IEditTalentExperiences) => {
    editTalentExperiences({
      experiences: _data.experiences,
      talent_id: id
    });
    onCancel();
  };

  const init = {
    occupation: '',
    company: '',
    start_date: '',
    end_date: '',
    description: '',
    talent_id: id
  };

  return (
    <Formik
      initialValues={{
        experiences: isEdit ? (data?.experiences.length ? data?.experiences : [init]) : [init]
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchemaEditTalentExperience}
    >
      {({ values, touched, errors }) => (
        <Styled.CommonForm>
          <FieldArray name='experiences'>
            {({ remove, unshift }) => (
              <PopupLayout
                title='Edit Experience'
                saveText='Save Changes'
                onCancel={onCancel}
                onSave={() => {}}
                type='submit'
                isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
              >
                {isLoading && (
                  <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />
                )}

                <Styled.AddExperiences onClick={() => unshift(init)}>
                  <Styled.Icon
                    className='icon'
                    style={{
                      WebkitMaskImage: `url(${FilePath(Icons.plus)})`,
                      WebkitMaskSize: '100% 100%',
                      maskImage: `url(${FilePath(Icons.plus)})`
                    }}
                  />
                  <Styled.Text>add new EXPERIENCE</Styled.Text>
                </Styled.AddExperiences>

                {getIn(values, 'experiences').map((value: any, index: number) => (
                  <Accordion
                    key={index}
                    index={index}
                    isOpen
                    icon={Icons.deleteIcon}
                    onClickIcon={() => remove(index)}
                    title={getIn(values, `experiences[${index}].occupation`)}
                    style={Styled.AccordionStyled}
                  >
                    <Styled.UserInfoContainer>
                      <Styled.CommonInput
                        mb={SPACES.l}
                        height='3.5rem'
                        label='Occupation'
                        name={`experiences[${index}].occupation`}
                        placeholder='Occupation'
                        width='100%'
                      />

                      <Styled.CommonInput
                        mb={SPACES.l}
                        height='3.5rem'
                        label='Company'
                        name={`experiences[${index}].company`}
                        placeholder='Company'
                        width='100%'
                      />

                      <Styled.CommonInputCalendar
                        key={index}
                        label='Start Date'
                        name={`experiences[${index}].start_date`}
                        placeholder='Start Date'
                        width='48%'
                        height='3.5rem'
                        mb={SPACES.l}
                      />

                      <Styled.CommonInputCalendar
                        key={index}
                        label='End Date'
                        name={`experiences[${index}].end_date`}
                        placeholder='End Date'
                        width='48%'
                        height='3.5rem'
                        mb={SPACES.l}
                      />
                    </Styled.UserInfoContainer>

                    <InputTextArea
                      rows={3}
                      maxLength={1000}
                      mt={SPACES.xxs}
                      {...InputConst.description}
                      name={`experiences[${index}].description`}
                    />
                  </Accordion>
                ))}
              </PopupLayout>
            )}
          </FieldArray>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
