import styled from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../../theme';
import { Button } from '../../../../common/component';

export const HeaderStyled = styled.header`
  width: 100%;
  min-height: 5.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${SPACES.xxxxxxl_};

  @media screen and (max-width: ${MEDIA.tablet_s}) {
    padding: 0 ${SPACES.l};
  }

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: 0 ${SPACES.l};
  }

  border-bottom: 1px solid ${COLORS.black};
`;

export const Logo = styled.img`
  width: 8.125rem;
  height: 2.5rem;
  @media screen and (max-width: ${MEDIA.mobile_l}) {
    width: 6.25rem;
  }

  @media screen and (max-width: ${MEDIA.mobile_m}) {
    width: 5.125rem;
  }
`;

export const Div = styled.div`
  display: flex;
  gap: 10px;
`;

export const Btn = styled(Button)`
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    width: fit-content;
    padding: ${SPACES.xxxs} ${SPACES.xs};
    margin-right: 0;
  }
`;
