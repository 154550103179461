import React from 'react';

import * as Styled from './not-found-item.styled';

export interface INotFoundItem {
  title: string;
  subTitle: string;
  isSearch?: boolean;
}

export const NotFoundItem = ({ title, subTitle, isSearch = false }: INotFoundItem) => (
  <Styled.Container>
    {isSearch ? <Styled.NotFoundSearch /> : <Styled.NotFound />}
    <Styled.NotFoundTitle>{title}</Styled.NotFoundTitle>
    <Styled.NotFoundText>{subTitle}</Styled.NotFoundText>
  </Styled.Container>
);
