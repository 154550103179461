export const fileUrlUtl = (url: string | undefined): string => {
  return process.env.REACT_APP_SERVER_URL + '/' + url;
};

export const FilePath = (path: any) => {
  if (['dev', 'prod', 'prod2'].includes(process.env.REACT_APP_ENV ?? '')) {
    return `/build${path}`;
  }
  return path;
};
