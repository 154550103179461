export const transformData = (editData: any, formData: any) => {
  Object.entries(editData).forEach((i: any) => {
    if (i[0] !== 'gallery' && i[0] !== 'categories') {
      formData.append(i[0], i[1]);

      return null;
    }
    if (i[0] === 'categories') {
      const a = JSON.stringify(i[1]);
      formData.append(i[0], a);

      return null;
    }
    for (let j = 0; j < i[1].length; j++) {
      const fileElement = i[1][j];
      const el = fileElement.file ? fileElement.file : fileElement.path;
      formData.append(`${i[0]}${j}`, el);
    }
  });
};
