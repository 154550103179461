import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';

export const SettingContainer = styled.div`
  display: flex;

  flex-direction: column;

  width: 30rem;
  position: absolute;
  top: 6.25rem;
  right: 10rem;
  z-index: 100;

  padding-bottom: ${SPACES.xxxxl};

  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;

  @media screen and (max-width: ${MEDIA.tablet}) {
    width: 100vw;
    height: 100vh;
    top: 3.5rem;
    right: 0;
    border: none;
    border-radius: 0;
  }
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.semi_bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  color: ${COLORS.black};
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACES.xxxxl};
`;

export const Title = styled.p`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.bold};
`;

export const SubTitle = styled.span`
  ${Fonts};
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};

  color: ${COLORS.semiPurple};
  cursor: pointer;
`;

export const Line = styled.div`
  width: calc(100% + ${SPACES.xxxxl});
  height: 1px;
  border-top: 1px solid ${COLORS.black};

  margin-top: ${SPACES.xxxxl};
`;

export const Line2 = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${COLORS.black};
`;

export const FavoritesOrReview = styled.div`
  padding: ${SPACES.xxxxl} ${SPACES.xxxxl} 0 ${SPACES.xxxxl};
  position: relative;
`;

export const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;

  border-radius: 50%;
  background: ${COLORS.semiPurple};

  position: absolute;

  top: 50px;
  left: 0.5rem;
  transform: translate(0, -50%);
`;

export const Info = styled.div`
  display: flex;
  gap: ${SPACES.l};
`;

export const Avatar = styled.img`
  height: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: ${SPACES.xxs};
  flex-direction: column;
`;

export const Time = styled(SubTitle)`
  cursor: text;
  font-weight: ${FONTS.WEIGHTS.normal};
  color: ${COLORS.black};
`;

export const Text = styled(Title)<{ isRole: boolean; isRoleUser: boolean }>`
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  line-height: ${FONTS.SIZES.xxxxl};

  & > span {
    cursor: ${({ isRole, isRoleUser }) => (isRole && isRoleUser ? 'pointer' : 'auto')};
    font-weight: ${FONTS.WEIGHTS.semi_bold};
    &:hover {
      color: ${({ isRoleUser, isRole }) =>
        isRoleUser && isRole ? COLORS.black : COLORS.purpleHover};
      text-decoration: ${({ isRoleUser, isRole }) => (isRoleUser && isRole ? 'underline' : 'none')};
    }
  }

  & > .link {
    color: ${COLORS.semiPurple};

    cursor: pointer;
  }
`;

export const Scroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 25rem;

  @media screen and (max-width: ${MEDIA.tablet}) {
    max-height: calc(100% - 8.5rem);
    padding-bottom: 2rem;
  }

  &::-webkit-scrollbar {
    width: 1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const Description = styled.div`
  margin-top: ${SPACES.l};
  padding: ${SPACES.s} ${SPACES.l};
  background-color: ${COLORS.white50};
  border-radius: 8px;
`;
