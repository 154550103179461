import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { ReactComponent as Location } from '../../../../assets/icons/location-icon.svg';
import { ReactComponent as Search } from '../../../../assets/icons/search-icon.svg';
import { ReactComponent as Sort } from '../../../../assets/icons/sort-icon.svg';
import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { SubTitle } from '../../../auth/component/sing-up/sing-up.styled';
import { Input, InputCalendar } from '../../../common/component';

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};

  color: ${COLORS.black};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: ${SPACES.xxxxl};
`;

export const Title = styled.span`
  font-family: ${FONTS.FAMILIES.slussen_extended};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.black};
  font-size: 3rem;
  line-height: 4rem;
  text-transform: uppercase;

  color: ${COLORS.black};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${SPACES.xxxxl_};
  }
`;

export const CommonForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;

  margin-top: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-top: ${SPACES.xxsm};
  }

  &.hide-mobile {
    top: 0;
  }
`;

export const FilterContainer = styled.div`
  width: 21.5rem;
  height: fit-content;

  padding-right: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: none;
    padding-right: 0;
  }
`;

export const ContentContainer = styled.div`
  width: calc(100% - 21.5rem);
  height: 100%;

  display: flex;
  flex-direction: column;

  padding-left: ${SPACES.xxxxl};

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    padding-left: 0;
    width: 100%;
  }
`;

export const EventContainer = styled.div<{ length: boolean }>`
  width: 100%;
  min-height: calc(100vh - 22rem);

  display: flex;
  flex-direction: column;

  ${({ length }) =>
    !length &&
    css`
      padding: 0;
      ${Center};
    `}

  @media screen and (max-width: ${MEDIA.tablet}) {
    padding-right: 0;
  }
`;

export const SearchIcon = styled(Search)`
  color: ${COLORS.black};
`;

export const LocationIcon = styled(Location)`
  color: ${COLORS.black};
`;
export const SortIcon = styled(Sort)`
  color: ${COLORS.black};
`;

export const Div = styled.div`
  position: relative;
  ${Center}
  gap:${SPACES.xxxxl};
  width: 100%;
  padding-bottom: ${SPACES.l};

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.s};
  }
`;

export const FilterRating = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;

  right: 0;
  top: 60px;

  z-index: 10;
  position: absolute;

  background: ${COLORS.white};
  border: 1px solid ${COLORS.black};
  border-radius: 8px;

  padding: ${SPACES.s};
`;

export const FilterRatingDiv = styled.div<{ isSelect: boolean }>`
  padding: ${SPACES.s};
  border-radius: 6px;

  cursor: pointer;

  background: ${({ isSelect }) => (isSelect ? COLORS.semiWhite : COLORS.white)};

  &:hover {
    background: ${COLORS.white200};
  }
`;

export const IconContainer = styled.div<{ isFocused: boolean }>`
  ${Center}

  height: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 8px;

  background: ${({ isFocused }) => (isFocused ? COLORS.black : COLORS.white)};

  & > .icon {
    background: ${({ isFocused }) => (isFocused ? COLORS.purple : COLORS.black)};
  }

  cursor: pointer;
  border: 1px solid ${COLORS.black};
`;

export const Icon = styled.div`
  width: 1.25rem;
  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const Sticky = styled.div<{ stickyTop?: string }>`
  width: 100%;
  position: sticky;
  height: fit-content;

  top: ${({ stickyTop }) => stickyTop ?? '0'};
  z-index: 10;

  &.isTop {
    padding-top: 0.5rem !important;
  }

  background: ${COLORS.white};
`;

export const Sticky2 = styled(Sticky)`
  width: clamp(21rem, 100%, 21rem);
`;

export const NawTab = styled.div`
  width: 100%;
  height: 3.5rem;

  margin-bottom: ${SPACES.xxxxxxls};

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-bottom: ${SPACES.l};
  }
`;

export const RoleContainer = styled.div`
  width: 100%;

  display: flex;
  overflow-x: auto;
  position: relative;

  gap: ${SPACES.xxxxl};

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.s};
  }
`;

const Hover = css`
  background: ${COLORS.black};

  & > p {
    color: ${COLORS.white};
  }

  & > div {
    background: ${COLORS.purple};
  }
`;

export const Role = styled.div<{ isValue: boolean }>`
  ${Center};
  max-height: 3.5rem;

  padding: ${SPACES.xxxxl};
  gap: 10px;

  border-radius: 8px;
  border: 1px solid ${COLORS.black};
  cursor: pointer;

  ${({ isValue }) =>
    isValue &&
    css`
      ${Hover}
    `}

  &:hover {
    ${Hover}
  }
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;
  background: ${COLORS.black};
`;

export const RoleText = styled(SubTitle)<{ isJob: boolean }>`
  font-weight: ${FONTS.WEIGHTS.bold};
  width: fit-content;
  width: ${({ isJob }) => (isJob ? '8.125rem' : 'fit-content')};
`;

export const DivEvent = styled.div`
  background: ${COLORS.white};

  &:hover {
    & > .line {
      border-top: 1px solid ${COLORS.purple};
    }
  }
`;

export const DivEvent2 = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.black};
  margin: ${SPACES.xxxxxxls} 0;

  @media screen and (max-width: ${MEDIA.tablet}) {
    margin: ${SPACES.l} 0;
  }
`;

export const Line2 = styled.div`
  width: calc(100% + 2.5rem);
  border-top: 1px solid ${COLORS.black};

  margin-left: -${SPACES.xxl};
`;

export const FiltersText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: ${SPACES.xxxxl} 0 ${SPACES.l} 0;

  & > span {
    ${Fonts};
  }

  & > .clear {
    cursor: pointer;
    color: ${COLORS.purple};
  }
`;

export const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 8px;

  background: ${COLORS.white};

  border: 1px solid ${COLORS.black};
`;

export const AccordionDiv = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 50px;

  cursor: pointer;

  padding: 0 ${SPACES.xxl};

  &:last-child > .line {
    display: none;
  }
`;

export const CommonInputCalendar = styled(InputCalendar)`
  width: 100%;
`;
export const CommonInput = styled(Input)`
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${SPACES.xxs};
  width: 100%;
`;
export const Line3 = styled.div`
  width: 0.625rem;
  border-top: 2px solid ${COLORS.black};
  margin: 0 10px;
`;

export const D = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const InputCheckBoxContainer = styled.div<{ isBottom?: boolean }>`
  display: flex;
  flex-direction: column;

  padding: 0.5rem 0;

  min-height: 3.125rem;
  max-height: 25rem;
  height: fit-content;

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.1px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  margin-top: ${SPACES.xxs};

  gap: ${SPACES.l};
`;

export const Filters = styled.div`
  @media screen and (max-width: ${MEDIA.tablet}) {
    margin-bottom: 4rem;
  }
`;
