import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { jobService } from '../../../../services';
import { COLORS } from '../../../../theme';
import { ArrowBack, Drawer, Loader, NotFoundItem, Portal } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { urlUtil } from '../../../home-layout/utils';
import { CreateJob, JobCard } from '../../../job/component';
import * as Styled from './my-job.styled';

export const MyJob = () => {
  const history = useHistory();

  const [editComponent, setEditComponent] = useState<null | string>(null);

  const onAddJob = (str: string | null) => {
    setEditComponent(str);
  };

  const onPushPublicEvent = (id: string) => () => {
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_JOB)}?id=${id}`);
  };
  const onBack = () => {
    history.goBack();
  };

  const { isLoading, data, refetch } = useQuery(APP_KEYS.QUERY_KEYS.GET_ALL_JOBS, () =>
    jobService.getMyJobs()
  );

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  return (
    <Styled.Container>
      <ArrowBack style={Styled.ArrowBackComponent} onClick={onBack} />
      <Styled.HeaderContainer>
        <div>
          <Styled.Title>My Job Postings</Styled.Title>
          <Styled.Text1>
            Created <Styled.Text>{data?.jobs.length} Jobs</Styled.Text>
          </Styled.Text1>
        </div>
        <Styled.Btn
          content='Post New Job'
          variant='primaryInverse'
          onClick={onAddJob.bind(this, 'create')}
        />
      </Styled.HeaderContainer>
      <Styled.Line />

      {data?.jobs.length ? (
        data?.jobs.map((job, index) => (
          <Styled.Div onClick={onPushPublicEvent(job?.id)} key={index}>
            <JobCard data={job} />
            <Styled.Line className='line' />
          </Styled.Div>
        ))
      ) : (
        <NotFoundItem
          title='No Posts Yet'
          subTitle='Your posts will appear here and you can view them anytime.'
        />
      )}

      {editComponent && (
        <Drawer
          onClose={onAddJob.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'create' && <CreateJob onCancel={onAddJob.bind(this, null)} />}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
