import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { userService } from '../../../../services';
import { SPACES } from '../../../../theme';
import { IAuthError, ICheckEmail } from '../../../../types';
import { Button } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { IMessage, IToken } from '../../../common/types';
import { AuthLayout } from '../../common/component/auth-layout/auth-layout';
import * as CommonStyled from '../../common/styles/common.styled';
import * as Styled from './check-email.styled';

export interface IHistoryState {
  state: undefined | { token: boolean };
}

export const CheckEmail = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { state }: IHistoryState = history.location as IHistoryState;

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });
    if (err.errorCode === 150) history.push(APP_KEYS.ROUTER_KEYS.CHECK_EMAIL);
  };

  const onSuccess = async ({ token }: IToken) => {
    localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, token);

    localStorage.getItem(APP_KEYS.STORAGE_KEYS.TEST_TOKEN) &&
      localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TEST_TOKEN);

    history.push({
      pathname: APP_KEYS.ROUTER_KEYS.HOME + APP_KEYS.ROUTER_KEYS.PAGE,
      state: { isCompleteProfile: true }
    });
  };

  const onSuccess2 = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });
  };

  const { mutate: checkEmail } = useMutation<any, AxiosError<IAuthError>, ICheckEmail>(
    (data: ICheckEmail) => userService.checkEmail(data),
    {
      onSuccess,
      onError
    }
  );

  const { mutate: resendLink } = useMutation<any, AxiosError<IAuthError>>(
    () =>
      userService.resendLink(
        state?.token ?? localStorage.getItem(APP_KEYS.STORAGE_KEYS.TEST_TOKEN)
      ),
    {
      onSuccess: onSuccess2,
      onError
    }
  );

  const onClick = () => {
    history.push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const onResendLink = () => {
    resendLink();
  };

  useEffect(() => {
    if (search.includes('?code=')) {
      checkEmail({ code: search.split('=')[1], token: search.split('=')[1] });
    }
  }, [search]);

  return (
    <AuthLayout onClick={onClick} isScroll isScrollMobile>
      <Styled.Div>
        <CommonStyled.CommonDiv>
          <CommonStyled.Title>check Your Email</CommonStyled.Title>
          <CommonStyled.SubTitle>
            To finalize your account, we have sent a link to the email provided. Didn’t receive any
            mail? Resend the link or go back to the previous step and check that the email you
            provided is correct.
          </CommonStyled.SubTitle>

          <Button
            content='RESEND LINK'
            type='submit'
            variant='inverse3'
            onClick={onResendLink}
            mt={SPACES.xxxxxxls}
          />
        </CommonStyled.CommonDiv>
      </Styled.Div>
    </AuthLayout>
  );
};
