import { Formik } from 'formik';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  initValueEvent,
  initValueJob,
  initValueTalent,
  initValueVendor,
  initValueVenue
} from '../../constants';
import { ComponentLayout } from './component-layout';

export const IndexComponentLayout = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tabs');

  const [value, setValue] = useState(tab ?? 'events');

  const Date: { [key: string]: any } = {
    events: {
      initValue: {
        ...initValueEvent,
        start_date: searchParams.get('start_date') ?? '',
        end_date: searchParams.get('end_date') ?? '',
        location: searchParams.get('location') ?? ''
      }
    },
    vendors: {
      initValue: {
        ...initValueVendor,
        location: searchParams.get('location') ?? ''
      }
    },
    talents: {
      initValue: {
        ...initValueTalent,
        min: searchParams.get('min') ?? '',
        max: searchParams.get('max') ?? '',
        location: searchParams.get('location') ?? ''
      }
    },
    venues: {
      initValue: {
        ...initValueVenue,
        location: searchParams.get('location') ?? ''
      }
    },
    jobs_posting: {
      initValue: {
        ...initValueJob,
        min: searchParams.get('min') ?? '',
        max: searchParams.get('max') ?? '',
        start_date: searchParams.get('start_date') ?? '',
        end_date: searchParams.get('end_date') ?? '',
        location: searchParams.get('location') ?? ''
      }
    },
    event_planners: {
      initValue: {
        ...initValueTalent,
        min: searchParams.get('min') ?? '',
        max: searchParams.get('max') ?? '',
        location: searchParams.get('location') ?? ''
      }
    }
  };

  const { initValue } = Date[value];

  return (
    <Formik initialValues={initValue} enableReinitialize onSubmit={() => {}}>
      <ComponentLayout value={value} setValue={setValue} />
    </Formik>
  );
};
