import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  min-height: 400px;

  margin: 0 auto;
  padding-bottom: 2rem;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: node;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.black};
`;

export const Title = styled.p`
  ${Fonts}
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxxxxxl};
  line-height: 3.5rem;
  margin-top: ${SPACES.xxxxxxls};

  @media screen and (max-width: ${MEDIA.tablet}) {
    font-size: ${FONTS.SIZES.xxxxl};
    line-height: ${FONTS.SIZES.xxxxxxl_};

    margin-top: ${SPACES.xxl};
  }
`;

export const TextContainer = styled.div<{ isSpace?: boolean; isBorder?: boolean }>`
  width: 100%;
  position: relative;
  border-bottom: ${({ isBorder }) => (isBorder ? 'none' : `1px solid ${COLORS.black}`)};

  padding: ${SPACES.m} 0;
  margin: ${SPACES.l} 0;

  ${Center};
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};
`;

export const TextContainer2 = styled(TextContainer)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    gap: ${SPACES.l};
    flex-direction: column;
    align-items: flex-start;

    justify-content: flex-start;

    margin: 0;
    padding: ${SPACES.m} 0 0 0;
  }
`;

export const SubTitle = styled.p`
  ${Fonts}
  font-weight: ${FONTS.WEIGHTS.bold};
  gap: 3px;
`;

export const Text = styled.p`
  ${Fonts}
`;

export const UserRole = styled.span`
  ${Fonts};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};

  &.click {
    cursor: pointer;

    &:hover {
      border-color: ${COLORS.purple};

      & > .role {
        border-color: ${COLORS.purple};
      }

      & > div > .text {
        color: ${COLORS.purpleHover};
      }
    }
  }
`;

export const Div2 = styled(Div)`
  gap: ${SPACES.xxs};
`;

export const IconRole = styled.div`
  height: 1.25rem;

  aspect-ratio: 1/1;

  background: ${COLORS.blue};
`;

export const DivCopy = styled.div`
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background: ${COLORS.white200};
    & > .icon {
      background: ${COLORS.semiPurple};
      cursor: pointer;
    }
  }
`;

export const CopyEvent = styled(IconRole)`
  background: ${COLORS.black};

  &.icon {
    cursor: pointer;
  }

  &:hover {
    background: ${COLORS.purple};
  }
`;

export const EditBnt = styled(Button)`
  text-transform: uppercase;
  height: 2.5rem;
`;

export const Status = styled(Text)<{ isColor: boolean }>`
  position: relative;
  ${Center};

  gap: ${SPACES.m};

  &:before {
    display: flex;
    content: '';
    width: 12px;
    height: 12px;

    background: ${COLORS.green};
    background: ${({ isColor }) => (isColor ? COLORS.green : COLORS.semiRed)};
  }
`;

export const IconsCardStyled = css`
  @media screen and (max-width: ${MEDIA.mobile_l}) {
    & > .width {
      width: 100%;
    }
  }
`;
