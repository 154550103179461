import { Slider } from '@mui/material';
import { styled as styledMaterial } from '@mui/material/styles';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../../theme';
import { IInputAvatar } from './input-avatar';

export const Wrapper = styled.div<Partial<IInputAvatar>>`
  width: 100%;
  position: relative;

  width: ${({ width }) => width ?? '100%'};

  opacity: ${({ readOnly }) => (readOnly ? '0.4' : '1')};

  margin-left: ${({ ml }) => ml ?? '0'};
  margin-right: ${({ mr }) => mr ?? '0'};
  margin-bottom: ${({ mb }) => mb ?? '0'};
  margin-top: ${({ mt }) => mt ?? '0'};
`;

const Fonts = css`
  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.l};
  color: ${COLORS.black};
`;
const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
  position: relative;
  display: block;
  ${Fonts}
  text-transform: capitalize;

  color: ${COLORS.black};
`;

export const ImageContainer = styled.div<{ isSpace?: boolean; readOnly?: boolean }>`
  width: 100%;
  position: relative;

  border: 1px dashed ${COLORS.black};
  padding: ${SPACES.xs} ${SPACES.s};

  ${Center}
  justify-content: ${({ isSpace }) => (isSpace ? 'space-between' : 'flex-start')};

  border-radius: 8px;
  margin-top: ${SPACES.xxxs};

  input {
    cursor: pointer;

    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0;
    overflow: hidden;

    z-index: 4;
  }
`;

export const Div = styled.div`
  ${Center}
  gap: ${SPACES.xs}
`;

export const Img = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
`;

export const EditIcon = styled.img`
  width: 1.125rem;
  aspect-ratio: 1/1;
  cursor: pointer;
  z-index: 3;
`;

export const Text = styled.p`
  font-family: ${FONTS.FAMILIES.slussen};
  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.black};
`;

export const SaveText = styled(Text)`
  font-size: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.bold};
  cursor: pointer;
  z-index: 3;
`;

export const AvatarEditorDiv = styled.div`
  width: 100%;
  background: ${COLORS.white50};
  border-radius: 8px;
`;

export const AvatarEditor = styled.div`
  max-width: 25rem;
  margin: 0 auto;
`;

export const CustomSliderDiv = styled.div`
  width: 100%;
  gap: ${SPACES.xxl};
  ${Center};

  margin: ${SPACES.xxl} 0;

  & > span {
    font-size: ${FONTS.SIZES.xxxxxl_};
  }
`;

export const CustomSlider = styledMaterial(Slider)(() => ({
  maxWidth: '25rem',
  width: 'fit-content',
  margin: '0 !important',
  color: `${COLORS.purple} !important`,
  '& .MuiSlider-thumb': {
    backgroundColor: COLORS.purple //color of thumbs
  },
  '& .MuiSlider-rail': {
    color: COLORS.white100 ////color of the slider outside  teh area between thumbs
  }
}));
