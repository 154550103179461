import React, { KeyboardEvent, useState } from 'react';

import '../../../../styles/popup-layout.css';
import { ArrowBack } from '../arrow-back/arrow-back';
import * as Styled from './gallery.styled';

export interface IGalleryPopup {
  images: any;
  onClose: () => void;
  index: number;
}

export const GalleryPopup = ({ images, onClose, index: i }: IGalleryPopup) => {
  const [index, setIndex] = useState(i ?? 0);
  const [wordData, setWordData] = useState(images[index]);

  const handleClick = (_index: number) => {
    const wordSlider = images[_index];
    setWordData(wordSlider);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowLeft') {
      setIndex((prev) => {
        const _prev = prev >= 1 ? prev - 1 : images.length - 1;
        handleClick(_prev);
        return _prev;
      });
    }
    if (e.key === 'ArrowRight') {
      setIndex((prev) => {
        const next = prev >= 0 && prev < images.length - 1 ? prev + 1 : 0;
        handleClick(next);
        return next;
      });
    }
  };

  return (
    <div className='confirm_purchase above-all' onKeyDown={onKeyDown} tabIndex={-1}>
      <Styled.ContainerPopup>
        <Styled.Carousel>
          <ArrowBack onClick={onClose} style={Styled.ArrowBackComponent} />
          <Styled.Count>
            {index + 1} OF {images.length}
          </Styled.Count>
          <Styled.FirstImage src={wordData.path} />
          <Styled.DivImage>
            {images.map((data: any, _index: number) => (
              <Styled.ImgCarousel
                key={_index}
                isFocus={wordData.id === data.id}
                src={data.path}
                onClick={() => handleClick(_index)}
                height='70'
                width='100'
              />
            ))}
          </Styled.DivImage>
        </Styled.Carousel>
      </Styled.ContainerPopup>
    </div>
  );
};
