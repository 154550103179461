import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { COLORS } from '../../../../theme';
import { ArrowBack, Drawer, Loader, Portal } from '../../../common/component';
import {
  ButtonContainer,
  Categories,
  IconsCard,
  StarAndTitle
} from '../../../common/component/public-page/public-page';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket, useScrollStart } from '../../../common/hooks';
import { getFavoriteById, toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { ReviewContainer } from '../../../review/component';
import { getEventPlanerById } from '../../hooks';
import { EventPlanerCreate } from '../event-planer-create/event-planer-create';
import * as Styled from './event-planer-public.styled';
import { FilePath } from '../../../../utils';

export const EventPlanerPublic = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);
  useScrollStart();

  const id = search.split('=')[1];

  const [editComponent, setEditComponent] = useState<null | string>(null);

  const { isLoading, data } = getEventPlanerById(id);

  const isUser = user?.id === data?.event_planer.user_id ?? false;

  const { isLoading: isLoadingFavorite, data: dataFavorite } = getFavoriteById(
    id,
    'EventPlaners',
    !isUser
  );
  const { mutate: createFavorite } = toggleFavorite(
    id,
    data?.event_planer.user_id,
    socket,
    'EventPlaners'
  );

  const abouts = [
    { icon: FilePath(Icons.phoneIcon), title: 'Phone Number', text: data?.event_planer.phone },
    { icon: FilePath(Icons.emailIcon), title: 'Email Address', text: data?.event_planer.email },
    { icon: FilePath(Icons.locationIcon), title: 'Location', text: data?.event_planer.location },
    {
      icon: FilePath(Icons.reteIcon),
      title: 'Hourly Rate',
      text: data?.event_planer.hourly_rate ? (
        <>
          <img src={FilePath(Icons.dollar)} alt='dollar' />
          {data?.event_planer?.hourly_rate}/h
        </>
      ) : null
    }
  ];

  const onEdit = (str: string | null) => {
    setEditComponent(str);
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toastContainer.success({ title: 'LINK COPIED!' });
  };

  const onBack = () => {
    history.goBack();
  };

  const onFavorite = async () => {
    await createFavorite({
      role: 'EventPlaners',
      user_id: user?.id,
      owner_id: data?.event_planer.id as string,
      to_user_id: data?.event_planer.user_id
    });
  };

  if (isLoading || isLoadingFavorite) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  const isFavorite = dataFavorite?.favorite === null;
  const favorite = isFavorite ? FilePath(Icons.favoriteIcon2) : FilePath(Icons.favoriteIcon);
  const avatar = data?.event_planer.avatar ?? Png.defaultBlack;
  const isAdmin = user?.role_site === 'admin';

  return (
    <Styled.Container>
      <ArrowBack
        onClick={onBack}
        isButton={{
          flag: true,
          isUser,
          isHiring: true,
          favorite,
          onCopyLink,
          onFavorite,
          onEdit
        }}
      />

      <Styled.InfoContainer>
        <Styled.ImageOne src={avatar} isLength={false} />
        <Styled.InfoContent>
          <Styled.Title>
            {data?.event_planer?.first_name} {data?.event_planer?.last_name}
          </Styled.Title>

          <Styled.TextContainer2 isSpace isBorder margin='0'>
            <StarAndTitle text={data?.event_planer.occupation} rating={data?.event_planer.rating} />

            <ButtonContainer
              isUser={isUser}
              onFavorite={onFavorite}
              favorite={favorite}
              isAdmin={isAdmin}
              isFavorite={isFavorite}
              onCopyLink={onCopyLink}
              onEdit={onEdit.bind(this, 'edit')}
            />
          </Styled.TextContainer2>

          <Styled.TextContainer style={{ margin: 0 }}>
            <Styled.SubTitle>About</Styled.SubTitle>
          </Styled.TextContainer>

          <IconsCard abouts={abouts} styled={Styled.IconsCardStyled} />
        </Styled.InfoContent>
      </Styled.InfoContainer>

      {data?.event_planer?.description && (
        <>
          <Styled.TextContainer>
            <Styled.SubTitle>Description</Styled.SubTitle>
          </Styled.TextContainer>

          <Styled.Text>{data?.event_planer?.description}</Styled.Text>
        </>
      )}

      {data?.event_planer.categories?.length && (
        <>
          <Styled.TextContainer>
            <Styled.SubTitle>Categories</Styled.SubTitle>
          </Styled.TextContainer>

          <Categories categories={data?.event_planer.categories} />
        </>
      )}

      <ReviewContainer
        socket={socket}
        full_name={`${data?.event_planer.first_name} ${data?.event_planer.last_name}`}
        user_id={user?.id}
        key_refetch={APP_KEYS.QUERY_KEYS.GET_EVENT_PLANER}
        id={id}
        to_user_id={data?.event_planer.user_id}
        rating={data?.event_planer.rating}
        reviews={data?.event_planer.reviews}
        component='EventPlaners'
      />

      {editComponent && (
        <Drawer
          onClose={onEdit.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'edit' && (
              <EventPlanerCreate isEdit onCancel={onEdit.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
