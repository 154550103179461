import React from 'react';

import { Icons } from '../../../../assets';
import { SPACES } from '../../../../theme';
import * as Styled from './arrow-back.styled';
import { FilePath } from '../../../../utils';

export interface IArrowBack {
  onClick: () => void;
  style?: any;
  isButton?: {
    flag: boolean;
    isHiring: boolean;
    isUser: boolean;
    favorite: string;
    onFavorite: () => void;
    onEdit: (value: string) => void;
    onCopyLink: () => void;
  };
}

export const ArrowBack = ({ onClick, style, isButton }: IArrowBack) => (
  <Styled.Container styled={style}>
    <Styled.Div onClick={onClick}>
      <Styled.Icon
        className='icon'
        style={{
          WebkitMaskImage: `url(${FilePath(Icons.arrowBack)})`,
          WebkitMaskSize: '100% 100%',
          maskImage: `url(${FilePath(Icons.arrowBack)})`
        }}
      />
      BACK
    </Styled.Div>
    {isButton && (
      <Styled.Div2>
        {isButton?.isUser ? (
          <Styled.EditBnt
            content={isButton.isHiring ? 'Edit' : 'REPOST'}
            variant='inverse2'
            startIcon={isButton.isHiring ? FilePath(Icons.editIcon) : FilePath(Icons.repostIcon)}
            widthIcon={SPACES.sm}
            pads={`${SPACES.xxs} ${SPACES.xs}`}
            onClick={isButton.onEdit.bind(this, 'edit')}
          />
        ) : (
          <Styled.CopyEvent
            onClick={isButton?.onFavorite}
            style={{
              WebkitMaskImage: `url(${isButton?.favorite})`,
              WebkitMaskSize: '100% 100%',
              maskImage: `url(${isButton?.favorite})`
            }}
          />
        )}

        <Styled.CopyEvent
          onClick={isButton?.onCopyLink}
          style={{
            WebkitMaskImage: `url(${FilePath(Icons.copyIcon)})`,
            WebkitMaskSize: '100% 100%',
            maskImage: `url(${FilePath(Icons.copyIcon)})`
          }}
        />
      </Styled.Div2>
    )}
  </Styled.Container>
);
