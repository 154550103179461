import React, { useEffect, useRef, useState } from "react";

import * as Styled from './description.styled';

export interface IDescription {
  isCard?: boolean;
  description?: string;
}

export const Description = ({ description, isCard }: IDescription) => {
  const htmlWithStyles = description
    ? description.replace(/<ol/g, '<ol style="margin-left: 20px;"')
    : '';
  const htmlWithStyles2 = htmlWithStyles.replace(/<ul/g, '<ul style="margin-left: 20px;"');
  const parsedDescription = { __html: htmlWithStyles2 };


  const [showEllipsis, setShowEllipsis] = useState(false);
  const containerRef = useRef(null);


  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      // @ts-ignore
      const totalHeight = [...containerElement.children].reduce((sum, child) => {
        return sum + child.clientHeight;
      }, 0);
      // @ts-ignore
      setShowEllipsis(totalHeight > containerElement.clientHeight);
    }
  }, []);

  const showEllipsisFromHTML = htmlWithStyles2.split(/<br\s*\/?>/i).filter(para => para.trim() !== '').length > 2;


  const finalShowEllipsis = showEllipsis || showEllipsisFromHTML;


  return (
    <Styled.Description
      isCard={isCard}
      ref={containerRef}
      showEllipsis={finalShowEllipsis}
      dangerouslySetInnerHTML={parsedDescription}
    />
  );
};
