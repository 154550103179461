import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES, TRANSITIONS } from '../../../../theme';
import { IButtonAppearances, IButtonProps, TNavLink } from '../../types';

const style = css<IButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? 'initial'};

  padding: ${({ pads }) => pads ?? `${SPACES.l} ${SPACES.xxxxl}`};
  margin-left: ${({ ml }) => ml ?? '0'};
  margin-right: ${({ mr }) => mr ?? '0'};
  margin-bottom: ${({ mb }) => mb ?? '0'};
  margin-top: ${({ mt }) => mt ?? '0'};

  font-family: ${FONTS.FAMILIES.slussen};
  font-style: normal;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: transparent;

  transition: all ${TRANSITIONS.duration.fast} ${TRANSITIONS.function.linear};
  cursor: pointer;

  &:disabled {
    cursor: initial;
    background-color: ${COLORS.gray50};
  }

  @media screen and (max-width: ${MEDIA.mobile_m}) {
    font-size: ${FONTS.SIZES.xxsm};
    line-height: ${FONTS.SIZES.xxxxl};
  }

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    font-size: ${FONTS.SIZES.s};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;

const buttonAppearances: IButtonAppearances = {
  primary: `
    background-color: ${COLORS.purple};
    border: 1px solid ${COLORS.purple};
    color: ${COLORS.black};
        cursor: pointer;


  &:hover,
  &:focus {
    background-color: ${COLORS.semiPurple};
  }
  &:active {
    background-color: ${COLORS.lightPink};
    border-color: ${COLORS.lightPink};
  }
  &:disabled {
    background-color: ${COLORS.semiWhite};
    border-color: ${COLORS.semiWhite};
    color: ${COLORS.white};

    &:hover {
      pointer-events: none;
    }
  }
`,
  primaryInverse: `
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.purple};
    color: ${COLORS.black};
        cursor: pointer;


  &:hover,
  &:focus {
    background-color: ${COLORS.semiPurple};
  }
  &:active {
    background-color: ${COLORS.lightPink};
    border-color: ${COLORS.lightPink};
  }
  &:disabled {
    background-color: ${COLORS.semiWhite};
    border-color: ${COLORS.semiWhite};
    color: ${COLORS.white};

    &:hover {
      pointer-events: none;
    }
  }
`,
  primaryInverse2: `
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.purple};
    color: ${COLORS.black};
        cursor: pointer;

  &:hover {
    background-color: ${COLORS.semiPurple};
  }
  &:active {
    background-color: ${COLORS.lightPink};
    border-color: ${COLORS.lightPink};
  }
  &:disabled {
    background-color: ${COLORS.semiWhite};
    border-color: ${COLORS.semiWhite};
    color: ${COLORS.white};

    &:hover {
      pointer-events: none;
    }
  }
`,
  inverse: `
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.semiWhite};
  color: ${COLORS.semiTransparentWhite};

  &:hover,
  &:focus {
    background-color:${COLORS.semiWhite};
    color: ${COLORS.white};
  }
  &:active {
   background-color:${COLORS.semiWhite};
    border-color: ${COLORS.semiWhite} ;
    color: ${COLORS.white};
  }
  &:disabled {
    color: ${COLORS.semiWhite};
    border-color: ${COLORS.semiWhite};

    &:hover {
      background-color: inherit;
      pointer-events: none;
    }
  }
  
`,
  inverse2: `
  background-color: none;
  border: none;
  color: ${COLORS.black};
  box-shadow: none;
  &:hover,
  &:focus {
   background: ${COLORS.white200} ;
   color: ${COLORS.semiPurple};
   
   & > div {
   background: ${COLORS.semiPurple} ;
   }
  }
  
  @media screen and (max-width: ${MEDIA.tablet}) {
      background-color:${COLORS.white50};
  }
`,
  inverse3: `
  background-color: ${COLORS.white50};
  border: none;
  color: ${COLORS.black};
  box-shadow: none;
  
  &:hover,
  &:focus {
    background-color:${COLORS.semiWhite};
  }
`
};

export const StyledButton = styled.button<IButtonProps>`
  ${style}
  ${({ variant }) => buttonAppearances[variant as keyof typeof buttonAppearances]}
  cursor: pointer;
`;

export const NavLink = styled(Link)<TNavLink>`
  text-decoration: none;
  color: inherit;
  ${style}
  ${({ variant }) => buttonAppearances[variant as keyof typeof buttonAppearances]}
`;

export const Icon = styled.img<IButtonProps>`
  height: ${({ widthIcon }) => (widthIcon ? `${widthIcon} !important` : '25px !important')};
  aspect-ratio: 1/1;
  margin-right: ${({ marginIcon }) => marginIcon ?? SPACES.xs};
  margin-left: ${({ marginIcon }) => marginIcon ?? SPACES.xs};
`;

export const Icon2 = styled.div<IButtonProps>`
  height: ${({ widthIcon }) => (widthIcon ? `${widthIcon} !important` : '25px !important')};
  aspect-ratio: 1/1;
  margin-right: ${({ marginIcon }) => marginIcon ?? SPACES.xs};

  background: ${COLORS.black};
`;
