import * as Yup from 'yup';

import { validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaUserInfo = Yup.object().shape({
  first_name: validateSchema.name,
  last_name: validateSchema.name,
  email: validateSchema.email,
  phone: validateSchema.phone,
  role: validateSchema.text,
  is_hide_phone: Yup.boolean()
});

export const validationSchemaCreateEvent = Yup.object().shape({
  gallery: validateSchema.gallery,
  name: validateSchema.text,
  location: validateSchema.text,
  venue: validateSchema.text,
  start_data: validateSchema.text,
  end_data: validateSchema.text.when('start_data', (start: any, schema) => {
    return schema.test('end_data', 'invalid date', function (value) {
      const { start_data } = this.parent;
      const startDateObj = new Date(start_data);
      const endDateObj = new Date(value);

      return endDateObj >= startDateObj;
    });
  }),
  start_time: validateSchema.text,
  end_time: validateSchema.text.when('start_data', (start, schema) => {
    return schema.test('end_time', 'invalid time', function (value) {
      const { start_data, end_data, start_time, end_time } = this.parent;
      const startDateObj = new Date(start_data);
      const endDateObj = new Date(end_data);

      if (endDateObj > startDateObj) {
        return true;
      }

      const startTimeObj = new Date(`2023-06-15T${start_time}`);
      const endTimeObj = new Date(`2023-06-15T${end_time}`);

      const startHours = startTimeObj.getHours();
      const startMinutes = startTimeObj.getMinutes();
      const endHours = endTimeObj.getHours();
      const endMinutes = endTimeObj.getMinutes();

      const isValid =
        startHours > endHours || (startHours === endHours && startMinutes > endMinutes);

      return !isValid;
    });
  }),

  status: validateSchema.text,
  categories: validateSchema.categories,
  description: validateSchema.text.max(1000)
});
