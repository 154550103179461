import { useRef } from 'react';

export const useCategories = (categories: any) => {
  const categoriesRef = useRef<HTMLDivElement | null>(null);

  const _categories =
    typeof categories === 'object'
      ? categories ?? []
      : categories === null
      ? []
      : JSON.parse(categories ?? '');

  _categories.sort((a: string, b: string) => a.length - b.length);

  const clientWidth =
    window.innerWidth > 950
      ? 890
      : categoriesRef?.current
      ? categoriesRef?.current.clientWidth
      : 288;

  const maxSymbol =
    _categories.length === 1 ? Math.round(clientWidth / 15) + 5 : Math.round(clientWidth / 15);

  const [index] = _categories.reduce(
    (acc: any, item: string) => {
      const sum = acc[1].length + item.length;

      if (sum <= maxSymbol) {
        return [++acc[0], (acc[1] += item)];
      } else {
        return acc;
      }
    },
    [0, '']
  );

  const categoriesVisible = [..._categories?.splice(0, index)];

  const length = _categories.length;

  return { categoriesVisible, length, categoriesRef };
};
