import { getIn, useFormikContext } from 'formik';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as uuid from 'uuid';

import { Icons } from '../../../../assets';
import { IGallery, ItemImage } from '../../types';
import * as Styled from './gallery.styled';
import { ImageCard } from './image-card';
import { FilePath } from '../../../../utils';

export const Gallery = ({ name, gallery }: IGallery) => {
  const { values, setValues, touched, errors } = useFormikContext();

  const value = getIn(values, name);

  const touche = getIn(touched, name);
  const error = getIn(errors, name);

  const isError = error && touche;

  const [cards, setCards] = useState<ItemImage[] | []>([]);

  const onDelete = (index: number) => {
    if (index > -1) {
      // only splice array when item is found
      setCards((prevValue: any) => {
        prevValue.splice(index, 1);
        return [...prevValue];
      });
    }
  };

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;

    setCards((prevValue: any) => {
      const files = [];
      // @ts-ignore
      for (let i = 0; i < inputFiles.length; i++) {
        // @ts-ignore
        const file = inputFiles[i];
        if (i <= 7) {
          files.push({ id: uuid.v4(), file });
        }
      }

      return [...prevValue, ...files];
    });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: ItemImage[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as ItemImage]
        ]
      })
    );
  }, []);

  useEffect(() => {
    setValues((prevValue: any) => ({ ...prevValue, [name]: [...cards] }));
  }, [cards]);

  useEffect(() => {
    setCards(value);
  }, []);

  useEffect(() => {
    setCards(gallery);
  }, [gallery]);

  const renderCard = useCallback(
    (card: ItemImage, index: number) => (
      <ImageCard
        key={card.id}
        index={index}
        id={card.id}
        path={card.path ? card.path : ''}
        moveCard={moveCard}
        file={card.file}
        onDelete={onDelete}
        setCards={setCards}
      />
    ),
    []
  );

  return (
    <Styled.Container>
      <DndProvider backend={HTML5Backend}>
        <Styled.Div>
          {cards?.length ? cards.map((card: ItemImage, i: number) => renderCard(card, i)) : null}

          {cards?.length < 8 ? (
            <Styled.InputContainer isLength={!!cards.length} isError={isError}>
              {cards.length === 0 ? (
                <Styled.DivNoImage>
                  <img src={FilePath(Icons.noImages)} alt='addIcon' />
                  <Styled.Title>No Images Uploaded</Styled.Title>
                  <Styled.Text>
                    Choose photos from your device and <span>UPLOAD</span>{' '}
                  </Styled.Text>
                </Styled.DivNoImage>
              ) : (
                <img src={FilePath(Icons.addIcon)} alt='addIcon' />
              )}
              <input name={name} type='file' accept='image/*' multiple onChange={onChangeAvatar} />
            </Styled.InputContainer>
          ) : null}

          {isError ? (
            <Styled.ErrorInfoContainer>
              <Styled.ErrorInfoText>{error}</Styled.ErrorInfoText>
            </Styled.ErrorInfoContainer>
          ) : null}
        </Styled.Div>
      </DndProvider>
    </Styled.Container>
  );
};
