import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { jobService } from '../../../services';
import { IAuthError, IJobTypeFormik } from '../../../types';
import { toastContainer } from '../../common/component/toast';
import { APP_KEYS } from '../../common/constants';
import { IMessage } from '../../common/types';
import { urlUtil } from '../../home-layout/utils';

const onError = (_err: AxiosError<IAuthError>, history: any) => {
  const err = _err.response?.data as IAuthError;

  toastContainer.error({ title: err.message ?? _err.message });
  history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
};

const onSuccess = async ({ message }: IMessage, client: any) => {
  toastContainer.success({ title: message });
  await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_JOBS]);
};

const onSuccessDelete = async ({ message }: IMessage, history: any, client: any) => {
  toastContainer.success({ title: message });
  await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ALL_JOBS);
  history.push(urlUtil(APP_KEYS.ROUTER_KEYS.PAGE));
};

const onSuccessEdit = async (data: IMessage, client: any) => {
  await onSuccess(data, client);
  await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_JOB);
};

export const createJobBy = () => {
  const history = useHistory();
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IJobTypeFormik>(
    (createData: IJobTypeFormik) => jobService.createJob(createData),
    {
      onSuccess: (data) => onSuccess(data, client),
      onError: (err: AxiosError<IAuthError>) => onError(err, history)
    }
  );
};

export const editJobById = (id: string) => {
  const history = useHistory();
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IJobTypeFormik>(
    (createData: IJobTypeFormik) => jobService.editJob(createData, id),
    {
      onSuccess: (data) => onSuccessEdit(data, client),
      onError: (err: AxiosError<IAuthError>) => onError(err, history)
    }
  );
};

export const deleteJobById = (id: string) => {
  const history = useHistory();
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>>(() => jobService.deleteJob(id), {
    onSuccess: (data) => onSuccessDelete(data, history, client),
    onError: (err: AxiosError<IAuthError>) => onError(err, history)
  });
};
