import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { IJobType } from '../../../../types';
import { hourUtil, isHiringDateAndTime } from '../../../../utils';
import * as CommonStyled from '../../../account-settings/component/my-job/my-job.styled';
import {
  ArrowBack,
  DeleteLayout,
  Description,
  Drawer,
  GalleryPopup,
  Loader,
  NotFoundItem,
  Portal
} from '../../../common/component';
import {
  ButtonContainer,
  Categories,
  IconsCard,
  ImageGeller,
  TextContainer
} from '../../../common/component/public-page/public-page';
import { toastContainer } from '../../../common/component/toast';
import { APP_KEYS } from '../../../common/constants';
import { useConnectionSocket, useScrollStart } from '../../../common/hooks';
import { getFavoriteById, toggleFavorite } from '../../../favorites/hooks';
import { useHomeLayoutContext } from '../../../home-layout/hooks';
import { urlUtil } from '../../../home-layout/utils';
import { JobCard } from '../../../job/component';
import { deleteEventById, getEventById } from '../../hooks';
import { CreateEvent } from '../create-event/create-event';
import * as Styled from './event-public.styled';

export const EventPublic = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { user } = useHomeLayoutContext();
  const { socket } = useConnectionSocket(user?.id);
  useScrollStart();

  const id = search.split('=')[1];

  const [editComponent, setEditComponent] = useState<null | string>(null);
  const [index, setIndex] = useState(0);

  const { isLoading, data } = getEventById(id);
  const isUser = user?.id === data?.event.user_id;

  const { mutate: editDelete } = deleteEventById(id);
  const { data: dataFavorite } = getFavoriteById(id, 'Events', !isUser);
  const { mutate: createFavorite } = toggleFavorite(id, data?.event.user_id, socket);

  const abouts = [
    {
      icon: Icons.calendarIcon,
      title: 'Date & Time',
      text: `${data?.event.start_data} - ${data?.event.end_data}, ${hourUtil(
        data?.event.start_time
      )}-${hourUtil(data?.event.end_time)}`
    },
    { icon: Icons.venueIcon, title: 'Venue', text: data?.event.venue }
  ];

  const onGallery = (str: string | null) => {
    setEditComponent(str);
  };

  const onImage = (str: string | null, i: number) => {
    setEditComponent(str);
    setIndex(i);
  };

  const onDelete = () => {
    editDelete();
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toastContainer.success({ title: 'LINK COPIED!' });
  };

  const onBack = () => {
    history.goBack();
  };

  const onFavorite = async () => {
    await createFavorite({
      role: 'Events',
      user_id: user?.id,
      owner_id: data?.event.id as string,
      to_user_id: data?.event.user_id
    });
  };

  const onPushPublicJob = (_id: string) => {
    history.push(`${urlUtil(APP_KEYS.ROUTER_KEYS.PUBLIC_JOB)}?id=${_id}`);
  };

  if (isLoading) {
    return <Loader size='medium' height='auto' margin='20%' color={COLORS.purple} />;
  }

  const dataImages = data?.event?.images && data?.event?.images?.sort((a, b) => a.index - b.index);
  // @ts-ignore
  const isFavorite = dataFavorite?.favorite === null;
  const favorite = isFavorite ? Icons.favoriteIcon2 : Icons.favoriteIcon;

  const isHiring =
    data?.event.status === 'Hiring' &&
    isHiringDateAndTime(data?.event.end_data, data?.event.end_time);

  const onToUser = () => {
    const links: { [key: string]: any } = {
      VENDOR: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENDOR}?id=${data?.user.owner_id}`,
      VENUE: `${APP_KEYS.ROUTER_KEYS.PUBLIC_VENUE}?id=${data?.user.owner_id}`,
      TALENT: `${APP_KEYS.ROUTER_KEYS.PUBLIC_TALENT}?id=${data?.user.owner_id}`,
      'EVENT PLANNER': `${APP_KEYS.ROUTER_KEYS.PUBLIC_EVENT_PLANER}?id=${data?.user.owner_id}`
    };

    // @ts-ignore
    const link = links[data?.user.role];

    history.push(urlUtil(link));
  };

  return (
    <Styled.Container>
      <ArrowBack
        onClick={onBack}
        isButton={{
          flag: true,
          isUser,
          isHiring,
          favorite,
          onCopyLink,
          onFavorite,
          onEdit: onGallery
        }}
      />

      <ImageGeller images={dataImages} onClick={onImage} text='gallery' />

      <Styled.Title>{data?.event?.name}</Styled.Title>

      <TextContainer isSpace isBorder margin='0' padding='0'>
        <Styled.Div>
          <Styled.Status isColor={isHiring}>
            {isHiring ? data?.event.status : 'Closed'}
          </Styled.Status>
        </Styled.Div>

        <ButtonContainer
          isUser={isUser}
          onFavorite={onFavorite}
          favorite={favorite}
          isAdmin={user?.role_site === 'admin'}
          isFavorite={isFavorite}
          onCopyLink={onCopyLink}
          onEdit={onGallery.bind(this, 'edit')}
        />
      </TextContainer>

      <TextContainer text='About' />
      <IconsCard styled={Styled.IconsCardStyled} abouts={abouts} />

      <TextContainer text='Description' margin={`${SPACES.xxxxl} 0`} />
      <Description description={data?.event?.description} />
      <TextContainer text='Categories' margin={`${SPACES.xxxxl} 0`} />
      <Categories categories={data?.event.categories} />
      <TextContainer
        text='Planners'
        isBorder
        padding='0'
        margin={`${SPACES.xxxxxxl_} 0 ${SPACES.l} 0`}
      />

      <Styled.UserPlaner isRole={false} onClick={onToUser}>
        <Styled.Div>
          <Styled.UserImage
            src={data?.user?.avatar ? data?.user?.avatar : Png.defaultBlack}
            alt='user-avatar'
          />
          <div>
            <Styled.SubTitle className='title'>
              {data?.user?.first_name} {data?.user?.last_name}
            </Styled.SubTitle>
            <Styled.UserRole>{data?.user?.role}</Styled.UserRole>
          </div>
        </Styled.Div>
        <Styled.Div>
          <Styled.Text>5.0</Styled.Text>
          <img src={Icons.starIcon} alt='star' />
        </Styled.Div>
      </Styled.UserPlaner>

      <TextContainer text='Current Roles' />

      {data?.jobs.length && data?.event.status === 'Hiring' ? (
        data.jobs &&
        data.jobs.map((job: IJobType, _index: number) => (
          <Styled.EventDiv key={_index}>
            <Styled.EventDiv2 onClick={onPushPublicJob.bind(this, job.id)}>
              <JobCard data={job} />
            </Styled.EventDiv2>
            <CommonStyled.Line className='line' />
          </Styled.EventDiv>
        ))
      ) : (
        <Styled.DivEvent>
          <NotFoundItem
            title={isHiring && !data?.jobs.length ? '' : 'The Event Is Closed'}
            subTitle='There are currently no open positions for this event.'
          />
        </Styled.DivEvent>
      )}

      {editComponent && (
        <Drawer
          onClose={onGallery.bind(this, null)}
          open={!!editComponent}
          slidePosition='bottom'
          contentPosition='bottom'
        >
          <Portal>
            {editComponent === 'gallery' && (
              <GalleryPopup
                images={dataImages}
                index={index}
                onClose={onImage.bind(this, null, 0)}
              />
            )}

            {editComponent === 'edit' && (
              <CreateEvent
                onDelete={onGallery.bind(this, 'delete')}
                isEdit
                isRepost={!isHiring}
                onCancel={onGallery.bind(this, null)}
              />
            )}
            {editComponent === 'delete' && (
              <DeleteLayout onSave={onDelete} onCancel={onGallery.bind(this, null)} />
            )}
          </Portal>
        </Drawer>
      )}
    </Styled.Container>
  );
};
