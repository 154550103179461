import React from 'react';

import * as Styled from './slider.styled';

export interface ISlider {
  min: number;
  max: number;
  value: any;
  onChange: (e: any) => void;
  text?: string;
}

export const Slider = ({ max, min, onChange, value, text }: ISlider) => (
  <Styled.CustomSliderDiv>
    <span>-</span>
    <Styled.Div>
      {text && <Styled.Text>{text}</Styled.Text>}

      <Styled.CustomSlider
        min={min}
        max={max}
        sx={{
          margin: '0 auto',
          width: '80%',
          color: 'cyan'
        }}
        size='medium'
        defaultValue={value}
        value={value}
        onChange={(e: any) => onChange(e.target?.value)}
      />
    </Styled.Div>

    <span>+</span>
  </Styled.CustomSliderDiv>
);
