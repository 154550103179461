import { AxiosError } from 'axios';
import { Formik, getIn } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Icons } from '../../../../assets';
import { reviewService } from '../../../../services';
import { SPACES } from '../../../../theme';
import { IAuthError, ICreateReview } from '../../../../types';
import { InputTextArea, PopupLayout } from '../../../common/component';
import { toastContainer } from '../../../common/component/toast';
import { IMessage } from '../../../common/types';
import { IReviewCreate } from '../../types';
import { validationSchemaCreateReview } from '../../validation/shema';
import * as Styled from './review-create.styled';
import { FilePath } from '../../../../utils';

export const ReviewCreate = ({
  socket,
  onCancel,
  component,
  to_user_id,
  key_refetch,
  id,
  full_name
}: IReviewCreate) => {
  const client = useQueryClient();

  const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;

    toastContainer.error({ title: err.message ?? _err.message });
  };

  const onSuccess = async ({ message }: IMessage) => {
    toastContainer.success({ title: message });

    onCancel();
    await client.invalidateQueries([key_refetch, id]);
    socket.emit('send_notification', { to_user_id });
  };

  const { mutate: createReview } = useMutation<any, AxiosError<IAuthError>, ICreateReview>(
    (createData: ICreateReview) => reviewService.createReview(createData),
    {
      onSuccess,
      onError
    }
  );

  const onSubmit = (_data: any) => {
    createReview({
      ..._data,
      role: component,
      to_user_id,
      owner_id: id,
      name: full_name
    });
  };

  return (
    <Formik
      initialValues={{
        is_worked: false,
        description: '',
        rating: 0
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchemaCreateReview}
    >
      {({ isValid, values, setValues, errors, touched }) => (
        <Styled.CommonForm>
          <PopupLayout
            onCancel={onCancel}
            onSave={() => {}}
            type='submit'
            disabled={!isValid}
            title='Leave Review'
            saveText='Submit'
            isValid={Object.keys(touched).length ? Object.keys(errors).length === 0 : true}
          >
            <Styled.TextContainer>
              <Styled.SubTitle>Have You Worked With {full_name} ?</Styled.SubTitle>
            </Styled.TextContainer>

            <Styled.BtnContainer>
              {[
                { text: 'NO', flag: false },
                { text: 'YES', flag: true }
              ].map(({ text, flag }, index) => (
                <Styled.Btn
                  key={index}
                  isSelect={getIn(values, 'is_worked') === flag}
                  onClick={() => setValues((v: any) => ({ ...v, is_worked: flag }))}
                >
                  {text}
                </Styled.Btn>
              ))}
            </Styled.BtnContainer>

            <Styled.TextContainer>
              <Styled.SubTitle>Rating</Styled.SubTitle>
            </Styled.TextContainer>

            <Styled.StarRatingDiv>
              {[...Array(5)].map((_, i) => {
                const ratingValue = i + 1;
                const value = getIn(values, 'rating');

                return (
                  <label>
                    <input
                      type='radio'
                      name='rating'
                      value={value}
                      onClick={() => setValues((v: any) => ({ ...v, rating: ratingValue }))}
                    />

                    <Styled.Icon
                      isSelect={ratingValue <= value}
                      className='icon'
                      style={{
                        WebkitMaskImage: `url(${FilePath(Icons.starIcon)})`,
                        WebkitMaskSize: '100% 100%',
                        maskImage: `url(${FilePath(Icons.starIcon)})`
                      }}
                    />
                  </label>
                );
              })}
            </Styled.StarRatingDiv>

            <Styled.TextContainer>
              <Styled.SubTitle>Write Review</Styled.SubTitle>
            </Styled.TextContainer>

            <InputTextArea
              name='description'
              placeholder='Tell us more about your experience'
              rows={3}
              label='Your Review'
              maxLength={1000}
              mt={SPACES.xxxxl}
            />
          </PopupLayout>
        </Styled.CommonForm>
      )}
    </Formik>
  );
};
